
@mixin lc-iframe() {

	iframe {

		max-width: 100%;
		border: none;

	}

}


@include lc-iframe;

