.footer__info {

	@include breakpoint( tb ) {

		display: flex;
		justify-content: space-evenly;

	}

}
