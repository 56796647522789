
$spacing: rem-calc( 5px );

.product-card {

	background: $white;
	border-radius: 3px;
	border: 1px solid $light-grey;
	box-shadow: 0 2px 2px rgba( 0, 0, 0, 0.05 );
	transition: all $default-time $lc-ease;

	padding: $spacing;
	display: inline-flex;
	align-items: stretch;
	position: relative;

	&:hover {

		border-color: $grey;
		box-shadow: 0 2px 2px rgba( 0, 0, 0, 0.1 );

	}

	&__link {

		display: flex;
		align-items: stretch;
		position: relative;

	}

	&__image {

		max-width: rem-calc( 66px );
		min-width: rem-calc( 66px );

		@include breakpoint( tb ) {

			max-width: rem-calc( 80px );
			min-width: rem-calc( 80px );

		}

	}

	&__details {

		padding: $spacing 0 $spacing $spacing;
		text-align: left;
		line-height: 1.2;

		* {

			@extend %font-lc;

		}

	}

	&__brand {

		font-size: rem-calc( 14px );
		font-weight: bold;
		margin: 0 0 $spacing;
		padding-right: rem-calc( 40px );

	}

	&__description {

		font-size: rem-calc( 12px );
		font-weight: normal;
		margin: 0 0 $spacing;
		padding-right: rem-calc( 40px );
		color: $very-dark;

	}

	&__price {

		font-size: rem-calc( 12px );
		font-family: "Lane Crawford";
		margin: 0 0 $spacing;
		font-weight: bold;

	}

}
