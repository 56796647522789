
@import "foot/social-follow";
@import "foot/info";



.country-change-en__btn,
.newsletter__subscribe-btn {

	@include breakpoint( tb ) {

		width: 380px;

	}

}

.country-change-en__btn {

	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;

}

// override for footer specific label border color
.country-change__country .lc-field__select-label,
.country-change__language .lc-field__select-label {

	border-color: black;

}

@media print {

	.desktop-needhelp__email:after {

		content: "customercare@lanecrawford.com";

	}

	body[ data-site-id = "zh_CN" ] .desktop-needhelp__email:after {

		content: "customercare@lanecrawford.com.cn";

	}

}
