
// we define the scale of font sizes we want
// to be able to use across the LC website first

$scale: 12, 14, 16, 18, 20, 24, 28, 32;

// mixin to be included for all the breakpoints

@mixin lc-text-sizes( $bp: "" ) {

	// loop through all the sizes in our scale, and
	// generate css classes for each one.

	@each $size in $scale {

		.#{$bp}txt-#{$size} {

			font-size: rem-calc( $size );

		}

	}

	.#{$bp}txt-none {

		font-size: inherit;

	}

}




// output styles in to breakpoints

@include lc-text-sizes;

@each $size in $breakpoint-classes {

	@include breakpoint($size) {

		@if $size != "mb" {

			@include lc-text-sizes( $size + "-" );

		}

	}

}

