
.site-header {

	z-index: 11;

	@include breakpoint( mb only ) {

		min-height: 60px;

	}

}


@include breakpoint( mb only ) {

	.site-header--fixed {

		position: sticky;
		top: -1px;
		z-index: 21;

	}

}
