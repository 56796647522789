
// we define the scale of radius we want

$radius-scale: 0, 2, 3, 5;

// mixin to be included for all the breakpoints

@mixin lc-radius() {

	// loop through all the positions and create
	// css classes to use

	@each $radius in $radius-scale {

		.rad-#{ $radius } {

			border-radius: $radius + px;

		}

	}

	.rad-c {

		// circle/ellipse shape
		border-radius: 100%;

	}

	.rad-p {

		// pill shape
		border-radius: 200px;

	}

	.rad-gc {

		// giftcard shape
		border-radius: 4.5% / 6.5%;

	}

}



// output styles in to breakpoints

@include lc-radius;
