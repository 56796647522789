

.lc-flag {

	font-size: rem-calc( 12px );
	line-height: rem-calc( 22px );
	display: inline-block;

	border: 1px solid;
	padding: 0 0.5rem;

	&--disabled {

		opacity: 0.3;

	}

}
