

$disabled-border: $light-grey;
$disabled-text: $grey;


button,
input,
select,
textarea {

	outline: 0;

	// this is needed because of recent
	// changes to the normalise.css library
	// -- maybe can delete when Foundation updates
	font-weight: inherit;
	color: inherit;

}

input,
select,
textarea,
option,
optgroup {

	// this is needed because of recent
	// changes to the normalise.css library
	// -- maybe can delete when Foundation updates
	font-family: inherit;

}

button,
input,
optgroup,
select,
textarea {

	// this is needed because of recent
	// changes to normalise.css in foundation
	line-height: normal;

}


@import "mixins";
@import "buttons";
@import "checkboxes";
@import "fieldsets";
@import "fields";
@import "labels";
@import "inputs";
@import "placeholders";
@import "radios";
@import "selects";
@import "textareas";
@import "autocomplete";
@import "errors";
@import "toggles";
