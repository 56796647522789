
// minicart only available on desktop.

@include breakpoint( tb ) {

	.minicart-item {

		position: relative;
		padding: 10px;
		overflow: hidden;
		transition: background-color 3s ease-in;

		&:not( :last-of-type ):after {

			content: "";
			position: absolute;
			left: 10px;
			right: 10px;
			bottom: 0;
			height: 1px;
			background: black;

		}

		&--highlight {

			background-color: $light-grey;
			transition: background-color 0.2s ease-out;

		}

	}

	.minicart-item__image,
	.minicart-item__details {

		float: left;

	}

	.minicart-item__image {

		width: 20%;

	}

	.minicart-item__details {

		width: 80%;
		padding-left: 1em;

	}

	.minicart-item__brand {

		max-width: 200px;

	}

	.minicart-item__description {

		max-width: 90%;

	}

	.minicart-item__meta-row {

		margin-top: 1em;
		min-height: 2em;

	}

	.minicart-item__meta-row--sale {

		margin-top: 2.4em;

	}

	.minicart-item__size,
	.minicart-item__quantity {

		text-transform: uppercase;

	}

	.minicart-item__size {

		float: left;

	}

	.minicart-item__price {

		text-transform: uppercase;

	}

	.minicart-item__sale-price {

		margin-top: -1.2em;

	}

	.minicart-item__flag {

		font-size: 10px;
		line-height: 19px;

	}

	.minicart-item__remove-icon {

		position: absolute;
		right: 0;
		top: 0.4em;
		padding: 3px 10px;
		cursor: pointer;

		.minicart__items--scroll & {

			right: 2px;

		}

		&.disabled {

			background: none;
			border: none;
			opacity: 0.3;

		}

	}

	.minicart-item__spinner {

		text-align: center;
		padding: 1.5em 0;
		opacity: 0.5;

		transition: opacity 0.3s ease;

	}


	.minicart-item__price,
	.minicart-item__quantity {

		position: absolute;
		right: 10px;

		.minicart__items--scroll & {

			right: 14px;

		}

	}



	//	.minicart-item--unavailable {
	//
	//	}


}
