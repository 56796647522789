
// active style labels

.lc-label,
.mb-lc-label,
.tb-lc-label,
.dt-lc-label {

	&:hover,
	&:focus,
	&:active,
	&.lc-label--active {

		color: $label-active;
		opacity: $label-opacity-active;
		box-shadow: $label-shadow-active;

		&.lc-label--inset {

			box-shadow: $label-shadow-active-inset;

			&.is-error {

				box-shadow: 0 0 0 2px #ca0814;

			}

		}

	}

}
