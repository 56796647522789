
// import the variables from ICOMOON output.
// this is a list of sass variables we can use below in our
// css definitions.

// each variable maps directly to the icon's name in ICOMOON,
// but with an extra "$icon-" in front.

@import "src/icons/variables";

// which small font-sizes we want to be
// able to use with icons. (uses scaling)
$smallicons: 10, 9, 8, 7, 6;

// mixin to allow icons be smaller than 12px;
@mixin lc-small-icon( $size ) {

	font-size: rem-calc( 20px );
	transform: scale( $size );

}

// icons that are grouped in to
// sets of prefixed icon names, where
// the group-name is the prefix name;
// 		social, facebook  ->  social-facebook
$groupedicons: (

	wash: (
		a:		$icon-care-1a,
		b:		$icon-care-1b,
		c:		$icon-care-1c,
		d:		$icon-care-1d,
		e:		$icon-care-1e,
		f:		$icon-care-1f,
		g:		$icon-care-1g,
		h:		$icon-care-1h,
		i:		$icon-care-1i,
		j:		$icon-care-1j,
		k:		$icon-care-1k,
		l:		$icon-care-1l,
		m:		$icon-care-1m,
		n:		$icon-care-1n,
	),

	bleach: (
		a: 		$icon-care-2a,
		b: 		$icon-care-2b,
		c: 		$icon-care-2c,
	),

	airDry: (
		a: 		$icon-care-3a,
		b: 		$icon-care-3b,
		c: 		$icon-care-3c,
		d: 		$icon-care-3d,
		e: 		$icon-care-3e,
		f: 		$icon-care-3f,
		g: 		$icon-care-3g,
		h: 		$icon-care-3h,
	),

	tumbleDry: (
		a: 		$icon-care-4a,
		b: 		$icon-care-4b,
		c: 		$icon-care-4c,
	),

	iron: (
		a:		$icon-care-5a,
		b:		$icon-care-5b,
		c:		$icon-care-5c,
		d:		$icon-care-5d,
	),

	dryClean: (
		a:		$icon-care-6a,
		b:		$icon-care-6b,
		c:		$icon-care-6c,
		d:		$icon-care-6d,
		e:		$icon-care-6e,
		f:		$icon-care-6f,
		g:		$icon-care-6g,
		h:		$icon-care-6h,
	),

	arrow: (
		up: 			$icon-arrow-up,
		down: 			$icon-arrow-down,
		left: 			$icon-arrow-left,
		right: 			$icon-arrow-right,
		up-thick: 		$icon-arrow-up-thick,
		down-thick: 	$icon-arrow-down-thick,
		left-thick: 	$icon-arrow-left-thick,
		right-thick: 	$icon-arrow-right-thick,
	),

	social: (
		facebook: 				$icon-social-facebook,
		facebook-standalone: 	$icon-social-facebook-standalone,
		google-plus: 			$icon-social-google-plus,
		instagram: 				$icon-social-instagram,
		pinterest: 				$icon-social-pinterest,
		renren: 				$icon-social-renren,
		twitter: 				$icon-social-twitter,
		wechat: 				$icon-social-wechat,
		weibo: 					$icon-social-weibo,
		whatsapp: 				$icon-social-whatsapp,
		youtube: 				$icon-social-youtube,
		apple:					$icon-social-apple,
	)

);

$icons: (

	bag: 						$icon-bag-empty,
	bag-empty: 					$icon-bag-empty,
	bag-filled: 				$icon-bag-filled,
	bag-move: 					$icon-bag-move,
	bag-add: 					$icon-bag-add,
	customer-care-service: 		$icon-customer-care-service,
	clock: 						$icon-clock,
	close: 						$icon-cross,
	conversion: 				$icon-conversion,
	cross: 						$icon-cross,
	delivery: 					$icon-delivery,
	edit: 						$icon-pencil-edit,
	envelope: 					$icon-envelope,
	exchange: 					$icon-exchange,
	exclamation-mark-circle:	$icon-exclamation-mark-circle,
	eye: 						$icon-eye,
	gift: 						$icon-gift,
	giftcard: 					$icon-giftcard,
	heart-empty: 				$icon-heart-empty,
	heart-filled: 				$icon-heart-filled,
	heart-move: 				$icon-heart-move,
	heart-add: 					$icon-heart-add,
	help: 						$icon-question-mark-circle,
	lc-on-black-circle: 		$icon-lc-on-black-circle,
	lc-location: 				$icon-lc-location,
	location: 					$icon-location,
	minus: 						$icon-minus,
	minus-thick:				$icon-minus-thick,
	mobile-schedule: 			$icon-mobile-schedule,
	nav-mobile: 				$icon-nav-mobile,
	ok: 						$icon-tick,
	parcel: 					$icon-parcel,
	pencil: 					$icon-pencil-edit,
	person-empty: 				$icon-person-empty,
	person-filled: 				$icon-person-filled,
	phone: 						$icon-phone,
	plus-thick: 				$icon-plus-thick,
	plus: 						$icon-plus,
	product-menu: 				$icon-product-menu,
	qr-code-icon: 				$icon-qr-code-icon,
	qr-code-wishlist: 			$icon-qr-code-wishlist,
	question-mark-circle: 		$icon-question-mark-circle,
	question-mark: 				$icon-question-mark,
	refresh: 					$icon-refresh,
	refund: 					$icon-refund,
	return: 					$icon-return,
	ruler: 						$icon-ruler,
	scan: 						$icon-scan,
	search: 					$icon-search,
	shopping-bag: 				$icon-bag-empty,
	size-chart: 				$icon-size-chart,
	sort: 						$icon-sort,
	sorting: 					$icon-sort,
	star-empty: 				$icon-star-empty,
	star-filled: 				$icon-star-filled,
	tick: 						$icon-tick,
	truck: 						$icon-truck,
	wishlist-grid-on: 			$icon-wishlist-grid-on,
	wishlist-list-on: 			$icon-wishlist-list-on,
	zoom-in: 					$icon-zoom-in,
	zoom-out: 					$icon-zoom-out,
	qrcode-checkout: 			$icon-qrcode-checkout,
	locked: 					$icon-locked,
	user-verify: 				$icon-user-verify,
	caution-triangle: 			$icon-caution-triangle,
	padlock: 					$icon-padlock,
	padlock-open: 				$icon-padlock-open,
	share: 						$icon-share
);


// data icon declaration for using
// the lc-icons we generate with icomoon.

.lc-icon {

	display: inline-block;
	vertical-align: middle;
	transition: opacity 0.5s $lc-ease-subtle;

	html:not( .fonts-loaded ) & {

		opacity: 0;

	}

	&[ data-icon ]:before {

		font-family: "lc-icons";
		content: attr( data-icon );
		speak: none;

		font-weight: normal;
		font-style: normal;

		line-height: inherit;
		vertical-align: middle;

		position: relative;
		top: rem-calc( -2px );

		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

	}

	&--square,
	&--circle {

		width: 1em;
		height: 1em;
		line-height: 1;
		box-sizing: content-box;

	}

	&--circle {

		border-radius: 100%;

	}

	// allow font sizes of 10/9/8/7/6 for icons only, this is because our
	// arrow icons in the icon font a f%£*&@ huge, and firefox won't allow
	// less than 12px fonts, so we need to scale them down.
	@each $s in $smallicons {

		$multiplier: $s * 0.05;

		&.txt-#{$s} {

			@include lc-small-icon( $multiplier );

		}

	}

	// generate all the grouped
	// icons from the grouped-icons list.
	@each $type in $groupedicons {

		$mapname: nth( $type, 1 );
		$map: nth( $type, 2 );

		@each $icon in $map {

			$iconname: nth( $icon, 1 );
			$iconvalue: nth( $icon, 2 );

			&[ data-icon = "#{ $mapname }-#{ $iconname }" ]:before {

				content: "#{ $iconvalue }";

			}

		}

	}

	// generate all the non-grouped
	// icons from the icons list.
	@each $icon in $icons {

		$iconname: nth( $icon, 1 );
		$iconvalue: nth( $icon, 2 );

		&[ data-icon = "#{ $iconname }" ]:before {

			content: "#{ $iconvalue }";

		}

	}

}

// bump the left/right arrows just slightly,
// to counter the heavy weighting that makes
// them seem off-centered.
.lc-label > .lc-icon[ data-icon *= "arrow-left" ] {

	position: relative;
	left: -1px;

}

.lc-label > .lc-icon[ data-icon *= "arrow-right" ] {

	position: relative;
	left: 1px;

}



// some extra little "shapes" for displaying some stuff
// like the "location" in the store-availability.

.lc-circle,
.lc-square {

	width: 1em;
	height: 1em;
	display: inline-block;
	position: relative;
	top: 0.1em;

	// use "txt-color" to change
	// the colour of this shape
	background-color: currentColor;

}

.lc-circle {

	border-radius: 1em;

}
