
@import "../includes/variables";

/*
 * custom css overrides to make Hotjar
 * components look a bit nicer in the LC UI
 * https://insights.hotjar.com/

 * need to use a very specific base-element
 * id to make sure the specificity is strong enough
 * to override the hotjar css styling.
 */

html#doc[ class ] body[ class ] {

	// Survey Widget
	// https://docs.hotjar.com/docs/how-do-hotjar-surveys-work

	div#_hj_survey_invite_container {

		* {

			font-family: $font-lc !important;
			border-radius: 0 !important;

		}

		// hotjar branding
		[ class *= "survey_powered_by" ] {

			display: none;
			opacity: 0;

		}

		// button style
		[ class *= "_hj" ][ class *= "survey_button" ] {

			background: black !important;
			font-family: $font-lc !important;
			text-transform: uppercase !important;
			letter-spacing: 1px !important;
			font-size: 16px !important;
			padding: 12px 24px !important;
			box-shadow: none !important;

		}

	}

	// Incoming Feedback Widget
	// https://docs.hotjar.com/docs/how-does-hotjar-incoming-feedback-work

	div#_hj_feedback_container {

		// hotjar branding
		[ id *= "_hotjar_branding" ] {

			display: none;
			opacity: 0;

		}

		[ id *= "_feedback_open" ] {

			[ class *= "_emotion_option" ] {

				// eyes/mouth

				* {

					&:before {

						color: white;

					}

				}

				// face background

				.path1 {

					&:before {

						color: $lc-beta;

					}

				}

			}

		}

	}

}
