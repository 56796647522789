


// we define the types of display we want

$scale: (
	i: inline,
	ib: inline-block,
	b: block,
	tb: table,
	tc: table-cell,
	n: none,
	f: flex,
	if: inline-flex
);

$sites: (
	hk: zh_HK,
	cn: zh_CN,
	row: en_US
);

$platforms: (
	app: lc-app,
	wechat: wechat-inapp,
	web: web
);

// mixin to be included for all the breakpoints

@mixin lc-display( $bp: "" ) {

	// loop through all the displays and create
	// css classes to use

	@each $size in $scale {

		$name: nth( $size, 1 );
		$val: nth( $size, 2 );

		.#{$bp}disp-#{$name} {

			display: $val;

		}

	}

}

@mixin lc-sites() {

	@each $site in $sites {

		$site-name: nth( $site, 1 );
		$site-id: nth( $site, 2 );

		body[ data-site-id = "#{ $site-id }" ] .hide-for-#{ $site-name } {

			display: none !important;

		}

		@each $compare-site in $sites {

			$compare-site-name: nth( $compare-site, 1 );
			$compare-site-id: nth( $compare-site, 2 );

			@if $compare-site-name != $site-name {

				body[ data-site-id = "#{ $compare-site-id }" ] .show-for-#{ $site-name } {

					display: none !important;

				}

			}

		}

	}

}

@mixin lc-platforms() {

	@each $platform in $platforms {

		$platform-name: nth( $platform, 1 );
		$platform-class: nth( $platform, 2 );

		html:not( .#{ $platform-class } ) .show-for-#{ $platform-name } {

			display: none !important;

		}

		html.#{ $platform-class } .hide-for-#{ $platform-name } {

			display: none !important;

		}

	}

}




// output styles in to breakpoints

@include lc-display;
@include lc-sites;
@include lc-platforms;

@each $size in $breakpoint-classes {

	@include breakpoint($size) {

		@if $size != "mb" {

			@include lc-display( $size + "-" );

		}

	}

}

@include breakpoint( mb only ) {

	@include lc-display( "mb-only-" );

}

@include breakpoint( tb only ) {

	@include lc-display( "tb-only-" );

}
