
//
// RADIO HTML STRUCTURE
//
// <div class="lc-field">
//   <label class="lc-field__radio-label">
// 	   <input type="radio" class="lc-field__radio" name="radioGroup">
// 	   <span class="lc-field__radio-fake"></span>
// 	   <span class="lc-field__radio-text">
// 	     radio text
// 	   </span>
// 	 <label>
// </div>
//

//
// CHECKBOX HTML STRUCTURE
//
// <div class="lc-field">
//   <label class="lc-field__checkbox-label">
// 	   <input type="checkbox" class="lc-field__checkbox" name="checkbox">
// 	   <span class="lc-field__checkbox-fake"></span>
// 	   <span class="lc-field__checkbox-text">
// 	     checkbox text
// 	   </span>
// 	 <label>
// </div>
//

//
// GENERAL CONTENT HTML STRUCTURE
//
//  - using content/image to present the ui
//    instead of showing a checkbox/radio
//
// <div class="lc-field">
//   <label class="lc-field__radio-label">
//    <input type="radio" class="lc-field__radio" name="xxx">
//    <div class="lc-field__radio-content">
//
//     <img class="disp-b" src="content image">
//     <p>content text</p>
//
//    </div>
//   </label>
// </div>
//


$size: 1.4em;
$size-small: 1em;
// size is relative to the current font size

$gap: rem-calc( 4px );
$gap-small: rem-calc( 2px );


@mixin check-radio( $name ) {

	.lc-field__#{ $name }-label,
	.lc-field__#{ $name }-label * {

		user-select: none;

	}

	.lc-field__#{ $name } {

		appearance: none;
		opacity: 0;
		position: absolute;
		margin-left: -9999em;

		&-fake {

			width: $size;
			height: $size;
			vertical-align: middle;

			border-radius: 100%;
			box-shadow: 0 0 0 1px $black;

			display: inline-block;
			position: absolute;
			left: 0;
			top: 0.05em;

			transition: box-shadow 0.2s $lc-ease-subtle;

			&:after {

				content: "";
				top: $gap;
				left: $gap;
				right: $gap;
				bottom: $gap;
				position: absolute;

				background: $black;
				border-radius: 100%;

				opacity: 0;
				transition: opacity 0.2s $lc-ease-subtle;

			}

			&.is-error {

				box-shadow: 0 0 0 1px $red;

			}

		}

		&-text {

			display: inline-block;
			vertical-align: middle;
			margin-left: 2.5em;

		}

		&-image,
		&-content {

			box-shadow: 0 0 0 1px transparent;
			transition: box-shadow 0.2s $lc-ease-subtle;
			cursor: pointer;

			&.is-error {

				box-shadow: 0 0 0 2px $red;

			}

		}

		&-content {

			box-shadow: 0 0 0 1px $grey;

		}

		&:checked ~ &-fake {

			box-shadow: 0 0 0 2px $black;

			&:after {

				opacity: 1;

			}

			&.is-error {

				box-shadow: 0 0 0 2px $red;

			}

		}

		&:focus ~ &-fake {

			background-color: rgba( 0, 0, 0, 0.075 );
			box-shadow: 0 0 0 2px $black, 0 0 0 3px $light-grey;

			&.is-error {

				box-shadow: 0 0 0 2px $red, 0 0 0 3px $light-grey;

			}

		}

		&:checked ~ &-image,
		&:checked ~ &-content {

			box-shadow: 0 0 0 2px $black;

		}

		&:checked ~ &-content {

			font-weight: bold;

		}

		&.is-error,
		&.errorBorder,
		&:invalid {

			box-shadow: 0 0 0 1px $red;

		}


	}

	.lc-field__#{ $name }-label {

		display: inline-block;
		position: relative;

		&:hover > .lc-field__#{ $name }-fake,
		&:hover > .lc-field__#{ $name }-image,
		&:hover > .lc-field__#{ $name }-content {

			box-shadow: 0 0 0 2px $black;

		}

		&.is-disabled .lc-field__#{ $name }-fake,
		&.is-disabled .lc-field__#{ $name }-image,
		&.is-disabled .lc-field__#{ $name }-content,
		&.disabled .lc-field__#{ $name }-fake,
		> .lc-field__#{ $name }-fake.disabled,
		> .lc-field__#{ $name }-fake.is-disabled,
		> .lc-field__#{ $name }[ disabled ] ~ .lc-field__#{ $name }-fake,
		> .lc-field__#{ $name }[ disabled ] ~ .lc-field__#{ $name }-image,
		> .lc-field__#{ $name }[ disabled ] ~ .lc-field__#{ $name }-content {

			opacity: 0.35;
			cursor: default;

		}

		&--small .lc-field__#{ $name }-fake {

			width: $size-small;
			height: $size-small;
			top: 0.25em;

			&:after {

				top: $gap-small;
				left: $gap-small;
				right: $gap-small;
				bottom: $gap-small;

			}

		}

		&--small .lc-field__#{ $name }-text {

			margin-left: 1.75em;

		}

	}

}
