.payment-logo {

	background-image: lc-image( "paymentCards.png" );
	display: inline-block;
	min-height: 48px;
	width: 65px;
	height: 42px;

	&__master {

		background-position: -5px -4px;

	}

	&__visa {

		background-position: -84px -4px;

	}

	&__ae {

		background-position: -158px -4px;

	}

	&__jcb {

		background-position: -229px -4px;

	}

	&__union {

		background-position: -299px -4px;

	}

	&__alipay {

		background-position: -452px -4px;

	}

	&__wechat-pay {

		background-position: -380px -4px;

	}

}
