


// we define the types of positions we want

$border-scale: 0, 1, 2, 3;

$border-colours: $lc-txt-colours;

$border-styles: (
    dot: dotted,
    dash: dashed,
    sol: solid,
    none: none
);

$border-coords: (
    t: top,
    r: right,
    b: bottom,
    l: left
);


// mixin to be included for all the breakpoints

@mixin lc-border( $bp: "" ) {

	// loop through all the positions and create
	// css classes to use

	@each $size in $border-scale {

		.#{$bp}bor-#{$size} {

			border: rem-calc( $size ) solid;

		}

		@each $coord in $border-coords {

			$name: nth( $coord, 1 );
			$val: nth( $coord, 2 );

			.#{$bp}bor-#{$name}#{$size} {

				border-#{$val}: rem-calc( $size ) solid;

			}

		}

		.#{$bp}bor-v#{$size} {

			border-top: rem-calc( $size ) solid;
			border-bottom: rem-calc( $size ) solid;

		}

		.#{$bp}bor-h#{$size} {

			border-left: rem-calc( $size ) solid;
			border-right: rem-calc( $size ) solid;

		}

	}

	@each $colour in $border-colours {

		$name: nth( $colour, 1 );
		$val: nth( $colour, 2 );

		.#{$bp}bor-#{$name} {

			border-color: $val;

		}

	}

	@each $style in $border-styles {

		$stylename: nth( $style, 1 );
		$styleval: nth( $style, 2 );

		.#{$bp}bor-#{$stylename} {

			border-style: $styleval;

		}

		@each $coord in $border-coords {

			$coordname: nth( $coord, 1 );
			$coordval: nth( $coord, 2 );

			.#{$bp}bor-#{$coordname}#{$stylename} {

				border-#{$coordval}-style: $styleval;

			}

		}

		.#{$bp}bor-v#{$stylename} {

			border-top-style: $styleval;
			border-bottom-style: $styleval;

		}

		.#{$bp}bor-h#{$stylename} {

			border-left-style: $styleval;
			border-right-style: $styleval;

		}

	}

}



// output styles in to breakpoints

@include lc-border;

@each $size in $breakpoint-classes {

	@include breakpoint($size) {

		@if $size != "mb" {

			@include lc-border( $size + "-" );

		}

	}

}

@include breakpoint(mb only) {

	@include lc-border( mb-only- );

}

@include breakpoint(tb only) {

	@include lc-border( tb-only- );

}
