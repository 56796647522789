
// we define the types of alignment we want

$align-coords: (
	l: left,
	r: right,
	c: center,
	j: justify
);

// we define the types of vertical alignment we want

$valign-coords: (
	t: 		top,
	b: 		bottom,
	m: 		middle,
	base: 	baseline,
	sub: 	sub,
	sup: 	super,
);


// mixin to be included for all the breakpoints

@mixin lc-align( $bp: "" ) {

	// loop through all the alignmnets and create
	// css classes to use

	@each $coord in $align-coords {

		$name: nth( $coord, 1 );
		$val: nth( $coord, 2 );

		.#{$bp}txt-#{$name} {

			text-align: $val;

		}

	}

}


// mixin to be included for all the breakpoints

@mixin lc-valign( $bp: "" ) {

	// loop through all the alignmnets and create
	// css classes to use

	@each $coord in $valign-coords {

		$name: nth( $coord, 1 );
		$val: nth( $coord, 2 );

		.#{$bp}va-#{$name} {

			vertical-align: $val;

		}

	}

}


// output styles in to breakpoints

@include lc-align;
@include lc-valign;

@each $size in $breakpoint-classes {

	@include breakpoint($size) {

		@if $size != "mb" {

			@include lc-align( $size + "-" );

		}

	}

}

@include breakpoint(mb only) {

	@include lc-align( mb-only- );

}

@include breakpoint(tb only) {

	@include lc-align( tb-only- );

}
