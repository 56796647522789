
$table-border-color: $black;
$cell-padding: rem-calc( 5px );
$cell-bg-color: $diamond;


@mixin lc-table( $bp: "" ) {

	.lc-table-elegant {

		th,
		td {

			padding: ( $cell-padding * 2 ) $cell-padding;

		}

		thead {

			th,
			td {

				border-bottom: 1px solid $table-border-color;
				border-top: 2px solid $table-border-color;
				padding: 1em $cell-padding 0.9em;

			}

		}

		th:not( :nth-last-of-type( 1 ) ),
		td:not( :nth-last-of-type( 1 ) ) {

			border-right: 1px solid rgba( $table-border-color, 0.05 );

		}

		tbody tr:nth-of-type( even ) th,
		tbody tr:nth-of-type( even ) td {

			background: $cell-bg-color;

		}

	}

}



@include lc-table;
