

@mixin lc-fields( $bp: "" ) {


	.lc-field {

		display: block;
		position: relative;

		// put a default vertical margin if there's
		// no margin class applied.

		&:not( [ class *= "mar-" ] ) {

			margin-top: rem-calc( 10px );
			margin-bottom: rem-calc( 10px );

		}

		&__input,
		&__select,
		&__textarea,
		&__autocomplete {

			text-align: center;
			text-transform: inherit;

			display: block;
			width: 100%;
			max-width: 100%;
			height: $lc-field-height;
			border-radius: 0;

			&:not( [ class *= "bg-" ] ) {

				background-color: $white;

			}

		}

		&__input,
		&__select,
		&__textarea,
		&__autocomplete,
		* {

			transition:
				border 0.33s $lc-ease-subtle,
				box-shadow 0.66s $lc-ease-subtle;

		}

	}

}

@mixin lc-fields-new( $bp: "" ) {

	.lc-field {

		&__input,
		&__select,
		&__textarea,
		&__autocomplete {

			&--new {

				text-align: left;
				height: $lc-field-height-new;
				line-height: 1.4;

				box-shadow: 0 0 0 3px transparent;

				&:not( [ disabled ] ) {

					&:focus,
					&:active {

						box-shadow: 0 0 0 1px $black;

					}

				}

			}

		}

		&__select--new:not( [ disabled ] ) {

			& ~ .lc-field__select-label {

				box-shadow: 0 0 0 3px transparent;

			}

		}

	}

}


@include lc-fields;
@include lc-fields-new;
