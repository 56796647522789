

$item-height: 38px;
$w-icon: 30px;
$h-icon: 58px;

$ease-in: $lc-ease-in;
$ease-out: $lc-ease-out;
$ease-time: 0.28s;

$notification-size: 8px;


.iconbar {

	position: absolute;
	right: 0;
	left: 0;
	top: 0;

	@include breakpoint( tb ) {

		top: rem-calc( 10px );
		left: rem-calc( 15px );
		right: rem-calc( 15px );

	}

	@include breakpoint( hg ) {

		left: 0;
		right: 0;

	}

}

.iconbar__item {

	@include breakpoint( mb only ) {

		position: absolute;
		width: $w-icon;
		height: $h-icon;
		right: 0;
		top: 0;

	}

	@include breakpoint( tb ) {

		position: relative;
		float: left;
		height: $item-height;
		line-height: $item-height;
		width: auto;

		&:not( .iconbar__item--last ):before {

			content: "";
			position: absolute;
			right: -1px;
			height: 14px;
			width: 1px;
			background: $dark-grey;
			top: 12px;
			z-index: 1;

		}

	}

	&--notification:after {

		content: "";
		background: $lc-beta;
		width: $notification-size;
		height: $notification-size;
		border-radius: $notification-size;

		position: absolute;
		right: 5px;
		top: 5px;
		z-index: 1010;

	}

}

.iconbar__item-inner {

	display: block;
	position: relative;
	background: $white;

	@include breakpoint( mb only ) {

		width: $w-icon;
		height: $h-icon;

		top: 0;
		text-align: center;
		vertical-align: middle;

	}

	@include breakpoint( tb ) {

		height: $item-height;
		padding: 0 10px 0 11px;
		border: 1px solid transparent;
		border-bottom: none;

		transition: border $ease-time $ease-out;

	}

}

.iconbar__item.is-active {

	opacity: 1;

	@include breakpoint( tb ) {

		&:before {

			display: none;

		}

		.iconbar__item-inner {

			border-color: #ccc;
			z-index: 1009;

			transition: border $ease-time $ease-in;

		}

	}

}


.iconbar__bag-count {

	color: $white;
	font-weight: bold;
	position: absolute;

	&[ data-items = "0" ] {

		opacity: 0;

	}

}

@include breakpoint( tb ) {

	.iconbar__company,
	.iconbar__user,
	.iconbar__site {

		position: absolute;

	}

	.iconbar__company,
	.iconbar__site {

		left: 0;

	}

	.iconbar__user {

		right: 0;

	}

	.iconbar__site {

		display: none;

	}

	.iconbar__bag-icon {

		position: relative;
		top: -2px;

	}

	.iconbar__bag-count {

		line-height: 36px;

		left: 2px;
		right: 0;
		top: 0;
		bottom: 0;
		text-align: center;
		vertical-align: middle;

		text-shadow:
			0 1px 0 $black,
			1px 1px 0 $black,
			1px 0 0 $black,
			0 -1px 0 $black,
			-1px -1px 0 $black,
			-1px 0 0 $black;

		transition: transform 0.4s $lc-ease-subtle, opacity 0.4s  $lc-ease-subtle;


		.anim-out & {

			opacity: 0;
			transform: translateY( -20px );

		}

		.anim-in & {

			opacity: 0;
			transform: translateY( 20px );

		}

		.no-anim & {

			transition: none;

		}

	}

	.iconbar__item--giftlist {

		.iconbar__item-inner {

			width: 40px;

		}

	}

}

.iconbar__giftlist-icon {

	position: absolute;
	left: 50%;
	transform: translate( -50%, -50% );
	top: 50%;

}

.iconbar__giftlist-icon--fill {

	opacity: 0;
	transform: translate( -50%, -70% );
	transition:
	  transform 0.035s linear 0.4s,
		opacity 0.3s ease-in;


	&.filled {

		opacity: 1;

		transform: translate( -50%, -50% );
		transition:
		  transform 0.3s $lc-bounce-in,
			opacity 0.35s ease-out;

	}

}



@include breakpoint( mb only ) {

	.iconbar__bag-icon,
	.iconbar__giftlist-icon,
	.iconbar__hamburger-icon,
	.iconbar__search-icon {

		font-size: 24px;

		height: $h-icon;
		line-height: $h-icon;
		width: 30px;
		padding: 0;

	}

	.iconbar__bag-icon,
	.iconbar__hamburger-icon,
	.iconbar__search-icon {

		top: 0;
		left: 0;
		position: absolute;

	}

	.iconbar__bag-icon,
	.iconbar__hamburger-icon {

		width: 40px;

	}

	.iconbar__bag-icon {

		font-size: 26px;
		width: 40px;

		&[ data-icon ]:before {

			top: -3px;

		}

	}

	.iconbar__bag-count {

		font-size: 14px;
		line-height: $h-icon;
		left: 0;
		top: 2px;
		width: 40px;
		height: $h-icon;
		vertical-align: middle;
		text-align: center;

	}

	.iconbar__giftlist-icon {

		font-size: 26px;
		top: 29px;

	}

	.iconbar__hamburger-icon {

		font-size: 21px;

	}

	.iconbar__search-icon {

		font-size: 20px;

	}

	.iconbar__item--giftlist {

		right: 41px;

	}

	.iconbar__item--hamburger {

		right: auto;
		left: 0;
		width: 40px;

		.iconbar__item-inner {

			width: 40px;

		}

	}

	.iconbar__item--bag {

		width: 40px;
		right: 1px;

		.iconbar__item-inner {

			width: 40px;

		}

	}

	.iconbar__item--search {

		right: auto;
		left: 40px;

	}

	// need to increase specificity, here.
	.iconbar {

		.iconbar__item--sign-in,
		.iconbar__item--register,
		.iconbar__item--myaccount,
		.iconbar__item--stores,
		.iconbar__item--phone {

			display: none;

		}

	}

}
