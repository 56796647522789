
@mixin lc-buttons( $bp: "" ) {

	.#{$bp}btn {

		@include font( lc );

		height: $lc-field-height;
		line-height: $lc-field-height;
		text-align: center;

		color: $white;
		background: $black;
		border: rem-calc( 1px ) solid $black;

		transition-property: border, background, opacity, color;
		transition-duration: 0.2s;
		transition-timing-function: $lc-ease-subtle;

		cursor: pointer;

		// only apply padding / line height if they are not defined
		// on the button specifically

		&:not( [ class *= "pad-" ] ) {

			padding-left: rem-calc( 10px );
			padding-right: rem-calc( 10px );

		}

		&--alt {

			color: $black;
			background: $white;

		}

		&--small {

			height: 2.5rem;
			line-height: 2.5rem;

		}

		&--tiny {

			height: 2rem;
			line-height: 1.9rem;

		}

		&--min {

			min-width: 10rem;

		}

		&--wide {

			min-width: 15rem;

		}

		&--full {

			display: block;
			width: 100%;

		}

		&--flex {

			align-items: center;
			justify-content: center;

		}

		&--select {

			background: transparent;
			border: none;
			outline: none;
			z-index: 1;

			appearance: none;

			&::-ms-expand {

				display: none;

			}

		}

		&--invert:not( .btn--disabled ),
		&--invert:not( [ disabled ] ),
		&--invert:not( .is-disabled ),
		&--invert:not( .disabled ) {

			&:hover,
			&:focus,
			&:active {

				color: $black;
				background: $white;

			}

			&.btn--alt {

				&:hover,
				&:focus,
				&:active {

					color: $white;
					background: $black;

				}

			}

		}

		&--disabled,
		&[ disabled ],
		&.is-disabled,
		&.disabled {

			color: $very-light;
			background: $grey;
			border-color: $dark-grey;
			cursor: default;

			&.btn--alt {

				color: $white;
				background: $light-grey;
				border-color: $dark-grey;

			}

		}

	}

}



// output styles in to breakpoints

@include lc-buttons;

@each $size in $breakpoint-classes {

	@include breakpoint($size) {

		@if $size != "mb" {

			@include lc-buttons( $size + "-" );

		}

	}

}

@include breakpoint(mb only) {

	@include lc-buttons( mb-only- );

}

@include breakpoint(tb only) {

	@include lc-buttons( tb-only- );

}
