
$image-mobile: rem-calc( 90px );
$image-desktop: rem-calc( 120px );
$price-desktop: rem-calc( 160px );

.commerce-brick {

	&__image,
	&__description,
	&__pricing {

		vertical-align: top;

	}

	&__image {

		width: $image-mobile;

		@include breakpoint( tb ) {

			width: 25%;
			min-width: $image-desktop;

		}

	}

	&__img-wrapper {

		max-width: rem-calc( 130px );

	}

	&__description {

		width: auto;

		@include breakpoint( tb ) {

			width: 55%;

		}

	}

	&__pricing {

		margin-left: $image-mobile;

		@include breakpoint( tb ) {

			width: 20%;
			min-width: $price-desktop;

		}

	}

}
