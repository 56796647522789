
// any element which is loading can look
// like a blank-space with a very light color

.loading-placeholder {

	background: $very-light;
	color: $very-light;

}

// if there's a large list of content loading
// via ajax, then a placeholder in the page is
// a good idea, see: <lcui:content-placeholder /> tag

.content-placeholder {

	&,
	&__spinner {

		min-height: 300px;
		width: 100%;

	}

	.lane-crawford-logo__svg {

		width: 70%;

	}

}
