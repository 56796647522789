
.lc-donut {

	position: relative;

	&__svg {

		display: block;
		max-width: 100%;
		margin: auto;
		transform-origin: 50%;

	}

	&__text {

		text-align: center;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate( -50%, -50% );

	}

	&__bg,
	&__donut {

		stroke: $very-light;
		stroke-width: 1;
		fill: transparent;

	}

	&__donut {

		stroke: currentColor;
		stroke-width: 6;
		opacity: 0;

		transition:
			opacity 0.3s $lc-ease-softer,
			stroke-dashoffset 1s $lc-ease-softer;

	}

	&--sliced {

		// shift the sliced charts down a little so
		// they still look centered vertically
		transform: translateY( 6.5% );

	}

}
