
// mixin to be included for all the breakpoints

@mixin lc-card( $bp: "" ) {

	.#{$bp}card {

		border-radius: rem-calc( 10px );
		border: 1px solid #e0e0e0;
		box-shadow: 0 3px 5px #eaeaea;

	}

}



// output styles in to breakpoints

@include lc-card;

@each $size in $breakpoint-classes {

	@include breakpoint($size) {

		@if $size != "mb" {

			@include lc-card( $size + "-" );

		}

	}

}

@include breakpoint( mb only ) {

	@include lc-card( "mb-only-" );

}

@include breakpoint( tb only ) {

	@include lc-card( "tb-only-" );

}
