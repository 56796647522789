

@mixin lc-textareas( $bp: "" ) {


	.lc-field__textarea {

		height: auto;
		min-height: $lc-field-height * 3;
		line-height: 1.4;
		padding-top: rem-calc( 5px );
		resize: vertical;

		transition:
			       border 0.3s $lc-ease-subtle,
		  	  line-height 0.05s $lc-ease-subtle,
		  	      padding 0.05s $lc-ease-subtle;



		&::placeholder {

			color: $grey;

		}

		&:-webkit-autofill {

			// enable custom "autofill" background color
			box-shadow: inset 0 0 0 400px $lc-autofill;

		}

		&:not( [ class *= "bor-" ] ) {

			// scss-lint:disable SelectorFormat

			border: 1px solid $grey;

			&:hover,
			&:active,
			&:focus {

				border-color: $black;

			}

			&.is-warning,
			&.warningBorder {

				border-color: $yellow;
				border-width: 2px;

			}

			&.is-error,
			&.errorBorder,
			&:invalid {

				border-color: $red;
				border-width: 2px;

			}

			&.disabled,
			&[ disabled ] {

				color: $disabled-text;
				border-color: $disabled-border;

			}

		}

		~ .lc-field__placeholder {

			top: 5px;
			line-height: 1.4;

			&.is-shifted {

				display: none;

			}

		}

	}

	.lc-field__counter {

		position: absolute;
		bottom: 0;
		right: rem-calc( 5px );
		transform: translateY( 100% );
		user-select: none;
		pointer-events: none;

		&.is-warning {

			color: $yellow;

		}

		&.is-error {

			color: $red;

		}

	}

}


@include lc-textareas;
