

.cur-p {

	cursor: pointer;

}

.cur-d {

	cursor: default;

}

.cur-h {

	cursor: help;

}

.cur-t {

	cursor: text;

}

.cur-z,
.cur-zi {

	cursor: zoom-in;
	cursor: -moz-zoom-in;
	cursor: -webkit-zoom-in;

}

.cur-zo {

	cursor: zoom-out;
	cursor: -moz-zoom-out;
	cursor: -webkit-zoom-out;

}

.no-select,
.no-select * {

	user-select: none;

}
