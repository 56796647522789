
// mixin to be included for all the breakpoints

@mixin lc-gaps( $bp: "" ) {

	@each $pos in $spacing {

		.#{$bp}gap-#{$pos} {

			/* stylelint-disable-next-line */
			gap: rem-calc( $pos );

		}

	}

}



// output styles in to breakpoints

@include lc-gaps;

@each $size in $breakpoint-classes {

	@include breakpoint($size) {

		@if $size != "mb" {

			@include lc-gaps( $size + "-" );

		}

	}

}
