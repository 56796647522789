
$width: 80%;
$gutter: 5px;


.scrolling-blocks {

	display: flex;
	flex-wrap: nowrap;
	align-items: stretch;

	overflow-x: auto;
	-webkit-overflow-scrolling: touch;

	@include breakpoint( tb ) {

		display: block;
		overflow: visible;

	}

	&.a-start {

		align-items: flex-start;

	}

	&.a-end {

		align-items: flex-end;

	}

	&__item {

		min-width: $width;
		padding: rem-calc( $gutter );
		flex: 1 1 0%;
		display: flex;

		@include breakpoint( tb ) {

			display: inline-block;
			min-width: auto;

		}

	}

	&.flush &__item {

		&:first-child {

			padding-left: 0;

		}

		&:last-child {

			padding-right: 0;

		}

	}

}
