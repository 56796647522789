
.ui-autocomplete {

	// position the menu slighty up so
	// that it merges it's border with the
	// element it's attached to.

	max-height: 120px;
	z-index: 1;

	@include breakpoint( tb ) {

		max-height: 160px;

	}

	.ui-menu-item {

		&-wrapper {

			white-space: nowrap;
			text-overflow: ellipsis;

			&.ui-state-active {

				background: $very-light;

			}

		}

		// give a bottom border for all list elements,
		// except for the last one.
		&:not( :last-child ) {

			border-bottom: 1px solid $light-grey;

		}

	}

}
