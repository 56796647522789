

@mixin lc-inputs( $bp: "" ) {

	input {

		// override weird safari/ios default rounding corners.
		border-radius: 0;
		appearance: none;

	}

	.lc-field__input {

		// line height and padding are due
		// to x-browser inconsistencies in line-height
		line-height: normal;
		padding-top: rem-calc( 10px );

		&:not( [ class *= "bor-" ] ) {

			// scss-lint:disable SelectorFormat

			border-left: none;
			border-right: none;
			border-top: 1px solid transparent;
			border-bottom: 1px solid $grey;

			&:hover,
			&:active,
			&:focus {

				border-bottom-color: $black;

			}

			&.is-error,
			&.errorBorder,
			&:invalid {

				border-bottom-color: $red;
				border-bottom-width: 2px;

			}

			&.disabled,
			&[ disabled ] {

				color: $disabled-text;
				border-bottom-color: $disabled-border;

			}

		}

		&::placeholder {

			color: $grey;

		}

		&:-webkit-autofill {

			// enable custom "autofill" background color

			border-top-color: $lc-autofill;
			box-shadow: inset 0 0 0 100px $lc-autofill;

		}

		&::-webkit-contacts-auto-fill-button,
		&::-webkit-credentials-auto-fill-button {

			// enable custom positioning of the
			// "autofill" icons in safari.

			position: absolute;
			right: 10px;
			bottom: 10px;

		}

	}

	input[ type = "number" ] {

		-moz-appearance: textfield;

		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {

			position: absolute;
			appearance: none;

		}

	}

	input::-ms-clear {

		width: 0;
		height: 0;
		position: absolute;
		visibility: hidden;

	}

}


@mixin lc-inputs-new( $bp: "" ) {

	.lc-field__input--new {

		padding-left: rem-calc( 10px );
		padding-right: rem-calc( 10px );
		padding-bottom: 0;
		padding-top: 0;

		&:not( [ class *= "bor-" ] ) {

			border: 1px solid $grey;

			&:hover,
			&:active,
			&:focus {

				border-color: $black;

			}

			&.is-error,
			&.errorBorder,
			&:invalid {

				border-color: $red;
				border-width: 1px;

				&:focus,
				&:active {

					box-shadow: 0 0 0 1px $red;

				}

			}

			&.disabled,
			&[ disabled ] {

				border-color: $disabled-border;

			}

		}

	}

}


@include lc-inputs;
@include lc-inputs-new;
