

@mixin lc-autocomplete( $bp: "" ) {

	.lc-field__autocomplete {

		&::placeholder {

			color: $grey;

		}

		&:not( [ class *= "bor-" ] ) {

			// scss-lint:disable SelectorFormat

			border: 1px solid $grey;

			&:hover,
			&:active,
			&:focus {

				border-color: $black;

			}

			&.is-error,
			&.errorBorder,
			&:invalid {

				border-color: $red;
				border-width: 2px;

			}

		}

	}

}


@include lc-autocomplete;
