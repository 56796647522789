
// mixin to be included for all the breakpoints

@mixin lc-floats( $bp: "" ) {


	.#{$bp}float-l {

		float: left;

	}

	.#{$bp}float-r {

		float: right;

	}

	.#{$bp}float-n {

		float: none;

	}

}



// output styles in to breakpoints

@include lc-floats;

@each $size in $breakpoint-classes {

	@include breakpoint($size) {

		@if $size != "mb" {

			@include lc-floats( $size + "-" );

		}

	}

}

@include breakpoint( mb only ) {

	@include lc-floats( "mb-only-" );

}

@include breakpoint( tb only ) {

	@include lc-floats( "tb-only-" );

}


