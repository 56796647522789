
.lc-popover {

	font-size: 14px;
	font-family: $font-lc;
	text-transform: none;
	color: $white;
	background: $very-dark;
	display: block;
	position: absolute;
	bottom: 0;
	left: 50%;
	line-height: 1.2;
	padding: 10px;
	border-radius: 3px;
	box-shadow: $lc-popout-shadow;

	z-index: 2;
	width: max-content;
	max-width: 300px;

	opacity: 0;
	transform: translateX( -50% ) translateY( calc( 100% + 25px ) );
	transition: all 0.3s $lc-ease 0.3s, visibility 0.01s linear 0.6s;
	visibility: hidden;

	&:after {

		content: "";
		background: inherit;
		width: 17px;
		height: 11px;
		clip-path: polygon( 0 100%, 50% 0, 100% 100% );
		position: absolute;
		left: 50%;
		top: -10px;
		transform: translateX( -50% );

	}

	/**
		this is to increase hit-size after opened
	**/
	&:before {

		content: "";
		position: absolute;
		left: -15px;
		top: -15px;
		bottom: -15px;
		right: -15px;

	}

	&[ data-left ] {

		left: 0;
		right: auto;
		transform: translateY( calc( 100% + 25px ) );

		&:after {

			transform: none;
			left: 10px;
			right: auto;

		}

	}

	&[ data-right ] {

		left: auto;
		right: 0;
		transform: translateY( calc( 100% + 25px ) );

		&:after {

			transform: none;
			left: auto;
			right: 10px;

		}

	}

}

.lc-popover-control:active .lc-popover,
.lc-popover-control:focus .lc-popover,
.lc-popover-control:hover .lc-popover {

	opacity: 1;
	transform: translateX( -50% ) translateY( calc( 100% + 5px ) );
	transition: all 0.3s $lc-ease 0.15s, visibility 0.01s linear 0s;
	visibility: visible;

	&[ data-left ],
	&[ data-right ] {

		transform: translateY( calc( 100% + 5px ) );

	}

}
