$s-close: 14px;

.lc-notification {

	text-align: center;
	font-size: rem-calc( 14px );
	border: 2px solid currentColor;
	position: relative;

	padding: rem-calc( 5px ) rem-calc( 40px ); /* so that text doesn't go over the "x" */
	margin-bottom: rem-calc( 10px ); /* so that notifications have space */

	&--alert {

		color: $red;

	}

	&--warning {

		color: $yellow;

	}

	&--success {

		color: $green;

	}

	&--info {

		color: $blue;

	}

	&__close {

		position: absolute;
		top: 0;
		right: 0;
		padding: 0.5rem; /* top, right and padding give the "x" a bigger touch-area for usability */
		cursor: pointer;

	}

}
