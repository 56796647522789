
// easing information for the menu
$ease-in: $lc-ease-in;
$ease-out: $lc-ease-out;
$ease-time: 0.15s;
$ease-distance: 30px;

$flyout-margin: 10px;


html,
body {

	will-change: overflow;

}



.mobile-flyout {

	@include off-screen-panel;

	padding: 0 $flyout-margin;

	@include breakpoint( tb ) {

		display: none;

	}

	&.is-open {

		@include off-screen-panel-open;

	}

	&.is-locked {

		overflow-x: hidden !important;
		overflow-y: hidden !important;

	}

}


@include breakpoint( mb only ) {

	.flyoutbar {

		position: relative;
		height: 59px;
		z-index: 30;
		margin: 0 (-$flyout-margin);
		background: white;

		&:after {

			content: "";
			position: absolute;
			background-color: black;
			bottom: -1px;
			height: 3px;
			width: auto;
			left: 10px;
			right: 10px;

		}

	}

	.flyoutbar__header {

		font-size: 24px;
		padding: 0;
		line-height: 60px;
		text-align: center;

		-webkit-transition: -webkit-transform $ease-time $ease-out, opacity $ease-time $ease-out;
		-moz-transition: -moz-transform $ease-time $ease-out, opacity $ease-time $ease-out;
		-ms-transition: -ms-transform $ease-time $ease-out, opacity $ease-time $ease-out;
		transition: transform $ease-time $ease-out, opacity $ease-time $ease-out;

		text-overflow: ellipsis;
		width: 70%;
		overflow: hidden;
		white-space: nowrap;
		margin: auto;

		&.anim-out {

			-webkit-transition: -webkit-transform $ease-time $ease-in, opacity $ease-time $ease-in;
			-moz-transition: -moz-transform $ease-time $ease-in, opacity $ease-time $ease-in;
			-ms-transition: -ms-transform $ease-time $ease-in, opacity $ease-time $ease-in;
			transition: transform $ease-time $ease-in, opacity $ease-time $ease-in;

			-webkit-transform: translateX( -$ease-distance );
			-moz-transform: translateX( -$ease-distance );
			-ms-transform: translateX( -$ease-distance );
			transform: translateX( -$ease-distance );

			opacity: 0;

			&.reverse {

				-webkit-transform: translateX( $ease-distance );
				-moz-transform: translateX( $ease-distance );
				-ms-transform: translateX( $ease-distance );
				transform: translateX( $ease-distance );

			}

		}

		&.anim-in {

			-webkit-transition: none;
			-moz-transition: none;
			-ms-transition: none;
			transition: none;

			-webkit-transform: translateX( $ease-distance );
			-moz-transform: translateX( $ease-distance );
			-ms-transform: translateX( $ease-distance );
			transform: translateX( $ease-distance );

			opacity: 0;

			&.reverse {

				-webkit-transform: translateX( -$ease-distance );
				-moz-transform: translateX( -$ease-distance );
				-ms-transform: translateX( -$ease-distance );
				transform: translateX( -$ease-distance );

			}

		}

	}

	.flyoutbar__list {

		position: absolute;
		left: $flyout-margin;
		right: $flyout-margin;
		top: 0;

	}

	$icon-w: 50px;
	$icon-h: 60px;

	.flyoutbar__item {

		border: none;
		position: absolute;
		width: $icon-w;
		height: $icon-h;
		right: -10px;
		top: 0;

	}

	.flyoutbar__item--back {

		right: auto;
		left: -10px;

	}

	.flyoutbar__item-inner {

		top: 0;
		padding: 0;
		width: $icon-w;
		height: $icon-h;
		line-height: $icon-h * 0.7;
		text-align: center;
		display: block;
		vertical-align: middle;

	}

	.flyoutbar__back-icon,
	.flyoutbar__hamburger-icon {

		position: relative;
		top: 0;
		padding: 0;

	}

	.flyoutbar__back-text,
	.flyoutbar__hamburger-text {

		display: none;

	}

	.flyoutbar__hamburger-icon {

		font-size: 21px;
		top: 8px;

	}

	.flyoutbar__back-icon {

		font-size: 26px;
		top: 7px;

		&:before {

			font-weight: bold;

		}

	}



}
