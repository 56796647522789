
$table-border-color: $black;
$shadow-width: rem-calc( 20px );

$min-width: rem-calc( 128px );
$iphone5-width: rem-calc( 150px );
$iphone6-width: rem-calc( 190px );
$tablet-width: rem-calc( 300px );


@mixin lc-scrolling-table( $bp: "" ) {

	.table-wrapper {

		// table has no overflow as it will be
		// wrapping the scrolling-table and the
		// fixed-table which need to be stuck inside
		overflow: hidden;
		position: relative;

		table {

			max-width: 300%;

			@include breakpoint( tb ) {

				max-width: 200%;

			}

		}

		// shadows to be shown when the table is
		// active
		&.is-active:before,
		&.is-active:after {

			content: "";
			top: 0;
			bottom: 0;
			width: $shadow-width;
			height: auto;
			position: absolute;
			pointer-events: none;
			opacity: 1;
			z-index: 1;

			transition: opacity 0.1s $lc-ease;

		}

		// shadow on left
		&:before {

			left: 0;
			right: auto;
			box-shadow: inset $shadow-width 0 $shadow-width -#{$shadow-width} $light-grey;

		}

		// shadow on right
		&:after {

			left: auto;
			right: 0;
			box-shadow: inset -#{$shadow-width} 0 $shadow-width -#{$shadow-width} $light-grey;

		}

		// hide the shadows if we're at the
		// start or the end
		&.at-start:before,
		&.at-end:after {

			opacity: 0;

		}


		td,
		th {

			&:nth-of-type( 1 ) {

				min-width: $min-width;
				max-width: $tablet-width;

			}

			// the first cell, on mobile, needs a fixed width
			// so that it looks the same on scrolling/fixed tables
			// and so it doesn't get larger than the screen width

			@media screen and (max-width: 359px) {

				&:nth-of-type( 1 ) {

					max-width: $iphone5-width;

				}

			}

			@include breakpoint( mb only ) {

				&:nth-of-type( 1 ) {

					max-width: $iphone6-width;

				}

			}

		}

	}

	// the scrolling element needs to have
	// overflow auto so it will scroll
	.table-scroller {

		overflow-x: auto;
		-webkit-overflow-scrolling: touch;

		padding-bottom: 20px;

	}

	.lc-table-fixed {

		position: absolute;
		top: 0;
		left: 0;
		pointer-events: none;

		td,
		th {

			// the first cell needs to have a solid color so it can sit
			// over the background table without peeking through
			&:nth-of-type( 1 ) {

				border-right: 2px solid $table-border-color;

			}

			// hide all the cells which are not the row-headers,
			// but also just incase there's a row-span column we
			// might need to make a cell "not-fixed" explicitly.
			&:not( :nth-of-type( 1 ) ),
			&.not-fixed {

				visibility: hidden;

			}

		}

	}

}


@include lc-scrolling-table;
