
$acc-b-top: 0 rem-calc( -1px ) 0 0 $very-light;
$acc-b-top-active: 0 rem-calc( -1px ) 0 0 $light-grey;

$acc-b-bottom: 0 rem-calc( 1px ) 0 0 $very-light;
$acc-b-bottom-active: 0 rem-calc( 1px ) 0 0 $light-grey;


.lc-accordion {

	&--subtle &__item {

		color: $dark-grey;
		box-shadow: $acc-b-top;

		&:last-of-type {

			box-shadow: $acc-b-top, $acc-b-bottom;

		}

		&:hover {

			box-shadow: $acc-b-top-active;

		}

		&:last-of-type:hover {

			box-shadow: $acc-b-top-active, $acc-b-bottom-active;

		}

		&.is-active {

			box-shadow: $acc-b-top-active;
			color: $black;

		}

		&:last-of-type.is-active {

			box-shadow: $acc-b-top-active, $acc-b-bottom-active;

		}

	}

}
