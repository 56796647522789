
.black-bar-holder {

	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	position: relative;
	z-index: 1;
	-webkit-font-smoothing: subpixel-antialiased;

}

.black-bar {

	position: relative;
	line-height: 1.2;
	letter-spacing: 0.1em;
	padding: rem-calc( 8px );
	white-space: nowrap;
	text-overflow: ellipsis;
	display: block;
	overflow: hidden;

	a {

		text-decoration: none;

	}

	a:hover {

		opacity: 0.7;
		transition-duration: 0.5s;

	}

}
