
// easing information for the minicart

$ease-in: $lc-ease-in;
$ease-out: $lc-ease-out;
$ease-time: 0.28s;




// minicart only available on desktop.

.minicart {

	display: none;

}

@include breakpoint( tb ) {


	.minicart {

		display: block;
		width: 340px;
		font-size: 11px;

		z-index: 51;
		transform: translateY( -1px );

		box-shadow: $lc-popout-shadow;

		opacity: 0;
		visibility: hidden;
		transition:
		 visibility 0.05s linear $ease-time,
			opacity $ease-time $ease-out;

		&.is-open {

			opacity: 1;
			visibility: visible;
			transition:
			 visibility 0.05s linear,
				opacity $ease-time $ease-in 0.05s;

		}

	}



	.minicart__items {

		min-height: 20px;
		max-height: 400px;
		overflow-y: hidden;
		text-transform: none;
		margin-left: -10px;
		margin-right: -10px;

		transition:
		        height 0.3s ease,
			max-height 0.3s ease,
			    border 0.3s ease;

		.minicart.is-open & {

			overflow-y: auto;

		}

	}

	.minicart__items--error {

		border-top: 2px solid $red;
		border-bottom: 2px solid $red;

	}

	.minicart__items--scroll {

		max-height: 260px;

	}

	.minicart__scrolly-top,
	.minicart__scrolly-bottom {

		height: 15px;
		left: 12px;
		right: 12px;
		position: absolute;
		z-index: 1;

		background: linear-gradient( 0deg, transparent 0%, rgba( 0, 0, 0, 0.03 ) 50%, rgba( 0, 0, 0, 0.1 ) 100% );

	}

	.minicart__scrolly-bottom {

		margin-top: -16px;
		background: linear-gradient( 180deg, transparent 0%, rgba( 0, 0, 0, 0.03 ) 50%, rgba( 0, 0, 0, 0.1 ) 100% );

	}



	.minicart__message {

		padding: 1.2em 3.4em;
		text-align: center;

		position: relative;
		line-height: 1.4;

		a {

			text-decoration: underline;

		}

		p {

			margin: 0;

		}

	}

	.minicart__message--not-available {

		color: $red;
		border-bottom: 1px solid;

	}

	.minicart__message--upper {

		text-transform: uppercase;

	}

	.minicart__remove-icon {

		position: absolute;
		top: 5px;
		right: 0;
		cursor: pointer;
		color: black;

	}


	.minicart__header {

		overflow: hidden;
		margin: 0;
		padding-bottom: 7px;
		border-bottom: 1px solid $dark-grey;

	}

	.minicart__footer {

		padding: 0;
		border: none;
		border-top: 1px solid $dark-grey;

	}

	.minicart-header__item-count,
	.minicart-header__total,
	.minicart-footer-row__title,
	.minicart-footer-row__value {

		width: 50%;
		float: left;

	}

	.minicart-header__total,
	.minicart-footer-row__value {

		text-align: right;

	}

	.minicart-footer-row__title,
	.minicart-footer-row__value {

		font-size: 1.2em;
		line-height: 33px;
		vertical-align: middle;
		padding-top: 1px;

	}

	.minicart-footer-row__title {

		width: 65%;

	}

	.minicart-footer-row__value {

		width: 35%;

		font-weight: bold;
		font-size: 1.4em;

	}

	.minicart-footer__row {

		overflow: hidden;
		border-top: 1px solid #ccc;

		&:first-child {

			border: none;

		}

	}

	.minicart-footer__button {

		width: 48%;
		min-width: 0;
		float: left;

		.icon {

			position: relative;
			top: -2px;
			right: -5px;
			font-size: 14px;

		}

	}

	.minicart-footer__button:last-child {

		float: right;

	}

	.minicart-footer__row--ddi .minicart-footer-row__value {

		@include font( sb );

		width: 100%;
		font-weight: normal;
		font-size: 14px;
		text-align: right;
		display: block;

	}

}
