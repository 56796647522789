
.an-r-180 {

	transition: transform $rotate-time $lc-ease;
	transform: rotateZ( 0deg );

	&.go {

		transform: rotateZ( -180deg );

	}

}

.an-r-360 {

	transition: transform $rotate-time $lc-ease;
	transform: rotateZ( 0deg );

	&.go {

		transform: rotateZ( -360deg );

	}

}
