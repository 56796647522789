
$txt-colours: $lc-txt-colours;

// mixin to be included for all the breakpoints

@mixin lc-colours( $bp: "" ) {

	@each $colour in $txt-colours {

		$name: nth( $colour, 1 );
		$val: nth( $colour, 2 );

		.#{$bp}txt-#{$name} {

			color: $val;

		}

		.#{$bp}bg-#{$name} {

			background-color: $val;

		}

	}

}



// output styles in to breakpoints

@include lc-colours;

@each $size in $breakpoint-classes {

	@include breakpoint($size) {

		@if $size != "mb" {

			@include lc-colours( $size + "-" );

		}

	}

}

