
// disabled / disbled & focussed labels

.lc-label--disable,
.lc-label--disabled,
[ class *= "lc-label" ][ disabled ] {

	&,
	&:hover,
	&:focus,
	&:active,
	&.lc-label--active {

		cursor: default;
		opacity: $label-opacity-disable;

	}

}
