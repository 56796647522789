
$blur:			5px;
$extra-blur: 	$blur * 2;

$ease-time: 	0.5s;



.blur-load {

	filter: blur( $blur );
	transition: opacity $ease-time $lc-ease-subtle;

}

.blur {

	filter: blur( $blur );

}

.extra-blur {

	filter: blur( $extra-blur );

}
