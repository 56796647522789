
// we define the scale of line-heights we want
// to be able to use across the LC website first

$lh-scale: (
	"1": 1,
	"1-2": 1.2,
	"1-4": 1.4,
	"1-6": 1.6,
	"2": 2
);


// mixin to be included for all the breakpoints

@mixin lc-line-height( $bp: "" ) {

	// loop through all the sizes in our scale, and
	// generate css classes for each one.

	@each $size in $lh-scale {

		$name: nth( $size, 1 );
		$val: nth( $size, 2 );

		.#{$bp}lh-#{$name} {

			line-height: $val;

		}

	}

}



// output styles in to breakpoints

@include lc-line-height;

@each $size in $breakpoint-classes {

	@include breakpoint($size) {

		@if $size != "mb" {

			@include lc-line-height( $size + "-" );

		}

	}

}

