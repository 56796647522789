
// ----------------------------------------------
//
//	 Flickity v1.2.1
//	 http://flickity.metafizzy.co
//
// ----------------------------------------------
//
//	 Customised CSS for LC Sliders
//
// ----------------------------------------------

.flickity-enabled {

	position: relative;

}

.flickity-enabled:focus {

	outline: none;

}

.flickity-viewport {

	overflow: hidden;
	position: relative;
	height: 100%;

}

.flickity-slider {

	position: absolute;
	width: 100%;
	height: 100%;

}

// draggable

.flickity-enabled.is-draggable {

	-webkit-tap-highlight-color: transparent;
	tap-highlight-color: transparent;
	user-select: none;

}

.flickity-enabled.is-draggable .flickity-viewport {

	cursor: move;
	cursor: grab;

}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {

	cursor: grabbing;

}

// ---- previous/next buttons ----

.flickity-prev-next-button {

	position: absolute;
	top: 50%;
	transform: translateY( -50% );

	width: rem-calc( 50px );
	height: rem-calc( 50px );

	border: none;
	cursor: pointer;
	opacity: 0.1;

	transition: opacity 0.2s $lc-ease;

	&.next {

		right: 0;

	}

	&.previous {

		left: 0;

	}

}

.flickity-enabled:hover .flickity-prev-next-button {

	opacity: 0.5;

}

.flickity-prev-next-button:focus {

	outline: none;
	box-shadow: 0 0 0 2px $black;

}

.flickity-prev-next-button:active {

	opacity: 1;

}

// right to left
//.flickity-rtl .flickity-prev-next-button.previous {
//	left: auto;
//	right: 10px;
//}
//.flickity-rtl .flickity-prev-next-button.next {
//	right: auto;
//	left: 10px;
//}
//.flickity-rtl .flickity-page-dots {
//	direction: rtl;
//}

.flickity-prev-next-button.is-disabled,
.flickity-enabled:hover .flickity-prev-next-button.is-disabled {

	opacity: 0;
	cursor: auto;

}

.flickity-prev-next-button svg {

	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: $ie9-transparent;

}

.flickity-prev-next-button .arrow {

	fill: $black;

}

// color & size if no SVG - IE8 and Android 2.3
.flickity-prev-next-button.no-svg {

	color: $black;
	font-size: 50px;

}

// ---- page dots ----

.flickity-page-dots {

	position: absolute;
	width: 100%;
	bottom: -2rem;
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: center;
	line-height: 1;

}

.flickity-page-dots .dot {

	display: inline-block;

	width: 2rem;
	height: 2rem;
	margin: 0 rem-calc( 2px );
	padding: rem-calc( 4px );

	cursor: pointer;

	> a {

		display: block;
		position: relative;
		height: 1px;
		top: 50%;

		background: $black;

	}

}

.flickity-page-dots .dot.is-selected {

	> a {

		height: 3px;
		margin-top: -1px;

	}

}
