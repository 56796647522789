
.an-height,
.an-slide {

	transition:
		height $slide-time $lc-ease-subtle,
		margin $slide-time $lc-ease-subtle,
		padding $slide-time $lc-ease-subtle;

}
