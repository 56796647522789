
/* stylelint-disable length-zero-no-unit */

// spinner background images for IE9
// because IE9 doesn't support animating things with CSS.

.spinner {

	background-image: lc-image( "spinner120w.gif" );

	&--dark {

		background-image: lc-image( "spinner120k.gif" );

	}

}

// when the browser supports animation, we will
// use SVG and animate the element.

.cssanimations {

	.spinner {

		animation: lc-spinner--static 3.5s steps( 36 ) infinite;
		background-image: lc-image( "spinner.svg" );

		&:not( .spinner--static ) {

			animation-name: lc-spinner;

		}

		&--dark {

			background-image: lc-image( "spinner--dark.svg" );

		}

		&--fast {

			animation-duration: 1.25s;

		}

	}

	@keyframes lc-spinner {

		0% { transform: translate( -50%, -50% ) rotate( 0deg ); }
		100% { transform: translate( -50%, -50% ) rotate( 360deg ); }

	}

	@keyframes lc-spinner--static {

		0% { transform: rotate( 0deg ); }
		100% { transform: rotate( 360deg ); }

	}

}



.spinner {

	width: 120px;
	height: 120px;

	background-position: center;
	background-repeat: no-repeat;
	background-size: 120px;

	transition: opacity 0.3s $lc-ease;
	transform-origin: 50%;
	z-index: 1;

	&:not( .spinner--static ) {

		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate( -50%, -50% );

	}

	&--static {

		left: auto;
		top: auto;
		transform: none;
		position: static;

	}

	&--small {

		width: 90px;
		height: 90px;
		background-size: 90px;

	}

	&--smaller {

		width: 60px;
		height: 60px;
		background-size: 60px;

	}

	&--tiny {

		width: 30px;
		height: 30px;
		background-size: 30px;

	}

}




$lanecrawford: "l","a","n","e","c","r","a2","w","f","o","r2","d";


.spinner-logo {

	max-width: 20rem;
	overflow: visible;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate( -50%, -50% );

	path {

		fill: $dark-grey;

	}

	&.loading path {

		animation: breathe 2.4s $lc-ease-subtle infinite;

	}

	@each $letter in $lanecrawford {

		$i: index( $lanecrawford, $letter );
		$t: 0.05s * $i;

		&.loading path.#{ $letter } {

			animation-delay: $t;

		}

	}

}

@keyframes breathe {

	14% { fill: $light-grey; }
	22% { transform: translateY( -3px ); }

}
