
// default labels

.lc-label,
.mb-lc-label,
.tb-lc-label,
.dt-lc-label {

	color: $label-default;
	opacity: $label-opacity-default;
	box-shadow: $label-shadow-default;

	text-align: center;
	display: inline-block;
	vertical-align: middle;

	box-sizing: border-box;
	background-size: cover;
	background-position: center;

	transition-property: opacity, box-shadow, width, height, color;
	transition-duration: 0.15s;
	transition-timing-function: $lc-ease-subtle;

}

.lc-label--circle {

	border-radius: rem-calc( 100px );

}

.lc-label--loading {

	background-image: lc-image( "spinner50w.gif" );
	background-size: 80%;
	background-repeat: no-repeat;
	background-color: $very-light;

}

.lc-label--square:not( .lc-label--fixed ),
.lc-label--circle:not( .lc-label--fixed ) {

	box-sizing: content-box;

}

//
// for "double" layer chips, the
// html structure should be something like this:
//
// <label class="lc-label">
// 		<span class="lc-label__row">
// 			first-row
// 		</span>
// 		<span class="lc-label__row">
// 			second-row
// 		</span>
// 	</label>
//

.lc-label__row {

	display: block;
	line-height: 1rem;
	padding-left: $label-padding-small * 1.5;
	padding-right: $label-padding-small * 1.5;

}

.lc-label__row:nth-of-type( 1 ) {

	margin-top: 0.25rem;

}



@mixin lc-label-size( $prefix ) {

	.#{ $prefix }lc-label {

		width: $label-height;
		height: $label-height;
		line-height: $label-height;

	}

	.#{ $prefix }lc-label--small {

		width: $label-height-small;
		height: $label-height-small;
		line-height: $label-height-small;

	}

	.#{ $prefix }lc-label--large {

		width: $label-height-large;
		height: $label-height-large;
		line-height: $label-height-large;

	}

	.lc-label--square:not( .lc-label--fixed ),
	.lc-label--circle:not( .lc-label--fixed ) {

		&.#{ $prefix }lc-label {

			width: auto;
			min-width: $label-height - ( $label-padding * 2 );
			padding: 0 $label-padding;

		}

		&.#{ $prefix }lc-label--small {

			width: auto;
			min-width: $label-height-small - ( $label-padding-small * 2 );
			padding: 0 $label-padding-small;

		}

		&.#{ $prefix }lc-label--large {

			width: auto;
			min-width: $label-height-large - ( $label-padding * 2 );
			padding: 0 $label-padding;

		}

	}


}



// output styles in to breakpoints

@include lc-label-size( "" );

@each $size in $breakpoint-classes {

	@include breakpoint($size) {

		@if $size != "mb" {

			@include lc-label-size( $size + "-" );

		}

	}

}

@include breakpoint( mb only ) {

	@include lc-label-size( "mb-" );

}
