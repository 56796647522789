

@mixin error-message() {

	@include font( lc );

	color: $red;
	font-size: rem-calc( 14px );

	display: block;
	text-align: center;

}

@mixin error-message-new() {

	@include font( sb );

	color: $red;
	font-size: rem-calc( 16px );

	display: block;
	margin: rem-calc( 4px );

}

.lc-field-error {

	@include error-message;

	font-size: rem-calc( 12px );

}

.lc-field-error--new {

	@include error-message-new;

	font-size: rem-calc( 14px );
	text-align: inherit;

	animation-name: fadeAndDropInOnLoad;
	animation-duration: $default-time;
	animation-timing-function: $lc-ease-out;
	animation-fill-mode: backwards;

}

.lc-field-error--bordered {

	@include error-message;

	font-size: rem-calc( 12px );

	border-top: 1px solid $red;
	border-bottom: 1px solid $red;

	padding-top: 0.4rem;
	padding-bottom: 0.4rem;
	margin-top: 1rem;
	margin-bottom: 1rem;

}

.lc-general-error,
.lc-unhandled-error {

	@include error-message;

	border-top: 1px solid $red;
	border-bottom: 1px solid $red;

	padding-top: 1rem;
	padding-bottom: 1rem;
	margin-top: 2rem;
	margin-bottom: 2rem;

}
