
//
// <div class="lc-field">
//   <label class="lc-field__checkbox-label">
// 	   <input type="checkbox" class="lc-field__checkbox">
// 	   <span class="lc-field__checkbox-fake"></span>
// 	   <span class="lc-field__checkbox-text">
// 	     checkbox text
// 	   </span>
// 	 <label>
// </div>
//



// included from _mixins.scss
@include check-radio( "checkbox" );

.lc-field__checkbox-fake {

	border-radius: 0;

	&:after {

		background-color: transparent;
		background-image: lc-image( "check.png" );
		background-size: cover;
		background-repeat: no-repeat;

	}

}

.lc-field__checkbox-label--small .lc-field__checkbox-fake {

	&:after {

		top: rem-calc( 1px );
		bottom: rem-calc( 1px );
		left: rem-calc( 1px );
		right: rem-calc( 1px );

	}

}
