
// we define the types of overflow we want

$overflow-names: (
	of: overflow,
	ofx: overflow-x,
	ofy: overflow-y,
);

$overflow-types: (
	a: auto,
	s: scroll,
	h: hidden,
	v: visible
);


// mixin to be included for all the breakpoints

@mixin lc-overflow( $bp: "" ) {

	// loop through all the values and create
	// css classes to use

	@each $name in $overflow-names {

		$name-key: nth( $name, 1 );
		$name-val: nth( $name, 2 );

		@each $type in $overflow-types {

			$type-key: nth( $type, 1 );
			$type-val: nth( $type, 2 );

			.#{$bp}#{$name-key}-#{$type-key} {

				#{$name-val}: $type-val;

				// special case to make overflow-scroll have inertia
				@if ( $type-key == "s" ) {

					-webkit-overflow-scrolling: touch;

				}

			}

		}

	}

}



// output styles in to breakpoints

@include lc-overflow;

@each $size in $breakpoint-classes {

	@include breakpoint($size) {

		@if $size != "mb" {

			@include lc-overflow( $size + "-" );

		}

	}

}
