.consent-landing-wrapper {

	max-width: 48rem;

}

.pipl-consent-dialog,
.pipl-thankyou-dialog {

	padding: 10px 40px 0;

	&__header {

		h2 {

			font-weight: bold;
			margin: 2rem 0 1rem;

		}

	}

}
