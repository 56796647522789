
// we define the types of positions we want

$scale: $spacing;
$percent: 0;

$coords: (
	t: top,
	r: right,
	b: bottom,
	l: left
);


// mixin to be included for all the breakpoints

@mixin lc-adjustments( $bp: "" ) {

	// loop through all the positions and create
	// css classes to use

	@each $coord in $coords {

		$coord-prop: nth( $coord, 1 );
		$coord-val: nth( $coord, 2 );

		@each $size in $scale {

			.#{$bp}#{$coord-prop}-#{$size} {

				#{$coord-val}: unquote( $size + "px" );

			}

		}

	}

}

@mixin lc-adjustments-percent( $bp: "" ) {

	// loop through all the positions and create
	// css classes to use

	@each $coord in $coords {

		$coord-prop: nth( $coord, 1 );
		$coord-val: nth( $coord, 2 );


		@while $percent <= 100 {

			.#{$bp}#{$coord-prop}-#{$percent}p {

				#{$coord-val}: unquote( $percent + "%" );

			}

			$percent: $percent + 5;

		}

	}

}



// output styles in to breakpoints

@include lc-adjustments;
@include lc-adjustments-percent;

@each $size in $breakpoint-classes {

	@include breakpoint($size) {

		@if $size != "mb" {

			@include lc-adjustments( $size + "-" );
			@include lc-adjustments-percent( $size + "-" );

		}

	}

}

@include breakpoint(mb only) {

	@include lc-adjustments( mb-only- );

}

@include breakpoint(tb only) {

	@include lc-adjustments( tb-only- );

}
