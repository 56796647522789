
// we define the scale of letter-spacings we want
// to be able to use across the LC website first

$ls-scale: (
	"1-": rem-calc( -0.3px ),
	"n": normal,
	"1": rem-calc( 0.3px ),
	"2": rem-calc( 0.6px ),
	"3": rem-calc( 1.2px ),
	"4": rem-calc( 2.4px )
);


// mixin to be included for all the breakpoints

@mixin lc-letter-spacing( $bp: "" ) {

	// loop through all the sizes in our scale, and
	// generate css classes for each one.

	@each $size in $ls-scale {

		$name: nth( $size, 1 );
		$val: nth( $size, 2 );

		.#{$bp}ls-#{$name} {

			letter-spacing: $val;

		}

	}

}



// output styles in to breakpoints

@include lc-letter-spacing;
