
// reset the fieldset from the Normalize.css
// usually this style would be fine, but our fieldsets
// on mobile have only top-border, and some might have
// no border at all. We can apply classes to style.

// example of fieldset styling:
// https://app.frontify.com/d/6HJB4tyh5cb1/code#/molecules/fieldsets-legends

fieldset {

	border: none;
	padding: 0;
	margin: 0;

}

.max-fieldset {

	max-width: 42rem;

}

@media screen and (min-width: 35em) {

	.max-fieldset-narrow {

		max-width: rem-calc( 500 );

	}

}
