
// mixin to be included for all the breakpoints

@mixin lc-hr( $bp: "" ) {

	.#{$bp}hr,
	.#{$bp}hr--thin {

		display: block;
		background: $black;

	}

	.#{$bp}hr {

		height: rem-calc( 3px );

	}

	.#{$bp}hr--thin {

		height: rem-calc( 1px );

	}

}

hr {

	border: none;

}




// output styles in to breakpoints

@include lc-hr;

@each $size in $breakpoint-classes {

	@include breakpoint($size) {

		@if $size != "mb" {

			@include lc-hr( $size + "-" );

		}

	}

}

