
.fade {

	transition: opacity $fade-time $lc-ease;

}

.fade-in {

	transition: opacity $fade-time $lc-ease-out;

}

.fade-out {

	transition: opacity $fade-time $lc-ease-in;

}
