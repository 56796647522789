
$square-size: rem-calc( 90px );
$square-desktop-size: rem-calc( 90px );

$fade-size: rem-calc( 20px );


.wishlist-squares {

	&__fade {

		position: relative;
		overflow: hidden;

		&__before,
		&__after {

			position: absolute;
			left: -$fade-size;
			top: 0;
			bottom: 0;
			height: auto;
			width: $fade-size;
			z-index: 2;
			box-shadow: 0 0 ( $fade-size / 2 ) 5px rgba( 255, 255, 255, 1 );

			@include breakpoint( tb ) {

				position: absolute;
				top: -$fade-size;
				left: 0;
				right: 0;
				height: $fade-size;
				width: auto;

			}

		}

		&__after {

			left: auto;
			right: -$fade-size;

			@include breakpoint( tb ) {

				left: 0;
				right: 0;
				top: auto;
				bottom: -$fade-size;

			}

		}

	}

	&__wrapper {

		text-align: center;
		width: 100%;

		-webkit-overflow-scrolling: touch;

		@include breakpoint( mb only ) {

			overflow-x: auto;
			overflow-y: hidden;
			scroll-snap-type: x mandatory;

			/* stylelint-disable-next-line */
			overscroll-behavior: contain;

		}

		@include breakpoint( tb ) {

			overflow-x: hidden;
			overflow-y: auto;

			padding: 5px 0;
			max-height: rem-calc( 290px );

		}

	}

	&__list {

		@include breakpoint( mb only ) {

			white-space: nowrap;

		}

	}

	&__create-icon {

		right: -15px;

	}

}

.wishlist-square {

	margin: rem-calc( 5px );
	font-size: rem-calc( 10px );
	position: relative;
	vertical-align: top;
	scroll-snap-align: center;

	&:first-child {

		@include breakpoint( mb only ) {

			margin-left: rem-calc( 20px );

		}

	}

	&:last-child {

		@include breakpoint( mb only ) {

			margin-right: rem-calc( 20px );

		}

	}

	&--disabled {

		opacity: 0.4;
		cursor: default !important;

		.link {

			opacity: 1;

		}

	}

	&__images,
	&__image,
	&__name {

		display: block;
		position: relative;
		width: $square-size;

		@include breakpoint( tb ) {

			width: $square-desktop-size;

		}

	}

	&__images,
	&__image {

		height: $square-size;

		@include breakpoint( tb ) {

			height: $square-desktop-size;

		}

	}

	&__images {

		overflow: hidden;
		border-radius: 3px;
		border: 1px solid $very-light;

	}

	&--selected &__images {

		border-radius: 4px;
		border: 2px solid $black;

		&:after {

			content: "";
			border-radius: 3px;
			border: 1px solid white;

			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;

		}

	}

	&--create &__images {

		border: 1px dashed $grey;

	}

	&__image {

		background-color: $very-light;
		background-image: lc-image( "lc-square-white.png" );
		background-position: center 40%;
		background-size: 110%;
		background-repeat: no-repeat;

		&:at-least( 2 ) {

			width: 50%;
			position: absolute;
			box-shadow: 0 0 0 1px $very-light;
			background-size: 160%;
			background-position: center;

		}

		&:at-least( 4 ) {

			width: 50%;
			height: 50%;
			background-size: 120%;

		}

		// when at least 2 images, 2nd and 4th image is 50% left.
		&:nth-last-child( n+2 ) ~ &:nth-child( 2 ),
		&:nth-last-child( n+2 ) ~ &:nth-child( 4 ) {

			left: 50%;
			transform: translateX( 1px );

		}

		// when exactly 3 images, the 2nd and 3rd image is 50% left, 50% height.
		&:nth-last-child( n+3 ):nth-last-child( -n+3 ):first-child ~ &:nth-child( 2 ),
		&:nth-last-child( n+3 ):nth-last-child( -n+3 ):first-child ~ &:nth-child( 3 ) {

			height: 50%;
			left: 50%;
			transform: translateX( 1px );
			background-size: 120%;

		}

		&:nth-last-child( n+3 ) ~ &:nth-child( n+3 ) {

			top: 50%;
			margin-top: 1px;

		}

	}

	&--create &__image {

		background: transparent;

	}

	&--empty &__image {

		background-position: center;
		background-size: cover;

	}

	&__name {

		margin-top: 10px;
		overflow: hidden;
		text-overflow: ellipsis;

	}

	&--selected &__name {

		font-weight: bold;

	}

	&__lock {

		font-size: 1rem;
		top: 1px;
		left: 1px;
		position: absolute;
		text-shadow:
			 1px  1px 0 $white,
			-1px -1px 0 $white,
			 1px -1px 0 $white,
			-1px  1px 0 $white;

	}

	&__plus {

		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate( -50%, -50% );

	}

}

// special styling for the wishlist move overlay

.ui-dialog--wishlist-add-move-popup {

	@include breakpoint( mb only ) {

		position: fixed;
		top: auto !important;
		bottom: 0 !important;
		left: 0;
		right: 0;

		transform: translate3d( 0, 110%, 0 );

	}

	&.is-open {

		@include breakpoint( mb only ) {

			transition: transform $default-time $lc-ease-out !important;
			transform: translate3d( 0, 0%, 0 );

		}

	}

	.wishlist-squares__wrapper {

		/* stylelint-disable-next-line */
		overscroll-behavior: contain;

		@include breakpoint( tb ) {

			max-height: rem-calc( 320px );

		}

		@media screen and (min-width: map-get($breakpoints, tb)) and (orientation: portrait) {

			max-height: rem-calc( 450px );

		}

	}

}
