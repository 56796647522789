
// some modifiers for animation times.

.an-short,
.an-short.go {

	transition-duration: $default-time * 0.5;
	animation-duration: $default-time;

}

.an-default,
.an-default.go {

	transition-duration: $default-time;
	animation-duration: $default-time * 2;

}

.an-long,
.an-long.go {

	transition-duration: $default-time * 2;
	animation-duration: $default-time * 4;

}

.an-vlong,
.an-vlong.go {

	transition-duration: $default-time * 4;
	animation-duration: $default-time * 8;

}
