

// easing information for the menu
$ease-in: $lc-ease-in;
$ease-out: $lc-ease-out;
$ease-time: 0.28s;



.megamenu {

	font-size: 14px;

}

// we hide menu if it's in the navigation area for phones,
// because we don't want a FOUC during JS execution.
@include breakpoint( mb only ) {

	.navigation > .megamenu {

		display: none;

	}

}


html.nav-open,
html.nav-open body {

	overflow: hidden;

}

// this is because safari places position fixed relatively!?
.megamenu__wrapper {

	position: static;

}

.megamenu__bu {

	z-index: 15;

}

.megamenu__item-item--image {

	.megamenu__item-link {

		position: relative;

	}

}

.megamenu__close-icon {

	display: none;

}


@include breakpoint( mb only ) {


	.megamenu__bu-item,
	.megamenu__section-item,
	.megamenu__item-item {

		display: block;
		position: relative;

		font-size: 18px;
		text-align: center;

		border-bottom: 1px solid $black;

	}

	.megamenu__bu-item:last-of-type,
	.megamenu__item-item:last-of-type,
	.megamenu__column:last-of-type .megamenu__section:last-of-type .megamenu__section-item {

		border-bottom: none;

	}

	.megamenu__bu-link,
	.megamenu__section-link,
	.megamenu__item-link {

		display: block;
		padding: 10px;
		line-height: 26px;
		font-weight: normal;
		min-height: 46px;

		&:after {

			content: $icon-arrow-right-thick;
			position: absolute;
			right: 5px;
			top: 0;

			width: 20px;
			height: 48px;
			line-height: 48px;

			font-family: "lc-icons";
			font-size: 14px;

		}

	}

	.megamenu__item-link:after {

		display: none;

	}

	.megamenu__bu-container,
	.megamenu__item {

		@include off-screen-panel;

		box-shadow: none;
		padding: 60px 10px 0;
		opacity: 1;

		// change the transform position for sub-menus because
		// we want them coming from the right.

		transform: translate3d( 201%, 0, 0 );

		&.is-open {

			@include off-screen-panel-open;

		}

		&.is-locked {

			overflow-x: hidden !important;
			overflow-y: hidden !important;

		}

	}

	.megamenu__item-item--image {

		.megamenu__item-link {

			padding: 0;

		}

	}

	.megamenu__section-bar,
	.megamenu__section-seeall {

		display: none;

	}

	.megamenu__bu-item,
	.megamenu__section,
	.megamenu__item-item {

		&[ data-show-on-mobile = false ] {

			display: none;

		}

	}

}




@include breakpoint( tb ) {

	.megamenu__wrapper {

		position: relative;

	}

	.megamenu__bu {

		margin-left: rem-calc( -15px );
		margin-right: rem-calc( -15px );

		.megamenu.is-active & {

			margin-left: 0;
			margin-right: 0;

		}

	}

	.megamenu__bu-item {

		display: inline-block;
		border-bottom: 3px solid transparent;
		position: relative;
		top: 2px;
		padding: 0;
		margin-left: -3px;

		transition: border 0.15s $lc-ease-subtle;

		// a generic selector for sale period!
		&[ data-bu *= sale ] .megamenu__bu-link {

			background: $lc-sale;

		}

		&.is-active,
		&.is-temp,
		&:hover {

			border-bottom-color: $black;

		}

		&.is-temp,
		&:hover {

			font-weight: bold;

		}

	}

	.megamenu__bu-link {

		display: block;
		padding: 0.4em 1.2em;

	}




	.megamenu__bu-container {

		background: $white;
		position: absolute;

		right: rem-calc( -15px );
		left: rem-calc( -15px );

		text-align: left;
		font-size: rem-calc( 11px );
		line-height: 1.9;

		padding: rem-calc( 15px ) rem-calc( 15px ) 0;

		opacity: 0;
		visibility: hidden;
		z-index: 10;

		transition:
		 visibility 0.05s linear $ease-time,
			opacity $ease-time $ease-out;

		@include breakpoint( tb only ) {

			padding-right: 3em;

		}

		&.is-active {

			opacity: 1;
			visibility: visible;
			transition:
			 visibility 0.05s linear,
				opacity $ease-time $ease-in 0.05s;

		}

	}

	.megamenu__column {

		width: 20%;
		float: left;

		@include breakpoint( tb only ) {

			width: 25%;

		}

	}

	.megamenu__section {

		margin-bottom: 2em;

	}




	.megamenu__section-link,
	.megamenu__item-link {

		display: block;
		padding-left: 8px;
		border-left: 3px solid transparent;

		transition:
		   border 0.18s $lc-ease-subtle,
			color 0.18s $lc-ease-subtle;

	}

	.megamenu__item-link {

		padding-top: 4px;
		padding-bottom: 4px;
		line-height: 1.2;

	}

	.megamenu__section-link,
	.megamenu__item-link {

		&:hover {

			color: $grey;

		}

		&.is-active,
		&:active,
		&:focus {

			border-left-color: $black;

			&:hover {

				color: $black;

			}

		}

		.megamenu__section-seeall {

			color: $grey;

		}

		.megamenu__section-bar {

			color: $black;

		}

	}


	.megamenu__item-item--image {

		margin: 0;
		max-height: rem-calc( 160px );

		.megamenu__item-title {

			display: none;

		}

		.megamenu__item-link {

			padding: 0 0 0 8px;

			img {

				max-width: rem-calc( 160px );
				max-height: rem-calc( 160px );

			}

			&.is-active,
			&:active,
			&:focus {

				border-left-color: transparent;

			}

		}

	}


	.megamenu__section-link {

		font-weight: bold;

	}

	.megamenu__section-bar {

		display: inline-block;
		margin: 0 5px;

	}

	.megamenu__section-seeall {

		font-weight: normal;

	}




	.megamenu-overlay {

		position: fixed;
		background: rgb( 43, 43, 43 );
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;

		z-index: 10;

		opacity: 0;
		visibility: hidden;
		transition:
		 visibility 0.05s linear $ease-time,
			opacity $ease-time $ease-out;

		&.is-active {

			opacity: 0.7;
			visibility: visible;
			transition:
			 visibility 0.05s linear,
				opacity $ease-time $ease-in 0.05s;

		}

	}

	.megamenu-fake-overlay {

		// this is an invisible overlay used to create rollover effects
		// when you leave the bu items by mousing upwards.

		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		display: none;
		z-index: 14;

		&.is-active {

			display: block;

		}

	}

	.megamenu__bu-item,
	.megamenu__section,
	.megamenu__item-item {

		&[ data-show-on-desktop = false ] {

			display: none;

		}

	}

	.megamenu__bu-container {

		.megamenu__section-link[ href = "" ],
		.megamenu__item-link[ href = "" ] {

			cursor: text;

		}

		.megamenu__section-link[ href = "" ].is-active,
		.megamenu__section-link[ href = "" ]:active,
		.megamenu__section-link[ href = "" ]:focus,
		.megamenu__item-link[ href = "" ].is-active,
		.megamenu__item-link[ href = "" ]:active,
		.megamenu__item-link[ href = "" ]:focus {

			border-left-color: transparent;

		}

	}

}




@include breakpoint( tb only ) {


	.megamenu__close-icon {

		width: 36px;
		display: block;

	}


}
