


// mixin to be included for all the breakpoints

@mixin lc-weights( $bp: "" ) {


	.#{$bp}txt-bold {

		font-weight: 600;

	}

	.#{$bp}txt-light {

		font-weight: 300;

	}

	.#{$bp}txt-italic {

		font-style: italic;

	}

	.#{$bp}txt-normal {

		font-style: normal;

	}

	.#{$bp}txt-underline {

		text-decoration: underline;

	}

}


b,
strong {

	font-weight: 600;

}

em,
i:not( .icon ):not( .lc-icon ):not( [ data-icon ] ):not( [ data-care-icon ] ) {

	font-style: italic;

}

strike,
.strike {

	text-decoration: line-through;

}

// this is just to cater for many old content-fragments
// which use the ".underline" class on links :(
.underline {

	text-decoration: underline;

}

.nocase {

	text-transform: none;

}

.capital {

	text-transform: capitalize;

}

.lower {

	text-transform: lowercase;

}

.upper {

	text-transform: uppercase;

}

.super,
.sub {

	position: relative;
	font-size: 0.625rem;
	line-height: 1;
	vertical-align: super;

}

.sub {

	vertical-align: sub;

}


// output styles in to breakpoints

@include lc-weights;

@each $size in $breakpoint-classes {

	@include breakpoint($size) {

		@if $size != "mb" {

			@include lc-weights( $size + "-" );

		}

	}

}
