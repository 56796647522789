
::selection {

	background-color: $lc-highlight;
	color: $black;

}

@import "text";
@import "links";
@import "sizes";
@import "styles";
@import "icons";
@import "paragraphs";
@import "line-height";
@import "letter-spacing";
@import "headings";
@import "address";
