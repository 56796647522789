
$dropdown-height: rem-calc( 50px );


.lc-dropdown {

	&--button-style {

		height: $dropdown-height;

	}

	&--button-style &__list {

		min-width: 100%;

	}

	&--button-style &__display,
	&--button-style &__icon {

		position: absolute;

	}

	&--button-style &__display {

		left: 0;
		right: 0;
		border: 1px solid $grey;
		padding-left: 1rem;
		padding-right: 1rem;
		height: $dropdown-height;
		transition: border 0.3s $lc-ease-subtle;

		&-text {

			text-overflow: ellipsis;
			display: block;
			overflow: hidden;
			white-space: nowrap;
			height: $dropdown-height;
			line-height: $dropdown-height;

		}

	}

	&--button-style &__icon {

		top: 50%;
		right: rem-calc( 12px );
		margin-top: -7px;

	}

	&--button-style:hover &__display,
	&--button-style.is-active &__display {

		border-color: $black;

	}

	&--button-style.is-active &__display {

		box-shadow: 0 0 0 1px $light-grey;

	}

}
