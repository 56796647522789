

$sheight: 46px;
$sclose: 30px;




.header-search {

	height: $sheight;

	position: absolute;
	bottom: -( $sheight + 1 );
	top: auto;
	left: 0;
	right: 0;

	background: $white;
	text-align: center;

	// it is 10 because select-boxes have "9 !important" (no idea why) and
	// so we need the search to go over the select boxes.
	z-index: 10;

	visibility: hidden;
	opacity: 0;

	transition:
	       opacity 0.2s ease-in 0,
		visibility 0.05s linear 0.3s;

	@include breakpoint( mb only ) {

		&:after {

			position: absolute;
			content: "";
			width: auto;
			left: 0;
			right: 0;
			bottom: 0;
			height: 1px;
			background: $black;

		}

	}

	@include breakpoint( tb ) {

		bottom: -$sheight;
		border-bottom: 1px solid $black;

	}

	&.is-open {

		opacity: 1;
		visibility: visible;
		transition:
		       opacity 0.2s ease-out 0.05s,
			visibility 0 linear 0;

	}

}




.header-search__close-icon {

	width: $sclose;
	height: $sclose;
	line-height: ($sclose + 2);
	border-radius: $sclose;

	position: absolute;
	right: 10px;
	top: 7px;
	cursor: pointer;

	transition: background-color 0.2s ease-out;

	@include breakpoint( tb ) {

		right: 15px;

	}

	&.is-clear {

		background-color: $light-grey;

	}

}


.header-search__input {

	@include font(lc);

	border: none;
	position: absolute;
	left: 11%;
	width: 78%;
	height: 100%;
	text-align: center;
	font-size: 16px;

	&:focus {

		outline: none;

	}

}




.navigation__search-icon {

	display: none;

	@include breakpoint( tb ) {

		display: block;
		font-size: 16px;

		width: 36px;
		text-align: center;

		position: absolute;
		right: 12px;
		top: auto;
		bottom: 7px;

		cursor: pointer;
		z-index: 17;
		user-select: none;

	}

	@include breakpoint( hg ) {

		right: 4px;

	}

}
