
$table-border-color: $light-grey;
$cell-padding: rem-calc( 5px );


@mixin lc-table( $bp: "" ) {

	.lc-table-details {

		th {

			font-weight: inherit;

		}

		th,
		td {

			padding: ( $cell-padding * 2 ) $cell-padding;

		}

		thead tr th,
		tr:not( :last-child ) td {

			border-bottom: 1px solid $table-border-color;

		}

	}

}



@include lc-table;
