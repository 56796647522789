
// mixin for including font styles.

@mixin font( $type ) {

	@if $type == "lc" {

		font-family: $font-lc;
		text-transform: uppercase;
		letter-spacing: rem-calc( 0.6px );

	}
	@elseif $type == "sb" {

		font-family: $font-lc;
		text-transform: none;
		letter-spacing: normal;

	}
	@elseif $type == "mono" {

		font-family: $font-mono;
		text-transform: none;
		letter-spacing: normal;

	}

}

// mixin for including font fallbacks.

@mixin font-fallback( $type ) {

	@if $type == "lc" {

		font-family: $font-lc-fallback;

	}
	@elseif $type == "sb" {

		font-family: $font-lc-fallback;

	}

}



%font-lc {

	@include font( lc );

}

%font-sb {

	@include font( sb );

}

%font-mono {

	@include font( mono );

}

%font-lc-fallback {

	@include font-fallback( lc );

}

%font-sb-fallback {

	@include font-fallback( sb );

}

body {

	// using "body" because ":root" was causing
	// email build process to fail when trying to
	// inline the :root tag

	--font-lc: $font-lc;
	--font-sb: $font-lc;
	--font-mono: $font-mono;
	--font-lc-fallback: $font-lc-fallback;
	--font-sb-fallback: $font-lc-fallback;

}
