

h1,
h2,
h3,
h4,
h5,
h6 {

	@extend .txt-lc;

	font-weight: normal;
	margin: rem-calc( 20px ) 0;

}

h1 {

	line-height: 1.2;
	font-size: rem-calc( 24px );

	@include breakpoint( tb ) {

		font-size: rem-calc( 32px );

	}

}

.bar-heading {

	display: block;
	position: relative;
	overflow: hidden;
	text-align: center;
	line-height: 1;

	&__text {

		display: inline-block;
		position: relative;
		max-width: 85%;
		padding-left: rem-calc( 10px );
		padding-right: rem-calc( 10px );

		&:before,
		&:after {

			content: "";
			display: block;
			position: absolute;
			background: currentColor;

			top: 50%;
			left: auto;
			right: 100%;
			width: 2000px;

			height: rem-calc( 3px );
			margin-top: rem-calc( -2px );

		}

		&:after {

			left: 100%;
			right: auto;

		}

	}

	&--thin &__text {

		&:before,
		&:after {

			height: rem-calc( 1px );
			margin-top: rem-calc( -1px );

		}

	}

}
