

.aspect-ratio {

	position: relative;

	&:before {

		content: "";
		display: block;
		width: 100%;
		padding-top: 100%;

	}

	&--product:before {

		padding-top: 137.5%;

	}

}

