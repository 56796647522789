
// mixin to be included for all the breakpoints

@mixin lc-paddings( $bp: "" ) {

	@each $pos in $spacing {

		.#{$bp}pad-#{$pos} {

			padding: rem-calc( $pos );

		}

	}

	@each $pos in $spacing {

		.#{$bp}pad-v#{$pos} {

			padding-top: rem-calc( $pos );
			padding-bottom: rem-calc( $pos );

		}

		.#{$bp}pad-h#{$pos} {

			padding-left: rem-calc( $pos );
			padding-right: rem-calc( $pos );

		}

	}

	@each $pos in $spacing {

		.#{$bp}pad-l#{$pos} {

			padding-left: rem-calc( $pos );

		}

		.#{$bp}pad-r#{$pos} {

			padding-right: rem-calc( $pos );

		}

		.#{$bp}pad-t#{$pos} {

			padding-top: rem-calc( $pos );

		}

		.#{$bp}pad-b#{$pos} {

			padding-bottom: rem-calc( $pos );

		}

	}

}



// output styles in to breakpoints

@include lc-paddings;

@each $size in $breakpoint-classes {

	@include breakpoint($size) {

		@if $size != "mb" {

			@include lc-paddings( $size + "-" );

		}

	}

}
