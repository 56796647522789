
// animations to run when the page is
// rendered for a smooth animation experience

@keyframes fadeInOnLoad {

	from { opacity: 0; }

}

@keyframes dropInOnLoad {

	from { transform: translateY( rem-calc( -10px ) ); }
	to { transform: translateY( 0 ); }

}

@keyframes fadeAndDropInOnLoad {

	from {

		opacity: 0;
		transform: translateY( rem-calc( -10px ) );

	}

	to {

		transform: translateY( 0 );

	}

}

@keyframes popInOnLoad {

	from { transform: translateY( rem-calc( 20px ) ); }
	to { transform: translateY( 0 ); }

}

@keyframes fadeAndPopInOnLoad {

	from {

		opacity: 0;
		transform: translateY( rem-calc( 20px ) );

	}

	to {

		transform: translateY( 0 );

	}

}

@keyframes fadeAndShiftROnLoad {

	from {

		opacity: 0;
		transform: translateX( rem-calc( -20px ) );

	}

	to {

		transform: translateY( 0 );

	}

}

@keyframes fadeAndShiftLOnLoad {

	from {

		opacity: 0;
		transform: translateX( rem-calc( 20px ) );

	}

	to {

		transform: translateY( 0 );

	}

}

@keyframes fadeAndGrowOnLoad {

	from {

		opacity: 0;
		transform: scale( 0.5 );

	}

	to {

		transform: scale( 1 );

	}

}

.an-load-fade,
.an-load-drop,
.an-load-fade-drop,
.an-load-fade-pop,
.an-load-grow,
.an-load-fade-shift-r,
.an-load-fade-shift-l {

	animation-duration: $default-time;
	animation-timing-function: $lc-ease-out;
	animation-fill-mode: backwards;

}

.an-load-fade {

	animation-name: fadeInOnLoad;

}

.an-load-drop {

	animation-name: dropInOnLoad;

}

.an-load-fade-drop {

	animation-name: fadeAndDropInOnLoad;

}

.an-load-pop {

	animation-name: popInOnLoad;

}

.an-load-fade-pop {

	animation-name: fadeAndPopInOnLoad;

}

.an-load-fade-shift-r {

	animation-name: fadeAndShiftROnLoad;

}

.an-load-fade-shift-l {

	animation-name: fadeAndShiftLOnLoad;

}

.an-load-grow {

	animation-name: fadeAndGrowOnLoad;

}
