
// color chip labels

.lc-label--color,
.lc-label--colour {

	border-radius: rem-calc( 100px );
	box-shadow: $label-shadow-inner;

	&:hover,
	&:focus,
	&:active,
	&.lc-label--active {

		box-shadow: $label-shadow-active-inner;

	}

}
