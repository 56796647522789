
// mixin to be included for all the breakpoints

@mixin lc-popout-shadow( $bp: "" ) {

	.pop-shadow {

		box-shadow: $lc-popout-shadow;

	}

	.card-shadow {

		box-shadow: $lc-card-shadow;

	}

	.subtle-shadow {

		box-shadow: $lc-subtle-shadow;

	}

}




// output styles in to breakpoints

@include lc-popout-shadow;
