

@mixin lc-input-placeholders( $bp: "" ) {

	.lc-field__placeholder {

		position: absolute;
		top: 0;
		left: 0;
		right: 0;

		color: $grey;
		height: $lc-field-height;
		line-height: 3.7rem;
		vertical-align: middle;

		transition: color 0.05s $lc-ease-subtle;

		cursor: text;
		pointer-events: none;

		:not( .lc-field__placeholder--select ) {

			cursor: text;

		}

		&.is-shifted {

			color: $dark-grey;

			height: 0.75rem;
			line-height: 0.75;
			transform: scale( 0.75 );

		}

	}

	.lc-field--no-placeholder .lc-field__placeholder.is-shifted {

		opacity: 0;

	}

}


@include lc-input-placeholders;
