
// we define the types of positions we want

$scale: (
	r: relative,
	a: absolute,
	s: static,
	f: fixed,
	stky: sticky
);


// mixin to be included for all the breakpoints

@mixin lc-position( $bp: "" ) {

	// loop through all the positions and create
	// css classes to use

	@each $size in $scale {

		$name: nth( $size, 1 );
		$val: nth( $size, 2 );

		.#{$bp}pos-#{$name} {

			position: $val;

		}

	}

}



// output styles in to breakpoints

@include lc-position;

@each $size in $breakpoint-classes {

	@include breakpoint($size) {

		@if $size != "mb" {

			@include lc-position( $size + "-" );

		}

	}

}

@include breakpoint(mb only) {

	@include lc-position( mb-only- );

}

@include breakpoint(tb only) {

	@include lc-position( tb-only- );

}
