


.lc-tabs {

	border-bottom: rem-calc( 1px ) solid;

	&__tab {

		display: inline-block;
		list-style: none;
		margin-left: -3px;
		margin-right: -3px;

	}

	&__link {

		display: block;
		position: relative;
		top: 2px;

		padding-top: 5px;
		padding-bottom: 5px;
		padding-left: 20px;
		padding-right: 20px;

		border-bottom: rem-calc( 3px ) solid transparent;
		transition: border 0.3s $lc-ease-subtle;

		&:hover,
		&:active,
		&:focus {

			border-bottom-color: $grey;

		}

		&[ aria-selected = true ] {

			font-weight: bold;
			border-bottom-color: $black;

		}

	}

	&__panel {

		display: none;

		&.is-active {

			display: block;

		}

		> :first-child {

			margin-top: 0;

		}

		> :last-child {

			margin-bottom: 0;

		}

	}

}

