
.lc-conversion {

	max-width: rem-calc( 400px );
	margin: auto;

}

.lc-conversion__equals {

	top: 50%;
	left: 50%;
	transform: translate( -50%, 4px );

	&:before,
	&:after {

		content: "";
		width: rem-calc( 12px );
		height: rem-calc( 1px );
		background: $grey;
		position: relative;
		display: block;

	}

	&:after {

		top: rem-calc( 4px );

	}

}
