
// empty declarations are left over from
// converting to the LC2 approach.
// leaving for posterity.

// .flyout-account {
//
// }
//
// .flyout-account__header {
//
// }
//
// .flyout-account__title {
//
// }
//
// .account-title__my-account {
//
// }
//
// .account-title__my-account,
// .account-title__account-name {
//
// 	}

.flyout-account__content {

	overflow: hidden;

	transform: translate3d( 0, 0, 0 );
	backface-visibility: hidden;
	perspective: 1000;

	will-change: height;
	transition: height 0.35s ease;

}

.account-title__arrow {

	position: absolute;
	right: 8px;
	top: 50%;
	margin-top: -9px;

	font-size: 14px;
	line-height: 18px;
	height: 18px;

	transition: transform 0.2s ease;

	.flyout-account__header.is-open & {

		transform: rotate( 180deg );

	}

}

// .flyout-account__list {
//
// }
//
// .flyout-account__list-item {
//
// }
//
// .flyout-account__list-link {
//
// }




@include breakpoint( tb ) {

	.header-my-account {

		z-index: 51;

		width: 240px;
		transform: translateY( -1px );

		display: none;

	}

}

