

$acc-b-top: 0 rem-calc( -1px ) 0 0 $grey;
$acc-b-top-semi: 0 rem-calc( -1px ) 0 0 $black;
$acc-b-top-active: 0 rem-calc( -3px ) 0 0 $black;

$acc-b-bottom: 0 rem-calc( 1px ) 0 0 $grey;
$acc-b-bottom-semi: 0 rem-calc( 1px ) 0 0 $black;
$acc-b-bottom-active: 0 rem-calc( 1px ) 0 0 $black;


.lc-accordion {


	&__item {

		box-shadow: $acc-b-top;
		transition: box-shadow 0.3s $lc-ease-subtle;

		&:last-of-type {

			box-shadow: $acc-b-top, $acc-b-bottom;

		}

		&:hover {

			box-shadow: $acc-b-top-semi;

		}

		&:last-of-type:hover {

			box-shadow: $acc-b-top-semi, $acc-b-bottom-semi;

		}

		&.is-active {

			box-shadow: $acc-b-top-active;

		}

		&:last-of-type.is-active {

			box-shadow: $acc-b-top-active, $acc-b-bottom-active;

		}

	}

	&__link {

		display: block;
		position: relative;

		&[ aria-selected = true ] {

			font-weight: bold;

		}

		[ data-icon ] {

			position: absolute;
			right: 1em;

			height: rem-calc( 14px );
			top: 50%;

			transform: translateY( -50% ) rotateZ( 0deg );
			transition: transform 0.3s $lc-ease-subtle;

		}

		&[ aria-selected = true ] [ data-icon ] {

			transform: translateY( -50% ) rotateZ( -180deg );

		}

	}

	&__panel {

		display: none;

		@media print {

			.lc-accordion__panel {

				display: block !important;

			}

		}

		> :first-child {

			margin-top: 0;

		}

		> :last-child {

			margin-bottom: 0;

		}

	}


}

