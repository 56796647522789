

@mixin lc-selects( $bp: "" ) {

	select {

		// override weird safari/ios default rounding corners.
		border-radius: 0;
		appearance: none;

	}

	.lc-field__select {

		@include font( lc );

		height: $lc-select-height;
		padding-left: 1rem;
		padding-right: 1rem;

		&.is-replaced {

			// make it hidden, and on top of the label.
			opacity: 0;
			position: relative;
			z-index: 1;

		}

		&:-webkit-autofill + .lc-field__select-label {

			// enable custom "autofill" background color
			box-shadow: inset 0 0 0 100px $lc-autofill;

		}

	}

	.lc-field__select-label,
	.lc-field__select-icon {

		position: absolute;

		top: 0;
		right: 15px;

		height: $lc-select-height;
		line-height: $lc-select-height;

		cursor: pointer;
		pointer-events: none;

	}

	.lc-field__select-label {

		@include font( lc );

		left: 0;
		right: 0;

		border: 1px solid $grey;

		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;

		&:not( [ class *= "bg-" ] ) {

			background-color: $white;

		}

	}

	.lc-field__select-icon {

		top: 2px;

	}

	.lc-field__select:active + .lc-field__select-label,
	.lc-field__select:focus + .lc-field__select-label,
	.lc-field__select:hover + .lc-field__select-label,
	.lc-field__select-label:hover,
	.lc-field__select-label:focus {

		border-color: $black;

	}

	.lc-field__select:focus + .lc-field__select-label,
	.lc-field__select-label:focus {

		box-shadow: 0 0 0 1px $light-grey;

	}

	// scss-lint:disable SelectorFormat
	.lc-field__select.is-error + .lc-field__select-label,
	.lc-field__select.errorBorder + .lc-field__select-label,
	.lc-field__select:invalid + .lc-field__select-label {

		border-color: $red;
		border-width: 2px;

	}

	.lc-field__select.disabled + .lc-field__select-label,
	.lc-field__select[ disabled ] + .lc-field__select-label,
	.lc-field__select[ disabled ] ~ .lc-field__select-icon {

		color: $disabled-text;
		border-color: $disabled-border;

	}

}



@mixin lc-selects-new( $bp: "" ) {

	.lc-field__select--new {

		@include font( sb );

		padding-left: rem-calc( 10px );
		padding-right: rem-calc( 10px );
		padding-bottom: 0;
		padding-top: 0;

	}

	.lc-field__select--new ~ .lc-field__select-label {

		@include font( sb );

		border: 1px solid $grey;
		box-shadow: 0 0 0 1px transparent;

	}

	.lc-field__select--new,
	.lc-field__select--new ~ .lc-field__select-label,
	.lc-field__select--new ~ .lc-field__select-icon {

		height: $lc-select-height-new;
		line-height: $lc-select-height-new;

	}

	.lc-field__select--new:focus + .lc-field__select-label,
	.lc-field__select-label:focus {

		box-shadow: 0 0 0 1px $black;

	}

	// scss-lint:disable SelectorFormat
	.lc-field__select--new.is-error + .lc-field__select-label,
	.lc-field__select--new.errorBorder + .lc-field__select-label,
	.lc-field__select--new:invalid + .lc-field__select-label {

		border-width: 1px;

	}

	// scss-lint:disable SelectorFormat
	.lc-field__select--new.is-error:focus + .lc-field__select-label,
	.lc-field__select--new.errorBorder:focus + .lc-field__select-label,
	.lc-field__select--new:invalid:focus + .lc-field__select-label {

		box-shadow: 0 0 0 1px $red;

	}

}


@include lc-selects;
@include lc-selects-new;
