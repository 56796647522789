
// inverted style labels

.lc-label--invert,
.lc-label--inverted {

	color: $label-active;
	box-shadow: $label-shadow-invert;

	&:hover,
	&:focus,
	&:active,
	&[ class *= "lc-label--active" ] {

		color: $label-inner;
		opacity: $label-opacity-active;
		box-shadow: $label-shadow-active-inset;

		background-color: $label-active;

	}

}
