
/* stylelint-disable length-zero-no-unit */

.an-pop-out {

	transform: translateY( 0rem );

	&.go {

		transition:
			transform $drop-time $lc-ease-in,
			opacity $drop-time $lc-ease-in;

		transform: translateY( -1rem );
		opacity: 0;

		&.far {

			transform: translateY( -3rem );

		}

	}

}

.an-pop-in {

	transform: translateY( 1rem );
	opacity: 0;

	&.far {

		transform: translateY( 3rem );

	}

	&.go {

		transition:
			transform $drop-time $lc-ease-out,
			opacity $drop-time $lc-ease-out;

		transform: translateY( 0rem );
		opacity: 1;

	}

}
