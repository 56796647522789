
$padding: rem-calc( 5px );

$bg: $very-light;
$ease: $lc-ease;



.lc-dropdown {

	position: relative;

	&,
	& * {

		user-select: none;

	}

	&.is-active &__display {

		background-color: $bg;

	}

	&__display {

		display: block;
		transition: background 0.2s $ease;

		&:hover,
		&:active,
		&:focus {

			font-weight: bold;

		}

	}

	&__icon {

		font-size: 12px;
		position: relative;
		height: 14px;
		width: 14px;
		top: -2px;
		margin-left: 5px;

		&:before {

			position: absolute;
			width: 14px;
			height: 14px;
			top: -1px;
			left: 0;

		}

	}

	&__list {

		background-color: $bg;
		padding: $padding;
		min-width: 15rem;
		z-index: 2; // it should be above select-lists
		position: absolute;
		bottom: 0;

		transition: transform 0.3s $ease, opacity 0.3s $ease;
		transform-origin: top;
		transform: translateY( 100% ) scaleY( 0 );
		opacity: 0;

		&--transparent {

			background-color: rgba( $bg, 0.9 );

		}

		&.is-open {

			transform: translateY( 100% ) scaleY( 1 );
			opacity: 1;

		}

		&-item {

			opacity: 0;
			transition: opacity 0.2s $ease;
			padding: $padding;

			&.is-selected {

				font-weight: bold;

			}

		}

		&.is-open &-item {

			opacity: 1;

			&:nth-of-type( 1 ) { transition-delay: 0.03s; }
			&:nth-of-type( 2 ) { transition-delay: 0.06s; }
			&:nth-of-type( 3 ) { transition-delay: 0.09s; }
			&:nth-of-type( 4 ) { transition-delay: 0.12s; }
			&:nth-of-type( 5 ) { transition-delay: 0.15s; }
			&:nth-of-type( n+6 ) { transition-delay: 0.18s; }

			&.is-disabled {

				opacity: 0.35 !important;

			}

		}

		&-item:hover,
		&-item:focus,
		&-item:active {

			background-color: rgba( 0, 0, 0, 0.05 );

		}


	}

}
