
// https://company-36148.frontify.com/document/45243#/ui-components/forms-toggle-switch
//
// <div class="lc-field">
//   <label class="lc-field__toggle-label">
// 	   <input type="checkbox" class="lc-field__toggle">
// 	   <span class="lc-field__toggle-fake"></span>
// 	   <span class="lc-field__toggle-text">
// 	     toggle text
// 	   </span>
// 	 <label>
// </div>
//

$toggle-name: "toggle";
$toggle-bg-off: #ccc;
$toggle-bg-on: $black;
$toggle-height: 1.5em;
$toggle-width: $toggle-height * 1.75;
$toggle-button-gap: 0.125em;
$toggle-button-size: $toggle-height - ( $toggle-button-gap * 2 );

// some variables included from ../forms/_mixins.scss

.lc-field {

	&__#{ $toggle-name } {

		opacity: 0;
		appearance: none;
		position: absolute;
		margin-left: -9999em;


		&-label,
		&-label * {

			user-select: none;

		}

		&-label {

			position: relative;

		}

		&-fake {

			// the track which button moves in

			width: $toggle-width;
			height: $toggle-height;
			border-radius: $toggle-height;
			vertical-align: middle;
			display: inline-block;
			position: relative;
			left: rem-calc( 1px );

			background: $toggle-bg-off;
			box-shadow: inset 0 2px 7px -5px $black, 0 0 0 1px rgba( $white, 0.5 );
			transition: background-color 0.2s $lc-ease-subtle;

			&:after {

				// the button that moves

				content: "";
				position: absolute;
				top: $toggle-button-gap;
				left: $toggle-button-gap;
				width: $toggle-button-size;
				height: $toggle-button-size;

				background: $white;
				border: 1px solid transparent;
				border-radius: $toggle-button-size;
				box-shadow: 0 2px 6px -3px $black;
				transition: transform 0.15s $lc-ease, border 0.15s $lc-ease;

			}

		}

		&-text {

			display: inline-block;
			vertical-align: middle;

		}

		&:not( :checked ) ~ &-text,
		&:not( :checked ) ~ * &-text {

			opacity: 0.75;

		}

		&:checked ~ &-fake,
		&:checked ~ * &-fake,
		&--state ~ &-fake,
		&--state ~ * &-fake {

			background: $toggle-bg-on;

		}

		&-label:not( .disabled ):not( .is-disabled ):hover > &:not( [ disabled ] ):not( .disabled ):not( &--state ) ~ &-fake:not( .disabled ),
		&-label:not( .disabled ):not( .is-disabled ):hover > &:not( [ disabled ] ):not( .disabled ):not( &--state ) ~ * &-fake:not( .disabled ),
		&:not( [ disabled ] ):not( .disabled ):not( &--state ):focus ~ &-fake:not( .disabled ),
		&:not( [ disabled ] ):not( .disabled ):not( &--state ):focus ~ * &-fake:not( .disabled ) {

			background: darken( $toggle-bg-off, 10% );

		}

		&-label:not( .disabled ):not( .is-disabled ):hover > &:not( [ disabled ] ):not( .disabled ):not( &--state ):checked ~ &-fake:not( .disabled ),
		&-label:not( .disabled ):not( .is-disabled ):hover > &:not( [ disabled ] ):not( .disabled ):not( &--state ):checked ~ * &-fake:not( .disabled ),
		&:not( [ disabled ] ):not( .disabled ):not( &--state ):checked:focus ~ &-fake:not( .disabled ),
		&:not( [ disabled ] ):not( .disabled ):not( &--state ):checked:focus ~ * &-fake:not( .disabled ) {

			background: lighten( $toggle-bg-on, 25% );

		}

		&:checked ~ &-fake:after,
		&:checked ~ * &-fake:after {

			transform: translateX( 89% );

		}

		&-label:not( .disabled ):not( .is-disabled ):hover > &:not( [ disabled ] ):not( .disabled ) ~ &-fake:not( .disabled ):after,
		&-label:not( .disabled ):not( .is-disabled ):hover > &:not( [ disabled ] ):not( .disabled ) ~ * &-fake:not( .disabled ):after,
		&:not( [ disabled ] ):not( .disabled ):focus ~ &-fake:not( .disabled ):after,
		&:not( [ disabled ] ):not( .disabled ):focus ~ * &-fake:not( .disabled ):after {

			transform: translateX( 6% );
			border: 1px solid rgba( 0, 0, 0, 0.5 );

		}

		&-label:not( .disabled ):not( .is-disabled ):hover > &:not( [ disabled ] ):not( .disabled ):checked ~ &-fake:not( .disabled ):after,
		&-label:not( .disabled ):not( .is-disabled ):hover > &:not( [ disabled ] ):not( .disabled ):checked ~ * &-fake:not( .disabled ):after,
		&:not( [ disabled ] ):not( .disabled ):checked:focus ~ &-fake:not( .disabled ):after,
		&:not( [ disabled ] ):not( .disabled ):checked:focus ~ * &-fake:not( .disabled ):after {

			transform: translateX( 83% );

		}

		&-label.disabled &-fake,
		&-label.disabled * &-fake,
		&-label.disabled &-text,
		&-label.disabled * &-text,
		&-label.is-disabled &-fake,
		&-label.is-disabled * &-fake,
		&-label.is-disabled &-text,
		&-label.is-disabled * &-text,
		&-label &-fake.disabled,
		&-label * &-fake.disabled,
		&-label &-text.disabled,
		&-label * &-text.disabled,
		&-label > &[ disabled ] ~ &-fake,
		&-label > &[ disabled ] ~ * &-fake,
		&-label > &[ disabled ] ~ &-text,
		&-label > &[ disabled ] ~ * &-text {

			opacity: 0.35;

		}

		&-fake + &-text,
		&-text + &-fake {

			margin-left: 0.5em;

		}

	}

}

// special custom handling for very small
// toggle switches, because at 12/14px the
// track and button get distorted using
// non-absolute pixel sizes.

$tiny-toggle-height: rem-calc( 16px );
$tiny-toggle-width: rem-calc( 28px );
$tiny-toggle-button-gap: rem-calc( 1px );
$tiny-toggle-button-size: $tiny-toggle-height - ( $tiny-toggle-button-gap * 2 );

$small-toggle-height: rem-calc( 20px );
$small-toggle-width: rem-calc( 36px );
$small-toggle-button-gap: rem-calc( 1px );
$small-toggle-button-size: $small-toggle-height - ( $small-toggle-button-gap * 2 );

.txt-12.lc-field__toggle-fake {

	// the track which button moves in

	width: $tiny-toggle-width;
	height: $tiny-toggle-height;
	border-radius: $tiny-toggle-height;

	&:after {

		// the button that moves

		top: $tiny-toggle-button-gap;
		left: $tiny-toggle-button-gap;
		width: $tiny-toggle-button-size;
		height: $tiny-toggle-button-size;
		border-radius: $tiny-toggle-button-size;

	}

}

.txt-14.lc-field__toggle-fake {

	// the track which button moves in

	width: $small-toggle-width;
	height: $small-toggle-height;
	border-radius: $small-toggle-height;

	&:after {

		// the button that moves

		top: $small-toggle-button-gap;
		left: $small-toggle-button-gap;
		width: $small-toggle-button-size;
		height: $small-toggle-button-size;
		border-radius: $small-toggle-button-size;

	}

}
