

.lc-tag {

	&:not( .lc-tag--large ):not( [ class *= txt-1 ] ):not( [ class *= txt-2 ] ):not( [ class *= txt-3 ] ) {

		font-size: rem-calc( 10px );

	}

	color: $dark-grey;
	background-color: $very-light;
	border: 1px solid $very-light;

	padding-left: rem-calc( 4px );
	padding-right: rem-calc( 4px );
	padding-top: rem-calc( 1px );
	padding-bottom: rem-calc( 1px );

	margin-top: rem-calc( 1px );
	margin-bottom: rem-calc( 1px );

	border-radius: 50px;

	transition-property: color, border, background;
	transition-duration: $default-time;
	transition-timing-function: $lc-ease;

	user-select: none;

	&--large {

		font-size: rem-calc( 14px );

		padding-left: rem-calc( 12px );
		padding-right: rem-calc( 12px );
		padding-top: rem-calc( 4px );
		padding-bottom: rem-calc( 4px );

	}

	&--selected {

		background-color: $very-dark;
		border-color: $very-dark;
		color: $white;

	}

}
