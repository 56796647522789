

.header-sign-in {

	@include breakpoint( tb ) {

		z-index: 51;

		width: 300px;
		transform: translateY( -1px );

		display: none;

	}

}
