
// mixin to be included for all the breakpoints

@mixin lc-list-bullets() {

	li {


		padding-left: 1.5em;
		padding-right: 1.5em;
		line-height: 1.2;
		margin-bottom: 0.3125em;

		&:before {

			content: "–";
			width: 1.5em;
			margin-left: -1.5em;

			display: inline-block;
			text-align: center;

		}

	}

}

@mixin lc-list-numbers() {

	li {

		list-style: decimal inside;
		line-height: 1.2;
		margin-bottom: 0.3125em;

	}

}



ul,
ol,
dl {

	list-style: none;
	padding: 0;
	margin: 0;

	&:not( [ class ] ):not( :last-child ) {

		margin-bottom: 1em;

	}

}

ul:not( [ class *= list ] ) > {

	@include lc-list-bullets;

}

ol:not( [ class *= list ] ) > {

	@include lc-list-numbers;

}

ul:not( [ class *= list ] ),
ol:not( [ class *= list ] ) {

	& & {

		margin-bottom: 0;
		margin-left: 1em;

	}

}

li {

	margin: 0;

}

dt {

	font-weight: bold;

}

dd {

	margin: 0 0 1em;

}

// mixin to be included for all the breakpoints

@mixin lc-lists( $bp: "" ) {

	.#{$bp}list {

		&--bulleted {

			@include lc-list-bullets;

		}

		&--numbered {

			@include lc-list-numbers;

		}

		&--inline {

			li {

				display: inline-block;

			}

			.breaker {

				display: block;
				height: rem-calc( 1px );

			}

		}

		&--divided {

			li {

				position: relative;

			}

			li:not( :last-child ):after {

				content: "";
				background: currentColor;

				position: absolute;
				top: 50%;
				right: -0.125em;
				transform: translateY( -50% );

				width: rem-calc( 1px );
				height: 1.2em;

				margin-top: -1px;
				margin-right: 1px;

			}

			li:not( :nth-of-type( 1 ) ) {

				// this needs to be relative to the font-size,
				// which is why it's in "em"
				margin-left: -0.125em;

			}

		}

	}

}



// output styles in to breakpoints

@include lc-lists;

@each $size in $breakpoint-classes {

	@include breakpoint($size) {

		@if $size != "mb" {

			@include lc-lists( $size + "-" );

		}

	}

}

@include breakpoint(mb only) {

	@include lc-lists( mb-only- );

}

@include breakpoint(tb only) {

	@include lc-lists( tb-only- );

}
