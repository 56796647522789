/*! Copyright 2024 Lane Crawford, All Rights Reserved. */

@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.3.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0;
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

.foundation-mq {
  font-family: "mb=0em&tb=48em&dt=64em&hg=75em";
}

html {
  box-sizing: border-box;
  font-size: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0;
}

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0;
}

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important;
}

button {
  padding: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
}

[data-whatinput='mouse'] button {
  outline: 0;
}

pre {
  overflow: auto;
}

.is-visible {
  display: block !important;
}

.is-hidden {
  display: none !important;
}

.row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
}

.row::before, .row::after {
  display: table;
  content: ' ';
}

.row::after {
  clear: both;
}

.row.collapse > .column, .row.collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}

.row .row {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

@media print, screen and (min-width: 48em) {
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
}

@media screen and (min-width: 64em) {
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
}

@media screen and (min-width: 75em) {
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
}

.row .row.collapse {
  margin-right: 0;
  margin-left: 0;
}

.row.expanded {
  max-width: none;
}

.row.expanded .row {
  margin-right: auto;
  margin-left: auto;
}

.row:not(.expanded) .row {
  max-width: none;
}

.row.gutter-mb > .column, .row.gutter-mb > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

.column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
  float: right;
}

.column.end:last-child:last-child, .end.columns:last-child:last-child {
  float: left;
}

.column.row.row, .row.row.columns {
  float: none;
}

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.mb-1 {
  width: 8.33333%;
}

.mb-push-1 {
  position: relative;
  left: 8.33333%;
}

.mb-pull-1 {
  position: relative;
  left: -8.33333%;
}

.mb-offset-0 {
  margin-left: 0%;
}

.mb-2 {
  width: 16.66667%;
}

.mb-push-2 {
  position: relative;
  left: 16.66667%;
}

.mb-pull-2 {
  position: relative;
  left: -16.66667%;
}

.mb-offset-1 {
  margin-left: 8.33333%;
}

.mb-3 {
  width: 25%;
}

.mb-push-3 {
  position: relative;
  left: 25%;
}

.mb-pull-3 {
  position: relative;
  left: -25%;
}

.mb-offset-2 {
  margin-left: 16.66667%;
}

.mb-4 {
  width: 33.33333%;
}

.mb-push-4 {
  position: relative;
  left: 33.33333%;
}

.mb-pull-4 {
  position: relative;
  left: -33.33333%;
}

.mb-offset-3 {
  margin-left: 25%;
}

.mb-5 {
  width: 41.66667%;
}

.mb-push-5 {
  position: relative;
  left: 41.66667%;
}

.mb-pull-5 {
  position: relative;
  left: -41.66667%;
}

.mb-offset-4 {
  margin-left: 33.33333%;
}

.mb-6 {
  width: 50%;
}

.mb-push-6 {
  position: relative;
  left: 50%;
}

.mb-pull-6 {
  position: relative;
  left: -50%;
}

.mb-offset-5 {
  margin-left: 41.66667%;
}

.mb-7 {
  width: 58.33333%;
}

.mb-push-7 {
  position: relative;
  left: 58.33333%;
}

.mb-pull-7 {
  position: relative;
  left: -58.33333%;
}

.mb-offset-6 {
  margin-left: 50%;
}

.mb-8 {
  width: 66.66667%;
}

.mb-push-8 {
  position: relative;
  left: 66.66667%;
}

.mb-pull-8 {
  position: relative;
  left: -66.66667%;
}

.mb-offset-7 {
  margin-left: 58.33333%;
}

.mb-9 {
  width: 75%;
}

.mb-push-9 {
  position: relative;
  left: 75%;
}

.mb-pull-9 {
  position: relative;
  left: -75%;
}

.mb-offset-8 {
  margin-left: 66.66667%;
}

.mb-10 {
  width: 83.33333%;
}

.mb-push-10 {
  position: relative;
  left: 83.33333%;
}

.mb-pull-10 {
  position: relative;
  left: -83.33333%;
}

.mb-offset-9 {
  margin-left: 75%;
}

.mb-11 {
  width: 91.66667%;
}

.mb-push-11 {
  position: relative;
  left: 91.66667%;
}

.mb-pull-11 {
  position: relative;
  left: -91.66667%;
}

.mb-offset-10 {
  margin-left: 83.33333%;
}

.mb-12 {
  width: 100%;
}

.mb-offset-11 {
  margin-left: 91.66667%;
}

.mb-up-1 > .column, .mb-up-1 > .columns {
  float: left;
  width: 100%;
}

.mb-up-1 > .column:nth-of-type(1n), .mb-up-1 > .columns:nth-of-type(1n) {
  clear: none;
}

.mb-up-1 > .column:nth-of-type(1n+1), .mb-up-1 > .columns:nth-of-type(1n+1) {
  clear: both;
}

.mb-up-1 > .column:last-child, .mb-up-1 > .columns:last-child {
  float: left;
}

.mb-up-2 > .column, .mb-up-2 > .columns {
  float: left;
  width: 50%;
}

.mb-up-2 > .column:nth-of-type(1n), .mb-up-2 > .columns:nth-of-type(1n) {
  clear: none;
}

.mb-up-2 > .column:nth-of-type(2n+1), .mb-up-2 > .columns:nth-of-type(2n+1) {
  clear: both;
}

.mb-up-2 > .column:last-child, .mb-up-2 > .columns:last-child {
  float: left;
}

.mb-up-3 > .column, .mb-up-3 > .columns {
  float: left;
  width: 33.33333%;
}

.mb-up-3 > .column:nth-of-type(1n), .mb-up-3 > .columns:nth-of-type(1n) {
  clear: none;
}

.mb-up-3 > .column:nth-of-type(3n+1), .mb-up-3 > .columns:nth-of-type(3n+1) {
  clear: both;
}

.mb-up-3 > .column:last-child, .mb-up-3 > .columns:last-child {
  float: left;
}

.mb-up-4 > .column, .mb-up-4 > .columns {
  float: left;
  width: 25%;
}

.mb-up-4 > .column:nth-of-type(1n), .mb-up-4 > .columns:nth-of-type(1n) {
  clear: none;
}

.mb-up-4 > .column:nth-of-type(4n+1), .mb-up-4 > .columns:nth-of-type(4n+1) {
  clear: both;
}

.mb-up-4 > .column:last-child, .mb-up-4 > .columns:last-child {
  float: left;
}

.mb-up-5 > .column, .mb-up-5 > .columns {
  float: left;
  width: 20%;
}

.mb-up-5 > .column:nth-of-type(1n), .mb-up-5 > .columns:nth-of-type(1n) {
  clear: none;
}

.mb-up-5 > .column:nth-of-type(5n+1), .mb-up-5 > .columns:nth-of-type(5n+1) {
  clear: both;
}

.mb-up-5 > .column:last-child, .mb-up-5 > .columns:last-child {
  float: left;
}

.mb-up-6 > .column, .mb-up-6 > .columns {
  float: left;
  width: 16.66667%;
}

.mb-up-6 > .column:nth-of-type(1n), .mb-up-6 > .columns:nth-of-type(1n) {
  clear: none;
}

.mb-up-6 > .column:nth-of-type(6n+1), .mb-up-6 > .columns:nth-of-type(6n+1) {
  clear: both;
}

.mb-up-6 > .column:last-child, .mb-up-6 > .columns:last-child {
  float: left;
}

.mb-up-7 > .column, .mb-up-7 > .columns {
  float: left;
  width: 14.28571%;
}

.mb-up-7 > .column:nth-of-type(1n), .mb-up-7 > .columns:nth-of-type(1n) {
  clear: none;
}

.mb-up-7 > .column:nth-of-type(7n+1), .mb-up-7 > .columns:nth-of-type(7n+1) {
  clear: both;
}

.mb-up-7 > .column:last-child, .mb-up-7 > .columns:last-child {
  float: left;
}

.mb-up-8 > .column, .mb-up-8 > .columns {
  float: left;
  width: 12.5%;
}

.mb-up-8 > .column:nth-of-type(1n), .mb-up-8 > .columns:nth-of-type(1n) {
  clear: none;
}

.mb-up-8 > .column:nth-of-type(8n+1), .mb-up-8 > .columns:nth-of-type(8n+1) {
  clear: both;
}

.mb-up-8 > .column:last-child, .mb-up-8 > .columns:last-child {
  float: left;
}

.mb-collapse > .column, .mb-collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}

.mb-collapse .row {
  margin-right: 0;
  margin-left: 0;
}

.expanded.row .mb-collapse.row {
  margin-right: 0;
  margin-left: 0;
}

.mb-uncollapse > .column, .mb-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

.mb-centered {
  margin-right: auto;
  margin-left: auto;
}

.mb-centered, .mb-centered:last-child:not(:first-child) {
  float: none;
  clear: both;
}

.mb-uncentered,
.mb-push-0,
.mb-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0;
}

@media print, screen and (min-width: 48em) {
  .tb-1 {
    width: 8.33333%;
  }
  .tb-push-1 {
    position: relative;
    left: 8.33333%;
  }
  .tb-pull-1 {
    position: relative;
    left: -8.33333%;
  }
  .tb-offset-0 {
    margin-left: 0%;
  }
  .tb-2 {
    width: 16.66667%;
  }
  .tb-push-2 {
    position: relative;
    left: 16.66667%;
  }
  .tb-pull-2 {
    position: relative;
    left: -16.66667%;
  }
  .tb-offset-1 {
    margin-left: 8.33333%;
  }
  .tb-3 {
    width: 25%;
  }
  .tb-push-3 {
    position: relative;
    left: 25%;
  }
  .tb-pull-3 {
    position: relative;
    left: -25%;
  }
  .tb-offset-2 {
    margin-left: 16.66667%;
  }
  .tb-4 {
    width: 33.33333%;
  }
  .tb-push-4 {
    position: relative;
    left: 33.33333%;
  }
  .tb-pull-4 {
    position: relative;
    left: -33.33333%;
  }
  .tb-offset-3 {
    margin-left: 25%;
  }
  .tb-5 {
    width: 41.66667%;
  }
  .tb-push-5 {
    position: relative;
    left: 41.66667%;
  }
  .tb-pull-5 {
    position: relative;
    left: -41.66667%;
  }
  .tb-offset-4 {
    margin-left: 33.33333%;
  }
  .tb-6 {
    width: 50%;
  }
  .tb-push-6 {
    position: relative;
    left: 50%;
  }
  .tb-pull-6 {
    position: relative;
    left: -50%;
  }
  .tb-offset-5 {
    margin-left: 41.66667%;
  }
  .tb-7 {
    width: 58.33333%;
  }
  .tb-push-7 {
    position: relative;
    left: 58.33333%;
  }
  .tb-pull-7 {
    position: relative;
    left: -58.33333%;
  }
  .tb-offset-6 {
    margin-left: 50%;
  }
  .tb-8 {
    width: 66.66667%;
  }
  .tb-push-8 {
    position: relative;
    left: 66.66667%;
  }
  .tb-pull-8 {
    position: relative;
    left: -66.66667%;
  }
  .tb-offset-7 {
    margin-left: 58.33333%;
  }
  .tb-9 {
    width: 75%;
  }
  .tb-push-9 {
    position: relative;
    left: 75%;
  }
  .tb-pull-9 {
    position: relative;
    left: -75%;
  }
  .tb-offset-8 {
    margin-left: 66.66667%;
  }
  .tb-10 {
    width: 83.33333%;
  }
  .tb-push-10 {
    position: relative;
    left: 83.33333%;
  }
  .tb-pull-10 {
    position: relative;
    left: -83.33333%;
  }
  .tb-offset-9 {
    margin-left: 75%;
  }
  .tb-11 {
    width: 91.66667%;
  }
  .tb-push-11 {
    position: relative;
    left: 91.66667%;
  }
  .tb-pull-11 {
    position: relative;
    left: -91.66667%;
  }
  .tb-offset-10 {
    margin-left: 83.33333%;
  }
  .tb-12 {
    width: 100%;
  }
  .tb-offset-11 {
    margin-left: 91.66667%;
  }
  .tb-up-1 > .column, .tb-up-1 > .columns {
    float: left;
    width: 100%;
  }
  .tb-up-1 > .column:nth-of-type(1n), .tb-up-1 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .tb-up-1 > .column:nth-of-type(1n+1), .tb-up-1 > .columns:nth-of-type(1n+1) {
    clear: both;
  }
  .tb-up-1 > .column:last-child, .tb-up-1 > .columns:last-child {
    float: left;
  }
  .tb-up-2 > .column, .tb-up-2 > .columns {
    float: left;
    width: 50%;
  }
  .tb-up-2 > .column:nth-of-type(1n), .tb-up-2 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .tb-up-2 > .column:nth-of-type(2n+1), .tb-up-2 > .columns:nth-of-type(2n+1) {
    clear: both;
  }
  .tb-up-2 > .column:last-child, .tb-up-2 > .columns:last-child {
    float: left;
  }
  .tb-up-3 > .column, .tb-up-3 > .columns {
    float: left;
    width: 33.33333%;
  }
  .tb-up-3 > .column:nth-of-type(1n), .tb-up-3 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .tb-up-3 > .column:nth-of-type(3n+1), .tb-up-3 > .columns:nth-of-type(3n+1) {
    clear: both;
  }
  .tb-up-3 > .column:last-child, .tb-up-3 > .columns:last-child {
    float: left;
  }
  .tb-up-4 > .column, .tb-up-4 > .columns {
    float: left;
    width: 25%;
  }
  .tb-up-4 > .column:nth-of-type(1n), .tb-up-4 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .tb-up-4 > .column:nth-of-type(4n+1), .tb-up-4 > .columns:nth-of-type(4n+1) {
    clear: both;
  }
  .tb-up-4 > .column:last-child, .tb-up-4 > .columns:last-child {
    float: left;
  }
  .tb-up-5 > .column, .tb-up-5 > .columns {
    float: left;
    width: 20%;
  }
  .tb-up-5 > .column:nth-of-type(1n), .tb-up-5 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .tb-up-5 > .column:nth-of-type(5n+1), .tb-up-5 > .columns:nth-of-type(5n+1) {
    clear: both;
  }
  .tb-up-5 > .column:last-child, .tb-up-5 > .columns:last-child {
    float: left;
  }
  .tb-up-6 > .column, .tb-up-6 > .columns {
    float: left;
    width: 16.66667%;
  }
  .tb-up-6 > .column:nth-of-type(1n), .tb-up-6 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .tb-up-6 > .column:nth-of-type(6n+1), .tb-up-6 > .columns:nth-of-type(6n+1) {
    clear: both;
  }
  .tb-up-6 > .column:last-child, .tb-up-6 > .columns:last-child {
    float: left;
  }
  .tb-up-7 > .column, .tb-up-7 > .columns {
    float: left;
    width: 14.28571%;
  }
  .tb-up-7 > .column:nth-of-type(1n), .tb-up-7 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .tb-up-7 > .column:nth-of-type(7n+1), .tb-up-7 > .columns:nth-of-type(7n+1) {
    clear: both;
  }
  .tb-up-7 > .column:last-child, .tb-up-7 > .columns:last-child {
    float: left;
  }
  .tb-up-8 > .column, .tb-up-8 > .columns {
    float: left;
    width: 12.5%;
  }
  .tb-up-8 > .column:nth-of-type(1n), .tb-up-8 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .tb-up-8 > .column:nth-of-type(8n+1), .tb-up-8 > .columns:nth-of-type(8n+1) {
    clear: both;
  }
  .tb-up-8 > .column:last-child, .tb-up-8 > .columns:last-child {
    float: left;
  }
  .tb-collapse > .column, .tb-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .tb-collapse .row {
    margin-right: 0;
    margin-left: 0;
  }
  .expanded.row .tb-collapse.row {
    margin-right: 0;
    margin-left: 0;
  }
  .tb-uncollapse > .column, .tb-uncollapse > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
  .tb-centered {
    margin-right: auto;
    margin-left: auto;
  }
  .tb-centered, .tb-centered:last-child:not(:first-child) {
    float: none;
    clear: both;
  }
  .tb-uncentered,
  .tb-push-0,
  .tb-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0;
  }
}

@media screen and (min-width: 64em) {
  .dt-1 {
    width: 8.33333%;
  }
  .dt-push-1 {
    position: relative;
    left: 8.33333%;
  }
  .dt-pull-1 {
    position: relative;
    left: -8.33333%;
  }
  .dt-offset-0 {
    margin-left: 0%;
  }
  .dt-2 {
    width: 16.66667%;
  }
  .dt-push-2 {
    position: relative;
    left: 16.66667%;
  }
  .dt-pull-2 {
    position: relative;
    left: -16.66667%;
  }
  .dt-offset-1 {
    margin-left: 8.33333%;
  }
  .dt-3 {
    width: 25%;
  }
  .dt-push-3 {
    position: relative;
    left: 25%;
  }
  .dt-pull-3 {
    position: relative;
    left: -25%;
  }
  .dt-offset-2 {
    margin-left: 16.66667%;
  }
  .dt-4 {
    width: 33.33333%;
  }
  .dt-push-4 {
    position: relative;
    left: 33.33333%;
  }
  .dt-pull-4 {
    position: relative;
    left: -33.33333%;
  }
  .dt-offset-3 {
    margin-left: 25%;
  }
  .dt-5 {
    width: 41.66667%;
  }
  .dt-push-5 {
    position: relative;
    left: 41.66667%;
  }
  .dt-pull-5 {
    position: relative;
    left: -41.66667%;
  }
  .dt-offset-4 {
    margin-left: 33.33333%;
  }
  .dt-6 {
    width: 50%;
  }
  .dt-push-6 {
    position: relative;
    left: 50%;
  }
  .dt-pull-6 {
    position: relative;
    left: -50%;
  }
  .dt-offset-5 {
    margin-left: 41.66667%;
  }
  .dt-7 {
    width: 58.33333%;
  }
  .dt-push-7 {
    position: relative;
    left: 58.33333%;
  }
  .dt-pull-7 {
    position: relative;
    left: -58.33333%;
  }
  .dt-offset-6 {
    margin-left: 50%;
  }
  .dt-8 {
    width: 66.66667%;
  }
  .dt-push-8 {
    position: relative;
    left: 66.66667%;
  }
  .dt-pull-8 {
    position: relative;
    left: -66.66667%;
  }
  .dt-offset-7 {
    margin-left: 58.33333%;
  }
  .dt-9 {
    width: 75%;
  }
  .dt-push-9 {
    position: relative;
    left: 75%;
  }
  .dt-pull-9 {
    position: relative;
    left: -75%;
  }
  .dt-offset-8 {
    margin-left: 66.66667%;
  }
  .dt-10 {
    width: 83.33333%;
  }
  .dt-push-10 {
    position: relative;
    left: 83.33333%;
  }
  .dt-pull-10 {
    position: relative;
    left: -83.33333%;
  }
  .dt-offset-9 {
    margin-left: 75%;
  }
  .dt-11 {
    width: 91.66667%;
  }
  .dt-push-11 {
    position: relative;
    left: 91.66667%;
  }
  .dt-pull-11 {
    position: relative;
    left: -91.66667%;
  }
  .dt-offset-10 {
    margin-left: 83.33333%;
  }
  .dt-12 {
    width: 100%;
  }
  .dt-offset-11 {
    margin-left: 91.66667%;
  }
  .dt-up-1 > .column, .dt-up-1 > .columns {
    float: left;
    width: 100%;
  }
  .dt-up-1 > .column:nth-of-type(1n), .dt-up-1 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .dt-up-1 > .column:nth-of-type(1n+1), .dt-up-1 > .columns:nth-of-type(1n+1) {
    clear: both;
  }
  .dt-up-1 > .column:last-child, .dt-up-1 > .columns:last-child {
    float: left;
  }
  .dt-up-2 > .column, .dt-up-2 > .columns {
    float: left;
    width: 50%;
  }
  .dt-up-2 > .column:nth-of-type(1n), .dt-up-2 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .dt-up-2 > .column:nth-of-type(2n+1), .dt-up-2 > .columns:nth-of-type(2n+1) {
    clear: both;
  }
  .dt-up-2 > .column:last-child, .dt-up-2 > .columns:last-child {
    float: left;
  }
  .dt-up-3 > .column, .dt-up-3 > .columns {
    float: left;
    width: 33.33333%;
  }
  .dt-up-3 > .column:nth-of-type(1n), .dt-up-3 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .dt-up-3 > .column:nth-of-type(3n+1), .dt-up-3 > .columns:nth-of-type(3n+1) {
    clear: both;
  }
  .dt-up-3 > .column:last-child, .dt-up-3 > .columns:last-child {
    float: left;
  }
  .dt-up-4 > .column, .dt-up-4 > .columns {
    float: left;
    width: 25%;
  }
  .dt-up-4 > .column:nth-of-type(1n), .dt-up-4 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .dt-up-4 > .column:nth-of-type(4n+1), .dt-up-4 > .columns:nth-of-type(4n+1) {
    clear: both;
  }
  .dt-up-4 > .column:last-child, .dt-up-4 > .columns:last-child {
    float: left;
  }
  .dt-up-5 > .column, .dt-up-5 > .columns {
    float: left;
    width: 20%;
  }
  .dt-up-5 > .column:nth-of-type(1n), .dt-up-5 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .dt-up-5 > .column:nth-of-type(5n+1), .dt-up-5 > .columns:nth-of-type(5n+1) {
    clear: both;
  }
  .dt-up-5 > .column:last-child, .dt-up-5 > .columns:last-child {
    float: left;
  }
  .dt-up-6 > .column, .dt-up-6 > .columns {
    float: left;
    width: 16.66667%;
  }
  .dt-up-6 > .column:nth-of-type(1n), .dt-up-6 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .dt-up-6 > .column:nth-of-type(6n+1), .dt-up-6 > .columns:nth-of-type(6n+1) {
    clear: both;
  }
  .dt-up-6 > .column:last-child, .dt-up-6 > .columns:last-child {
    float: left;
  }
  .dt-up-7 > .column, .dt-up-7 > .columns {
    float: left;
    width: 14.28571%;
  }
  .dt-up-7 > .column:nth-of-type(1n), .dt-up-7 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .dt-up-7 > .column:nth-of-type(7n+1), .dt-up-7 > .columns:nth-of-type(7n+1) {
    clear: both;
  }
  .dt-up-7 > .column:last-child, .dt-up-7 > .columns:last-child {
    float: left;
  }
  .dt-up-8 > .column, .dt-up-8 > .columns {
    float: left;
    width: 12.5%;
  }
  .dt-up-8 > .column:nth-of-type(1n), .dt-up-8 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .dt-up-8 > .column:nth-of-type(8n+1), .dt-up-8 > .columns:nth-of-type(8n+1) {
    clear: both;
  }
  .dt-up-8 > .column:last-child, .dt-up-8 > .columns:last-child {
    float: left;
  }
  .dt-collapse > .column, .dt-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .dt-collapse .row {
    margin-right: 0;
    margin-left: 0;
  }
  .expanded.row .dt-collapse.row {
    margin-right: 0;
    margin-left: 0;
  }
  .dt-uncollapse > .column, .dt-uncollapse > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
  .dt-centered {
    margin-right: auto;
    margin-left: auto;
  }
  .dt-centered, .dt-centered:last-child:not(:first-child) {
    float: none;
    clear: both;
  }
  .dt-uncentered,
  .dt-push-0,
  .dt-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0;
  }
}

@media screen and (min-width: 75em) {
  .hg-1 {
    width: 8.33333%;
  }
  .hg-push-1 {
    position: relative;
    left: 8.33333%;
  }
  .hg-pull-1 {
    position: relative;
    left: -8.33333%;
  }
  .hg-offset-0 {
    margin-left: 0%;
  }
  .hg-2 {
    width: 16.66667%;
  }
  .hg-push-2 {
    position: relative;
    left: 16.66667%;
  }
  .hg-pull-2 {
    position: relative;
    left: -16.66667%;
  }
  .hg-offset-1 {
    margin-left: 8.33333%;
  }
  .hg-3 {
    width: 25%;
  }
  .hg-push-3 {
    position: relative;
    left: 25%;
  }
  .hg-pull-3 {
    position: relative;
    left: -25%;
  }
  .hg-offset-2 {
    margin-left: 16.66667%;
  }
  .hg-4 {
    width: 33.33333%;
  }
  .hg-push-4 {
    position: relative;
    left: 33.33333%;
  }
  .hg-pull-4 {
    position: relative;
    left: -33.33333%;
  }
  .hg-offset-3 {
    margin-left: 25%;
  }
  .hg-5 {
    width: 41.66667%;
  }
  .hg-push-5 {
    position: relative;
    left: 41.66667%;
  }
  .hg-pull-5 {
    position: relative;
    left: -41.66667%;
  }
  .hg-offset-4 {
    margin-left: 33.33333%;
  }
  .hg-6 {
    width: 50%;
  }
  .hg-push-6 {
    position: relative;
    left: 50%;
  }
  .hg-pull-6 {
    position: relative;
    left: -50%;
  }
  .hg-offset-5 {
    margin-left: 41.66667%;
  }
  .hg-7 {
    width: 58.33333%;
  }
  .hg-push-7 {
    position: relative;
    left: 58.33333%;
  }
  .hg-pull-7 {
    position: relative;
    left: -58.33333%;
  }
  .hg-offset-6 {
    margin-left: 50%;
  }
  .hg-8 {
    width: 66.66667%;
  }
  .hg-push-8 {
    position: relative;
    left: 66.66667%;
  }
  .hg-pull-8 {
    position: relative;
    left: -66.66667%;
  }
  .hg-offset-7 {
    margin-left: 58.33333%;
  }
  .hg-9 {
    width: 75%;
  }
  .hg-push-9 {
    position: relative;
    left: 75%;
  }
  .hg-pull-9 {
    position: relative;
    left: -75%;
  }
  .hg-offset-8 {
    margin-left: 66.66667%;
  }
  .hg-10 {
    width: 83.33333%;
  }
  .hg-push-10 {
    position: relative;
    left: 83.33333%;
  }
  .hg-pull-10 {
    position: relative;
    left: -83.33333%;
  }
  .hg-offset-9 {
    margin-left: 75%;
  }
  .hg-11 {
    width: 91.66667%;
  }
  .hg-push-11 {
    position: relative;
    left: 91.66667%;
  }
  .hg-pull-11 {
    position: relative;
    left: -91.66667%;
  }
  .hg-offset-10 {
    margin-left: 83.33333%;
  }
  .hg-12 {
    width: 100%;
  }
  .hg-offset-11 {
    margin-left: 91.66667%;
  }
  .hg-up-1 > .column, .hg-up-1 > .columns {
    float: left;
    width: 100%;
  }
  .hg-up-1 > .column:nth-of-type(1n), .hg-up-1 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .hg-up-1 > .column:nth-of-type(1n+1), .hg-up-1 > .columns:nth-of-type(1n+1) {
    clear: both;
  }
  .hg-up-1 > .column:last-child, .hg-up-1 > .columns:last-child {
    float: left;
  }
  .hg-up-2 > .column, .hg-up-2 > .columns {
    float: left;
    width: 50%;
  }
  .hg-up-2 > .column:nth-of-type(1n), .hg-up-2 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .hg-up-2 > .column:nth-of-type(2n+1), .hg-up-2 > .columns:nth-of-type(2n+1) {
    clear: both;
  }
  .hg-up-2 > .column:last-child, .hg-up-2 > .columns:last-child {
    float: left;
  }
  .hg-up-3 > .column, .hg-up-3 > .columns {
    float: left;
    width: 33.33333%;
  }
  .hg-up-3 > .column:nth-of-type(1n), .hg-up-3 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .hg-up-3 > .column:nth-of-type(3n+1), .hg-up-3 > .columns:nth-of-type(3n+1) {
    clear: both;
  }
  .hg-up-3 > .column:last-child, .hg-up-3 > .columns:last-child {
    float: left;
  }
  .hg-up-4 > .column, .hg-up-4 > .columns {
    float: left;
    width: 25%;
  }
  .hg-up-4 > .column:nth-of-type(1n), .hg-up-4 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .hg-up-4 > .column:nth-of-type(4n+1), .hg-up-4 > .columns:nth-of-type(4n+1) {
    clear: both;
  }
  .hg-up-4 > .column:last-child, .hg-up-4 > .columns:last-child {
    float: left;
  }
  .hg-up-5 > .column, .hg-up-5 > .columns {
    float: left;
    width: 20%;
  }
  .hg-up-5 > .column:nth-of-type(1n), .hg-up-5 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .hg-up-5 > .column:nth-of-type(5n+1), .hg-up-5 > .columns:nth-of-type(5n+1) {
    clear: both;
  }
  .hg-up-5 > .column:last-child, .hg-up-5 > .columns:last-child {
    float: left;
  }
  .hg-up-6 > .column, .hg-up-6 > .columns {
    float: left;
    width: 16.66667%;
  }
  .hg-up-6 > .column:nth-of-type(1n), .hg-up-6 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .hg-up-6 > .column:nth-of-type(6n+1), .hg-up-6 > .columns:nth-of-type(6n+1) {
    clear: both;
  }
  .hg-up-6 > .column:last-child, .hg-up-6 > .columns:last-child {
    float: left;
  }
  .hg-up-7 > .column, .hg-up-7 > .columns {
    float: left;
    width: 14.28571%;
  }
  .hg-up-7 > .column:nth-of-type(1n), .hg-up-7 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .hg-up-7 > .column:nth-of-type(7n+1), .hg-up-7 > .columns:nth-of-type(7n+1) {
    clear: both;
  }
  .hg-up-7 > .column:last-child, .hg-up-7 > .columns:last-child {
    float: left;
  }
  .hg-up-8 > .column, .hg-up-8 > .columns {
    float: left;
    width: 12.5%;
  }
  .hg-up-8 > .column:nth-of-type(1n), .hg-up-8 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .hg-up-8 > .column:nth-of-type(8n+1), .hg-up-8 > .columns:nth-of-type(8n+1) {
    clear: both;
  }
  .hg-up-8 > .column:last-child, .hg-up-8 > .columns:last-child {
    float: left;
  }
  .hg-collapse > .column, .hg-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .hg-collapse .row {
    margin-right: 0;
    margin-left: 0;
  }
  .expanded.row .hg-collapse.row {
    margin-right: 0;
    margin-left: 0;
  }
  .hg-uncollapse > .column, .hg-uncollapse > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
  .hg-centered {
    margin-right: auto;
    margin-left: auto;
  }
  .hg-centered, .hg-centered:last-child:not(:first-child) {
    float: none;
    clear: both;
  }
  .hg-uncentered,
  .hg-push-0,
  .hg-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0;
  }
}

.column-block {
  margin-bottom: 1.25rem;
}

.column-block > :last-child {
  margin-bottom: 0;
}

.hide {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

.hide-for-mb {
  display: none !important;
}

.show-for-mb {
  display: none !important;
}

@media screen and (max-width: 47.9375em) {
  .hide-for-mb-only {
    display: none !important;
  }
}

@media screen and (max-width: 0em), screen and (min-width: 48em) {
  .show-for-mb-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 48em) {
  .hide-for-tb {
    display: none !important;
  }
}

@media screen and (max-width: 47.9375em) {
  .show-for-tb {
    display: none !important;
  }
}

@media print, screen and (min-width: 48em) and (max-width: 63.9375em) {
  .hide-for-tb-only {
    display: none !important;
  }
}

@media screen and (max-width: 47.9375em), screen and (min-width: 64em) {
  .show-for-tb-only {
    display: none !important;
  }
}

@media screen and (min-width: 64em) {
  .hide-for-dt {
    display: none !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .show-for-dt {
    display: none !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-dt-only {
    display: none !important;
  }
}

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-dt-only {
    display: none !important;
  }
}

@media screen and (min-width: 75em) {
  .hide-for-hg {
    display: none !important;
  }
}

@media screen and (max-width: 74.9375em) {
  .show-for-hg {
    display: none !important;
  }
}

@media screen and (min-width: 75em) {
  .hide-for-hg-only {
    display: none !important;
  }
}

@media screen and (max-width: 74.9375em) {
  .show-for-hg-only {
    display: none !important;
  }
}

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
}

.show-for-landscape,
.hide-for-portrait {
  display: block !important;
}

@media screen and (orientation: landscape) {
  .show-for-landscape,
  .hide-for-portrait {
    display: block !important;
  }
}

@media screen and (orientation: portrait) {
  .show-for-landscape,
  .hide-for-portrait {
    display: none !important;
  }
}

.hide-for-landscape,
.show-for-portrait {
  display: none !important;
}

@media screen and (orientation: landscape) {
  .hide-for-landscape,
  .show-for-portrait {
    display: none !important;
  }
}

@media screen and (orientation: portrait) {
  .hide-for-landscape,
  .show-for-portrait {
    display: block !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.clearfix::before, .clearfix::after {
  display: table;
  content: ' ';
}

.clearfix::after {
  clear: both;
}

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #8a8a8a;
  font-weight: bold;
  cursor: help;
}

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 8px 14px;
  border-radius: 3px;
  background-color: #000;
  font-size: 16px;
  color: #fff;
}

.tooltip::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 10px;
  content: '';
  border-top-width: 0;
  border-bottom-style: solid;
  border-color: transparent transparent #000;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip.top::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 10px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #000 transparent transparent;
  top: 100%;
  bottom: auto;
}

.tooltip.left::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 10px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #000;
  top: 50%;
  bottom: auto;
  left: 100%;
  transform: translateY(-50%);
}

.tooltip.right::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 10px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #000 transparent transparent;
  top: 50%;
  right: 100%;
  bottom: auto;
  left: auto;
  transform: translateY(-50%);
}

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 3.125rem;
  height: 3.125rem;
  border: none;
  cursor: pointer;
  opacity: 0.1;
  transition: opacity 0.2s cubic-bezier(0.43, 0, 0.36, 1);
}

.flickity-prev-next-button.next {
  right: 0;
}

.flickity-prev-next-button.previous {
  left: 0;
}

.flickity-enabled:hover .flickity-prev-next-button {
  opacity: 0.5;
}

.flickity-prev-next-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px #000;
}

.flickity-prev-next-button:active {
  opacity: 1;
}

.flickity-prev-next-button.is-disabled,
.flickity-enabled:hover .flickity-prev-next-button.is-disabled {
  opacity: 0;
  cursor: auto;
}

.flickity-prev-next-button svg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.001);
}

.flickity-prev-next-button .arrow {
  fill: #000;
}

.flickity-prev-next-button.no-svg {
  color: #000;
  font-size: 50px;
}

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -2rem;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  margin: 0 0.125rem;
  padding: 0.25rem;
  cursor: pointer;
}

.flickity-page-dots .dot > a {
  display: block;
  position: relative;
  height: 1px;
  top: 50%;
  background: #000;
}

.flickity-page-dots .dot.is-selected > a {
  height: 3px;
  margin-top: -1px;
}

/*
 * custom css overrides to make Hotjar
 * components look a bit nicer in the LC UI
 * https://insights.hotjar.com/

 * need to use a very specific base-element
 * id to make sure the specificity is strong enough
 * to override the hotjar css styling.
 */
html#doc[class] body[class] div#_hj_survey_invite_container * {
  font-family: "Lane Crawford", 微软雅黑体, "Microsoft YaHei", 华文细黑, "STXihei", 华文黑体, "STHeiti", 冬青黑体, "Hiragino Sans GB", sans-serif !important;
  border-radius: 0 !important;
}

html#doc[class] body[class] div#_hj_survey_invite_container [class*="survey_powered_by"] {
  display: none;
  opacity: 0;
}

html#doc[class] body[class] div#_hj_survey_invite_container [class*="_hj"][class*="survey_button"] {
  background: black !important;
  font-family: "Lane Crawford", 微软雅黑体, "Microsoft YaHei", 华文细黑, "STXihei", 华文黑体, "STHeiti", 冬青黑体, "Hiragino Sans GB", sans-serif !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
  font-size: 16px !important;
  padding: 12px 24px !important;
  box-shadow: none !important;
}

html#doc[class] body[class] div#_hj_feedback_container [id*="_hotjar_branding"] {
  display: none;
  opacity: 0;
}

html#doc[class] body[class] div#_hj_feedback_container [id*="_feedback_open"] [class*="_emotion_option"] *:before {
  color: white;
}

html#doc[class] body[class] div#_hj_feedback_container [id*="_feedback_open"] [class*="_emotion_option"] .path1:before {
  color: #a0783c;
}

.txt-lc, h1,
h2,
h3,
h4,
h5,
h6, .product-card__details * {
  font-family: "Lane Crawford", 微软雅黑体, "Microsoft YaHei", 华文细黑, "STXihei", 华文黑体, "STHeiti", 冬青黑体, "Hiragino Sans GB", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.0375rem;
}

.txt-sb {
  font-family: "Lane Crawford", 微软雅黑体, "Microsoft YaHei", 华文细黑, "STXihei", 华文黑体, "STHeiti", 冬青黑体, "Hiragino Sans GB", sans-serif;
  text-transform: none;
  letter-spacing: normal;
}

.txt-mono {
  font-family: "SF Mono", "Monaco", "Inconsolata", "Fira Mono", "Droid Sans Mono", "Source Code Pro", monospace;
  text-transform: none;
  letter-spacing: normal;
}

html:not(.fonts-loaded) .txt-lc, html:not(.fonts-loaded) h1, html:not(.fonts-loaded)
h2, html:not(.fonts-loaded)
h3, html:not(.fonts-loaded)
h4, html:not(.fonts-loaded)
h5, html:not(.fonts-loaded)
h6 {
  font-family: "Georgia", 微软雅黑体, "Microsoft YaHei", 华文细黑, "STXihei", 华文黑体, "STHeiti", 冬青黑体, "Hiragino Sans GB", serif;
}

html:not(.fonts-loaded) .txt-sb {
  font-family: "Georgia", 微软雅黑体, "Microsoft YaHei", 华文细黑, "STXihei", 华文黑体, "STHeiti", 冬青黑体, "Hiragino Sans GB", serif;
}

body {
  --font-lc: "Lane Crawford", 微软雅黑体, "Microsoft YaHei", 华文细黑, "STXihei", 华文黑体, "STHeiti", 冬青黑体, "Hiragino Sans GB", sans-serif;
  --font-sb: "Lane Crawford", 微软雅黑体, "Microsoft YaHei", 华文细黑, "STXihei", 华文黑体, "STHeiti", 冬青黑体, "Hiragino Sans GB", sans-serif;
  --font-mono: "SF Mono", "Monaco", "Inconsolata", "Fira Mono", "Droid Sans Mono", "Source Code Pro", monospace;
  --font-lc-fallback: "Georgia", 微软雅黑体, "Microsoft YaHei", 华文细黑, "STXihei", 华文黑体, "STHeiti", 冬青黑体, "Hiragino Sans GB", serif;
  --font-sb-fallback: "Georgia", 微软雅黑体, "Microsoft YaHei", 华文细黑, "STXihei", 华文黑体, "STHeiti", 冬青黑体, "Hiragino Sans GB", serif;
}

button,
input,
select,
textarea {
  outline: 0;
  font-weight: inherit;
  color: inherit;
}

input,
select,
textarea,
option,
optgroup {
  font-family: inherit;
}

button,
input,
optgroup,
select,
textarea {
  line-height: normal;
}

.btn {
  font-family: "Lane Crawford", 微软雅黑体, "Microsoft YaHei", 华文细黑, "STXihei", 华文黑体, "STHeiti", 冬青黑体, "Hiragino Sans GB", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.0375rem;
  height: 3.125rem;
  line-height: 3.125rem;
  text-align: center;
  color: #fff;
  background: #000;
  border: 0.0625rem solid #000;
  transition-property: border, background, opacity, color;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.32, 0.05, 0.66, 1);
  cursor: pointer;
}

.btn:not([class*="pad-"]) {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.btn--alt {
  color: #000;
  background: #fff;
}

.btn--small {
  height: 2.5rem;
  line-height: 2.5rem;
}

.btn--tiny {
  height: 2rem;
  line-height: 1.9rem;
}

.btn--min {
  min-width: 10rem;
}

.btn--wide {
  min-width: 15rem;
}

.btn--full {
  display: block;
  width: 100%;
}

.btn--flex {
  align-items: center;
  justify-content: center;
}

.btn--select {
  background: transparent;
  border: none;
  outline: none;
  z-index: 1;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.btn--select::-ms-expand {
  display: none;
}

.btn--invert:not(.btn--disabled):hover, .btn--invert:not(.btn--disabled):focus, .btn--invert:not(.btn--disabled):active, .btn--invert:not([disabled]):hover, .btn--invert:not([disabled]):focus, .btn--invert:not([disabled]):active, .btn--invert:not(.is-disabled):hover, .btn--invert:not(.is-disabled):focus, .btn--invert:not(.is-disabled):active, .btn--invert:not(.disabled):hover, .btn--invert:not(.disabled):focus, .btn--invert:not(.disabled):active {
  color: #000;
  background: #fff;
}

.btn--invert:not(.btn--disabled).btn--alt:hover, .btn--invert:not(.btn--disabled).btn--alt:focus, .btn--invert:not(.btn--disabled).btn--alt:active, .btn--invert:not([disabled]).btn--alt:hover, .btn--invert:not([disabled]).btn--alt:focus, .btn--invert:not([disabled]).btn--alt:active, .btn--invert:not(.is-disabled).btn--alt:hover, .btn--invert:not(.is-disabled).btn--alt:focus, .btn--invert:not(.is-disabled).btn--alt:active, .btn--invert:not(.disabled).btn--alt:hover, .btn--invert:not(.disabled).btn--alt:focus, .btn--invert:not(.disabled).btn--alt:active {
  color: #fff;
  background: #000;
}

.btn--disabled, .btn[disabled], .btn.is-disabled, .btn.disabled {
  color: #eeeded;
  background: #999;
  border-color: #666;
  cursor: default;
}

.btn--disabled.btn--alt, .btn[disabled].btn--alt, .btn.is-disabled.btn--alt, .btn.disabled.btn--alt {
  color: #fff;
  background: #ccc;
  border-color: #666;
}

@media print, screen and (min-width: 48em) {
  .tb-btn {
    font-family: "Lane Crawford", 微软雅黑体, "Microsoft YaHei", 华文细黑, "STXihei", 华文黑体, "STHeiti", 冬青黑体, "Hiragino Sans GB", sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.0375rem;
    height: 3.125rem;
    line-height: 3.125rem;
    text-align: center;
    color: #fff;
    background: #000;
    border: 0.0625rem solid #000;
    transition-property: border, background, opacity, color;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.32, 0.05, 0.66, 1);
    cursor: pointer;
  }
  .tb-btn:not([class*="pad-"]) {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .tb-btn--alt {
    color: #000;
    background: #fff;
  }
  .tb-btn--small {
    height: 2.5rem;
    line-height: 2.5rem;
  }
  .tb-btn--tiny {
    height: 2rem;
    line-height: 1.9rem;
  }
  .tb-btn--min {
    min-width: 10rem;
  }
  .tb-btn--wide {
    min-width: 15rem;
  }
  .tb-btn--full {
    display: block;
    width: 100%;
  }
  .tb-btn--flex {
    align-items: center;
    justify-content: center;
  }
  .tb-btn--select {
    background: transparent;
    border: none;
    outline: none;
    z-index: 1;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
  }
  .tb-btn--select::-ms-expand {
    display: none;
  }
  .tb-btn--invert:not(.btn--disabled):hover, .tb-btn--invert:not(.btn--disabled):focus, .tb-btn--invert:not(.btn--disabled):active, .tb-btn--invert:not([disabled]):hover, .tb-btn--invert:not([disabled]):focus, .tb-btn--invert:not([disabled]):active, .tb-btn--invert:not(.is-disabled):hover, .tb-btn--invert:not(.is-disabled):focus, .tb-btn--invert:not(.is-disabled):active, .tb-btn--invert:not(.disabled):hover, .tb-btn--invert:not(.disabled):focus, .tb-btn--invert:not(.disabled):active {
    color: #000;
    background: #fff;
  }
  .tb-btn--invert:not(.btn--disabled).btn--alt:hover, .tb-btn--invert:not(.btn--disabled).btn--alt:focus, .tb-btn--invert:not(.btn--disabled).btn--alt:active, .tb-btn--invert:not([disabled]).btn--alt:hover, .tb-btn--invert:not([disabled]).btn--alt:focus, .tb-btn--invert:not([disabled]).btn--alt:active, .tb-btn--invert:not(.is-disabled).btn--alt:hover, .tb-btn--invert:not(.is-disabled).btn--alt:focus, .tb-btn--invert:not(.is-disabled).btn--alt:active, .tb-btn--invert:not(.disabled).btn--alt:hover, .tb-btn--invert:not(.disabled).btn--alt:focus, .tb-btn--invert:not(.disabled).btn--alt:active {
    color: #fff;
    background: #000;
  }
  .tb-btn--disabled, .tb-btn[disabled], .tb-btn.is-disabled, .tb-btn.disabled {
    color: #eeeded;
    background: #999;
    border-color: #666;
    cursor: default;
  }
  .tb-btn--disabled.btn--alt, .tb-btn[disabled].btn--alt, .tb-btn.is-disabled.btn--alt, .tb-btn.disabled.btn--alt {
    color: #fff;
    background: #ccc;
    border-color: #666;
  }
}

@media screen and (min-width: 64em) {
  .dt-btn {
    font-family: "Lane Crawford", 微软雅黑体, "Microsoft YaHei", 华文细黑, "STXihei", 华文黑体, "STHeiti", 冬青黑体, "Hiragino Sans GB", sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.0375rem;
    height: 3.125rem;
    line-height: 3.125rem;
    text-align: center;
    color: #fff;
    background: #000;
    border: 0.0625rem solid #000;
    transition-property: border, background, opacity, color;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.32, 0.05, 0.66, 1);
    cursor: pointer;
  }
  .dt-btn:not([class*="pad-"]) {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .dt-btn--alt {
    color: #000;
    background: #fff;
  }
  .dt-btn--small {
    height: 2.5rem;
    line-height: 2.5rem;
  }
  .dt-btn--tiny {
    height: 2rem;
    line-height: 1.9rem;
  }
  .dt-btn--min {
    min-width: 10rem;
  }
  .dt-btn--wide {
    min-width: 15rem;
  }
  .dt-btn--full {
    display: block;
    width: 100%;
  }
  .dt-btn--flex {
    align-items: center;
    justify-content: center;
  }
  .dt-btn--select {
    background: transparent;
    border: none;
    outline: none;
    z-index: 1;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
  }
  .dt-btn--select::-ms-expand {
    display: none;
  }
  .dt-btn--invert:not(.btn--disabled):hover, .dt-btn--invert:not(.btn--disabled):focus, .dt-btn--invert:not(.btn--disabled):active, .dt-btn--invert:not([disabled]):hover, .dt-btn--invert:not([disabled]):focus, .dt-btn--invert:not([disabled]):active, .dt-btn--invert:not(.is-disabled):hover, .dt-btn--invert:not(.is-disabled):focus, .dt-btn--invert:not(.is-disabled):active, .dt-btn--invert:not(.disabled):hover, .dt-btn--invert:not(.disabled):focus, .dt-btn--invert:not(.disabled):active {
    color: #000;
    background: #fff;
  }
  .dt-btn--invert:not(.btn--disabled).btn--alt:hover, .dt-btn--invert:not(.btn--disabled).btn--alt:focus, .dt-btn--invert:not(.btn--disabled).btn--alt:active, .dt-btn--invert:not([disabled]).btn--alt:hover, .dt-btn--invert:not([disabled]).btn--alt:focus, .dt-btn--invert:not([disabled]).btn--alt:active, .dt-btn--invert:not(.is-disabled).btn--alt:hover, .dt-btn--invert:not(.is-disabled).btn--alt:focus, .dt-btn--invert:not(.is-disabled).btn--alt:active, .dt-btn--invert:not(.disabled).btn--alt:hover, .dt-btn--invert:not(.disabled).btn--alt:focus, .dt-btn--invert:not(.disabled).btn--alt:active {
    color: #fff;
    background: #000;
  }
  .dt-btn--disabled, .dt-btn[disabled], .dt-btn.is-disabled, .dt-btn.disabled {
    color: #eeeded;
    background: #999;
    border-color: #666;
    cursor: default;
  }
  .dt-btn--disabled.btn--alt, .dt-btn[disabled].btn--alt, .dt-btn.is-disabled.btn--alt, .dt-btn.disabled.btn--alt {
    color: #fff;
    background: #ccc;
    border-color: #666;
  }
}

@media screen and (min-width: 75em) {
  .hg-btn {
    font-family: "Lane Crawford", 微软雅黑体, "Microsoft YaHei", 华文细黑, "STXihei", 华文黑体, "STHeiti", 冬青黑体, "Hiragino Sans GB", sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.0375rem;
    height: 3.125rem;
    line-height: 3.125rem;
    text-align: center;
    color: #fff;
    background: #000;
    border: 0.0625rem solid #000;
    transition-property: border, background, opacity, color;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.32, 0.05, 0.66, 1);
    cursor: pointer;
  }
  .hg-btn:not([class*="pad-"]) {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .hg-btn--alt {
    color: #000;
    background: #fff;
  }
  .hg-btn--small {
    height: 2.5rem;
    line-height: 2.5rem;
  }
  .hg-btn--tiny {
    height: 2rem;
    line-height: 1.9rem;
  }
  .hg-btn--min {
    min-width: 10rem;
  }
  .hg-btn--wide {
    min-width: 15rem;
  }
  .hg-btn--full {
    display: block;
    width: 100%;
  }
  .hg-btn--flex {
    align-items: center;
    justify-content: center;
  }
  .hg-btn--select {
    background: transparent;
    border: none;
    outline: none;
    z-index: 1;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
  }
  .hg-btn--select::-ms-expand {
    display: none;
  }
  .hg-btn--invert:not(.btn--disabled):hover, .hg-btn--invert:not(.btn--disabled):focus, .hg-btn--invert:not(.btn--disabled):active, .hg-btn--invert:not([disabled]):hover, .hg-btn--invert:not([disabled]):focus, .hg-btn--invert:not([disabled]):active, .hg-btn--invert:not(.is-disabled):hover, .hg-btn--invert:not(.is-disabled):focus, .hg-btn--invert:not(.is-disabled):active, .hg-btn--invert:not(.disabled):hover, .hg-btn--invert:not(.disabled):focus, .hg-btn--invert:not(.disabled):active {
    color: #000;
    background: #fff;
  }
  .hg-btn--invert:not(.btn--disabled).btn--alt:hover, .hg-btn--invert:not(.btn--disabled).btn--alt:focus, .hg-btn--invert:not(.btn--disabled).btn--alt:active, .hg-btn--invert:not([disabled]).btn--alt:hover, .hg-btn--invert:not([disabled]).btn--alt:focus, .hg-btn--invert:not([disabled]).btn--alt:active, .hg-btn--invert:not(.is-disabled).btn--alt:hover, .hg-btn--invert:not(.is-disabled).btn--alt:focus, .hg-btn--invert:not(.is-disabled).btn--alt:active, .hg-btn--invert:not(.disabled).btn--alt:hover, .hg-btn--invert:not(.disabled).btn--alt:focus, .hg-btn--invert:not(.disabled).btn--alt:active {
    color: #fff;
    background: #000;
  }
  .hg-btn--disabled, .hg-btn[disabled], .hg-btn.is-disabled, .hg-btn.disabled {
    color: #eeeded;
    background: #999;
    border-color: #666;
    cursor: default;
  }
  .hg-btn--disabled.btn--alt, .hg-btn[disabled].btn--alt, .hg-btn.is-disabled.btn--alt, .hg-btn.disabled.btn--alt {
    color: #fff;
    background: #ccc;
    border-color: #666;
  }
}

@media screen and (max-width: 47.9375em) {
  .mb-only-btn {
    font-family: "Lane Crawford", 微软雅黑体, "Microsoft YaHei", 华文细黑, "STXihei", 华文黑体, "STHeiti", 冬青黑体, "Hiragino Sans GB", sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.0375rem;
    height: 3.125rem;
    line-height: 3.125rem;
    text-align: center;
    color: #fff;
    background: #000;
    border: 0.0625rem solid #000;
    transition-property: border, background, opacity, color;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.32, 0.05, 0.66, 1);
    cursor: pointer;
  }
  .mb-only-btn:not([class*="pad-"]) {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .mb-only-btn--alt {
    color: #000;
    background: #fff;
  }
  .mb-only-btn--small {
    height: 2.5rem;
    line-height: 2.5rem;
  }
  .mb-only-btn--tiny {
    height: 2rem;
    line-height: 1.9rem;
  }
  .mb-only-btn--min {
    min-width: 10rem;
  }
  .mb-only-btn--wide {
    min-width: 15rem;
  }
  .mb-only-btn--full {
    display: block;
    width: 100%;
  }
  .mb-only-btn--flex {
    align-items: center;
    justify-content: center;
  }
  .mb-only-btn--select {
    background: transparent;
    border: none;
    outline: none;
    z-index: 1;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
  }
  .mb-only-btn--select::-ms-expand {
    display: none;
  }
  .mb-only-btn--invert:not(.btn--disabled):hover, .mb-only-btn--invert:not(.btn--disabled):focus, .mb-only-btn--invert:not(.btn--disabled):active, .mb-only-btn--invert:not([disabled]):hover, .mb-only-btn--invert:not([disabled]):focus, .mb-only-btn--invert:not([disabled]):active, .mb-only-btn--invert:not(.is-disabled):hover, .mb-only-btn--invert:not(.is-disabled):focus, .mb-only-btn--invert:not(.is-disabled):active, .mb-only-btn--invert:not(.disabled):hover, .mb-only-btn--invert:not(.disabled):focus, .mb-only-btn--invert:not(.disabled):active {
    color: #000;
    background: #fff;
  }
  .mb-only-btn--invert:not(.btn--disabled).btn--alt:hover, .mb-only-btn--invert:not(.btn--disabled).btn--alt:focus, .mb-only-btn--invert:not(.btn--disabled).btn--alt:active, .mb-only-btn--invert:not([disabled]).btn--alt:hover, .mb-only-btn--invert:not([disabled]).btn--alt:focus, .mb-only-btn--invert:not([disabled]).btn--alt:active, .mb-only-btn--invert:not(.is-disabled).btn--alt:hover, .mb-only-btn--invert:not(.is-disabled).btn--alt:focus, .mb-only-btn--invert:not(.is-disabled).btn--alt:active, .mb-only-btn--invert:not(.disabled).btn--alt:hover, .mb-only-btn--invert:not(.disabled).btn--alt:focus, .mb-only-btn--invert:not(.disabled).btn--alt:active {
    color: #fff;
    background: #000;
  }
  .mb-only-btn--disabled, .mb-only-btn[disabled], .mb-only-btn.is-disabled, .mb-only-btn.disabled {
    color: #eeeded;
    background: #999;
    border-color: #666;
    cursor: default;
  }
  .mb-only-btn--disabled.btn--alt, .mb-only-btn[disabled].btn--alt, .mb-only-btn.is-disabled.btn--alt, .mb-only-btn.disabled.btn--alt {
    color: #fff;
    background: #ccc;
    border-color: #666;
  }
}

@media print, screen and (min-width: 48em) and (max-width: 63.9375em) {
  .tb-only-btn {
    font-family: "Lane Crawford", 微软雅黑体, "Microsoft YaHei", 华文细黑, "STXihei", 华文黑体, "STHeiti", 冬青黑体, "Hiragino Sans GB", sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.0375rem;
    height: 3.125rem;
    line-height: 3.125rem;
    text-align: center;
    color: #fff;
    background: #000;
    border: 0.0625rem solid #000;
    transition-property: border, background, opacity, color;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.32, 0.05, 0.66, 1);
    cursor: pointer;
  }
  .tb-only-btn:not([class*="pad-"]) {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .tb-only-btn--alt {
    color: #000;
    background: #fff;
  }
  .tb-only-btn--small {
    height: 2.5rem;
    line-height: 2.5rem;
  }
  .tb-only-btn--tiny {
    height: 2rem;
    line-height: 1.9rem;
  }
  .tb-only-btn--min {
    min-width: 10rem;
  }
  .tb-only-btn--wide {
    min-width: 15rem;
  }
  .tb-only-btn--full {
    display: block;
    width: 100%;
  }
  .tb-only-btn--flex {
    align-items: center;
    justify-content: center;
  }
  .tb-only-btn--select {
    background: transparent;
    border: none;
    outline: none;
    z-index: 1;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
  }
  .tb-only-btn--select::-ms-expand {
    display: none;
  }
  .tb-only-btn--invert:not(.btn--disabled):hover, .tb-only-btn--invert:not(.btn--disabled):focus, .tb-only-btn--invert:not(.btn--disabled):active, .tb-only-btn--invert:not([disabled]):hover, .tb-only-btn--invert:not([disabled]):focus, .tb-only-btn--invert:not([disabled]):active, .tb-only-btn--invert:not(.is-disabled):hover, .tb-only-btn--invert:not(.is-disabled):focus, .tb-only-btn--invert:not(.is-disabled):active, .tb-only-btn--invert:not(.disabled):hover, .tb-only-btn--invert:not(.disabled):focus, .tb-only-btn--invert:not(.disabled):active {
    color: #000;
    background: #fff;
  }
  .tb-only-btn--invert:not(.btn--disabled).btn--alt:hover, .tb-only-btn--invert:not(.btn--disabled).btn--alt:focus, .tb-only-btn--invert:not(.btn--disabled).btn--alt:active, .tb-only-btn--invert:not([disabled]).btn--alt:hover, .tb-only-btn--invert:not([disabled]).btn--alt:focus, .tb-only-btn--invert:not([disabled]).btn--alt:active, .tb-only-btn--invert:not(.is-disabled).btn--alt:hover, .tb-only-btn--invert:not(.is-disabled).btn--alt:focus, .tb-only-btn--invert:not(.is-disabled).btn--alt:active, .tb-only-btn--invert:not(.disabled).btn--alt:hover, .tb-only-btn--invert:not(.disabled).btn--alt:focus, .tb-only-btn--invert:not(.disabled).btn--alt:active {
    color: #fff;
    background: #000;
  }
  .tb-only-btn--disabled, .tb-only-btn[disabled], .tb-only-btn.is-disabled, .tb-only-btn.disabled {
    color: #eeeded;
    background: #999;
    border-color: #666;
    cursor: default;
  }
  .tb-only-btn--disabled.btn--alt, .tb-only-btn[disabled].btn--alt, .tb-only-btn.is-disabled.btn--alt, .tb-only-btn.disabled.btn--alt {
    color: #fff;
    background: #ccc;
    border-color: #666;
  }
}

.lc-field__checkbox-label,
.lc-field__checkbox-label * {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.lc-field__checkbox {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  opacity: 0;
  position: absolute;
  margin-left: -9999em;
}

.lc-field__checkbox-fake {
  width: 1.4em;
  height: 1.4em;
  vertical-align: middle;
  border-radius: 100%;
  box-shadow: 0 0 0 1px #000;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0.05em;
  transition: box-shadow 0.2s cubic-bezier(0.32, 0.05, 0.66, 1);
}

.lc-field__checkbox-fake:after {
  content: "";
  top: 0.25rem;
  left: 0.25rem;
  right: 0.25rem;
  bottom: 0.25rem;
  position: absolute;
  background: #000;
  border-radius: 100%;
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.32, 0.05, 0.66, 1);
}

.lc-field__checkbox-fake.is-error {
  box-shadow: 0 0 0 1px #ca0814;
}

.lc-field__checkbox-text {
  display: inline-block;
  vertical-align: middle;
  margin-left: 2.5em;
}

.lc-field__checkbox-image, .lc-field__checkbox-content {
  box-shadow: 0 0 0 1px transparent;
  transition: box-shadow 0.2s cubic-bezier(0.32, 0.05, 0.66, 1);
  cursor: pointer;
}

.lc-field__checkbox-image.is-error, .lc-field__checkbox-content.is-error {
  box-shadow: 0 0 0 2px #ca0814;
}

.lc-field__checkbox-content {
  box-shadow: 0 0 0 1px #999;
}

.lc-field__checkbox:checked ~ .lc-field__checkbox-fake {
  box-shadow: 0 0 0 2px #000;
}

.lc-field__checkbox:checked ~ .lc-field__checkbox-fake:after {
  opacity: 1;
}

.lc-field__checkbox:checked ~ .lc-field__checkbox-fake.is-error {
  box-shadow: 0 0 0 2px #ca0814;
}

.lc-field__checkbox:focus ~ .lc-field__checkbox-fake {
  background-color: rgba(0, 0, 0, 0.075);
  box-shadow: 0 0 0 2px #000, 0 0 0 3px #ccc;
}

.lc-field__checkbox:focus ~ .lc-field__checkbox-fake.is-error {
  box-shadow: 0 0 0 2px #ca0814, 0 0 0 3px #ccc;
}

.lc-field__checkbox:checked ~ .lc-field__checkbox-image,
.lc-field__checkbox:checked ~ .lc-field__checkbox-content {
  box-shadow: 0 0 0 2px #000;
}

.lc-field__checkbox:checked ~ .lc-field__checkbox-content {
  font-weight: bold;
}

.lc-field__checkbox.is-error, .lc-field__checkbox.errorBorder, .lc-field__checkbox:invalid {
  box-shadow: 0 0 0 1px #ca0814;
}

.lc-field__checkbox-label {
  display: inline-block;
  position: relative;
}

.lc-field__checkbox-label:hover > .lc-field__checkbox-fake,
.lc-field__checkbox-label:hover > .lc-field__checkbox-image,
.lc-field__checkbox-label:hover > .lc-field__checkbox-content {
  box-shadow: 0 0 0 2px #000;
}

.lc-field__checkbox-label.is-disabled .lc-field__checkbox-fake,
.lc-field__checkbox-label.is-disabled .lc-field__checkbox-image,
.lc-field__checkbox-label.is-disabled .lc-field__checkbox-content,
.lc-field__checkbox-label.disabled .lc-field__checkbox-fake,
.lc-field__checkbox-label > .lc-field__checkbox-fake.disabled,
.lc-field__checkbox-label > .lc-field__checkbox-fake.is-disabled,
.lc-field__checkbox-label > .lc-field__checkbox[disabled] ~ .lc-field__checkbox-fake,
.lc-field__checkbox-label > .lc-field__checkbox[disabled] ~ .lc-field__checkbox-image,
.lc-field__checkbox-label > .lc-field__checkbox[disabled] ~ .lc-field__checkbox-content {
  opacity: 0.35;
  cursor: default;
}

.lc-field__checkbox-label--small .lc-field__checkbox-fake {
  width: 1em;
  height: 1em;
  top: 0.25em;
}

.lc-field__checkbox-label--small .lc-field__checkbox-fake:after {
  top: 0.125rem;
  left: 0.125rem;
  right: 0.125rem;
  bottom: 0.125rem;
}

.lc-field__checkbox-label--small .lc-field__checkbox-text {
  margin-left: 1.75em;
}

.lc-field__checkbox-fake {
  border-radius: 0;
}

.lc-field__checkbox-fake:after {
  background-color: transparent;
  background-image: url( ../../dist/img/check.png );
  background-size: cover;
  background-repeat: no-repeat;
}

.lc-field__checkbox-label--small .lc-field__checkbox-fake:after {
  top: 0.0625rem;
  bottom: 0.0625rem;
  left: 0.0625rem;
  right: 0.0625rem;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

.max-fieldset {
  max-width: 42rem;
}

@media screen and (min-width: 35em) {
  .max-fieldset-narrow {
    max-width: 31.25rem;
  }
}

.lc-field {
  display: block;
  position: relative;
}

.lc-field:not([class*="mar-"]) {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.lc-field__input, .lc-field__select, .lc-field__textarea, .lc-field__autocomplete {
  text-align: center;
  text-transform: inherit;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 3.125rem;
  border-radius: 0;
}

.lc-field__input:not([class*="bg-"]), .lc-field__select:not([class*="bg-"]), .lc-field__textarea:not([class*="bg-"]), .lc-field__autocomplete:not([class*="bg-"]) {
  background-color: #fff;
}

.lc-field__input, .lc-field__select, .lc-field__textarea, .lc-field__autocomplete,
.lc-field * {
  transition: border 0.33s cubic-bezier(0.32, 0.05, 0.66, 1), box-shadow 0.66s cubic-bezier(0.32, 0.05, 0.66, 1);
}

.lc-field__input--new, .lc-field__select--new, .lc-field__textarea--new, .lc-field__autocomplete--new {
  text-align: left;
  height: 2.5rem;
  line-height: 1.4;
  box-shadow: 0 0 0 3px transparent;
}

.lc-field__input--new:not([disabled]):focus, .lc-field__input--new:not([disabled]):active, .lc-field__select--new:not([disabled]):focus, .lc-field__select--new:not([disabled]):active, .lc-field__textarea--new:not([disabled]):focus, .lc-field__textarea--new:not([disabled]):active, .lc-field__autocomplete--new:not([disabled]):focus, .lc-field__autocomplete--new:not([disabled]):active {
  box-shadow: 0 0 0 1px #000;
}

.lc-field__select--new:not([disabled]) ~ .lc-field__select-label {
  box-shadow: 0 0 0 3px transparent;
}

.lc-field__label {
  font-size: 0.75rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0.3125rem;
}

.lc-field__label--new {
  color: #3a3a3a;
  font-family: "Lane Crawford", 微软雅黑体, "Microsoft YaHei", 华文细黑, "STXihei", 华文黑体, "STHeiti", 冬青黑体, "Hiragino Sans GB", sans-serif;
  font-size: 1em;
  letter-spacing: 0.025em;
  text-transform: inherit;
  margin-bottom: 0.125rem;
}

.lc-field__help {
  color: #666;
  font-family: "Lane Crawford", 微软雅黑体, "Microsoft YaHei", 华文细黑, "STXihei", 华文黑体, "STHeiti", 冬青黑体, "Hiragino Sans GB", sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.025em;
  text-transform: none;
  display: block;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

input {
  border-radius: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.lc-field__input {
  line-height: normal;
  padding-top: 0.625rem;
}

.lc-field__input:not([class*="bor-"]) {
  border-left: none;
  border-right: none;
  border-top: 1px solid transparent;
  border-bottom: 1px solid #999;
}

.lc-field__input:not([class*="bor-"]):hover, .lc-field__input:not([class*="bor-"]):active, .lc-field__input:not([class*="bor-"]):focus {
  border-bottom-color: #000;
}

.lc-field__input:not([class*="bor-"]).is-error, .lc-field__input:not([class*="bor-"]).errorBorder, .lc-field__input:not([class*="bor-"]):invalid {
  border-bottom-color: #ca0814;
  border-bottom-width: 2px;
}

.lc-field__input:not([class*="bor-"]).disabled, .lc-field__input:not([class*="bor-"])[disabled] {
  color: #999;
  border-bottom-color: #ccc;
}

.lc-field__input::-moz-placeholder {
  color: #999;
}

.lc-field__input:-ms-input-placeholder {
  color: #999;
}

.lc-field__input::placeholder {
  color: #999;
}

.lc-field__input:-webkit-autofill {
  border-top-color: #f0f9f8;
  box-shadow: inset 0 0 0 100px #f0f9f8;
}

.lc-field__input::-webkit-contacts-auto-fill-button, .lc-field__input::-webkit-credentials-auto-fill-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  position: absolute;
  -webkit-appearance: none;
          appearance: none;
}

input::-ms-clear {
  width: 0;
  height: 0;
  position: absolute;
  visibility: hidden;
}

.lc-field__input--new {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-bottom: 0;
  padding-top: 0;
}

.lc-field__input--new:not([class*="bor-"]) {
  border: 1px solid #999;
}

.lc-field__input--new:not([class*="bor-"]):hover, .lc-field__input--new:not([class*="bor-"]):active, .lc-field__input--new:not([class*="bor-"]):focus {
  border-color: #000;
}

.lc-field__input--new:not([class*="bor-"]).is-error, .lc-field__input--new:not([class*="bor-"]).errorBorder, .lc-field__input--new:not([class*="bor-"]):invalid {
  border-color: #ca0814;
  border-width: 1px;
}

.lc-field__input--new:not([class*="bor-"]).is-error:focus, .lc-field__input--new:not([class*="bor-"]).is-error:active, .lc-field__input--new:not([class*="bor-"]).errorBorder:focus, .lc-field__input--new:not([class*="bor-"]).errorBorder:active, .lc-field__input--new:not([class*="bor-"]):invalid:focus, .lc-field__input--new:not([class*="bor-"]):invalid:active {
  box-shadow: 0 0 0 1px #ca0814;
}

.lc-field__input--new:not([class*="bor-"]).disabled, .lc-field__input--new:not([class*="bor-"])[disabled] {
  border-color: #ccc;
}

.lc-field__placeholder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: #999;
  height: 3.125rem;
  line-height: 3.7rem;
  vertical-align: middle;
  transition: color 0.05s cubic-bezier(0.32, 0.05, 0.66, 1);
  cursor: text;
  pointer-events: none;
}

.lc-field__placeholder :not(.lc-field__placeholder--select) {
  cursor: text;
}

.lc-field__placeholder.is-shifted {
  color: #666;
  height: 0.75rem;
  line-height: 0.75;
  transform: scale(0.75);
}

.lc-field--no-placeholder .lc-field__placeholder.is-shifted {
  opacity: 0;
}

.lc-field__radio-label,
.lc-field__radio-label * {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.lc-field__radio {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  opacity: 0;
  position: absolute;
  margin-left: -9999em;
}

.lc-field__radio-fake {
  width: 1.4em;
  height: 1.4em;
  vertical-align: middle;
  border-radius: 100%;
  box-shadow: 0 0 0 1px #000;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0.05em;
  transition: box-shadow 0.2s cubic-bezier(0.32, 0.05, 0.66, 1);
}

.lc-field__radio-fake:after {
  content: "";
  top: 0.25rem;
  left: 0.25rem;
  right: 0.25rem;
  bottom: 0.25rem;
  position: absolute;
  background: #000;
  border-radius: 100%;
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.32, 0.05, 0.66, 1);
}

.lc-field__radio-fake.is-error {
  box-shadow: 0 0 0 1px #ca0814;
}

.lc-field__radio-text {
  display: inline-block;
  vertical-align: middle;
  margin-left: 2.5em;
}

.lc-field__radio-image, .lc-field__radio-content {
  box-shadow: 0 0 0 1px transparent;
  transition: box-shadow 0.2s cubic-bezier(0.32, 0.05, 0.66, 1);
  cursor: pointer;
}

.lc-field__radio-image.is-error, .lc-field__radio-content.is-error {
  box-shadow: 0 0 0 2px #ca0814;
}

.lc-field__radio-content {
  box-shadow: 0 0 0 1px #999;
}

.lc-field__radio:checked ~ .lc-field__radio-fake {
  box-shadow: 0 0 0 2px #000;
}

.lc-field__radio:checked ~ .lc-field__radio-fake:after {
  opacity: 1;
}

.lc-field__radio:checked ~ .lc-field__radio-fake.is-error {
  box-shadow: 0 0 0 2px #ca0814;
}

.lc-field__radio:focus ~ .lc-field__radio-fake {
  background-color: rgba(0, 0, 0, 0.075);
  box-shadow: 0 0 0 2px #000, 0 0 0 3px #ccc;
}

.lc-field__radio:focus ~ .lc-field__radio-fake.is-error {
  box-shadow: 0 0 0 2px #ca0814, 0 0 0 3px #ccc;
}

.lc-field__radio:checked ~ .lc-field__radio-image,
.lc-field__radio:checked ~ .lc-field__radio-content {
  box-shadow: 0 0 0 2px #000;
}

.lc-field__radio:checked ~ .lc-field__radio-content {
  font-weight: bold;
}

.lc-field__radio.is-error, .lc-field__radio.errorBorder, .lc-field__radio:invalid {
  box-shadow: 0 0 0 1px #ca0814;
}

.lc-field__radio-label {
  display: inline-block;
  position: relative;
}

.lc-field__radio-label:hover > .lc-field__radio-fake,
.lc-field__radio-label:hover > .lc-field__radio-image,
.lc-field__radio-label:hover > .lc-field__radio-content {
  box-shadow: 0 0 0 2px #000;
}

.lc-field__radio-label.is-disabled .lc-field__radio-fake,
.lc-field__radio-label.is-disabled .lc-field__radio-image,
.lc-field__radio-label.is-disabled .lc-field__radio-content,
.lc-field__radio-label.disabled .lc-field__radio-fake,
.lc-field__radio-label > .lc-field__radio-fake.disabled,
.lc-field__radio-label > .lc-field__radio-fake.is-disabled,
.lc-field__radio-label > .lc-field__radio[disabled] ~ .lc-field__radio-fake,
.lc-field__radio-label > .lc-field__radio[disabled] ~ .lc-field__radio-image,
.lc-field__radio-label > .lc-field__radio[disabled] ~ .lc-field__radio-content {
  opacity: 0.35;
  cursor: default;
}

.lc-field__radio-label--small .lc-field__radio-fake {
  width: 1em;
  height: 1em;
  top: 0.25em;
}

.lc-field__radio-label--small .lc-field__radio-fake:after {
  top: 0.125rem;
  left: 0.125rem;
  right: 0.125rem;
  bottom: 0.125rem;
}

.lc-field__radio-label--small .lc-field__radio-text {
  margin-left: 1.75em;
}

select {
  border-radius: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.lc-field__select {
  font-family: "Lane Crawford", 微软雅黑体, "Microsoft YaHei", 华文细黑, "STXihei", 华文黑体, "STHeiti", 冬青黑体, "Hiragino Sans GB", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.0375rem;
  height: 3.125rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.lc-field__select.is-replaced {
  opacity: 0;
  position: relative;
  z-index: 1;
}

.lc-field__select:-webkit-autofill + .lc-field__select-label {
  box-shadow: inset 0 0 0 100px #f0f9f8;
}

.lc-field__select-label,
.lc-field__select-icon {
  position: absolute;
  top: 0;
  right: 15px;
  height: 3.125rem;
  line-height: 3.125rem;
  cursor: pointer;
  pointer-events: none;
}

.lc-field__select-label {
  font-family: "Lane Crawford", 微软雅黑体, "Microsoft YaHei", 华文细黑, "STXihei", 华文黑体, "STHeiti", 冬青黑体, "Hiragino Sans GB", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.0375rem;
  left: 0;
  right: 0;
  border: 1px solid #999;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.lc-field__select-label:not([class*="bg-"]) {
  background-color: #fff;
}

.lc-field__select-icon {
  top: 2px;
}

.lc-field__select:active + .lc-field__select-label,
.lc-field__select:focus + .lc-field__select-label,
.lc-field__select:hover + .lc-field__select-label,
.lc-field__select-label:hover,
.lc-field__select-label:focus {
  border-color: #000;
}

.lc-field__select:focus + .lc-field__select-label,
.lc-field__select-label:focus {
  box-shadow: 0 0 0 1px #ccc;
}

.lc-field__select.is-error + .lc-field__select-label,
.lc-field__select.errorBorder + .lc-field__select-label,
.lc-field__select:invalid + .lc-field__select-label {
  border-color: #ca0814;
  border-width: 2px;
}

.lc-field__select.disabled + .lc-field__select-label,
.lc-field__select[disabled] + .lc-field__select-label,
.lc-field__select[disabled] ~ .lc-field__select-icon {
  color: #999;
  border-color: #ccc;
}

.lc-field__select--new {
  font-family: "Lane Crawford", 微软雅黑体, "Microsoft YaHei", 华文细黑, "STXihei", 华文黑体, "STHeiti", 冬青黑体, "Hiragino Sans GB", sans-serif;
  text-transform: none;
  letter-spacing: normal;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-bottom: 0;
  padding-top: 0;
}

.lc-field__select--new ~ .lc-field__select-label {
  font-family: "Lane Crawford", 微软雅黑体, "Microsoft YaHei", 华文细黑, "STXihei", 华文黑体, "STHeiti", 冬青黑体, "Hiragino Sans GB", sans-serif;
  text-transform: none;
  letter-spacing: normal;
  border: 1px solid #999;
  box-shadow: 0 0 0 1px transparent;
}

.lc-field__select--new,
.lc-field__select--new ~ .lc-field__select-label,
.lc-field__select--new ~ .lc-field__select-icon {
  height: 2.5rem;
  line-height: 2.5rem;
}

.lc-field__select--new:focus + .lc-field__select-label,
.lc-field__select-label:focus {
  box-shadow: 0 0 0 1px #000;
}

.lc-field__select--new.is-error + .lc-field__select-label,
.lc-field__select--new.errorBorder + .lc-field__select-label,
.lc-field__select--new:invalid + .lc-field__select-label {
  border-width: 1px;
}

.lc-field__select--new.is-error:focus + .lc-field__select-label,
.lc-field__select--new.errorBorder:focus + .lc-field__select-label,
.lc-field__select--new:invalid:focus + .lc-field__select-label {
  box-shadow: 0 0 0 1px #ca0814;
}

.lc-field__textarea {
  height: auto;
  min-height: 9.375rem;
  line-height: 1.4;
  padding-top: 0.3125rem;
  resize: vertical;
  transition: border 0.3s cubic-bezier(0.32, 0.05, 0.66, 1), line-height 0.05s cubic-bezier(0.32, 0.05, 0.66, 1), padding 0.05s cubic-bezier(0.32, 0.05, 0.66, 1);
}

.lc-field__textarea::-moz-placeholder {
  color: #999;
}

.lc-field__textarea:-ms-input-placeholder {
  color: #999;
}

.lc-field__textarea::placeholder {
  color: #999;
}

.lc-field__textarea:-webkit-autofill {
  box-shadow: inset 0 0 0 400px #f0f9f8;
}

.lc-field__textarea:not([class*="bor-"]) {
  border: 1px solid #999;
}

.lc-field__textarea:not([class*="bor-"]):hover, .lc-field__textarea:not([class*="bor-"]):active, .lc-field__textarea:not([class*="bor-"]):focus {
  border-color: #000;
}

.lc-field__textarea:not([class*="bor-"]).is-warning, .lc-field__textarea:not([class*="bor-"]).warningBorder {
  border-color: #eca142;
  border-width: 2px;
}

.lc-field__textarea:not([class*="bor-"]).is-error, .lc-field__textarea:not([class*="bor-"]).errorBorder, .lc-field__textarea:not([class*="bor-"]):invalid {
  border-color: #ca0814;
  border-width: 2px;
}

.lc-field__textarea:not([class*="bor-"]).disabled, .lc-field__textarea:not([class*="bor-"])[disabled] {
  color: #999;
  border-color: #ccc;
}

.lc-field__textarea ~ .lc-field__placeholder {
  top: 5px;
  line-height: 1.4;
}

.lc-field__textarea ~ .lc-field__placeholder.is-shifted {
  display: none;
}

.lc-field__counter {
  position: absolute;
  bottom: 0;
  right: 0.3125rem;
  transform: translateY(100%);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
}

.lc-field__counter.is-warning {
  color: #eca142;
}

.lc-field__counter.is-error {
  color: #ca0814;
}

.lc-field__autocomplete::-moz-placeholder {
  color: #999;
}

.lc-field__autocomplete:-ms-input-placeholder {
  color: #999;
}

.lc-field__autocomplete::placeholder {
  color: #999;
}

.lc-field__autocomplete:not([class*="bor-"]) {
  border: 1px solid #999;
}

.lc-field__autocomplete:not([class*="bor-"]):hover, .lc-field__autocomplete:not([class*="bor-"]):active, .lc-field__autocomplete:not([class*="bor-"]):focus {
  border-color: #000;
}

.lc-field__autocomplete:not([class*="bor-"]).is-error, .lc-field__autocomplete:not([class*="bor-"]).errorBorder, .lc-field__autocomplete:not([class*="bor-"]):invalid {
  border-color: #ca0814;
  border-width: 2px;
}

.lc-field-error {
  font-family: "Lane Crawford", 微软雅黑体, "Microsoft YaHei", 华文细黑, "STXihei", 华文黑体, "STHeiti", 冬青黑体, "Hiragino Sans GB", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.0375rem;
  color: #ca0814;
  font-size: 0.875rem;
  display: block;
  text-align: center;
  font-size: 0.75rem;
}

.lc-field-error--new {
  font-family: "Lane Crawford", 微软雅黑体, "Microsoft YaHei", 华文细黑, "STXihei", 华文黑体, "STHeiti", 冬青黑体, "Hiragino Sans GB", sans-serif;
  text-transform: none;
  letter-spacing: normal;
  color: #ca0814;
  font-size: 1rem;
  display: block;
  margin: 0.25rem;
  font-size: 0.875rem;
  text-align: inherit;
  -webkit-animation-name: fadeAndDropInOnLoad;
          animation-name: fadeAndDropInOnLoad;
  -webkit-animation-duration: 0.33s;
          animation-duration: 0.33s;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.455, 0.375, 0.96);
          animation-timing-function: cubic-bezier(0.08, 0.455, 0.375, 0.96);
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
}

.lc-field-error--bordered {
  font-family: "Lane Crawford", 微软雅黑体, "Microsoft YaHei", 华文细黑, "STXihei", 华文黑体, "STHeiti", 冬青黑体, "Hiragino Sans GB", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.0375rem;
  color: #ca0814;
  font-size: 0.875rem;
  display: block;
  text-align: center;
  font-size: 0.75rem;
  border-top: 1px solid #ca0814;
  border-bottom: 1px solid #ca0814;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.lc-general-error,
.lc-unhandled-error {
  font-family: "Lane Crawford", 微软雅黑体, "Microsoft YaHei", 华文细黑, "STXihei", 华文黑体, "STHeiti", 冬青黑体, "Hiragino Sans GB", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.0375rem;
  color: #ca0814;
  font-size: 0.875rem;
  display: block;
  text-align: center;
  border-top: 1px solid #ca0814;
  border-bottom: 1px solid #ca0814;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.lc-field__toggle {
  opacity: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  position: absolute;
  margin-left: -9999em;
}

.lc-field__toggle-label,
.lc-field__toggle-label * {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.lc-field__toggle-label {
  position: relative;
}

.lc-field__toggle-fake {
  width: 2.625em;
  height: 1.5em;
  border-radius: 1.5em;
  vertical-align: middle;
  display: inline-block;
  position: relative;
  left: 0.0625rem;
  background: #ccc;
  box-shadow: inset 0 2px 7px -5px #000, 0 0 0 1px rgba(255, 255, 255, 0.5);
  transition: background-color 0.2s cubic-bezier(0.32, 0.05, 0.66, 1);
}

.lc-field__toggle-fake:after {
  content: "";
  position: absolute;
  top: 0.125em;
  left: 0.125em;
  width: 1.25em;
  height: 1.25em;
  background: #fff;
  border: 1px solid transparent;
  border-radius: 1.25em;
  box-shadow: 0 2px 6px -3px #000;
  transition: transform 0.15s cubic-bezier(0.43, 0, 0.36, 1), border 0.15s cubic-bezier(0.43, 0, 0.36, 1);
}

.lc-field__toggle-text {
  display: inline-block;
  vertical-align: middle;
}

.lc-field__toggle:not(:checked) ~ .lc-field__toggle-text,
.lc-field__toggle:not(:checked) ~ * .lc-field__toggle-text {
  opacity: 0.75;
}

.lc-field__toggle:checked ~ .lc-field__toggle-fake,
.lc-field__toggle:checked ~ * .lc-field__toggle-fake,
.lc-field__toggle--state ~ .lc-field__toggle-fake,
.lc-field__toggle--state ~ * .lc-field__toggle-fake {
  background: #000;
}

.lc-field__toggle-label:not(.disabled):not(.is-disabled):hover > .lc-field__toggle:not([disabled]):not(.disabled):not(.lc-field__toggle--state) ~ .lc-field__toggle-fake:not(.disabled),
.lc-field__toggle-label:not(.disabled):not(.is-disabled):hover > .lc-field__toggle:not([disabled]):not(.disabled):not(.lc-field__toggle--state) ~ * .lc-field__toggle-fake:not(.disabled),
.lc-field__toggle:not([disabled]):not(.disabled):not(.lc-field__toggle--state):focus ~ .lc-field__toggle-fake:not(.disabled),
.lc-field__toggle:not([disabled]):not(.disabled):not(.lc-field__toggle--state):focus ~ * .lc-field__toggle-fake:not(.disabled) {
  background: #b3b3b3;
}

.lc-field__toggle-label:not(.disabled):not(.is-disabled):hover > .lc-field__toggle:not([disabled]):not(.disabled):not(.lc-field__toggle--state):checked ~ .lc-field__toggle-fake:not(.disabled),
.lc-field__toggle-label:not(.disabled):not(.is-disabled):hover > .lc-field__toggle:not([disabled]):not(.disabled):not(.lc-field__toggle--state):checked ~ * .lc-field__toggle-fake:not(.disabled),
.lc-field__toggle:not([disabled]):not(.disabled):not(.lc-field__toggle--state):checked:focus ~ .lc-field__toggle-fake:not(.disabled),
.lc-field__toggle:not([disabled]):not(.disabled):not(.lc-field__toggle--state):checked:focus ~ * .lc-field__toggle-fake:not(.disabled) {
  background: #404040;
}

.lc-field__toggle:checked ~ .lc-field__toggle-fake:after,
.lc-field__toggle:checked ~ * .lc-field__toggle-fake:after {
  transform: translateX(89%);
}

.lc-field__toggle-label:not(.disabled):not(.is-disabled):hover > .lc-field__toggle:not([disabled]):not(.disabled) ~ .lc-field__toggle-fake:not(.disabled):after,
.lc-field__toggle-label:not(.disabled):not(.is-disabled):hover > .lc-field__toggle:not([disabled]):not(.disabled) ~ * .lc-field__toggle-fake:not(.disabled):after,
.lc-field__toggle:not([disabled]):not(.disabled):focus ~ .lc-field__toggle-fake:not(.disabled):after,
.lc-field__toggle:not([disabled]):not(.disabled):focus ~ * .lc-field__toggle-fake:not(.disabled):after {
  transform: translateX(6%);
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.lc-field__toggle-label:not(.disabled):not(.is-disabled):hover > .lc-field__toggle:not([disabled]):not(.disabled):checked ~ .lc-field__toggle-fake:not(.disabled):after,
.lc-field__toggle-label:not(.disabled):not(.is-disabled):hover > .lc-field__toggle:not([disabled]):not(.disabled):checked ~ * .lc-field__toggle-fake:not(.disabled):after,
.lc-field__toggle:not([disabled]):not(.disabled):checked:focus ~ .lc-field__toggle-fake:not(.disabled):after,
.lc-field__toggle:not([disabled]):not(.disabled):checked:focus ~ * .lc-field__toggle-fake:not(.disabled):after {
  transform: translateX(83%);
}

.lc-field__toggle-label.disabled .lc-field__toggle-fake,
.lc-field__toggle-label.disabled * .lc-field__toggle-fake,
.lc-field__toggle-label.disabled .lc-field__toggle-text,
.lc-field__toggle-label.disabled * .lc-field__toggle-text,
.lc-field__toggle-label.is-disabled .lc-field__toggle-fake,
.lc-field__toggle-label.is-disabled * .lc-field__toggle-fake,
.lc-field__toggle-label.is-disabled .lc-field__toggle-text,
.lc-field__toggle-label.is-disabled * .lc-field__toggle-text,
.lc-field__toggle-label .lc-field__toggle-fake.disabled,
.lc-field__toggle-label * .lc-field__toggle-fake.disabled,
.lc-field__toggle-label .lc-field__toggle-text.disabled,
.lc-field__toggle-label * .lc-field__toggle-text.disabled,
.lc-field__toggle-label > .lc-field__toggle[disabled] ~ .lc-field__toggle-fake,
.lc-field__toggle-label > .lc-field__toggle[disabled] ~ * .lc-field__toggle-fake,
.lc-field__toggle-label > .lc-field__toggle[disabled] ~ .lc-field__toggle-text,
.lc-field__toggle-label > .lc-field__toggle[disabled] ~ * .lc-field__toggle-text {
  opacity: 0.35;
}

.lc-field__toggle-fake + .lc-field__toggle-text,
.lc-field__toggle-text + .lc-field__toggle-fake {
  margin-left: 0.5em;
}

.txt-12.lc-field__toggle-fake {
  width: 1.75rem;
  height: 1rem;
  border-radius: 1rem;
}

.txt-12.lc-field__toggle-fake:after {
  top: 0.0625rem;
  left: 0.0625rem;
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 0.875rem;
}

.txt-14.lc-field__toggle-fake {
  width: 2.25rem;
  height: 1.25rem;
  border-radius: 1.25rem;
}

.txt-14.lc-field__toggle-fake:after {
  top: 0.0625rem;
  left: 0.0625rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 1.125rem;
}

::-moz-selection {
  background-color: #c6cacc;
  color: #000;
}

::selection {
  background-color: #c6cacc;
  color: #000;
}

a, .link {
  text-decoration: none;
  outline: none;
  transition: opacity 0.2s ease;
}

a:not([class]):hover, a:not([class]):focus, a:not([class]):active, .link:hover, .link:focus, .link:active {
  opacity: 0.5;
}

.link[class*="btn"]:hover:not(.btn--alt), .link[class*="btn"]:focus:not(.btn--alt), .link[class*="btn"]:active:not(.btn--alt) {
  opacity: 0.7;
}

a {
  color: inherit;
}

a:not([class]) {
  text-decoration: underline;
}

.txt-12 {
  font-size: 0.75rem;
}

.txt-14 {
  font-size: 0.875rem;
}

.txt-16 {
  font-size: 1rem;
}

.txt-18 {
  font-size: 1.125rem;
}

.txt-20 {
  font-size: 1.25rem;
}

.txt-24 {
  font-size: 1.5rem;
}

.txt-28 {
  font-size: 1.75rem;
}

.txt-32 {
  font-size: 2rem;
}

.txt-none {
  font-size: inherit;
}

@media print, screen and (min-width: 48em) {
  .tb-txt-12 {
    font-size: 0.75rem;
  }
  .tb-txt-14 {
    font-size: 0.875rem;
  }
  .tb-txt-16 {
    font-size: 1rem;
  }
  .tb-txt-18 {
    font-size: 1.125rem;
  }
  .tb-txt-20 {
    font-size: 1.25rem;
  }
  .tb-txt-24 {
    font-size: 1.5rem;
  }
  .tb-txt-28 {
    font-size: 1.75rem;
  }
  .tb-txt-32 {
    font-size: 2rem;
  }
  .tb-txt-none {
    font-size: inherit;
  }
}

@media screen and (min-width: 64em) {
  .dt-txt-12 {
    font-size: 0.75rem;
  }
  .dt-txt-14 {
    font-size: 0.875rem;
  }
  .dt-txt-16 {
    font-size: 1rem;
  }
  .dt-txt-18 {
    font-size: 1.125rem;
  }
  .dt-txt-20 {
    font-size: 1.25rem;
  }
  .dt-txt-24 {
    font-size: 1.5rem;
  }
  .dt-txt-28 {
    font-size: 1.75rem;
  }
  .dt-txt-32 {
    font-size: 2rem;
  }
  .dt-txt-none {
    font-size: inherit;
  }
}

@media screen and (min-width: 75em) {
  .hg-txt-12 {
    font-size: 0.75rem;
  }
  .hg-txt-14 {
    font-size: 0.875rem;
  }
  .hg-txt-16 {
    font-size: 1rem;
  }
  .hg-txt-18 {
    font-size: 1.125rem;
  }
  .hg-txt-20 {
    font-size: 1.25rem;
  }
  .hg-txt-24 {
    font-size: 1.5rem;
  }
  .hg-txt-28 {
    font-size: 1.75rem;
  }
  .hg-txt-32 {
    font-size: 2rem;
  }
  .hg-txt-none {
    font-size: inherit;
  }
}

b,
strong {
  font-weight: 600;
}

em,
i:not(.icon):not(.lc-icon):not([data-icon]):not([data-care-icon]) {
  font-style: italic;
}

strike,
.strike {
  text-decoration: line-through;
}

.underline {
  text-decoration: underline;
}

.nocase {
  text-transform: none;
}

.capital {
  text-transform: capitalize;
}

.lower {
  text-transform: lowercase;
}

.upper {
  text-transform: uppercase;
}

.super,
.sub {
  position: relative;
  font-size: 0.625rem;
  line-height: 1;
  vertical-align: super;
}

.sub {
  vertical-align: sub;
}

.txt-bold {
  font-weight: 600;
}

.txt-light {
  font-weight: 300;
}

.txt-italic {
  font-style: italic;
}

.txt-normal {
  font-style: normal;
}

.txt-underline {
  text-decoration: underline;
}

@media print, screen and (min-width: 48em) {
  .tb-txt-bold {
    font-weight: 600;
  }
  .tb-txt-light {
    font-weight: 300;
  }
  .tb-txt-italic {
    font-style: italic;
  }
  .tb-txt-normal {
    font-style: normal;
  }
  .tb-txt-underline {
    text-decoration: underline;
  }
}

@media screen and (min-width: 64em) {
  .dt-txt-bold {
    font-weight: 600;
  }
  .dt-txt-light {
    font-weight: 300;
  }
  .dt-txt-italic {
    font-style: italic;
  }
  .dt-txt-normal {
    font-style: normal;
  }
  .dt-txt-underline {
    text-decoration: underline;
  }
}

@media screen and (min-width: 75em) {
  .hg-txt-bold {
    font-weight: 600;
  }
  .hg-txt-light {
    font-weight: 300;
  }
  .hg-txt-italic {
    font-style: italic;
  }
  .hg-txt-normal {
    font-style: normal;
  }
  .hg-txt-underline {
    text-decoration: underline;
  }
}

.lc-icon {
  display: inline-block;
  vertical-align: middle;
  transition: opacity 0.5s cubic-bezier(0.32, 0.05, 0.66, 1);
}

html:not(.fonts-loaded) .lc-icon {
  opacity: 0;
}

.lc-icon[data-icon]:before {
  font-family: "lc-icons";
  content: attr(data-icon);
  speak: none;
  font-weight: normal;
  font-style: normal;
  line-height: inherit;
  vertical-align: middle;
  position: relative;
  top: -0.125rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lc-icon--square, .lc-icon--circle {
  width: 1em;
  height: 1em;
  line-height: 1;
  box-sizing: content-box;
}

.lc-icon--circle {
  border-radius: 100%;
}

.lc-icon.txt-10 {
  font-size: 1.25rem;
  transform: scale(0.5);
}

.lc-icon.txt-9 {
  font-size: 1.25rem;
  transform: scale(0.45);
}

.lc-icon.txt-8 {
  font-size: 1.25rem;
  transform: scale(0.4);
}

.lc-icon.txt-7 {
  font-size: 1.25rem;
  transform: scale(0.35);
}

.lc-icon.txt-6 {
  font-size: 1.25rem;
  transform: scale(0.3);
}

.lc-icon[data-icon="wash-a"]:before {
  content: "";
}

.lc-icon[data-icon="wash-b"]:before {
  content: "";
}

.lc-icon[data-icon="wash-c"]:before {
  content: "";
}

.lc-icon[data-icon="wash-d"]:before {
  content: "";
}

.lc-icon[data-icon="wash-e"]:before {
  content: "";
}

.lc-icon[data-icon="wash-f"]:before {
  content: "";
}

.lc-icon[data-icon="wash-g"]:before {
  content: "";
}

.lc-icon[data-icon="wash-h"]:before {
  content: "";
}

.lc-icon[data-icon="wash-i"]:before {
  content: "";
}

.lc-icon[data-icon="wash-j"]:before {
  content: "";
}

.lc-icon[data-icon="wash-k"]:before {
  content: "";
}

.lc-icon[data-icon="wash-l"]:before {
  content: "";
}

.lc-icon[data-icon="wash-m"]:before {
  content: "";
}

.lc-icon[data-icon="wash-n"]:before {
  content: "";
}

.lc-icon[data-icon="bleach-a"]:before {
  content: "";
}

.lc-icon[data-icon="bleach-b"]:before {
  content: "";
}

.lc-icon[data-icon="bleach-c"]:before {
  content: "";
}

.lc-icon[data-icon="airDry-a"]:before {
  content: "";
}

.lc-icon[data-icon="airDry-b"]:before {
  content: "";
}

.lc-icon[data-icon="airDry-c"]:before {
  content: "";
}

.lc-icon[data-icon="airDry-d"]:before {
  content: "";
}

.lc-icon[data-icon="airDry-e"]:before {
  content: "";
}

.lc-icon[data-icon="airDry-f"]:before {
  content: "";
}

.lc-icon[data-icon="airDry-g"]:before {
  content: "";
}

.lc-icon[data-icon="airDry-h"]:before {
  content: "";
}

.lc-icon[data-icon="tumbleDry-a"]:before {
  content: "";
}

.lc-icon[data-icon="tumbleDry-b"]:before {
  content: "";
}

.lc-icon[data-icon="tumbleDry-c"]:before {
  content: "";
}

.lc-icon[data-icon="iron-a"]:before {
  content: "";
}

.lc-icon[data-icon="iron-b"]:before {
  content: "";
}

.lc-icon[data-icon="iron-c"]:before {
  content: "";
}

.lc-icon[data-icon="iron-d"]:before {
  content: "";
}

.lc-icon[data-icon="dryClean-a"]:before {
  content: "";
}

.lc-icon[data-icon="dryClean-b"]:before {
  content: "";
}

.lc-icon[data-icon="dryClean-c"]:before {
  content: "";
}

.lc-icon[data-icon="dryClean-d"]:before {
  content: "";
}

.lc-icon[data-icon="dryClean-e"]:before {
  content: "";
}

.lc-icon[data-icon="dryClean-f"]:before {
  content: "";
}

.lc-icon[data-icon="dryClean-g"]:before {
  content: "";
}

.lc-icon[data-icon="dryClean-h"]:before {
  content: "";
}

.lc-icon[data-icon="arrow-up"]:before {
  content: "";
}

.lc-icon[data-icon="arrow-down"]:before {
  content: "";
}

.lc-icon[data-icon="arrow-left"]:before {
  content: "";
}

.lc-icon[data-icon="arrow-right"]:before {
  content: "";
}

.lc-icon[data-icon="arrow-up-thick"]:before {
  content: "";
}

.lc-icon[data-icon="arrow-down-thick"]:before {
  content: "";
}

.lc-icon[data-icon="arrow-left-thick"]:before {
  content: "";
}

.lc-icon[data-icon="arrow-right-thick"]:before {
  content: "";
}

.lc-icon[data-icon="social-facebook"]:before {
  content: "";
}

.lc-icon[data-icon="social-facebook-standalone"]:before {
  content: "";
}

.lc-icon[data-icon="social-google-plus"]:before {
  content: "";
}

.lc-icon[data-icon="social-instagram"]:before {
  content: "";
}

.lc-icon[data-icon="social-pinterest"]:before {
  content: "";
}

.lc-icon[data-icon="social-renren"]:before {
  content: "";
}

.lc-icon[data-icon="social-twitter"]:before {
  content: "";
}

.lc-icon[data-icon="social-wechat"]:before {
  content: "";
}

.lc-icon[data-icon="social-weibo"]:before {
  content: "";
}

.lc-icon[data-icon="social-whatsapp"]:before {
  content: "";
}

.lc-icon[data-icon="social-youtube"]:before {
  content: "";
}

.lc-icon[data-icon="social-apple"]:before {
  content: "";
}

.lc-icon[data-icon="bag"]:before {
  content: "";
}

.lc-icon[data-icon="bag-empty"]:before {
  content: "";
}

.lc-icon[data-icon="bag-filled"]:before {
  content: "";
}

.lc-icon[data-icon="bag-move"]:before {
  content: "";
}

.lc-icon[data-icon="bag-add"]:before {
  content: "";
}

.lc-icon[data-icon="customer-care-service"]:before {
  content: "";
}

.lc-icon[data-icon="clock"]:before {
  content: "";
}

.lc-icon[data-icon="close"]:before {
  content: "";
}

.lc-icon[data-icon="conversion"]:before {
  content: "";
}

.lc-icon[data-icon="cross"]:before {
  content: "";
}

.lc-icon[data-icon="delivery"]:before {
  content: "";
}

.lc-icon[data-icon="edit"]:before {
  content: "";
}

.lc-icon[data-icon="envelope"]:before {
  content: "";
}

.lc-icon[data-icon="exchange"]:before {
  content: "";
}

.lc-icon[data-icon="exclamation-mark-circle"]:before {
  content: "";
}

.lc-icon[data-icon="eye"]:before {
  content: "";
}

.lc-icon[data-icon="gift"]:before {
  content: "";
}

.lc-icon[data-icon="giftcard"]:before {
  content: "";
}

.lc-icon[data-icon="heart-empty"]:before {
  content: "";
}

.lc-icon[data-icon="heart-filled"]:before {
  content: "";
}

.lc-icon[data-icon="heart-move"]:before {
  content: "";
}

.lc-icon[data-icon="heart-add"]:before {
  content: "";
}

.lc-icon[data-icon="help"]:before {
  content: "";
}

.lc-icon[data-icon="lc-on-black-circle"]:before {
  content: "";
}

.lc-icon[data-icon="lc-location"]:before {
  content: "";
}

.lc-icon[data-icon="location"]:before {
  content: "";
}

.lc-icon[data-icon="minus"]:before {
  content: "";
}

.lc-icon[data-icon="minus-thick"]:before {
  content: "";
}

.lc-icon[data-icon="mobile-schedule"]:before {
  content: "";
}

.lc-icon[data-icon="nav-mobile"]:before {
  content: "";
}

.lc-icon[data-icon="ok"]:before {
  content: "";
}

.lc-icon[data-icon="parcel"]:before {
  content: "";
}

.lc-icon[data-icon="pencil"]:before {
  content: "";
}

.lc-icon[data-icon="person-empty"]:before {
  content: "";
}

.lc-icon[data-icon="person-filled"]:before {
  content: "";
}

.lc-icon[data-icon="phone"]:before {
  content: "";
}

.lc-icon[data-icon="plus-thick"]:before {
  content: "";
}

.lc-icon[data-icon="plus"]:before {
  content: "";
}

.lc-icon[data-icon="product-menu"]:before {
  content: "";
}

.lc-icon[data-icon="qr-code-icon"]:before {
  content: "";
}

.lc-icon[data-icon="qr-code-wishlist"]:before {
  content: "";
}

.lc-icon[data-icon="question-mark-circle"]:before {
  content: "";
}

.lc-icon[data-icon="question-mark"]:before {
  content: "";
}

.lc-icon[data-icon="refresh"]:before {
  content: "";
}

.lc-icon[data-icon="refund"]:before {
  content: "";
}

.lc-icon[data-icon="return"]:before {
  content: "";
}

.lc-icon[data-icon="ruler"]:before {
  content: "";
}

.lc-icon[data-icon="scan"]:before {
  content: "";
}

.lc-icon[data-icon="search"]:before {
  content: "";
}

.lc-icon[data-icon="shopping-bag"]:before {
  content: "";
}

.lc-icon[data-icon="size-chart"]:before {
  content: "";
}

.lc-icon[data-icon="sort"]:before {
  content: "";
}

.lc-icon[data-icon="sorting"]:before {
  content: "";
}

.lc-icon[data-icon="star-empty"]:before {
  content: "";
}

.lc-icon[data-icon="star-filled"]:before {
  content: "";
}

.lc-icon[data-icon="tick"]:before {
  content: "";
}

.lc-icon[data-icon="truck"]:before {
  content: "";
}

.lc-icon[data-icon="wishlist-grid-on"]:before {
  content: "";
}

.lc-icon[data-icon="wishlist-list-on"]:before {
  content: "";
}

.lc-icon[data-icon="zoom-in"]:before {
  content: "";
}

.lc-icon[data-icon="zoom-out"]:before {
  content: "";
}

.lc-icon[data-icon="qrcode-checkout"]:before {
  content: "";
}

.lc-icon[data-icon="locked"]:before {
  content: "";
}

.lc-icon[data-icon="user-verify"]:before {
  content: "";
}

.lc-icon[data-icon="caution-triangle"]:before {
  content: "";
}

.lc-icon[data-icon="padlock"]:before {
  content: "";
}

.lc-icon[data-icon="padlock-open"]:before {
  content: "";
}

.lc-icon[data-icon="share"]:before {
  content: "";
}

.lc-label > .lc-icon[data-icon*="arrow-left"] {
  position: relative;
  left: -1px;
}

.lc-label > .lc-icon[data-icon*="arrow-right"] {
  position: relative;
  left: 1px;
}

.lc-circle,
.lc-square {
  width: 1em;
  height: 1em;
  display: inline-block;
  position: relative;
  top: 0.1em;
  background-color: currentColor;
}

.lc-circle {
  border-radius: 1em;
}

p {
  margin: 1em 0;
}

.lh-1 {
  line-height: 1;
}

.lh-1-2 {
  line-height: 1.2;
}

.lh-1-4 {
  line-height: 1.4;
}

.lh-1-6 {
  line-height: 1.6;
}

.lh-2 {
  line-height: 2;
}

@media print, screen and (min-width: 48em) {
  .tb-lh-1 {
    line-height: 1;
  }
  .tb-lh-1-2 {
    line-height: 1.2;
  }
  .tb-lh-1-4 {
    line-height: 1.4;
  }
  .tb-lh-1-6 {
    line-height: 1.6;
  }
  .tb-lh-2 {
    line-height: 2;
  }
}

@media screen and (min-width: 64em) {
  .dt-lh-1 {
    line-height: 1;
  }
  .dt-lh-1-2 {
    line-height: 1.2;
  }
  .dt-lh-1-4 {
    line-height: 1.4;
  }
  .dt-lh-1-6 {
    line-height: 1.6;
  }
  .dt-lh-2 {
    line-height: 2;
  }
}

@media screen and (min-width: 75em) {
  .hg-lh-1 {
    line-height: 1;
  }
  .hg-lh-1-2 {
    line-height: 1.2;
  }
  .hg-lh-1-4 {
    line-height: 1.4;
  }
  .hg-lh-1-6 {
    line-height: 1.6;
  }
  .hg-lh-2 {
    line-height: 2;
  }
}

.ls-1- {
  letter-spacing: -0.01875rem;
}

.ls-n {
  letter-spacing: normal;
}

.ls-1 {
  letter-spacing: 0.01875rem;
}

.ls-2 {
  letter-spacing: 0.0375rem;
}

.ls-3 {
  letter-spacing: 0.075rem;
}

.ls-4 {
  letter-spacing: 0.15rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 1.25rem 0;
}

h1 {
  line-height: 1.2;
  font-size: 1.5rem;
}

@media print, screen and (min-width: 48em) {
  h1 {
    font-size: 2rem;
  }
}

.bar-heading {
  display: block;
  position: relative;
  overflow: hidden;
  text-align: center;
  line-height: 1;
}

.bar-heading__text {
  display: inline-block;
  position: relative;
  max-width: 85%;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.bar-heading__text:before, .bar-heading__text:after {
  content: "";
  display: block;
  position: absolute;
  background: currentColor;
  top: 50%;
  left: auto;
  right: 100%;
  width: 2000px;
  height: 0.1875rem;
  margin-top: -0.125rem;
}

.bar-heading__text:after {
  left: 100%;
  right: auto;
}

.bar-heading--thin .bar-heading__text:before, .bar-heading--thin .bar-heading__text:after {
  height: 0.0625rem;
  margin-top: -0.0625rem;
}

address {
  font-style: normal;
}

[class^="display-address__"] {
  margin: 0;
}

.lc-tabs {
  border-bottom: 0.0625rem solid;
}

.lc-tabs__tab {
  display: inline-block;
  list-style: none;
  margin-left: -3px;
  margin-right: -3px;
}

.lc-tabs__link {
  display: block;
  position: relative;
  top: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 0.1875rem solid transparent;
  transition: border 0.3s cubic-bezier(0.32, 0.05, 0.66, 1);
}

.lc-tabs__link:hover, .lc-tabs__link:active, .lc-tabs__link:focus {
  border-bottom-color: #999;
}

.lc-tabs__link[aria-selected=true] {
  font-weight: bold;
  border-bottom-color: #000;
}

.lc-tabs__panel {
  display: none;
}

.lc-tabs__panel.is-active {
  display: block;
}

.lc-tabs__panel > :first-child {
  margin-top: 0;
}

.lc-tabs__panel > :last-child {
  margin-bottom: 0;
}

.lc-label,
.mb-lc-label,
.tb-lc-label,
.dt-lc-label {
  color: #666;
  opacity: 1;
  box-shadow: 0 0 0 1px transparent inset, 0 0 0 1px #999;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
  transition-property: opacity, box-shadow, width, height, color;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0.32, 0.05, 0.66, 1);
}

.lc-label--circle {
  border-radius: 6.25rem;
}

.lc-label--loading {
  background-image: url( ../../dist/img/spinner50w.gif );
  background-size: 80%;
  background-repeat: no-repeat;
  background-color: #eeeded;
}

.lc-label--square:not(.lc-label--fixed),
.lc-label--circle:not(.lc-label--fixed) {
  box-sizing: content-box;
}

.lc-label__row {
  display: block;
  line-height: 1rem;
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}

.lc-label__row:nth-of-type(1) {
  margin-top: 0.25rem;
}

.lc-label {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
}

.lc-label--small {
  width: 1.625rem;
  height: 1.625rem;
  line-height: 1.625rem;
}

.lc-label--large {
  width: 3.25rem;
  height: 3.25rem;
  line-height: 3.25rem;
}

.lc-label--square:not(.lc-label--fixed).lc-label,
.lc-label--circle:not(.lc-label--fixed).lc-label {
  width: auto;
  min-width: 2rem;
  padding: 0 0.25rem;
}

.lc-label--square:not(.lc-label--fixed).lc-label--small,
.lc-label--circle:not(.lc-label--fixed).lc-label--small {
  width: auto;
  min-width: 1.125rem;
  padding: 0 0.25rem;
}

.lc-label--square:not(.lc-label--fixed).lc-label--large,
.lc-label--circle:not(.lc-label--fixed).lc-label--large {
  width: auto;
  min-width: 2.75rem;
  padding: 0 0.25rem;
}

@media print, screen and (min-width: 48em) {
  .tb-lc-label {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
  }
  .tb-lc-label--small {
    width: 1.625rem;
    height: 1.625rem;
    line-height: 1.625rem;
  }
  .tb-lc-label--large {
    width: 3.25rem;
    height: 3.25rem;
    line-height: 3.25rem;
  }
  .lc-label--square:not(.lc-label--fixed).tb-lc-label,
  .lc-label--circle:not(.lc-label--fixed).tb-lc-label {
    width: auto;
    min-width: 2rem;
    padding: 0 0.25rem;
  }
  .lc-label--square:not(.lc-label--fixed).tb-lc-label--small,
  .lc-label--circle:not(.lc-label--fixed).tb-lc-label--small {
    width: auto;
    min-width: 1.125rem;
    padding: 0 0.25rem;
  }
  .lc-label--square:not(.lc-label--fixed).tb-lc-label--large,
  .lc-label--circle:not(.lc-label--fixed).tb-lc-label--large {
    width: auto;
    min-width: 2.75rem;
    padding: 0 0.25rem;
  }
}

@media screen and (min-width: 64em) {
  .dt-lc-label {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
  }
  .dt-lc-label--small {
    width: 1.625rem;
    height: 1.625rem;
    line-height: 1.625rem;
  }
  .dt-lc-label--large {
    width: 3.25rem;
    height: 3.25rem;
    line-height: 3.25rem;
  }
  .lc-label--square:not(.lc-label--fixed).dt-lc-label,
  .lc-label--circle:not(.lc-label--fixed).dt-lc-label {
    width: auto;
    min-width: 2rem;
    padding: 0 0.25rem;
  }
  .lc-label--square:not(.lc-label--fixed).dt-lc-label--small,
  .lc-label--circle:not(.lc-label--fixed).dt-lc-label--small {
    width: auto;
    min-width: 1.125rem;
    padding: 0 0.25rem;
  }
  .lc-label--square:not(.lc-label--fixed).dt-lc-label--large,
  .lc-label--circle:not(.lc-label--fixed).dt-lc-label--large {
    width: auto;
    min-width: 2.75rem;
    padding: 0 0.25rem;
  }
}

@media screen and (min-width: 75em) {
  .hg-lc-label {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
  }
  .hg-lc-label--small {
    width: 1.625rem;
    height: 1.625rem;
    line-height: 1.625rem;
  }
  .hg-lc-label--large {
    width: 3.25rem;
    height: 3.25rem;
    line-height: 3.25rem;
  }
  .lc-label--square:not(.lc-label--fixed).hg-lc-label,
  .lc-label--circle:not(.lc-label--fixed).hg-lc-label {
    width: auto;
    min-width: 2rem;
    padding: 0 0.25rem;
  }
  .lc-label--square:not(.lc-label--fixed).hg-lc-label--small,
  .lc-label--circle:not(.lc-label--fixed).hg-lc-label--small {
    width: auto;
    min-width: 1.125rem;
    padding: 0 0.25rem;
  }
  .lc-label--square:not(.lc-label--fixed).hg-lc-label--large,
  .lc-label--circle:not(.lc-label--fixed).hg-lc-label--large {
    width: auto;
    min-width: 2.75rem;
    padding: 0 0.25rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .mb-lc-label {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
  }
  .mb-lc-label--small {
    width: 1.625rem;
    height: 1.625rem;
    line-height: 1.625rem;
  }
  .mb-lc-label--large {
    width: 3.25rem;
    height: 3.25rem;
    line-height: 3.25rem;
  }
  .lc-label--square:not(.lc-label--fixed).mb-lc-label,
  .lc-label--circle:not(.lc-label--fixed).mb-lc-label {
    width: auto;
    min-width: 2rem;
    padding: 0 0.25rem;
  }
  .lc-label--square:not(.lc-label--fixed).mb-lc-label--small,
  .lc-label--circle:not(.lc-label--fixed).mb-lc-label--small {
    width: auto;
    min-width: 1.125rem;
    padding: 0 0.25rem;
  }
  .lc-label--square:not(.lc-label--fixed).mb-lc-label--large,
  .lc-label--circle:not(.lc-label--fixed).mb-lc-label--large {
    width: auto;
    min-width: 2.75rem;
    padding: 0 0.25rem;
  }
}

.lc-label:hover, .lc-label:focus, .lc-label:active, .lc-label.lc-label--active,
.mb-lc-label:hover,
.mb-lc-label:focus,
.mb-lc-label:active,
.mb-lc-label.lc-label--active,
.tb-lc-label:hover,
.tb-lc-label:focus,
.tb-lc-label:active,
.tb-lc-label.lc-label--active,
.dt-lc-label:hover,
.dt-lc-label:focus,
.dt-lc-label:active,
.dt-lc-label.lc-label--active {
  color: #000;
  opacity: 1;
  box-shadow: 0 0 0 1px transparent inset, 0 0 0 2px #000;
}

.lc-label:hover.lc-label--inset, .lc-label:focus.lc-label--inset, .lc-label:active.lc-label--inset, .lc-label.lc-label--active.lc-label--inset,
.mb-lc-label:hover.lc-label--inset,
.mb-lc-label:focus.lc-label--inset,
.mb-lc-label:active.lc-label--inset,
.mb-lc-label.lc-label--active.lc-label--inset,
.tb-lc-label:hover.lc-label--inset,
.tb-lc-label:focus.lc-label--inset,
.tb-lc-label:active.lc-label--inset,
.tb-lc-label.lc-label--active.lc-label--inset,
.dt-lc-label:hover.lc-label--inset,
.dt-lc-label:focus.lc-label--inset,
.dt-lc-label:active.lc-label--inset,
.dt-lc-label.lc-label--active.lc-label--inset {
  box-shadow: 0 0 0 1px #000 inset, 0 0 0 1px #000;
}

.lc-label:hover.lc-label--inset.is-error, .lc-label:focus.lc-label--inset.is-error, .lc-label:active.lc-label--inset.is-error, .lc-label.lc-label--active.lc-label--inset.is-error,
.mb-lc-label:hover.lc-label--inset.is-error,
.mb-lc-label:focus.lc-label--inset.is-error,
.mb-lc-label:active.lc-label--inset.is-error,
.mb-lc-label.lc-label--active.lc-label--inset.is-error,
.tb-lc-label:hover.lc-label--inset.is-error,
.tb-lc-label:focus.lc-label--inset.is-error,
.tb-lc-label:active.lc-label--inset.is-error,
.tb-lc-label.lc-label--active.lc-label--inset.is-error,
.dt-lc-label:hover.lc-label--inset.is-error,
.dt-lc-label:focus.lc-label--inset.is-error,
.dt-lc-label:active.lc-label--inset.is-error,
.dt-lc-label.lc-label--active.lc-label--inset.is-error {
  box-shadow: 0 0 0 2px #ca0814;
}

.lc-label--disable, .lc-label--disable:hover, .lc-label--disable:focus, .lc-label--disable:active, .lc-label--disable.lc-label--active,
.lc-label--disabled,
.lc-label--disabled:hover,
.lc-label--disabled:focus,
.lc-label--disabled:active,
.lc-label--disabled.lc-label--active,
[class*="lc-label"][disabled],
[class*="lc-label"][disabled]:hover,
[class*="lc-label"][disabled]:focus,
[class*="lc-label"][disabled]:active,
[class*="lc-label"][disabled].lc-label--active {
  cursor: default;
  opacity: 0.3;
}

.lc-label--invert,
.lc-label--inverted {
  color: #000;
  box-shadow: 0 0 0 1px transparent inset, 0 0 0 1px #666;
}

.lc-label--invert:hover, .lc-label--invert:focus, .lc-label--invert:active, .lc-label--invert[class*="lc-label--active"],
.lc-label--inverted:hover,
.lc-label--inverted:focus,
.lc-label--inverted:active,
.lc-label--inverted[class*="lc-label--active"] {
  color: #fff;
  opacity: 1;
  box-shadow: 0 0 0 1px #000 inset, 0 0 0 1px #000;
  background-color: #000;
}

.lc-label--color,
.lc-label--colour {
  border-radius: 6.25rem;
  box-shadow: 0 0 0 2px #fff inset, 0 0 0 1px #999;
}

.lc-label--color:hover, .lc-label--color:focus, .lc-label--color:active, .lc-label--color.lc-label--active,
.lc-label--colour:hover,
.lc-label--colour:focus,
.lc-label--colour:active,
.lc-label--colour.lc-label--active {
  box-shadow: 0 0 0 2px #fff inset, 0 0 0 2px #000;
}

.lc-flag {
  font-size: 0.75rem;
  line-height: 1.375rem;
  display: inline-block;
  border: 1px solid;
  padding: 0 0.5rem;
}

.lc-flag--disabled {
  opacity: 0.3;
}

.lc-tag {
  color: #666;
  background-color: #eeeded;
  border: 1px solid #eeeded;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 0.0625rem;
  padding-bottom: 0.0625rem;
  margin-top: 0.0625rem;
  margin-bottom: 0.0625rem;
  border-radius: 50px;
  transition-property: color, border, background;
  transition-duration: 0.33s;
  transition-timing-function: cubic-bezier(0.43, 0, 0.36, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.lc-tag:not(.lc-tag--large):not([class*=txt-1]):not([class*=txt-2]):not([class*=txt-3]) {
  font-size: 0.625rem;
}

.lc-tag--large {
  font-size: 0.875rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.lc-tag--selected {
  background-color: #3a3a3a;
  border-color: #3a3a3a;
  color: #fff;
}

.lc-dropdown {
  position: relative;
}

.lc-dropdown,
.lc-dropdown * {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.lc-dropdown.is-active .lc-dropdown__display {
  background-color: #eeeded;
}

.lc-dropdown__display {
  display: block;
  transition: background 0.2s cubic-bezier(0.43, 0, 0.36, 1);
}

.lc-dropdown__display:hover, .lc-dropdown__display:active, .lc-dropdown__display:focus {
  font-weight: bold;
}

.lc-dropdown__icon {
  font-size: 12px;
  position: relative;
  height: 14px;
  width: 14px;
  top: -2px;
  margin-left: 5px;
}

.lc-dropdown__icon:before {
  position: absolute;
  width: 14px;
  height: 14px;
  top: -1px;
  left: 0;
}

.lc-dropdown__list {
  background-color: #eeeded;
  padding: 0.3125rem;
  min-width: 15rem;
  z-index: 2;
  position: absolute;
  bottom: 0;
  transition: transform 0.3s cubic-bezier(0.43, 0, 0.36, 1), opacity 0.3s cubic-bezier(0.43, 0, 0.36, 1);
  transform-origin: top;
  transform: translateY(100%) scaleY(0);
  opacity: 0;
}

.lc-dropdown__list--transparent {
  background-color: rgba(238, 237, 237, 0.9);
}

.lc-dropdown__list.is-open {
  transform: translateY(100%) scaleY(1);
  opacity: 1;
}

.lc-dropdown__list-item {
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.43, 0, 0.36, 1);
  padding: 0.3125rem;
}

.lc-dropdown__list-item.is-selected {
  font-weight: bold;
}

.lc-dropdown__list.is-open .lc-dropdown__list-item {
  opacity: 1;
}

.lc-dropdown__list.is-open .lc-dropdown__list-item:nth-of-type(1) {
  transition-delay: 0.03s;
}

.lc-dropdown__list.is-open .lc-dropdown__list-item:nth-of-type(2) {
  transition-delay: 0.06s;
}

.lc-dropdown__list.is-open .lc-dropdown__list-item:nth-of-type(3) {
  transition-delay: 0.09s;
}

.lc-dropdown__list.is-open .lc-dropdown__list-item:nth-of-type(4) {
  transition-delay: 0.12s;
}

.lc-dropdown__list.is-open .lc-dropdown__list-item:nth-of-type(5) {
  transition-delay: 0.15s;
}

.lc-dropdown__list.is-open .lc-dropdown__list-item:nth-of-type(n+6) {
  transition-delay: 0.18s;
}

.lc-dropdown__list.is-open .lc-dropdown__list-item.is-disabled {
  opacity: 0.35 !important;
}

.lc-dropdown__list-item:hover, .lc-dropdown__list-item:focus, .lc-dropdown__list-item:active {
  background-color: rgba(0, 0, 0, 0.05);
}

.lc-dropdown--button-style {
  height: 3.125rem;
}

.lc-dropdown--button-style .lc-dropdown__list {
  min-width: 100%;
}

.lc-dropdown--button-style .lc-dropdown__display,
.lc-dropdown--button-style .lc-dropdown__icon {
  position: absolute;
}

.lc-dropdown--button-style .lc-dropdown__display {
  left: 0;
  right: 0;
  border: 1px solid #999;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 3.125rem;
  transition: border 0.3s cubic-bezier(0.32, 0.05, 0.66, 1);
}

.lc-dropdown--button-style .lc-dropdown__display-text {
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  height: 3.125rem;
  line-height: 3.125rem;
}

.lc-dropdown--button-style .lc-dropdown__icon {
  top: 50%;
  right: 0.75rem;
  margin-top: -7px;
}

.lc-dropdown--button-style:hover .lc-dropdown__display,
.lc-dropdown--button-style.is-active .lc-dropdown__display {
  border-color: #000;
}

.lc-dropdown--button-style.is-active .lc-dropdown__display {
  box-shadow: 0 0 0 1px #ccc;
}

.lc-accordion__item {
  box-shadow: 0 -0.0625rem 0 0 #999;
  transition: box-shadow 0.3s cubic-bezier(0.32, 0.05, 0.66, 1);
}

.lc-accordion__item:last-of-type {
  box-shadow: 0 -0.0625rem 0 0 #999, 0 0.0625rem 0 0 #999;
}

.lc-accordion__item:hover {
  box-shadow: 0 -0.0625rem 0 0 #000;
}

.lc-accordion__item:last-of-type:hover {
  box-shadow: 0 -0.0625rem 0 0 #000, 0 0.0625rem 0 0 #000;
}

.lc-accordion__item.is-active {
  box-shadow: 0 -0.1875rem 0 0 #000;
}

.lc-accordion__item:last-of-type.is-active {
  box-shadow: 0 -0.1875rem 0 0 #000, 0 0.0625rem 0 0 #000;
}

.lc-accordion__link {
  display: block;
  position: relative;
}

.lc-accordion__link[aria-selected=true] {
  font-weight: bold;
}

.lc-accordion__link [data-icon] {
  position: absolute;
  right: 1em;
  height: 0.875rem;
  top: 50%;
  transform: translateY(-50%) rotateZ(0deg);
  transition: transform 0.3s cubic-bezier(0.32, 0.05, 0.66, 1);
}

.lc-accordion__link[aria-selected=true] [data-icon] {
  transform: translateY(-50%) rotateZ(-180deg);
}

.lc-accordion__panel {
  display: none;
}

@media print {
  .lc-accordion__panel .lc-accordion__panel {
    display: block !important;
  }
}

.lc-accordion__panel > :first-child {
  margin-top: 0;
}

.lc-accordion__panel > :last-child {
  margin-bottom: 0;
}

.lc-accordion--subtle .lc-accordion__item {
  color: #666;
  box-shadow: 0 -0.0625rem 0 0 #eeeded;
}

.lc-accordion--subtle .lc-accordion__item:last-of-type {
  box-shadow: 0 -0.0625rem 0 0 #eeeded, 0 0.0625rem 0 0 #eeeded;
}

.lc-accordion--subtle .lc-accordion__item:hover {
  box-shadow: 0 -0.0625rem 0 0 #ccc;
}

.lc-accordion--subtle .lc-accordion__item:last-of-type:hover {
  box-shadow: 0 -0.0625rem 0 0 #ccc, 0 0.0625rem 0 0 #ccc;
}

.lc-accordion--subtle .lc-accordion__item.is-active {
  box-shadow: 0 -0.0625rem 0 0 #ccc;
  color: #000;
}

.lc-accordion--subtle .lc-accordion__item:last-of-type.is-active {
  box-shadow: 0 -0.0625rem 0 0 #ccc, 0 0.0625rem 0 0 #ccc;
}

.lc-conversion {
  max-width: 25rem;
  margin: auto;
}

.lc-conversion__equals {
  top: 50%;
  left: 50%;
  transform: translate(-50%, 4px);
}

.lc-conversion__equals:before, .lc-conversion__equals:after {
  content: "";
  width: 0.75rem;
  height: 0.0625rem;
  background: #999;
  position: relative;
  display: block;
}

.lc-conversion__equals:after {
  top: 0.25rem;
}

@-webkit-keyframes opacity-hack {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes opacity-hack {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.modal-bg,
.ui-widget-overlay {
  background: rgba(0, 0, 0, 0.8);
  z-index: 12;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.ui-widget-preloader {
  z-index: 9999;
}

.ui-dialog {
  top: 1rem;
  position: absolute;
  z-index: 13;
  background: #fff;
  outline: none;
  left: 0.5rem;
  right: 0.5rem;
  transform: none;
  min-width: auto;
  max-width: none;
}

@media print, screen and (min-width: 48em) {
  .ui-dialog--fixed {
    width: 44rem;
    margin: auto;
  }
}

@media screen and (min-width: 54em) {
  .ui-dialog--fixed {
    width: auto;
    margin: auto 8vw;
  }
}

@media screen and (min-width: 60em) {
  .ui-dialog--fixed {
    width: 48rem;
    margin: auto;
  }
}

.ui-dialog--with-width {
  width: auto;
  margin: auto 0.5rem;
}

@media screen and (max-width: 47.9375em) {
  .ui-dialog--with-width {
    width: auto !important;
    margin: auto;
  }
}

@media print, screen and (min-width: 48em) {
  .ui-dialog--with-width {
    width: auto;
    margin: auto;
  }
}

@media screen and (min-width: 54em) {
  .ui-dialog--with-width {
    width: auto;
    margin: auto;
  }
}

@media screen and (min-width: 60em) {
  .ui-dialog--with-width {
    width: auto;
    margin: auto;
  }
}

.ui-dialog--full {
  top: 10px !important;
  left: 10px;
  right: 10px;
  position: absolute;
}

html.wechat-inapp .ui-dialog {
  -webkit-animation: opacity-hack 20s ease infinite 0.33s;
          animation: opacity-hack 20s ease infinite 0.33s;
}

.ui-dialog-titlebar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 1px;
}

.ui-dialog-titlebar-close {
  top: 0;
  right: 0;
  position: absolute;
  z-index: 1;
  padding: 1rem;
}

.lc-help-popup,
.lc-help-panel {
  font-size: 14px;
  background: #eeeded;
  padding: 0.625rem;
  display: none;
}

.lc-popover {
  font-size: 14px;
  font-family: "Lane Crawford", 微软雅黑体, "Microsoft YaHei", 华文细黑, "STXihei", 华文黑体, "STHeiti", 冬青黑体, "Hiragino Sans GB", sans-serif;
  text-transform: none;
  color: #fff;
  background: #3a3a3a;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  line-height: 1.2;
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0 2px 8px -3px #666;
  z-index: 2;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 300px;
  opacity: 0;
  transform: translateX(-50%) translateY(calc( 100% + 25px));
  transition: all 0.3s cubic-bezier(0.43, 0, 0.36, 1) 0.3s, visibility 0.01s linear 0.6s;
  visibility: hidden;
  /**
		this is to increase hit-size after opened
	**/
}

.lc-popover:after {
  content: "";
  background: inherit;
  width: 17px;
  height: 11px;
  -webkit-clip-path: polygon(0 100%, 50% 0, 100% 100%);
          clip-path: polygon(0 100%, 50% 0, 100% 100%);
  position: absolute;
  left: 50%;
  top: -10px;
  transform: translateX(-50%);
}

.lc-popover:before {
  content: "";
  position: absolute;
  left: -15px;
  top: -15px;
  bottom: -15px;
  right: -15px;
}

.lc-popover[data-left] {
  left: 0;
  right: auto;
  transform: translateY(calc( 100% + 25px));
}

.lc-popover[data-left]:after {
  transform: none;
  left: 10px;
  right: auto;
}

.lc-popover[data-right] {
  left: auto;
  right: 0;
  transform: translateY(calc( 100% + 25px));
}

.lc-popover[data-right]:after {
  transform: none;
  left: auto;
  right: 10px;
}

.lc-popover-control:active .lc-popover,
.lc-popover-control:focus .lc-popover,
.lc-popover-control:hover .lc-popover {
  opacity: 1;
  transform: translateX(-50%) translateY(calc( 100% + 5px));
  transition: all 0.3s cubic-bezier(0.43, 0, 0.36, 1) 0.15s, visibility 0.01s linear 0s;
  visibility: visible;
}

.lc-popover-control:active .lc-popover[data-left], .lc-popover-control:active .lc-popover[data-right],
.lc-popover-control:focus .lc-popover[data-left],
.lc-popover-control:focus .lc-popover[data-right],
.lc-popover-control:hover .lc-popover[data-left],
.lc-popover-control:hover .lc-popover[data-right] {
  transform: translateY(calc( 100% + 5px));
}

.ui-autocomplete {
  max-height: 120px;
  z-index: 1;
}

@media print, screen and (min-width: 48em) {
  .ui-autocomplete {
    max-height: 160px;
  }
}

.ui-autocomplete .ui-menu-item-wrapper {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ui-autocomplete .ui-menu-item-wrapper.ui-state-active {
  background: #eeeded;
}

.ui-autocomplete .ui-menu-item:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

.lc-donut {
  position: relative;
}

.lc-donut__svg {
  display: block;
  max-width: 100%;
  margin: auto;
  transform-origin: 50%;
}

.lc-donut__text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lc-donut__bg, .lc-donut__donut {
  stroke: #eeeded;
  stroke-width: 1;
  fill: transparent;
}

.lc-donut__donut {
  stroke: currentColor;
  stroke-width: 6;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.39, 0.09, 0.24, 1), stroke-dashoffset 1s cubic-bezier(0.39, 0.09, 0.24, 1);
}

.lc-donut--sliced {
  transform: translateY(6.5%);
}

.lc-notification {
  text-align: center;
  font-size: 0.875rem;
  border: 2px solid currentColor;
  position: relative;
  padding: 0.3125rem 2.5rem;
  /* so that text doesn't go over the "x" */
  margin-bottom: 0.625rem;
  /* so that notifications have space */
}

.lc-notification--alert {
  color: #ca0814;
}

.lc-notification--warning {
  color: #eca142;
}

.lc-notification--success {
  color: #50ad51;
}

.lc-notification--info {
  color: #357edb;
}

.lc-notification__close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  /* top, right and padding give the "x" a bigger touch-area for usability */
  cursor: pointer;
}

.loading-placeholder {
  background: #eeeded;
  color: #eeeded;
}

.content-placeholder, .content-placeholder__spinner {
  min-height: 300px;
  width: 100%;
}

.content-placeholder .lane-crawford-logo__svg {
  width: 70%;
}

.wishlist-squares__fade {
  position: relative;
  overflow: hidden;
}

.wishlist-squares__fade__before, .wishlist-squares__fade__after {
  position: absolute;
  left: -1.25rem;
  top: 0;
  bottom: 0;
  height: auto;
  width: 1.25rem;
  z-index: 2;
  box-shadow: 0 0 0.625rem 5px white;
}

@media print, screen and (min-width: 48em) {
  .wishlist-squares__fade__before, .wishlist-squares__fade__after {
    position: absolute;
    top: -1.25rem;
    left: 0;
    right: 0;
    height: 1.25rem;
    width: auto;
  }
}

.wishlist-squares__fade__after {
  left: auto;
  right: -1.25rem;
}

@media print, screen and (min-width: 48em) {
  .wishlist-squares__fade__after {
    left: 0;
    right: 0;
    top: auto;
    bottom: -1.25rem;
  }
}

.wishlist-squares__wrapper {
  text-align: center;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

@media screen and (max-width: 47.9375em) {
  .wishlist-squares__wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
    /* stylelint-disable-next-line */
    -ms-scroll-chaining: none;
        overscroll-behavior: contain;
  }
}

@media print, screen and (min-width: 48em) {
  .wishlist-squares__wrapper {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 5px 0;
    max-height: 18.125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .wishlist-squares__list {
    white-space: nowrap;
  }
}

.wishlist-squares__create-icon {
  right: -15px;
}

.wishlist-square {
  margin: 0.3125rem;
  font-size: 0.625rem;
  position: relative;
  vertical-align: top;
  scroll-snap-align: center;
}

@media screen and (max-width: 47.9375em) {
  .wishlist-square:first-child {
    margin-left: 1.25rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .wishlist-square:last-child {
    margin-right: 1.25rem;
  }
}

.wishlist-square--disabled {
  opacity: 0.4;
  cursor: default !important;
}

.wishlist-square--disabled .link {
  opacity: 1;
}

.wishlist-square__images, .wishlist-square__image, .wishlist-square__name {
  display: block;
  position: relative;
  width: 5.625rem;
}

@media print, screen and (min-width: 48em) {
  .wishlist-square__images, .wishlist-square__image, .wishlist-square__name {
    width: 5.625rem;
  }
}

.wishlist-square__images, .wishlist-square__image {
  height: 5.625rem;
}

@media print, screen and (min-width: 48em) {
  .wishlist-square__images, .wishlist-square__image {
    height: 5.625rem;
  }
}

.wishlist-square__images {
  overflow: hidden;
  border-radius: 3px;
  border: 1px solid #eeeded;
}

.wishlist-square--selected .wishlist-square__images {
  border-radius: 4px;
  border: 2px solid #000;
}

.wishlist-square--selected .wishlist-square__images:after {
  content: "";
  border-radius: 3px;
  border: 1px solid white;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.wishlist-square--create .wishlist-square__images {
  border: 1px dashed #999;
}

.wishlist-square__image {
  background-color: #eeeded;
  background-image: url( ../../dist/img/lc-square-white.png );
  background-position: center 40%;
  background-size: 110%;
  background-repeat: no-repeat;
}

.wishlist-square__image:nth-last-child(n+2), .wishlist-square__image:nth-last-child(n+2) ~ .wishlist-square__image {
  width: 50%;
  position: absolute;
  box-shadow: 0 0 0 1px #eeeded;
  background-size: 160%;
  background-position: center;
}

.wishlist-square__image:nth-last-child(n+4), .wishlist-square__image:nth-last-child(n+4) ~ .wishlist-square__image {
  width: 50%;
  height: 50%;
  background-size: 120%;
}

.wishlist-square__image:nth-last-child(n+2) ~ .wishlist-square__image:nth-child(2),
.wishlist-square__image:nth-last-child(n+2) ~ .wishlist-square__image:nth-child(4) {
  left: 50%;
  transform: translateX(1px);
}

.wishlist-square__image:nth-last-child(n+3):nth-last-child(-n+3):first-child ~ .wishlist-square__image:nth-child(2),
.wishlist-square__image:nth-last-child(n+3):nth-last-child(-n+3):first-child ~ .wishlist-square__image:nth-child(3) {
  height: 50%;
  left: 50%;
  transform: translateX(1px);
  background-size: 120%;
}

.wishlist-square__image:nth-last-child(n+3) ~ .wishlist-square__image:nth-child(n+3) {
  top: 50%;
  margin-top: 1px;
}

.wishlist-square--create .wishlist-square__image {
  background: transparent;
}

.wishlist-square--empty .wishlist-square__image {
  background-position: center;
  background-size: cover;
}

.wishlist-square__name {
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wishlist-square--selected .wishlist-square__name {
  font-weight: bold;
}

.wishlist-square__lock {
  font-size: 1rem;
  top: 1px;
  left: 1px;
  position: absolute;
  text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
}

.wishlist-square__plus {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 47.9375em) {
  .ui-dialog--wishlist-add-move-popup {
    position: fixed;
    top: auto !important;
    bottom: 0 !important;
    left: 0;
    right: 0;
    transform: translate3d(0, 110%, 0);
  }
}

@media screen and (max-width: 47.9375em) {
  .ui-dialog--wishlist-add-move-popup.is-open {
    transition: transform 0.33s cubic-bezier(0.08, 0.455, 0.375, 0.96) !important;
    transform: translate3d(0, 0%, 0);
  }
}

.ui-dialog--wishlist-add-move-popup .wishlist-squares__wrapper {
  /* stylelint-disable-next-line */
  -ms-scroll-chaining: none;
      overscroll-behavior: contain;
}

@media print, screen and (min-width: 48em) {
  .ui-dialog--wishlist-add-move-popup .wishlist-squares__wrapper {
    max-height: 20rem;
  }
}

@media screen and (min-width: 768px) and (orientation: portrait) {
  .ui-dialog--wishlist-add-move-popup .wishlist-squares__wrapper {
    max-height: 28.125rem;
  }
}

.scrolling-blocks {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media print, screen and (min-width: 48em) {
  .scrolling-blocks {
    display: block;
    overflow: visible;
  }
}

.scrolling-blocks.a-start {
  align-items: flex-start;
}

.scrolling-blocks.a-end {
  align-items: flex-end;
}

.scrolling-blocks__item {
  min-width: 80%;
  padding: 0.3125rem;
  flex: 1 1 0%;
  display: flex;
}

@media print, screen and (min-width: 48em) {
  .scrolling-blocks__item {
    display: inline-block;
    min-width: auto;
  }
}

.scrolling-blocks.flush .scrolling-blocks__item:first-child {
  padding-left: 0;
}

.scrolling-blocks.flush .scrolling-blocks__item:last-child {
  padding-right: 0;
}

.card {
  border-radius: 0.625rem;
  border: 1px solid #e0e0e0;
  box-shadow: 0 3px 5px #eaeaea;
}

@media print, screen and (min-width: 48em) {
  .tb-card {
    border-radius: 0.625rem;
    border: 1px solid #e0e0e0;
    box-shadow: 0 3px 5px #eaeaea;
  }
}

@media screen and (min-width: 64em) {
  .dt-card {
    border-radius: 0.625rem;
    border: 1px solid #e0e0e0;
    box-shadow: 0 3px 5px #eaeaea;
  }
}

@media screen and (min-width: 75em) {
  .hg-card {
    border-radius: 0.625rem;
    border: 1px solid #e0e0e0;
    box-shadow: 0 3px 5px #eaeaea;
  }
}

@media screen and (max-width: 47.9375em) {
  .mb-only-card {
    border-radius: 0.625rem;
    border: 1px solid #e0e0e0;
    box-shadow: 0 3px 5px #eaeaea;
  }
}

@media print, screen and (min-width: 48em) and (max-width: 63.9375em) {
  .tb-only-card {
    border-radius: 0.625rem;
    border: 1px solid #e0e0e0;
    box-shadow: 0 3px 5px #eaeaea;
  }
}

.product-brick {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.product-brick__hover {
  opacity: 0;
}

.product-brick__heart, .product-brick__chips, .product-brick__icon {
  z-index: 2;
}

.product-brick__chips {
  min-height: 30px;
}

@media print, screen and (min-width: 48em) {
  .product-brick__name {
    min-height: 2rem;
  }
  .ie9 .product-brick__link {
    background-color: rgba(0, 0, 0, 0.001);
  }
  .product-brick__index, .product-brick__hover {
    transition-property: opacity;
    transition-duration: 0.25s;
    transition-timing-function: cubic-bezier(0.32, 0.05, 0.66, 1);
    transform: translate3d(0, 0, 0);
  }
  .product-brick--hover:hover .product-brick__index:not([src*="coming_soon"]), .product-brick--hover:focus .product-brick__index:not([src*="coming_soon"]) {
    opacity: 0.95;
  }
  .product-brick--hover:hover .product-brick__hover:not([src*="coming_soon"]), .product-brick--hover:focus .product-brick__hover:not([src*="coming_soon"]) {
    opacity: 1;
  }
}

.product-brick--placeholder .product-brick__images {
  background-image: linear-gradient(45deg, transparent 30%, rgba(143, 141, 141, 0.2) 50%, transparent 70%);
  background-size: 800%;
  -webkit-animation: productplaceholder 3s ease-out infinite;
          animation: productplaceholder 3s ease-out infinite;
  background-repeat: no-repeat;
}

@-webkit-keyframes productplaceholder {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 100%;
  }
}

@keyframes productplaceholder {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 100%;
  }
}

.product-card {
  background: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
  transition: all 0.33s cubic-bezier(0.43, 0, 0.36, 1);
  padding: 0.3125rem;
  display: inline-flex;
  align-items: stretch;
  position: relative;
}

.product-card:hover {
  border-color: #999;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.product-card__link {
  display: flex;
  align-items: stretch;
  position: relative;
}

.product-card__image {
  max-width: 4.125rem;
  min-width: 4.125rem;
}

@media print, screen and (min-width: 48em) {
  .product-card__image {
    max-width: 5rem;
    min-width: 5rem;
  }
}

.product-card__details {
  padding: 0.3125rem 0 0.3125rem 0.3125rem;
  text-align: left;
  line-height: 1.2;
}

.product-card__brand {
  font-size: 0.875rem;
  font-weight: bold;
  margin: 0 0 0.3125rem;
  padding-right: 2.5rem;
}

.product-card__description {
  font-size: 0.75rem;
  font-weight: normal;
  margin: 0 0 0.3125rem;
  padding-right: 2.5rem;
  color: #3a3a3a;
}

.product-card__price {
  font-size: 0.75rem;
  font-family: "Lane Crawford";
  margin: 0 0 0.3125rem;
  font-weight: bold;
}

.commerce-brick__image, .commerce-brick__description, .commerce-brick__pricing {
  vertical-align: top;
}

.commerce-brick__image {
  width: 5.625rem;
}

@media print, screen and (min-width: 48em) {
  .commerce-brick__image {
    width: 25%;
    min-width: 7.5rem;
  }
}

.commerce-brick__img-wrapper {
  max-width: 8.125rem;
}

.commerce-brick__description {
  width: auto;
}

@media print, screen and (min-width: 48em) {
  .commerce-brick__description {
    width: 55%;
  }
}

.commerce-brick__pricing {
  margin-left: 5.625rem;
}

@media print, screen and (min-width: 48em) {
  .commerce-brick__pricing {
    width: 20%;
    min-width: 10rem;
  }
}

hr {
  border: none;
}

.hr,
.hr--thin {
  display: block;
  background: #000;
}

.hr {
  height: 0.1875rem;
}

.hr--thin {
  height: 0.0625rem;
}

@media print, screen and (min-width: 48em) {
  .tb-hr,
  .tb-hr--thin {
    display: block;
    background: #000;
  }
  .tb-hr {
    height: 0.1875rem;
  }
  .tb-hr--thin {
    height: 0.0625rem;
  }
}

@media screen and (min-width: 64em) {
  .dt-hr,
  .dt-hr--thin {
    display: block;
    background: #000;
  }
  .dt-hr {
    height: 0.1875rem;
  }
  .dt-hr--thin {
    height: 0.0625rem;
  }
}

@media screen and (min-width: 75em) {
  .hg-hr,
  .hg-hr--thin {
    display: block;
    background: #000;
  }
  .hg-hr {
    height: 0.1875rem;
  }
  .hg-hr--thin {
    height: 0.0625rem;
  }
}

iframe {
  max-width: 100%;
  border: none;
}

.row.flush {
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}

.mb-auto {
  width: auto;
}

@media print, screen and (min-width: 48em) {
  .tb-auto {
    width: auto;
  }
}

@media screen and (min-width: 64em) {
  .dt-auto {
    width: auto;
  }
}

@media screen and (min-width: 75em) {
  .hg-auto {
    width: auto;
  }
}

/* stylelint-disable length-zero-no-unit */
.spinner {
  background-image: url( ../../dist/img/spinner120w.gif );
}

.spinner--dark {
  background-image: url( ../../dist/img/spinner120k.gif );
}

.cssanimations .spinner {
  -webkit-animation: lc-spinner--static 3.5s steps(36) infinite;
          animation: lc-spinner--static 3.5s steps(36) infinite;
  background-image: url( ../../dist/img/spinner.svg );
}

.cssanimations .spinner:not(.spinner--static) {
  -webkit-animation-name: lc-spinner;
          animation-name: lc-spinner;
}

.cssanimations .spinner--dark {
  background-image: url( ../../dist/img/spinner--dark.svg );
}

.cssanimations .spinner--fast {
  -webkit-animation-duration: 1.25s;
          animation-duration: 1.25s;
}

@-webkit-keyframes lc-spinner {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes lc-spinner {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-webkit-keyframes lc-spinner--static {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes lc-spinner--static {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  width: 120px;
  height: 120px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 120px;
  transition: opacity 0.3s cubic-bezier(0.43, 0, 0.36, 1);
  transform-origin: 50%;
  z-index: 1;
}

.spinner:not(.spinner--static) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner--static {
  left: auto;
  top: auto;
  transform: none;
  position: static;
}

.spinner--small {
  width: 90px;
  height: 90px;
  background-size: 90px;
}

.spinner--smaller {
  width: 60px;
  height: 60px;
  background-size: 60px;
}

.spinner--tiny {
  width: 30px;
  height: 30px;
  background-size: 30px;
}

.spinner-logo {
  max-width: 20rem;
  overflow: visible;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner-logo path {
  fill: #666;
}

.spinner-logo.loading path {
  -webkit-animation: breathe 2.4s cubic-bezier(0.32, 0.05, 0.66, 1) infinite;
          animation: breathe 2.4s cubic-bezier(0.32, 0.05, 0.66, 1) infinite;
}

.spinner-logo.loading path.l {
  -webkit-animation-delay: 0.05s;
          animation-delay: 0.05s;
}

.spinner-logo.loading path.a {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}

.spinner-logo.loading path.n {
  -webkit-animation-delay: 0.15s;
          animation-delay: 0.15s;
}

.spinner-logo.loading path.e {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

.spinner-logo.loading path.c {
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
}

.spinner-logo.loading path.r {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

.spinner-logo.loading path.a2 {
  -webkit-animation-delay: 0.35s;
          animation-delay: 0.35s;
}

.spinner-logo.loading path.w {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

.spinner-logo.loading path.f {
  -webkit-animation-delay: 0.45s;
          animation-delay: 0.45s;
}

.spinner-logo.loading path.o {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

.spinner-logo.loading path.r2 {
  -webkit-animation-delay: 0.55s;
          animation-delay: 0.55s;
}

.spinner-logo.loading path.d {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}

@-webkit-keyframes breathe {
  14% {
    fill: #ccc;
  }
  22% {
    transform: translateY(-3px);
  }
}

@keyframes breathe {
  14% {
    fill: #ccc;
  }
  22% {
    transform: translateY(-3px);
  }
}

ul,
ol,
dl {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul:not([class]):not(:last-child),
ol:not([class]):not(:last-child),
dl:not([class]):not(:last-child) {
  margin-bottom: 1em;
}

ul:not([class*=list]) > li {
  padding-left: 1.5em;
  padding-right: 1.5em;
  line-height: 1.2;
  margin-bottom: 0.3125em;
}

ul:not([class*=list]) > li:before {
  content: "–";
  width: 1.5em;
  margin-left: -1.5em;
  display: inline-block;
  text-align: center;
}

ol:not([class*=list]) > li {
  list-style: decimal inside;
  line-height: 1.2;
  margin-bottom: 0.3125em;
}

ul:not([class*=list]) ul:not([class*=list]),
ol:not([class*=list]) ul:not([class*=list]), ul:not([class*=list])
ol:not([class*=list]),
ol:not([class*=list])
ol:not([class*=list]) {
  margin-bottom: 0;
  margin-left: 1em;
}

li {
  margin: 0;
}

dt {
  font-weight: bold;
}

dd {
  margin: 0 0 1em;
}

.list--bulleted li {
  padding-left: 1.5em;
  padding-right: 1.5em;
  line-height: 1.2;
  margin-bottom: 0.3125em;
}

.list--bulleted li:before {
  content: "–";
  width: 1.5em;
  margin-left: -1.5em;
  display: inline-block;
  text-align: center;
}

.list--numbered li {
  list-style: decimal inside;
  line-height: 1.2;
  margin-bottom: 0.3125em;
}

.list--inline li {
  display: inline-block;
}

.list--inline .breaker {
  display: block;
  height: 0.0625rem;
}

.list--divided li {
  position: relative;
}

.list--divided li:not(:last-child):after {
  content: "";
  background: currentColor;
  position: absolute;
  top: 50%;
  right: -0.125em;
  transform: translateY(-50%);
  width: 0.0625rem;
  height: 1.2em;
  margin-top: -1px;
  margin-right: 1px;
}

.list--divided li:not(:nth-of-type(1)) {
  margin-left: -0.125em;
}

@media print, screen and (min-width: 48em) {
  .tb-list--bulleted li {
    padding-left: 1.5em;
    padding-right: 1.5em;
    line-height: 1.2;
    margin-bottom: 0.3125em;
  }
  .tb-list--bulleted li:before {
    content: "–";
    width: 1.5em;
    margin-left: -1.5em;
    display: inline-block;
    text-align: center;
  }
  .tb-list--numbered li {
    list-style: decimal inside;
    line-height: 1.2;
    margin-bottom: 0.3125em;
  }
  .tb-list--inline li {
    display: inline-block;
  }
  .tb-list--inline .breaker {
    display: block;
    height: 0.0625rem;
  }
  .tb-list--divided li {
    position: relative;
  }
  .tb-list--divided li:not(:last-child):after {
    content: "";
    background: currentColor;
    position: absolute;
    top: 50%;
    right: -0.125em;
    transform: translateY(-50%);
    width: 0.0625rem;
    height: 1.2em;
    margin-top: -1px;
    margin-right: 1px;
  }
  .tb-list--divided li:not(:nth-of-type(1)) {
    margin-left: -0.125em;
  }
}

@media screen and (min-width: 64em) {
  .dt-list--bulleted li {
    padding-left: 1.5em;
    padding-right: 1.5em;
    line-height: 1.2;
    margin-bottom: 0.3125em;
  }
  .dt-list--bulleted li:before {
    content: "–";
    width: 1.5em;
    margin-left: -1.5em;
    display: inline-block;
    text-align: center;
  }
  .dt-list--numbered li {
    list-style: decimal inside;
    line-height: 1.2;
    margin-bottom: 0.3125em;
  }
  .dt-list--inline li {
    display: inline-block;
  }
  .dt-list--inline .breaker {
    display: block;
    height: 0.0625rem;
  }
  .dt-list--divided li {
    position: relative;
  }
  .dt-list--divided li:not(:last-child):after {
    content: "";
    background: currentColor;
    position: absolute;
    top: 50%;
    right: -0.125em;
    transform: translateY(-50%);
    width: 0.0625rem;
    height: 1.2em;
    margin-top: -1px;
    margin-right: 1px;
  }
  .dt-list--divided li:not(:nth-of-type(1)) {
    margin-left: -0.125em;
  }
}

@media screen and (min-width: 75em) {
  .hg-list--bulleted li {
    padding-left: 1.5em;
    padding-right: 1.5em;
    line-height: 1.2;
    margin-bottom: 0.3125em;
  }
  .hg-list--bulleted li:before {
    content: "–";
    width: 1.5em;
    margin-left: -1.5em;
    display: inline-block;
    text-align: center;
  }
  .hg-list--numbered li {
    list-style: decimal inside;
    line-height: 1.2;
    margin-bottom: 0.3125em;
  }
  .hg-list--inline li {
    display: inline-block;
  }
  .hg-list--inline .breaker {
    display: block;
    height: 0.0625rem;
  }
  .hg-list--divided li {
    position: relative;
  }
  .hg-list--divided li:not(:last-child):after {
    content: "";
    background: currentColor;
    position: absolute;
    top: 50%;
    right: -0.125em;
    transform: translateY(-50%);
    width: 0.0625rem;
    height: 1.2em;
    margin-top: -1px;
    margin-right: 1px;
  }
  .hg-list--divided li:not(:nth-of-type(1)) {
    margin-left: -0.125em;
  }
}

@media screen and (max-width: 47.9375em) {
  .mb-only-list--bulleted li {
    padding-left: 1.5em;
    padding-right: 1.5em;
    line-height: 1.2;
    margin-bottom: 0.3125em;
  }
  .mb-only-list--bulleted li:before {
    content: "–";
    width: 1.5em;
    margin-left: -1.5em;
    display: inline-block;
    text-align: center;
  }
  .mb-only-list--numbered li {
    list-style: decimal inside;
    line-height: 1.2;
    margin-bottom: 0.3125em;
  }
  .mb-only-list--inline li {
    display: inline-block;
  }
  .mb-only-list--inline .breaker {
    display: block;
    height: 0.0625rem;
  }
  .mb-only-list--divided li {
    position: relative;
  }
  .mb-only-list--divided li:not(:last-child):after {
    content: "";
    background: currentColor;
    position: absolute;
    top: 50%;
    right: -0.125em;
    transform: translateY(-50%);
    width: 0.0625rem;
    height: 1.2em;
    margin-top: -1px;
    margin-right: 1px;
  }
  .mb-only-list--divided li:not(:nth-of-type(1)) {
    margin-left: -0.125em;
  }
}

@media print, screen and (min-width: 48em) and (max-width: 63.9375em) {
  .tb-only-list--bulleted li {
    padding-left: 1.5em;
    padding-right: 1.5em;
    line-height: 1.2;
    margin-bottom: 0.3125em;
  }
  .tb-only-list--bulleted li:before {
    content: "–";
    width: 1.5em;
    margin-left: -1.5em;
    display: inline-block;
    text-align: center;
  }
  .tb-only-list--numbered li {
    list-style: decimal inside;
    line-height: 1.2;
    margin-bottom: 0.3125em;
  }
  .tb-only-list--inline li {
    display: inline-block;
  }
  .tb-only-list--inline .breaker {
    display: block;
    height: 0.0625rem;
  }
  .tb-only-list--divided li {
    position: relative;
  }
  .tb-only-list--divided li:not(:last-child):after {
    content: "";
    background: currentColor;
    position: absolute;
    top: 50%;
    right: -0.125em;
    transform: translateY(-50%);
    width: 0.0625rem;
    height: 1.2em;
    margin-top: -1px;
    margin-right: 1px;
  }
  .tb-only-list--divided li:not(:nth-of-type(1)) {
    margin-left: -0.125em;
  }
}

.aspect-ratio {
  position: relative;
}

.aspect-ratio:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 100%;
}

.aspect-ratio--product:before {
  padding-top: 137.5%;
}

.lazy-load {
  transition: opacity 0.5s cubic-bezier(0.32, 0.05, 0.66, 1);
}

.blur-load {
  filter: blur(5px);
  transition: opacity 0.5s cubic-bezier(0.32, 0.05, 0.66, 1);
}

.blur {
  filter: blur(5px);
}

.extra-blur {
  filter: blur(10px);
}

.cur-p {
  cursor: pointer;
}

.cur-d {
  cursor: default;
}

.cur-h {
  cursor: help;
}

.cur-t {
  cursor: text;
}

.cur-z,
.cur-zi {
  cursor: zoom-in;
  cursor: -webkit-zoom-in;
}

.cur-zo {
  cursor: zoom-out;
  cursor: -webkit-zoom-out;
}

.no-select,
.no-select * {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.bor-0 {
  border: 0 solid;
}

.bor-t0 {
  border-top: 0 solid;
}

.bor-r0 {
  border-right: 0 solid;
}

.bor-b0 {
  border-bottom: 0 solid;
}

.bor-l0 {
  border-left: 0 solid;
}

.bor-v0 {
  border-top: 0 solid;
  border-bottom: 0 solid;
}

.bor-h0 {
  border-left: 0 solid;
  border-right: 0 solid;
}

.bor-1 {
  border: 0.0625rem solid;
}

.bor-t1 {
  border-top: 0.0625rem solid;
}

.bor-r1 {
  border-right: 0.0625rem solid;
}

.bor-b1 {
  border-bottom: 0.0625rem solid;
}

.bor-l1 {
  border-left: 0.0625rem solid;
}

.bor-v1 {
  border-top: 0.0625rem solid;
  border-bottom: 0.0625rem solid;
}

.bor-h1 {
  border-left: 0.0625rem solid;
  border-right: 0.0625rem solid;
}

.bor-2 {
  border: 0.125rem solid;
}

.bor-t2 {
  border-top: 0.125rem solid;
}

.bor-r2 {
  border-right: 0.125rem solid;
}

.bor-b2 {
  border-bottom: 0.125rem solid;
}

.bor-l2 {
  border-left: 0.125rem solid;
}

.bor-v2 {
  border-top: 0.125rem solid;
  border-bottom: 0.125rem solid;
}

.bor-h2 {
  border-left: 0.125rem solid;
  border-right: 0.125rem solid;
}

.bor-3 {
  border: 0.1875rem solid;
}

.bor-t3 {
  border-top: 0.1875rem solid;
}

.bor-r3 {
  border-right: 0.1875rem solid;
}

.bor-b3 {
  border-bottom: 0.1875rem solid;
}

.bor-l3 {
  border-left: 0.1875rem solid;
}

.bor-v3 {
  border-top: 0.1875rem solid;
  border-bottom: 0.1875rem solid;
}

.bor-h3 {
  border-left: 0.1875rem solid;
  border-right: 0.1875rem solid;
}

.bor-trans {
  border-color: transparent;
}

.bor-white {
  border-color: #fff;
}

.bor-very-light {
  border-color: #eeeded;
}

.bor-light-grey {
  border-color: #ccc;
}

.bor-grey {
  border-color: #999;
}

.bor-dark-grey {
  border-color: #666;
}

.bor-very-dark {
  border-color: #3a3a3a;
}

.bor-black {
  border-color: #000;
}

.bor-gold {
  border-color: #a08a40;
}

.bor-platinum {
  border-color: #a7a9ac;
}

.bor-diamond {
  border-color: #f4f4f4;
}

.bor-gift {
  border-color: #999082;
}

.bor-red {
  border-color: #ca0814;
}

.bor-blue {
  border-color: #357edb;
}

.bor-yellow {
  border-color: #eca142;
}

.bor-orange {
  border-color: #f0591c;
}

.bor-green {
  border-color: #50ad51;
}

.bor-beta {
  border-color: #a0783c;
}

.bor-campaign {
  border-color: var(--campaign-color, #a0783c);
}

.bor-sale {
  border-color: var(--sale-color, #fee000);
}

.bor-sale-public {
  border-color: #fee000;
}

.bor-sale-private {
  border-color: #360e51;
}

.bor-sale-flash {
  border-color: #00cfb4;
}

.bor-sale-final {
  border-color: #ef5096;
}

.bor-dot {
  border-style: dotted;
}

.bor-tdot {
  border-top-style: dotted;
}

.bor-rdot {
  border-right-style: dotted;
}

.bor-bdot {
  border-bottom-style: dotted;
}

.bor-ldot {
  border-left-style: dotted;
}

.bor-vdot {
  border-top-style: dotted;
  border-bottom-style: dotted;
}

.bor-hdot {
  border-left-style: dotted;
  border-right-style: dotted;
}

.bor-dash {
  border-style: dashed;
}

.bor-tdash {
  border-top-style: dashed;
}

.bor-rdash {
  border-right-style: dashed;
}

.bor-bdash {
  border-bottom-style: dashed;
}

.bor-ldash {
  border-left-style: dashed;
}

.bor-vdash {
  border-top-style: dashed;
  border-bottom-style: dashed;
}

.bor-hdash {
  border-left-style: dashed;
  border-right-style: dashed;
}

.bor-sol {
  border-style: solid;
}

.bor-tsol {
  border-top-style: solid;
}

.bor-rsol {
  border-right-style: solid;
}

.bor-bsol {
  border-bottom-style: solid;
}

.bor-lsol {
  border-left-style: solid;
}

.bor-vsol {
  border-top-style: solid;
  border-bottom-style: solid;
}

.bor-hsol {
  border-left-style: solid;
  border-right-style: solid;
}

.bor-none {
  border-style: none;
}

.bor-tnone {
  border-top-style: none;
}

.bor-rnone {
  border-right-style: none;
}

.bor-bnone {
  border-bottom-style: none;
}

.bor-lnone {
  border-left-style: none;
}

.bor-vnone {
  border-top-style: none;
  border-bottom-style: none;
}

.bor-hnone {
  border-left-style: none;
  border-right-style: none;
}

@media print, screen and (min-width: 48em) {
  .tb-bor-0 {
    border: 0 solid;
  }
  .tb-bor-t0 {
    border-top: 0 solid;
  }
  .tb-bor-r0 {
    border-right: 0 solid;
  }
  .tb-bor-b0 {
    border-bottom: 0 solid;
  }
  .tb-bor-l0 {
    border-left: 0 solid;
  }
  .tb-bor-v0 {
    border-top: 0 solid;
    border-bottom: 0 solid;
  }
  .tb-bor-h0 {
    border-left: 0 solid;
    border-right: 0 solid;
  }
  .tb-bor-1 {
    border: 0.0625rem solid;
  }
  .tb-bor-t1 {
    border-top: 0.0625rem solid;
  }
  .tb-bor-r1 {
    border-right: 0.0625rem solid;
  }
  .tb-bor-b1 {
    border-bottom: 0.0625rem solid;
  }
  .tb-bor-l1 {
    border-left: 0.0625rem solid;
  }
  .tb-bor-v1 {
    border-top: 0.0625rem solid;
    border-bottom: 0.0625rem solid;
  }
  .tb-bor-h1 {
    border-left: 0.0625rem solid;
    border-right: 0.0625rem solid;
  }
  .tb-bor-2 {
    border: 0.125rem solid;
  }
  .tb-bor-t2 {
    border-top: 0.125rem solid;
  }
  .tb-bor-r2 {
    border-right: 0.125rem solid;
  }
  .tb-bor-b2 {
    border-bottom: 0.125rem solid;
  }
  .tb-bor-l2 {
    border-left: 0.125rem solid;
  }
  .tb-bor-v2 {
    border-top: 0.125rem solid;
    border-bottom: 0.125rem solid;
  }
  .tb-bor-h2 {
    border-left: 0.125rem solid;
    border-right: 0.125rem solid;
  }
  .tb-bor-3 {
    border: 0.1875rem solid;
  }
  .tb-bor-t3 {
    border-top: 0.1875rem solid;
  }
  .tb-bor-r3 {
    border-right: 0.1875rem solid;
  }
  .tb-bor-b3 {
    border-bottom: 0.1875rem solid;
  }
  .tb-bor-l3 {
    border-left: 0.1875rem solid;
  }
  .tb-bor-v3 {
    border-top: 0.1875rem solid;
    border-bottom: 0.1875rem solid;
  }
  .tb-bor-h3 {
    border-left: 0.1875rem solid;
    border-right: 0.1875rem solid;
  }
  .tb-bor-trans {
    border-color: transparent;
  }
  .tb-bor-white {
    border-color: #fff;
  }
  .tb-bor-very-light {
    border-color: #eeeded;
  }
  .tb-bor-light-grey {
    border-color: #ccc;
  }
  .tb-bor-grey {
    border-color: #999;
  }
  .tb-bor-dark-grey {
    border-color: #666;
  }
  .tb-bor-very-dark {
    border-color: #3a3a3a;
  }
  .tb-bor-black {
    border-color: #000;
  }
  .tb-bor-gold {
    border-color: #a08a40;
  }
  .tb-bor-platinum {
    border-color: #a7a9ac;
  }
  .tb-bor-diamond {
    border-color: #f4f4f4;
  }
  .tb-bor-gift {
    border-color: #999082;
  }
  .tb-bor-red {
    border-color: #ca0814;
  }
  .tb-bor-blue {
    border-color: #357edb;
  }
  .tb-bor-yellow {
    border-color: #eca142;
  }
  .tb-bor-orange {
    border-color: #f0591c;
  }
  .tb-bor-green {
    border-color: #50ad51;
  }
  .tb-bor-beta {
    border-color: #a0783c;
  }
  .tb-bor-campaign {
    border-color: var(--campaign-color, #a0783c);
  }
  .tb-bor-sale {
    border-color: var(--sale-color, #fee000);
  }
  .tb-bor-sale-public {
    border-color: #fee000;
  }
  .tb-bor-sale-private {
    border-color: #360e51;
  }
  .tb-bor-sale-flash {
    border-color: #00cfb4;
  }
  .tb-bor-sale-final {
    border-color: #ef5096;
  }
  .tb-bor-dot {
    border-style: dotted;
  }
  .tb-bor-tdot {
    border-top-style: dotted;
  }
  .tb-bor-rdot {
    border-right-style: dotted;
  }
  .tb-bor-bdot {
    border-bottom-style: dotted;
  }
  .tb-bor-ldot {
    border-left-style: dotted;
  }
  .tb-bor-vdot {
    border-top-style: dotted;
    border-bottom-style: dotted;
  }
  .tb-bor-hdot {
    border-left-style: dotted;
    border-right-style: dotted;
  }
  .tb-bor-dash {
    border-style: dashed;
  }
  .tb-bor-tdash {
    border-top-style: dashed;
  }
  .tb-bor-rdash {
    border-right-style: dashed;
  }
  .tb-bor-bdash {
    border-bottom-style: dashed;
  }
  .tb-bor-ldash {
    border-left-style: dashed;
  }
  .tb-bor-vdash {
    border-top-style: dashed;
    border-bottom-style: dashed;
  }
  .tb-bor-hdash {
    border-left-style: dashed;
    border-right-style: dashed;
  }
  .tb-bor-sol {
    border-style: solid;
  }
  .tb-bor-tsol {
    border-top-style: solid;
  }
  .tb-bor-rsol {
    border-right-style: solid;
  }
  .tb-bor-bsol {
    border-bottom-style: solid;
  }
  .tb-bor-lsol {
    border-left-style: solid;
  }
  .tb-bor-vsol {
    border-top-style: solid;
    border-bottom-style: solid;
  }
  .tb-bor-hsol {
    border-left-style: solid;
    border-right-style: solid;
  }
  .tb-bor-none {
    border-style: none;
  }
  .tb-bor-tnone {
    border-top-style: none;
  }
  .tb-bor-rnone {
    border-right-style: none;
  }
  .tb-bor-bnone {
    border-bottom-style: none;
  }
  .tb-bor-lnone {
    border-left-style: none;
  }
  .tb-bor-vnone {
    border-top-style: none;
    border-bottom-style: none;
  }
  .tb-bor-hnone {
    border-left-style: none;
    border-right-style: none;
  }
}

@media screen and (min-width: 64em) {
  .dt-bor-0 {
    border: 0 solid;
  }
  .dt-bor-t0 {
    border-top: 0 solid;
  }
  .dt-bor-r0 {
    border-right: 0 solid;
  }
  .dt-bor-b0 {
    border-bottom: 0 solid;
  }
  .dt-bor-l0 {
    border-left: 0 solid;
  }
  .dt-bor-v0 {
    border-top: 0 solid;
    border-bottom: 0 solid;
  }
  .dt-bor-h0 {
    border-left: 0 solid;
    border-right: 0 solid;
  }
  .dt-bor-1 {
    border: 0.0625rem solid;
  }
  .dt-bor-t1 {
    border-top: 0.0625rem solid;
  }
  .dt-bor-r1 {
    border-right: 0.0625rem solid;
  }
  .dt-bor-b1 {
    border-bottom: 0.0625rem solid;
  }
  .dt-bor-l1 {
    border-left: 0.0625rem solid;
  }
  .dt-bor-v1 {
    border-top: 0.0625rem solid;
    border-bottom: 0.0625rem solid;
  }
  .dt-bor-h1 {
    border-left: 0.0625rem solid;
    border-right: 0.0625rem solid;
  }
  .dt-bor-2 {
    border: 0.125rem solid;
  }
  .dt-bor-t2 {
    border-top: 0.125rem solid;
  }
  .dt-bor-r2 {
    border-right: 0.125rem solid;
  }
  .dt-bor-b2 {
    border-bottom: 0.125rem solid;
  }
  .dt-bor-l2 {
    border-left: 0.125rem solid;
  }
  .dt-bor-v2 {
    border-top: 0.125rem solid;
    border-bottom: 0.125rem solid;
  }
  .dt-bor-h2 {
    border-left: 0.125rem solid;
    border-right: 0.125rem solid;
  }
  .dt-bor-3 {
    border: 0.1875rem solid;
  }
  .dt-bor-t3 {
    border-top: 0.1875rem solid;
  }
  .dt-bor-r3 {
    border-right: 0.1875rem solid;
  }
  .dt-bor-b3 {
    border-bottom: 0.1875rem solid;
  }
  .dt-bor-l3 {
    border-left: 0.1875rem solid;
  }
  .dt-bor-v3 {
    border-top: 0.1875rem solid;
    border-bottom: 0.1875rem solid;
  }
  .dt-bor-h3 {
    border-left: 0.1875rem solid;
    border-right: 0.1875rem solid;
  }
  .dt-bor-trans {
    border-color: transparent;
  }
  .dt-bor-white {
    border-color: #fff;
  }
  .dt-bor-very-light {
    border-color: #eeeded;
  }
  .dt-bor-light-grey {
    border-color: #ccc;
  }
  .dt-bor-grey {
    border-color: #999;
  }
  .dt-bor-dark-grey {
    border-color: #666;
  }
  .dt-bor-very-dark {
    border-color: #3a3a3a;
  }
  .dt-bor-black {
    border-color: #000;
  }
  .dt-bor-gold {
    border-color: #a08a40;
  }
  .dt-bor-platinum {
    border-color: #a7a9ac;
  }
  .dt-bor-diamond {
    border-color: #f4f4f4;
  }
  .dt-bor-gift {
    border-color: #999082;
  }
  .dt-bor-red {
    border-color: #ca0814;
  }
  .dt-bor-blue {
    border-color: #357edb;
  }
  .dt-bor-yellow {
    border-color: #eca142;
  }
  .dt-bor-orange {
    border-color: #f0591c;
  }
  .dt-bor-green {
    border-color: #50ad51;
  }
  .dt-bor-beta {
    border-color: #a0783c;
  }
  .dt-bor-campaign {
    border-color: var(--campaign-color, #a0783c);
  }
  .dt-bor-sale {
    border-color: var(--sale-color, #fee000);
  }
  .dt-bor-sale-public {
    border-color: #fee000;
  }
  .dt-bor-sale-private {
    border-color: #360e51;
  }
  .dt-bor-sale-flash {
    border-color: #00cfb4;
  }
  .dt-bor-sale-final {
    border-color: #ef5096;
  }
  .dt-bor-dot {
    border-style: dotted;
  }
  .dt-bor-tdot {
    border-top-style: dotted;
  }
  .dt-bor-rdot {
    border-right-style: dotted;
  }
  .dt-bor-bdot {
    border-bottom-style: dotted;
  }
  .dt-bor-ldot {
    border-left-style: dotted;
  }
  .dt-bor-vdot {
    border-top-style: dotted;
    border-bottom-style: dotted;
  }
  .dt-bor-hdot {
    border-left-style: dotted;
    border-right-style: dotted;
  }
  .dt-bor-dash {
    border-style: dashed;
  }
  .dt-bor-tdash {
    border-top-style: dashed;
  }
  .dt-bor-rdash {
    border-right-style: dashed;
  }
  .dt-bor-bdash {
    border-bottom-style: dashed;
  }
  .dt-bor-ldash {
    border-left-style: dashed;
  }
  .dt-bor-vdash {
    border-top-style: dashed;
    border-bottom-style: dashed;
  }
  .dt-bor-hdash {
    border-left-style: dashed;
    border-right-style: dashed;
  }
  .dt-bor-sol {
    border-style: solid;
  }
  .dt-bor-tsol {
    border-top-style: solid;
  }
  .dt-bor-rsol {
    border-right-style: solid;
  }
  .dt-bor-bsol {
    border-bottom-style: solid;
  }
  .dt-bor-lsol {
    border-left-style: solid;
  }
  .dt-bor-vsol {
    border-top-style: solid;
    border-bottom-style: solid;
  }
  .dt-bor-hsol {
    border-left-style: solid;
    border-right-style: solid;
  }
  .dt-bor-none {
    border-style: none;
  }
  .dt-bor-tnone {
    border-top-style: none;
  }
  .dt-bor-rnone {
    border-right-style: none;
  }
  .dt-bor-bnone {
    border-bottom-style: none;
  }
  .dt-bor-lnone {
    border-left-style: none;
  }
  .dt-bor-vnone {
    border-top-style: none;
    border-bottom-style: none;
  }
  .dt-bor-hnone {
    border-left-style: none;
    border-right-style: none;
  }
}

@media screen and (min-width: 75em) {
  .hg-bor-0 {
    border: 0 solid;
  }
  .hg-bor-t0 {
    border-top: 0 solid;
  }
  .hg-bor-r0 {
    border-right: 0 solid;
  }
  .hg-bor-b0 {
    border-bottom: 0 solid;
  }
  .hg-bor-l0 {
    border-left: 0 solid;
  }
  .hg-bor-v0 {
    border-top: 0 solid;
    border-bottom: 0 solid;
  }
  .hg-bor-h0 {
    border-left: 0 solid;
    border-right: 0 solid;
  }
  .hg-bor-1 {
    border: 0.0625rem solid;
  }
  .hg-bor-t1 {
    border-top: 0.0625rem solid;
  }
  .hg-bor-r1 {
    border-right: 0.0625rem solid;
  }
  .hg-bor-b1 {
    border-bottom: 0.0625rem solid;
  }
  .hg-bor-l1 {
    border-left: 0.0625rem solid;
  }
  .hg-bor-v1 {
    border-top: 0.0625rem solid;
    border-bottom: 0.0625rem solid;
  }
  .hg-bor-h1 {
    border-left: 0.0625rem solid;
    border-right: 0.0625rem solid;
  }
  .hg-bor-2 {
    border: 0.125rem solid;
  }
  .hg-bor-t2 {
    border-top: 0.125rem solid;
  }
  .hg-bor-r2 {
    border-right: 0.125rem solid;
  }
  .hg-bor-b2 {
    border-bottom: 0.125rem solid;
  }
  .hg-bor-l2 {
    border-left: 0.125rem solid;
  }
  .hg-bor-v2 {
    border-top: 0.125rem solid;
    border-bottom: 0.125rem solid;
  }
  .hg-bor-h2 {
    border-left: 0.125rem solid;
    border-right: 0.125rem solid;
  }
  .hg-bor-3 {
    border: 0.1875rem solid;
  }
  .hg-bor-t3 {
    border-top: 0.1875rem solid;
  }
  .hg-bor-r3 {
    border-right: 0.1875rem solid;
  }
  .hg-bor-b3 {
    border-bottom: 0.1875rem solid;
  }
  .hg-bor-l3 {
    border-left: 0.1875rem solid;
  }
  .hg-bor-v3 {
    border-top: 0.1875rem solid;
    border-bottom: 0.1875rem solid;
  }
  .hg-bor-h3 {
    border-left: 0.1875rem solid;
    border-right: 0.1875rem solid;
  }
  .hg-bor-trans {
    border-color: transparent;
  }
  .hg-bor-white {
    border-color: #fff;
  }
  .hg-bor-very-light {
    border-color: #eeeded;
  }
  .hg-bor-light-grey {
    border-color: #ccc;
  }
  .hg-bor-grey {
    border-color: #999;
  }
  .hg-bor-dark-grey {
    border-color: #666;
  }
  .hg-bor-very-dark {
    border-color: #3a3a3a;
  }
  .hg-bor-black {
    border-color: #000;
  }
  .hg-bor-gold {
    border-color: #a08a40;
  }
  .hg-bor-platinum {
    border-color: #a7a9ac;
  }
  .hg-bor-diamond {
    border-color: #f4f4f4;
  }
  .hg-bor-gift {
    border-color: #999082;
  }
  .hg-bor-red {
    border-color: #ca0814;
  }
  .hg-bor-blue {
    border-color: #357edb;
  }
  .hg-bor-yellow {
    border-color: #eca142;
  }
  .hg-bor-orange {
    border-color: #f0591c;
  }
  .hg-bor-green {
    border-color: #50ad51;
  }
  .hg-bor-beta {
    border-color: #a0783c;
  }
  .hg-bor-campaign {
    border-color: var(--campaign-color, #a0783c);
  }
  .hg-bor-sale {
    border-color: var(--sale-color, #fee000);
  }
  .hg-bor-sale-public {
    border-color: #fee000;
  }
  .hg-bor-sale-private {
    border-color: #360e51;
  }
  .hg-bor-sale-flash {
    border-color: #00cfb4;
  }
  .hg-bor-sale-final {
    border-color: #ef5096;
  }
  .hg-bor-dot {
    border-style: dotted;
  }
  .hg-bor-tdot {
    border-top-style: dotted;
  }
  .hg-bor-rdot {
    border-right-style: dotted;
  }
  .hg-bor-bdot {
    border-bottom-style: dotted;
  }
  .hg-bor-ldot {
    border-left-style: dotted;
  }
  .hg-bor-vdot {
    border-top-style: dotted;
    border-bottom-style: dotted;
  }
  .hg-bor-hdot {
    border-left-style: dotted;
    border-right-style: dotted;
  }
  .hg-bor-dash {
    border-style: dashed;
  }
  .hg-bor-tdash {
    border-top-style: dashed;
  }
  .hg-bor-rdash {
    border-right-style: dashed;
  }
  .hg-bor-bdash {
    border-bottom-style: dashed;
  }
  .hg-bor-ldash {
    border-left-style: dashed;
  }
  .hg-bor-vdash {
    border-top-style: dashed;
    border-bottom-style: dashed;
  }
  .hg-bor-hdash {
    border-left-style: dashed;
    border-right-style: dashed;
  }
  .hg-bor-sol {
    border-style: solid;
  }
  .hg-bor-tsol {
    border-top-style: solid;
  }
  .hg-bor-rsol {
    border-right-style: solid;
  }
  .hg-bor-bsol {
    border-bottom-style: solid;
  }
  .hg-bor-lsol {
    border-left-style: solid;
  }
  .hg-bor-vsol {
    border-top-style: solid;
    border-bottom-style: solid;
  }
  .hg-bor-hsol {
    border-left-style: solid;
    border-right-style: solid;
  }
  .hg-bor-none {
    border-style: none;
  }
  .hg-bor-tnone {
    border-top-style: none;
  }
  .hg-bor-rnone {
    border-right-style: none;
  }
  .hg-bor-bnone {
    border-bottom-style: none;
  }
  .hg-bor-lnone {
    border-left-style: none;
  }
  .hg-bor-vnone {
    border-top-style: none;
    border-bottom-style: none;
  }
  .hg-bor-hnone {
    border-left-style: none;
    border-right-style: none;
  }
}

@media screen and (max-width: 47.9375em) {
  .mb-only-bor-0 {
    border: 0 solid;
  }
  .mb-only-bor-t0 {
    border-top: 0 solid;
  }
  .mb-only-bor-r0 {
    border-right: 0 solid;
  }
  .mb-only-bor-b0 {
    border-bottom: 0 solid;
  }
  .mb-only-bor-l0 {
    border-left: 0 solid;
  }
  .mb-only-bor-v0 {
    border-top: 0 solid;
    border-bottom: 0 solid;
  }
  .mb-only-bor-h0 {
    border-left: 0 solid;
    border-right: 0 solid;
  }
  .mb-only-bor-1 {
    border: 0.0625rem solid;
  }
  .mb-only-bor-t1 {
    border-top: 0.0625rem solid;
  }
  .mb-only-bor-r1 {
    border-right: 0.0625rem solid;
  }
  .mb-only-bor-b1 {
    border-bottom: 0.0625rem solid;
  }
  .mb-only-bor-l1 {
    border-left: 0.0625rem solid;
  }
  .mb-only-bor-v1 {
    border-top: 0.0625rem solid;
    border-bottom: 0.0625rem solid;
  }
  .mb-only-bor-h1 {
    border-left: 0.0625rem solid;
    border-right: 0.0625rem solid;
  }
  .mb-only-bor-2 {
    border: 0.125rem solid;
  }
  .mb-only-bor-t2 {
    border-top: 0.125rem solid;
  }
  .mb-only-bor-r2 {
    border-right: 0.125rem solid;
  }
  .mb-only-bor-b2 {
    border-bottom: 0.125rem solid;
  }
  .mb-only-bor-l2 {
    border-left: 0.125rem solid;
  }
  .mb-only-bor-v2 {
    border-top: 0.125rem solid;
    border-bottom: 0.125rem solid;
  }
  .mb-only-bor-h2 {
    border-left: 0.125rem solid;
    border-right: 0.125rem solid;
  }
  .mb-only-bor-3 {
    border: 0.1875rem solid;
  }
  .mb-only-bor-t3 {
    border-top: 0.1875rem solid;
  }
  .mb-only-bor-r3 {
    border-right: 0.1875rem solid;
  }
  .mb-only-bor-b3 {
    border-bottom: 0.1875rem solid;
  }
  .mb-only-bor-l3 {
    border-left: 0.1875rem solid;
  }
  .mb-only-bor-v3 {
    border-top: 0.1875rem solid;
    border-bottom: 0.1875rem solid;
  }
  .mb-only-bor-h3 {
    border-left: 0.1875rem solid;
    border-right: 0.1875rem solid;
  }
  .mb-only-bor-trans {
    border-color: transparent;
  }
  .mb-only-bor-white {
    border-color: #fff;
  }
  .mb-only-bor-very-light {
    border-color: #eeeded;
  }
  .mb-only-bor-light-grey {
    border-color: #ccc;
  }
  .mb-only-bor-grey {
    border-color: #999;
  }
  .mb-only-bor-dark-grey {
    border-color: #666;
  }
  .mb-only-bor-very-dark {
    border-color: #3a3a3a;
  }
  .mb-only-bor-black {
    border-color: #000;
  }
  .mb-only-bor-gold {
    border-color: #a08a40;
  }
  .mb-only-bor-platinum {
    border-color: #a7a9ac;
  }
  .mb-only-bor-diamond {
    border-color: #f4f4f4;
  }
  .mb-only-bor-gift {
    border-color: #999082;
  }
  .mb-only-bor-red {
    border-color: #ca0814;
  }
  .mb-only-bor-blue {
    border-color: #357edb;
  }
  .mb-only-bor-yellow {
    border-color: #eca142;
  }
  .mb-only-bor-orange {
    border-color: #f0591c;
  }
  .mb-only-bor-green {
    border-color: #50ad51;
  }
  .mb-only-bor-beta {
    border-color: #a0783c;
  }
  .mb-only-bor-campaign {
    border-color: var(--campaign-color, #a0783c);
  }
  .mb-only-bor-sale {
    border-color: var(--sale-color, #fee000);
  }
  .mb-only-bor-sale-public {
    border-color: #fee000;
  }
  .mb-only-bor-sale-private {
    border-color: #360e51;
  }
  .mb-only-bor-sale-flash {
    border-color: #00cfb4;
  }
  .mb-only-bor-sale-final {
    border-color: #ef5096;
  }
  .mb-only-bor-dot {
    border-style: dotted;
  }
  .mb-only-bor-tdot {
    border-top-style: dotted;
  }
  .mb-only-bor-rdot {
    border-right-style: dotted;
  }
  .mb-only-bor-bdot {
    border-bottom-style: dotted;
  }
  .mb-only-bor-ldot {
    border-left-style: dotted;
  }
  .mb-only-bor-vdot {
    border-top-style: dotted;
    border-bottom-style: dotted;
  }
  .mb-only-bor-hdot {
    border-left-style: dotted;
    border-right-style: dotted;
  }
  .mb-only-bor-dash {
    border-style: dashed;
  }
  .mb-only-bor-tdash {
    border-top-style: dashed;
  }
  .mb-only-bor-rdash {
    border-right-style: dashed;
  }
  .mb-only-bor-bdash {
    border-bottom-style: dashed;
  }
  .mb-only-bor-ldash {
    border-left-style: dashed;
  }
  .mb-only-bor-vdash {
    border-top-style: dashed;
    border-bottom-style: dashed;
  }
  .mb-only-bor-hdash {
    border-left-style: dashed;
    border-right-style: dashed;
  }
  .mb-only-bor-sol {
    border-style: solid;
  }
  .mb-only-bor-tsol {
    border-top-style: solid;
  }
  .mb-only-bor-rsol {
    border-right-style: solid;
  }
  .mb-only-bor-bsol {
    border-bottom-style: solid;
  }
  .mb-only-bor-lsol {
    border-left-style: solid;
  }
  .mb-only-bor-vsol {
    border-top-style: solid;
    border-bottom-style: solid;
  }
  .mb-only-bor-hsol {
    border-left-style: solid;
    border-right-style: solid;
  }
  .mb-only-bor-none {
    border-style: none;
  }
  .mb-only-bor-tnone {
    border-top-style: none;
  }
  .mb-only-bor-rnone {
    border-right-style: none;
  }
  .mb-only-bor-bnone {
    border-bottom-style: none;
  }
  .mb-only-bor-lnone {
    border-left-style: none;
  }
  .mb-only-bor-vnone {
    border-top-style: none;
    border-bottom-style: none;
  }
  .mb-only-bor-hnone {
    border-left-style: none;
    border-right-style: none;
  }
}

@media print, screen and (min-width: 48em) and (max-width: 63.9375em) {
  .tb-only-bor-0 {
    border: 0 solid;
  }
  .tb-only-bor-t0 {
    border-top: 0 solid;
  }
  .tb-only-bor-r0 {
    border-right: 0 solid;
  }
  .tb-only-bor-b0 {
    border-bottom: 0 solid;
  }
  .tb-only-bor-l0 {
    border-left: 0 solid;
  }
  .tb-only-bor-v0 {
    border-top: 0 solid;
    border-bottom: 0 solid;
  }
  .tb-only-bor-h0 {
    border-left: 0 solid;
    border-right: 0 solid;
  }
  .tb-only-bor-1 {
    border: 0.0625rem solid;
  }
  .tb-only-bor-t1 {
    border-top: 0.0625rem solid;
  }
  .tb-only-bor-r1 {
    border-right: 0.0625rem solid;
  }
  .tb-only-bor-b1 {
    border-bottom: 0.0625rem solid;
  }
  .tb-only-bor-l1 {
    border-left: 0.0625rem solid;
  }
  .tb-only-bor-v1 {
    border-top: 0.0625rem solid;
    border-bottom: 0.0625rem solid;
  }
  .tb-only-bor-h1 {
    border-left: 0.0625rem solid;
    border-right: 0.0625rem solid;
  }
  .tb-only-bor-2 {
    border: 0.125rem solid;
  }
  .tb-only-bor-t2 {
    border-top: 0.125rem solid;
  }
  .tb-only-bor-r2 {
    border-right: 0.125rem solid;
  }
  .tb-only-bor-b2 {
    border-bottom: 0.125rem solid;
  }
  .tb-only-bor-l2 {
    border-left: 0.125rem solid;
  }
  .tb-only-bor-v2 {
    border-top: 0.125rem solid;
    border-bottom: 0.125rem solid;
  }
  .tb-only-bor-h2 {
    border-left: 0.125rem solid;
    border-right: 0.125rem solid;
  }
  .tb-only-bor-3 {
    border: 0.1875rem solid;
  }
  .tb-only-bor-t3 {
    border-top: 0.1875rem solid;
  }
  .tb-only-bor-r3 {
    border-right: 0.1875rem solid;
  }
  .tb-only-bor-b3 {
    border-bottom: 0.1875rem solid;
  }
  .tb-only-bor-l3 {
    border-left: 0.1875rem solid;
  }
  .tb-only-bor-v3 {
    border-top: 0.1875rem solid;
    border-bottom: 0.1875rem solid;
  }
  .tb-only-bor-h3 {
    border-left: 0.1875rem solid;
    border-right: 0.1875rem solid;
  }
  .tb-only-bor-trans {
    border-color: transparent;
  }
  .tb-only-bor-white {
    border-color: #fff;
  }
  .tb-only-bor-very-light {
    border-color: #eeeded;
  }
  .tb-only-bor-light-grey {
    border-color: #ccc;
  }
  .tb-only-bor-grey {
    border-color: #999;
  }
  .tb-only-bor-dark-grey {
    border-color: #666;
  }
  .tb-only-bor-very-dark {
    border-color: #3a3a3a;
  }
  .tb-only-bor-black {
    border-color: #000;
  }
  .tb-only-bor-gold {
    border-color: #a08a40;
  }
  .tb-only-bor-platinum {
    border-color: #a7a9ac;
  }
  .tb-only-bor-diamond {
    border-color: #f4f4f4;
  }
  .tb-only-bor-gift {
    border-color: #999082;
  }
  .tb-only-bor-red {
    border-color: #ca0814;
  }
  .tb-only-bor-blue {
    border-color: #357edb;
  }
  .tb-only-bor-yellow {
    border-color: #eca142;
  }
  .tb-only-bor-orange {
    border-color: #f0591c;
  }
  .tb-only-bor-green {
    border-color: #50ad51;
  }
  .tb-only-bor-beta {
    border-color: #a0783c;
  }
  .tb-only-bor-campaign {
    border-color: var(--campaign-color, #a0783c);
  }
  .tb-only-bor-sale {
    border-color: var(--sale-color, #fee000);
  }
  .tb-only-bor-sale-public {
    border-color: #fee000;
  }
  .tb-only-bor-sale-private {
    border-color: #360e51;
  }
  .tb-only-bor-sale-flash {
    border-color: #00cfb4;
  }
  .tb-only-bor-sale-final {
    border-color: #ef5096;
  }
  .tb-only-bor-dot {
    border-style: dotted;
  }
  .tb-only-bor-tdot {
    border-top-style: dotted;
  }
  .tb-only-bor-rdot {
    border-right-style: dotted;
  }
  .tb-only-bor-bdot {
    border-bottom-style: dotted;
  }
  .tb-only-bor-ldot {
    border-left-style: dotted;
  }
  .tb-only-bor-vdot {
    border-top-style: dotted;
    border-bottom-style: dotted;
  }
  .tb-only-bor-hdot {
    border-left-style: dotted;
    border-right-style: dotted;
  }
  .tb-only-bor-dash {
    border-style: dashed;
  }
  .tb-only-bor-tdash {
    border-top-style: dashed;
  }
  .tb-only-bor-rdash {
    border-right-style: dashed;
  }
  .tb-only-bor-bdash {
    border-bottom-style: dashed;
  }
  .tb-only-bor-ldash {
    border-left-style: dashed;
  }
  .tb-only-bor-vdash {
    border-top-style: dashed;
    border-bottom-style: dashed;
  }
  .tb-only-bor-hdash {
    border-left-style: dashed;
    border-right-style: dashed;
  }
  .tb-only-bor-sol {
    border-style: solid;
  }
  .tb-only-bor-tsol {
    border-top-style: solid;
  }
  .tb-only-bor-rsol {
    border-right-style: solid;
  }
  .tb-only-bor-bsol {
    border-bottom-style: solid;
  }
  .tb-only-bor-lsol {
    border-left-style: solid;
  }
  .tb-only-bor-vsol {
    border-top-style: solid;
    border-bottom-style: solid;
  }
  .tb-only-bor-hsol {
    border-left-style: solid;
    border-right-style: solid;
  }
  .tb-only-bor-none {
    border-style: none;
  }
  .tb-only-bor-tnone {
    border-top-style: none;
  }
  .tb-only-bor-rnone {
    border-right-style: none;
  }
  .tb-only-bor-bnone {
    border-bottom-style: none;
  }
  .tb-only-bor-lnone {
    border-left-style: none;
  }
  .tb-only-bor-vnone {
    border-top-style: none;
    border-bottom-style: none;
  }
  .tb-only-bor-hnone {
    border-left-style: none;
    border-right-style: none;
  }
}

.rad-0 {
  border-radius: 0px;
}

.rad-2 {
  border-radius: 2px;
}

.rad-3 {
  border-radius: 3px;
}

.rad-5 {
  border-radius: 5px;
}

.rad-c {
  border-radius: 100%;
}

.rad-p {
  border-radius: 200px;
}

.rad-gc {
  border-radius: 4.5% / 6.5%;
}

.txt-trans {
  color: transparent;
}

.bg-trans {
  background-color: transparent;
}

.txt-white {
  color: #fff;
}

.bg-white {
  background-color: #fff;
}

.txt-very-light {
  color: #eeeded;
}

.bg-very-light {
  background-color: #eeeded;
}

.txt-light-grey {
  color: #ccc;
}

.bg-light-grey {
  background-color: #ccc;
}

.txt-grey {
  color: #999;
}

.bg-grey {
  background-color: #999;
}

.txt-dark-grey {
  color: #666;
}

.bg-dark-grey {
  background-color: #666;
}

.txt-very-dark {
  color: #3a3a3a;
}

.bg-very-dark {
  background-color: #3a3a3a;
}

.txt-black {
  color: #000;
}

.bg-black {
  background-color: #000;
}

.txt-gold {
  color: #a08a40;
}

.bg-gold {
  background-color: #a08a40;
}

.txt-platinum {
  color: #a7a9ac;
}

.bg-platinum {
  background-color: #a7a9ac;
}

.txt-diamond {
  color: #f4f4f4;
}

.bg-diamond {
  background-color: #f4f4f4;
}

.txt-gift {
  color: #999082;
}

.bg-gift {
  background-color: #999082;
}

.txt-red {
  color: #ca0814;
}

.bg-red {
  background-color: #ca0814;
}

.txt-blue {
  color: #357edb;
}

.bg-blue {
  background-color: #357edb;
}

.txt-yellow {
  color: #eca142;
}

.bg-yellow {
  background-color: #eca142;
}

.txt-orange {
  color: #f0591c;
}

.bg-orange {
  background-color: #f0591c;
}

.txt-green {
  color: #50ad51;
}

.bg-green {
  background-color: #50ad51;
}

.txt-beta {
  color: #a0783c;
}

.bg-beta {
  background-color: #a0783c;
}

.txt-campaign {
  color: var(--campaign-color, #a0783c);
}

.bg-campaign {
  background-color: var(--campaign-color, #a0783c);
}

.txt-sale {
  color: var(--sale-color, #fee000);
}

.bg-sale {
  background-color: var(--sale-color, #fee000);
}

.txt-sale-public {
  color: #fee000;
}

.bg-sale-public {
  background-color: #fee000;
}

.txt-sale-private {
  color: #360e51;
}

.bg-sale-private {
  background-color: #360e51;
}

.txt-sale-flash {
  color: #00cfb4;
}

.bg-sale-flash {
  background-color: #00cfb4;
}

.txt-sale-final {
  color: #ef5096;
}

.bg-sale-final {
  background-color: #ef5096;
}

@media print, screen and (min-width: 48em) {
  .tb-txt-trans {
    color: transparent;
  }
  .tb-bg-trans {
    background-color: transparent;
  }
  .tb-txt-white {
    color: #fff;
  }
  .tb-bg-white {
    background-color: #fff;
  }
  .tb-txt-very-light {
    color: #eeeded;
  }
  .tb-bg-very-light {
    background-color: #eeeded;
  }
  .tb-txt-light-grey {
    color: #ccc;
  }
  .tb-bg-light-grey {
    background-color: #ccc;
  }
  .tb-txt-grey {
    color: #999;
  }
  .tb-bg-grey {
    background-color: #999;
  }
  .tb-txt-dark-grey {
    color: #666;
  }
  .tb-bg-dark-grey {
    background-color: #666;
  }
  .tb-txt-very-dark {
    color: #3a3a3a;
  }
  .tb-bg-very-dark {
    background-color: #3a3a3a;
  }
  .tb-txt-black {
    color: #000;
  }
  .tb-bg-black {
    background-color: #000;
  }
  .tb-txt-gold {
    color: #a08a40;
  }
  .tb-bg-gold {
    background-color: #a08a40;
  }
  .tb-txt-platinum {
    color: #a7a9ac;
  }
  .tb-bg-platinum {
    background-color: #a7a9ac;
  }
  .tb-txt-diamond {
    color: #f4f4f4;
  }
  .tb-bg-diamond {
    background-color: #f4f4f4;
  }
  .tb-txt-gift {
    color: #999082;
  }
  .tb-bg-gift {
    background-color: #999082;
  }
  .tb-txt-red {
    color: #ca0814;
  }
  .tb-bg-red {
    background-color: #ca0814;
  }
  .tb-txt-blue {
    color: #357edb;
  }
  .tb-bg-blue {
    background-color: #357edb;
  }
  .tb-txt-yellow {
    color: #eca142;
  }
  .tb-bg-yellow {
    background-color: #eca142;
  }
  .tb-txt-orange {
    color: #f0591c;
  }
  .tb-bg-orange {
    background-color: #f0591c;
  }
  .tb-txt-green {
    color: #50ad51;
  }
  .tb-bg-green {
    background-color: #50ad51;
  }
  .tb-txt-beta {
    color: #a0783c;
  }
  .tb-bg-beta {
    background-color: #a0783c;
  }
  .tb-txt-campaign {
    color: var(--campaign-color, #a0783c);
  }
  .tb-bg-campaign {
    background-color: var(--campaign-color, #a0783c);
  }
  .tb-txt-sale {
    color: var(--sale-color, #fee000);
  }
  .tb-bg-sale {
    background-color: var(--sale-color, #fee000);
  }
  .tb-txt-sale-public {
    color: #fee000;
  }
  .tb-bg-sale-public {
    background-color: #fee000;
  }
  .tb-txt-sale-private {
    color: #360e51;
  }
  .tb-bg-sale-private {
    background-color: #360e51;
  }
  .tb-txt-sale-flash {
    color: #00cfb4;
  }
  .tb-bg-sale-flash {
    background-color: #00cfb4;
  }
  .tb-txt-sale-final {
    color: #ef5096;
  }
  .tb-bg-sale-final {
    background-color: #ef5096;
  }
}

@media screen and (min-width: 64em) {
  .dt-txt-trans {
    color: transparent;
  }
  .dt-bg-trans {
    background-color: transparent;
  }
  .dt-txt-white {
    color: #fff;
  }
  .dt-bg-white {
    background-color: #fff;
  }
  .dt-txt-very-light {
    color: #eeeded;
  }
  .dt-bg-very-light {
    background-color: #eeeded;
  }
  .dt-txt-light-grey {
    color: #ccc;
  }
  .dt-bg-light-grey {
    background-color: #ccc;
  }
  .dt-txt-grey {
    color: #999;
  }
  .dt-bg-grey {
    background-color: #999;
  }
  .dt-txt-dark-grey {
    color: #666;
  }
  .dt-bg-dark-grey {
    background-color: #666;
  }
  .dt-txt-very-dark {
    color: #3a3a3a;
  }
  .dt-bg-very-dark {
    background-color: #3a3a3a;
  }
  .dt-txt-black {
    color: #000;
  }
  .dt-bg-black {
    background-color: #000;
  }
  .dt-txt-gold {
    color: #a08a40;
  }
  .dt-bg-gold {
    background-color: #a08a40;
  }
  .dt-txt-platinum {
    color: #a7a9ac;
  }
  .dt-bg-platinum {
    background-color: #a7a9ac;
  }
  .dt-txt-diamond {
    color: #f4f4f4;
  }
  .dt-bg-diamond {
    background-color: #f4f4f4;
  }
  .dt-txt-gift {
    color: #999082;
  }
  .dt-bg-gift {
    background-color: #999082;
  }
  .dt-txt-red {
    color: #ca0814;
  }
  .dt-bg-red {
    background-color: #ca0814;
  }
  .dt-txt-blue {
    color: #357edb;
  }
  .dt-bg-blue {
    background-color: #357edb;
  }
  .dt-txt-yellow {
    color: #eca142;
  }
  .dt-bg-yellow {
    background-color: #eca142;
  }
  .dt-txt-orange {
    color: #f0591c;
  }
  .dt-bg-orange {
    background-color: #f0591c;
  }
  .dt-txt-green {
    color: #50ad51;
  }
  .dt-bg-green {
    background-color: #50ad51;
  }
  .dt-txt-beta {
    color: #a0783c;
  }
  .dt-bg-beta {
    background-color: #a0783c;
  }
  .dt-txt-campaign {
    color: var(--campaign-color, #a0783c);
  }
  .dt-bg-campaign {
    background-color: var(--campaign-color, #a0783c);
  }
  .dt-txt-sale {
    color: var(--sale-color, #fee000);
  }
  .dt-bg-sale {
    background-color: var(--sale-color, #fee000);
  }
  .dt-txt-sale-public {
    color: #fee000;
  }
  .dt-bg-sale-public {
    background-color: #fee000;
  }
  .dt-txt-sale-private {
    color: #360e51;
  }
  .dt-bg-sale-private {
    background-color: #360e51;
  }
  .dt-txt-sale-flash {
    color: #00cfb4;
  }
  .dt-bg-sale-flash {
    background-color: #00cfb4;
  }
  .dt-txt-sale-final {
    color: #ef5096;
  }
  .dt-bg-sale-final {
    background-color: #ef5096;
  }
}

@media screen and (min-width: 75em) {
  .hg-txt-trans {
    color: transparent;
  }
  .hg-bg-trans {
    background-color: transparent;
  }
  .hg-txt-white {
    color: #fff;
  }
  .hg-bg-white {
    background-color: #fff;
  }
  .hg-txt-very-light {
    color: #eeeded;
  }
  .hg-bg-very-light {
    background-color: #eeeded;
  }
  .hg-txt-light-grey {
    color: #ccc;
  }
  .hg-bg-light-grey {
    background-color: #ccc;
  }
  .hg-txt-grey {
    color: #999;
  }
  .hg-bg-grey {
    background-color: #999;
  }
  .hg-txt-dark-grey {
    color: #666;
  }
  .hg-bg-dark-grey {
    background-color: #666;
  }
  .hg-txt-very-dark {
    color: #3a3a3a;
  }
  .hg-bg-very-dark {
    background-color: #3a3a3a;
  }
  .hg-txt-black {
    color: #000;
  }
  .hg-bg-black {
    background-color: #000;
  }
  .hg-txt-gold {
    color: #a08a40;
  }
  .hg-bg-gold {
    background-color: #a08a40;
  }
  .hg-txt-platinum {
    color: #a7a9ac;
  }
  .hg-bg-platinum {
    background-color: #a7a9ac;
  }
  .hg-txt-diamond {
    color: #f4f4f4;
  }
  .hg-bg-diamond {
    background-color: #f4f4f4;
  }
  .hg-txt-gift {
    color: #999082;
  }
  .hg-bg-gift {
    background-color: #999082;
  }
  .hg-txt-red {
    color: #ca0814;
  }
  .hg-bg-red {
    background-color: #ca0814;
  }
  .hg-txt-blue {
    color: #357edb;
  }
  .hg-bg-blue {
    background-color: #357edb;
  }
  .hg-txt-yellow {
    color: #eca142;
  }
  .hg-bg-yellow {
    background-color: #eca142;
  }
  .hg-txt-orange {
    color: #f0591c;
  }
  .hg-bg-orange {
    background-color: #f0591c;
  }
  .hg-txt-green {
    color: #50ad51;
  }
  .hg-bg-green {
    background-color: #50ad51;
  }
  .hg-txt-beta {
    color: #a0783c;
  }
  .hg-bg-beta {
    background-color: #a0783c;
  }
  .hg-txt-campaign {
    color: var(--campaign-color, #a0783c);
  }
  .hg-bg-campaign {
    background-color: var(--campaign-color, #a0783c);
  }
  .hg-txt-sale {
    color: var(--sale-color, #fee000);
  }
  .hg-bg-sale {
    background-color: var(--sale-color, #fee000);
  }
  .hg-txt-sale-public {
    color: #fee000;
  }
  .hg-bg-sale-public {
    background-color: #fee000;
  }
  .hg-txt-sale-private {
    color: #360e51;
  }
  .hg-bg-sale-private {
    background-color: #360e51;
  }
  .hg-txt-sale-flash {
    color: #00cfb4;
  }
  .hg-bg-sale-flash {
    background-color: #00cfb4;
  }
  .hg-txt-sale-final {
    color: #ef5096;
  }
  .hg-bg-sale-final {
    background-color: #ef5096;
  }
}

.mar-0 {
  margin: 0;
}

.mar-2 {
  margin: 0.125rem;
}

.mar-5 {
  margin: 0.3125rem;
}

.mar-10 {
  margin: 0.625rem;
}

.mar-15 {
  margin: 0.9375rem;
}

.mar-20 {
  margin: 1.25rem;
}

.mar-30 {
  margin: 1.875rem;
}

.mar-40 {
  margin: 2.5rem;
}

.mar-50 {
  margin: 3.125rem;
}

.mar-v0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mar-h0 {
  margin-left: 0;
  margin-right: 0;
}

.mar-v2 {
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}

.mar-h2 {
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}

.mar-v5 {
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem;
}

.mar-h5 {
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
}

.mar-v10 {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.mar-h10 {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}

.mar-v15 {
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem;
}

.mar-h15 {
  margin-left: 0.9375rem;
  margin-right: 0.9375rem;
}

.mar-v20 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.mar-h20 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.mar-v30 {
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
}

.mar-h30 {
  margin-left: 1.875rem;
  margin-right: 1.875rem;
}

.mar-v40 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.mar-h40 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.mar-v50 {
  margin-top: 3.125rem;
  margin-bottom: 3.125rem;
}

.mar-h50 {
  margin-left: 3.125rem;
  margin-right: 3.125rem;
}

.mar-l0 {
  margin-left: 0;
}

.mar-r0 {
  margin-right: 0;
}

.mar-t0 {
  margin-top: 0;
}

.mar-b0 {
  margin-bottom: 0;
}

.mar-l2 {
  margin-left: 0.125rem;
}

.mar-r2 {
  margin-right: 0.125rem;
}

.mar-t2 {
  margin-top: 0.125rem;
}

.mar-b2 {
  margin-bottom: 0.125rem;
}

.mar-l5 {
  margin-left: 0.3125rem;
}

.mar-r5 {
  margin-right: 0.3125rem;
}

.mar-t5 {
  margin-top: 0.3125rem;
}

.mar-b5 {
  margin-bottom: 0.3125rem;
}

.mar-l10 {
  margin-left: 0.625rem;
}

.mar-r10 {
  margin-right: 0.625rem;
}

.mar-t10 {
  margin-top: 0.625rem;
}

.mar-b10 {
  margin-bottom: 0.625rem;
}

.mar-l15 {
  margin-left: 0.9375rem;
}

.mar-r15 {
  margin-right: 0.9375rem;
}

.mar-t15 {
  margin-top: 0.9375rem;
}

.mar-b15 {
  margin-bottom: 0.9375rem;
}

.mar-l20 {
  margin-left: 1.25rem;
}

.mar-r20 {
  margin-right: 1.25rem;
}

.mar-t20 {
  margin-top: 1.25rem;
}

.mar-b20 {
  margin-bottom: 1.25rem;
}

.mar-l30 {
  margin-left: 1.875rem;
}

.mar-r30 {
  margin-right: 1.875rem;
}

.mar-t30 {
  margin-top: 1.875rem;
}

.mar-b30 {
  margin-bottom: 1.875rem;
}

.mar-l40 {
  margin-left: 2.5rem;
}

.mar-r40 {
  margin-right: 2.5rem;
}

.mar-t40 {
  margin-top: 2.5rem;
}

.mar-b40 {
  margin-bottom: 2.5rem;
}

.mar-l50 {
  margin-left: 3.125rem;
}

.mar-r50 {
  margin-right: 3.125rem;
}

.mar-t50 {
  margin-top: 3.125rem;
}

.mar-b50 {
  margin-bottom: 3.125rem;
}

.mar-a {
  margin: auto;
}

.mar-va {
  margin-top: auto;
  margin-bottom: auto;
}

.mar-ha {
  margin-left: auto;
  margin-right: auto;
}

@media print, screen and (min-width: 48em) {
  .tb-mar-0 {
    margin: 0;
  }
  .tb-mar-2 {
    margin: 0.125rem;
  }
  .tb-mar-5 {
    margin: 0.3125rem;
  }
  .tb-mar-10 {
    margin: 0.625rem;
  }
  .tb-mar-15 {
    margin: 0.9375rem;
  }
  .tb-mar-20 {
    margin: 1.25rem;
  }
  .tb-mar-30 {
    margin: 1.875rem;
  }
  .tb-mar-40 {
    margin: 2.5rem;
  }
  .tb-mar-50 {
    margin: 3.125rem;
  }
  .tb-mar-v0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .tb-mar-h0 {
    margin-left: 0;
    margin-right: 0;
  }
  .tb-mar-v2 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }
  .tb-mar-h2 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }
  .tb-mar-v5 {
    margin-top: 0.3125rem;
    margin-bottom: 0.3125rem;
  }
  .tb-mar-h5 {
    margin-left: 0.3125rem;
    margin-right: 0.3125rem;
  }
  .tb-mar-v10 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .tb-mar-h10 {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
  .tb-mar-v15 {
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }
  .tb-mar-h15 {
    margin-left: 0.9375rem;
    margin-right: 0.9375rem;
  }
  .tb-mar-v20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .tb-mar-h20 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .tb-mar-v30 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }
  .tb-mar-h30 {
    margin-left: 1.875rem;
    margin-right: 1.875rem;
  }
  .tb-mar-v40 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .tb-mar-h40 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .tb-mar-v50 {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }
  .tb-mar-h50 {
    margin-left: 3.125rem;
    margin-right: 3.125rem;
  }
  .tb-mar-l0 {
    margin-left: 0;
  }
  .tb-mar-r0 {
    margin-right: 0;
  }
  .tb-mar-t0 {
    margin-top: 0;
  }
  .tb-mar-b0 {
    margin-bottom: 0;
  }
  .tb-mar-l2 {
    margin-left: 0.125rem;
  }
  .tb-mar-r2 {
    margin-right: 0.125rem;
  }
  .tb-mar-t2 {
    margin-top: 0.125rem;
  }
  .tb-mar-b2 {
    margin-bottom: 0.125rem;
  }
  .tb-mar-l5 {
    margin-left: 0.3125rem;
  }
  .tb-mar-r5 {
    margin-right: 0.3125rem;
  }
  .tb-mar-t5 {
    margin-top: 0.3125rem;
  }
  .tb-mar-b5 {
    margin-bottom: 0.3125rem;
  }
  .tb-mar-l10 {
    margin-left: 0.625rem;
  }
  .tb-mar-r10 {
    margin-right: 0.625rem;
  }
  .tb-mar-t10 {
    margin-top: 0.625rem;
  }
  .tb-mar-b10 {
    margin-bottom: 0.625rem;
  }
  .tb-mar-l15 {
    margin-left: 0.9375rem;
  }
  .tb-mar-r15 {
    margin-right: 0.9375rem;
  }
  .tb-mar-t15 {
    margin-top: 0.9375rem;
  }
  .tb-mar-b15 {
    margin-bottom: 0.9375rem;
  }
  .tb-mar-l20 {
    margin-left: 1.25rem;
  }
  .tb-mar-r20 {
    margin-right: 1.25rem;
  }
  .tb-mar-t20 {
    margin-top: 1.25rem;
  }
  .tb-mar-b20 {
    margin-bottom: 1.25rem;
  }
  .tb-mar-l30 {
    margin-left: 1.875rem;
  }
  .tb-mar-r30 {
    margin-right: 1.875rem;
  }
  .tb-mar-t30 {
    margin-top: 1.875rem;
  }
  .tb-mar-b30 {
    margin-bottom: 1.875rem;
  }
  .tb-mar-l40 {
    margin-left: 2.5rem;
  }
  .tb-mar-r40 {
    margin-right: 2.5rem;
  }
  .tb-mar-t40 {
    margin-top: 2.5rem;
  }
  .tb-mar-b40 {
    margin-bottom: 2.5rem;
  }
  .tb-mar-l50 {
    margin-left: 3.125rem;
  }
  .tb-mar-r50 {
    margin-right: 3.125rem;
  }
  .tb-mar-t50 {
    margin-top: 3.125rem;
  }
  .tb-mar-b50 {
    margin-bottom: 3.125rem;
  }
  .tb-mar-a {
    margin: auto;
  }
  .tb-mar-va {
    margin-top: auto;
    margin-bottom: auto;
  }
  .tb-mar-ha {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 64em) {
  .dt-mar-0 {
    margin: 0;
  }
  .dt-mar-2 {
    margin: 0.125rem;
  }
  .dt-mar-5 {
    margin: 0.3125rem;
  }
  .dt-mar-10 {
    margin: 0.625rem;
  }
  .dt-mar-15 {
    margin: 0.9375rem;
  }
  .dt-mar-20 {
    margin: 1.25rem;
  }
  .dt-mar-30 {
    margin: 1.875rem;
  }
  .dt-mar-40 {
    margin: 2.5rem;
  }
  .dt-mar-50 {
    margin: 3.125rem;
  }
  .dt-mar-v0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .dt-mar-h0 {
    margin-left: 0;
    margin-right: 0;
  }
  .dt-mar-v2 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }
  .dt-mar-h2 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }
  .dt-mar-v5 {
    margin-top: 0.3125rem;
    margin-bottom: 0.3125rem;
  }
  .dt-mar-h5 {
    margin-left: 0.3125rem;
    margin-right: 0.3125rem;
  }
  .dt-mar-v10 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .dt-mar-h10 {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
  .dt-mar-v15 {
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }
  .dt-mar-h15 {
    margin-left: 0.9375rem;
    margin-right: 0.9375rem;
  }
  .dt-mar-v20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .dt-mar-h20 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .dt-mar-v30 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }
  .dt-mar-h30 {
    margin-left: 1.875rem;
    margin-right: 1.875rem;
  }
  .dt-mar-v40 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .dt-mar-h40 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .dt-mar-v50 {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }
  .dt-mar-h50 {
    margin-left: 3.125rem;
    margin-right: 3.125rem;
  }
  .dt-mar-l0 {
    margin-left: 0;
  }
  .dt-mar-r0 {
    margin-right: 0;
  }
  .dt-mar-t0 {
    margin-top: 0;
  }
  .dt-mar-b0 {
    margin-bottom: 0;
  }
  .dt-mar-l2 {
    margin-left: 0.125rem;
  }
  .dt-mar-r2 {
    margin-right: 0.125rem;
  }
  .dt-mar-t2 {
    margin-top: 0.125rem;
  }
  .dt-mar-b2 {
    margin-bottom: 0.125rem;
  }
  .dt-mar-l5 {
    margin-left: 0.3125rem;
  }
  .dt-mar-r5 {
    margin-right: 0.3125rem;
  }
  .dt-mar-t5 {
    margin-top: 0.3125rem;
  }
  .dt-mar-b5 {
    margin-bottom: 0.3125rem;
  }
  .dt-mar-l10 {
    margin-left: 0.625rem;
  }
  .dt-mar-r10 {
    margin-right: 0.625rem;
  }
  .dt-mar-t10 {
    margin-top: 0.625rem;
  }
  .dt-mar-b10 {
    margin-bottom: 0.625rem;
  }
  .dt-mar-l15 {
    margin-left: 0.9375rem;
  }
  .dt-mar-r15 {
    margin-right: 0.9375rem;
  }
  .dt-mar-t15 {
    margin-top: 0.9375rem;
  }
  .dt-mar-b15 {
    margin-bottom: 0.9375rem;
  }
  .dt-mar-l20 {
    margin-left: 1.25rem;
  }
  .dt-mar-r20 {
    margin-right: 1.25rem;
  }
  .dt-mar-t20 {
    margin-top: 1.25rem;
  }
  .dt-mar-b20 {
    margin-bottom: 1.25rem;
  }
  .dt-mar-l30 {
    margin-left: 1.875rem;
  }
  .dt-mar-r30 {
    margin-right: 1.875rem;
  }
  .dt-mar-t30 {
    margin-top: 1.875rem;
  }
  .dt-mar-b30 {
    margin-bottom: 1.875rem;
  }
  .dt-mar-l40 {
    margin-left: 2.5rem;
  }
  .dt-mar-r40 {
    margin-right: 2.5rem;
  }
  .dt-mar-t40 {
    margin-top: 2.5rem;
  }
  .dt-mar-b40 {
    margin-bottom: 2.5rem;
  }
  .dt-mar-l50 {
    margin-left: 3.125rem;
  }
  .dt-mar-r50 {
    margin-right: 3.125rem;
  }
  .dt-mar-t50 {
    margin-top: 3.125rem;
  }
  .dt-mar-b50 {
    margin-bottom: 3.125rem;
  }
  .dt-mar-a {
    margin: auto;
  }
  .dt-mar-va {
    margin-top: auto;
    margin-bottom: auto;
  }
  .dt-mar-ha {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 75em) {
  .hg-mar-0 {
    margin: 0;
  }
  .hg-mar-2 {
    margin: 0.125rem;
  }
  .hg-mar-5 {
    margin: 0.3125rem;
  }
  .hg-mar-10 {
    margin: 0.625rem;
  }
  .hg-mar-15 {
    margin: 0.9375rem;
  }
  .hg-mar-20 {
    margin: 1.25rem;
  }
  .hg-mar-30 {
    margin: 1.875rem;
  }
  .hg-mar-40 {
    margin: 2.5rem;
  }
  .hg-mar-50 {
    margin: 3.125rem;
  }
  .hg-mar-v0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .hg-mar-h0 {
    margin-left: 0;
    margin-right: 0;
  }
  .hg-mar-v2 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }
  .hg-mar-h2 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }
  .hg-mar-v5 {
    margin-top: 0.3125rem;
    margin-bottom: 0.3125rem;
  }
  .hg-mar-h5 {
    margin-left: 0.3125rem;
    margin-right: 0.3125rem;
  }
  .hg-mar-v10 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .hg-mar-h10 {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
  .hg-mar-v15 {
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }
  .hg-mar-h15 {
    margin-left: 0.9375rem;
    margin-right: 0.9375rem;
  }
  .hg-mar-v20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .hg-mar-h20 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .hg-mar-v30 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }
  .hg-mar-h30 {
    margin-left: 1.875rem;
    margin-right: 1.875rem;
  }
  .hg-mar-v40 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .hg-mar-h40 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .hg-mar-v50 {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }
  .hg-mar-h50 {
    margin-left: 3.125rem;
    margin-right: 3.125rem;
  }
  .hg-mar-l0 {
    margin-left: 0;
  }
  .hg-mar-r0 {
    margin-right: 0;
  }
  .hg-mar-t0 {
    margin-top: 0;
  }
  .hg-mar-b0 {
    margin-bottom: 0;
  }
  .hg-mar-l2 {
    margin-left: 0.125rem;
  }
  .hg-mar-r2 {
    margin-right: 0.125rem;
  }
  .hg-mar-t2 {
    margin-top: 0.125rem;
  }
  .hg-mar-b2 {
    margin-bottom: 0.125rem;
  }
  .hg-mar-l5 {
    margin-left: 0.3125rem;
  }
  .hg-mar-r5 {
    margin-right: 0.3125rem;
  }
  .hg-mar-t5 {
    margin-top: 0.3125rem;
  }
  .hg-mar-b5 {
    margin-bottom: 0.3125rem;
  }
  .hg-mar-l10 {
    margin-left: 0.625rem;
  }
  .hg-mar-r10 {
    margin-right: 0.625rem;
  }
  .hg-mar-t10 {
    margin-top: 0.625rem;
  }
  .hg-mar-b10 {
    margin-bottom: 0.625rem;
  }
  .hg-mar-l15 {
    margin-left: 0.9375rem;
  }
  .hg-mar-r15 {
    margin-right: 0.9375rem;
  }
  .hg-mar-t15 {
    margin-top: 0.9375rem;
  }
  .hg-mar-b15 {
    margin-bottom: 0.9375rem;
  }
  .hg-mar-l20 {
    margin-left: 1.25rem;
  }
  .hg-mar-r20 {
    margin-right: 1.25rem;
  }
  .hg-mar-t20 {
    margin-top: 1.25rem;
  }
  .hg-mar-b20 {
    margin-bottom: 1.25rem;
  }
  .hg-mar-l30 {
    margin-left: 1.875rem;
  }
  .hg-mar-r30 {
    margin-right: 1.875rem;
  }
  .hg-mar-t30 {
    margin-top: 1.875rem;
  }
  .hg-mar-b30 {
    margin-bottom: 1.875rem;
  }
  .hg-mar-l40 {
    margin-left: 2.5rem;
  }
  .hg-mar-r40 {
    margin-right: 2.5rem;
  }
  .hg-mar-t40 {
    margin-top: 2.5rem;
  }
  .hg-mar-b40 {
    margin-bottom: 2.5rem;
  }
  .hg-mar-l50 {
    margin-left: 3.125rem;
  }
  .hg-mar-r50 {
    margin-right: 3.125rem;
  }
  .hg-mar-t50 {
    margin-top: 3.125rem;
  }
  .hg-mar-b50 {
    margin-bottom: 3.125rem;
  }
  .hg-mar-a {
    margin: auto;
  }
  .hg-mar-va {
    margin-top: auto;
    margin-bottom: auto;
  }
  .hg-mar-ha {
    margin-left: auto;
    margin-right: auto;
  }
}

.pad-0 {
  padding: 0;
}

.pad-2 {
  padding: 0.125rem;
}

.pad-5 {
  padding: 0.3125rem;
}

.pad-10 {
  padding: 0.625rem;
}

.pad-15 {
  padding: 0.9375rem;
}

.pad-20 {
  padding: 1.25rem;
}

.pad-30 {
  padding: 1.875rem;
}

.pad-40 {
  padding: 2.5rem;
}

.pad-50 {
  padding: 3.125rem;
}

.pad-v0 {
  padding-top: 0;
  padding-bottom: 0;
}

.pad-h0 {
  padding-left: 0;
  padding-right: 0;
}

.pad-v2 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.pad-h2 {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}

.pad-v5 {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
}

.pad-h5 {
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
}

.pad-v10 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.pad-h10 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.pad-v15 {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
}

.pad-h15 {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.pad-v20 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.pad-h20 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.pad-v30 {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}

.pad-h30 {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
}

.pad-v40 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.pad-h40 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.pad-v50 {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
}

.pad-h50 {
  padding-left: 3.125rem;
  padding-right: 3.125rem;
}

.pad-l0 {
  padding-left: 0;
}

.pad-r0 {
  padding-right: 0;
}

.pad-t0 {
  padding-top: 0;
}

.pad-b0 {
  padding-bottom: 0;
}

.pad-l2 {
  padding-left: 0.125rem;
}

.pad-r2 {
  padding-right: 0.125rem;
}

.pad-t2 {
  padding-top: 0.125rem;
}

.pad-b2 {
  padding-bottom: 0.125rem;
}

.pad-l5 {
  padding-left: 0.3125rem;
}

.pad-r5 {
  padding-right: 0.3125rem;
}

.pad-t5 {
  padding-top: 0.3125rem;
}

.pad-b5 {
  padding-bottom: 0.3125rem;
}

.pad-l10 {
  padding-left: 0.625rem;
}

.pad-r10 {
  padding-right: 0.625rem;
}

.pad-t10 {
  padding-top: 0.625rem;
}

.pad-b10 {
  padding-bottom: 0.625rem;
}

.pad-l15 {
  padding-left: 0.9375rem;
}

.pad-r15 {
  padding-right: 0.9375rem;
}

.pad-t15 {
  padding-top: 0.9375rem;
}

.pad-b15 {
  padding-bottom: 0.9375rem;
}

.pad-l20 {
  padding-left: 1.25rem;
}

.pad-r20 {
  padding-right: 1.25rem;
}

.pad-t20 {
  padding-top: 1.25rem;
}

.pad-b20 {
  padding-bottom: 1.25rem;
}

.pad-l30 {
  padding-left: 1.875rem;
}

.pad-r30 {
  padding-right: 1.875rem;
}

.pad-t30 {
  padding-top: 1.875rem;
}

.pad-b30 {
  padding-bottom: 1.875rem;
}

.pad-l40 {
  padding-left: 2.5rem;
}

.pad-r40 {
  padding-right: 2.5rem;
}

.pad-t40 {
  padding-top: 2.5rem;
}

.pad-b40 {
  padding-bottom: 2.5rem;
}

.pad-l50 {
  padding-left: 3.125rem;
}

.pad-r50 {
  padding-right: 3.125rem;
}

.pad-t50 {
  padding-top: 3.125rem;
}

.pad-b50 {
  padding-bottom: 3.125rem;
}

@media print, screen and (min-width: 48em) {
  .tb-pad-0 {
    padding: 0;
  }
  .tb-pad-2 {
    padding: 0.125rem;
  }
  .tb-pad-5 {
    padding: 0.3125rem;
  }
  .tb-pad-10 {
    padding: 0.625rem;
  }
  .tb-pad-15 {
    padding: 0.9375rem;
  }
  .tb-pad-20 {
    padding: 1.25rem;
  }
  .tb-pad-30 {
    padding: 1.875rem;
  }
  .tb-pad-40 {
    padding: 2.5rem;
  }
  .tb-pad-50 {
    padding: 3.125rem;
  }
  .tb-pad-v0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .tb-pad-h0 {
    padding-left: 0;
    padding-right: 0;
  }
  .tb-pad-v2 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .tb-pad-h2 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .tb-pad-v5 {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }
  .tb-pad-h5 {
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
  }
  .tb-pad-v10 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .tb-pad-h10 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .tb-pad-v15 {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }
  .tb-pad-h15 {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
  .tb-pad-v20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .tb-pad-h20 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .tb-pad-v30 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }
  .tb-pad-h30 {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
  .tb-pad-v40 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .tb-pad-h40 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .tb-pad-v50 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }
  .tb-pad-h50 {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }
  .tb-pad-l0 {
    padding-left: 0;
  }
  .tb-pad-r0 {
    padding-right: 0;
  }
  .tb-pad-t0 {
    padding-top: 0;
  }
  .tb-pad-b0 {
    padding-bottom: 0;
  }
  .tb-pad-l2 {
    padding-left: 0.125rem;
  }
  .tb-pad-r2 {
    padding-right: 0.125rem;
  }
  .tb-pad-t2 {
    padding-top: 0.125rem;
  }
  .tb-pad-b2 {
    padding-bottom: 0.125rem;
  }
  .tb-pad-l5 {
    padding-left: 0.3125rem;
  }
  .tb-pad-r5 {
    padding-right: 0.3125rem;
  }
  .tb-pad-t5 {
    padding-top: 0.3125rem;
  }
  .tb-pad-b5 {
    padding-bottom: 0.3125rem;
  }
  .tb-pad-l10 {
    padding-left: 0.625rem;
  }
  .tb-pad-r10 {
    padding-right: 0.625rem;
  }
  .tb-pad-t10 {
    padding-top: 0.625rem;
  }
  .tb-pad-b10 {
    padding-bottom: 0.625rem;
  }
  .tb-pad-l15 {
    padding-left: 0.9375rem;
  }
  .tb-pad-r15 {
    padding-right: 0.9375rem;
  }
  .tb-pad-t15 {
    padding-top: 0.9375rem;
  }
  .tb-pad-b15 {
    padding-bottom: 0.9375rem;
  }
  .tb-pad-l20 {
    padding-left: 1.25rem;
  }
  .tb-pad-r20 {
    padding-right: 1.25rem;
  }
  .tb-pad-t20 {
    padding-top: 1.25rem;
  }
  .tb-pad-b20 {
    padding-bottom: 1.25rem;
  }
  .tb-pad-l30 {
    padding-left: 1.875rem;
  }
  .tb-pad-r30 {
    padding-right: 1.875rem;
  }
  .tb-pad-t30 {
    padding-top: 1.875rem;
  }
  .tb-pad-b30 {
    padding-bottom: 1.875rem;
  }
  .tb-pad-l40 {
    padding-left: 2.5rem;
  }
  .tb-pad-r40 {
    padding-right: 2.5rem;
  }
  .tb-pad-t40 {
    padding-top: 2.5rem;
  }
  .tb-pad-b40 {
    padding-bottom: 2.5rem;
  }
  .tb-pad-l50 {
    padding-left: 3.125rem;
  }
  .tb-pad-r50 {
    padding-right: 3.125rem;
  }
  .tb-pad-t50 {
    padding-top: 3.125rem;
  }
  .tb-pad-b50 {
    padding-bottom: 3.125rem;
  }
}

@media screen and (min-width: 64em) {
  .dt-pad-0 {
    padding: 0;
  }
  .dt-pad-2 {
    padding: 0.125rem;
  }
  .dt-pad-5 {
    padding: 0.3125rem;
  }
  .dt-pad-10 {
    padding: 0.625rem;
  }
  .dt-pad-15 {
    padding: 0.9375rem;
  }
  .dt-pad-20 {
    padding: 1.25rem;
  }
  .dt-pad-30 {
    padding: 1.875rem;
  }
  .dt-pad-40 {
    padding: 2.5rem;
  }
  .dt-pad-50 {
    padding: 3.125rem;
  }
  .dt-pad-v0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .dt-pad-h0 {
    padding-left: 0;
    padding-right: 0;
  }
  .dt-pad-v2 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .dt-pad-h2 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .dt-pad-v5 {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }
  .dt-pad-h5 {
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
  }
  .dt-pad-v10 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .dt-pad-h10 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .dt-pad-v15 {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }
  .dt-pad-h15 {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
  .dt-pad-v20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .dt-pad-h20 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .dt-pad-v30 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }
  .dt-pad-h30 {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
  .dt-pad-v40 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .dt-pad-h40 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .dt-pad-v50 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }
  .dt-pad-h50 {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }
  .dt-pad-l0 {
    padding-left: 0;
  }
  .dt-pad-r0 {
    padding-right: 0;
  }
  .dt-pad-t0 {
    padding-top: 0;
  }
  .dt-pad-b0 {
    padding-bottom: 0;
  }
  .dt-pad-l2 {
    padding-left: 0.125rem;
  }
  .dt-pad-r2 {
    padding-right: 0.125rem;
  }
  .dt-pad-t2 {
    padding-top: 0.125rem;
  }
  .dt-pad-b2 {
    padding-bottom: 0.125rem;
  }
  .dt-pad-l5 {
    padding-left: 0.3125rem;
  }
  .dt-pad-r5 {
    padding-right: 0.3125rem;
  }
  .dt-pad-t5 {
    padding-top: 0.3125rem;
  }
  .dt-pad-b5 {
    padding-bottom: 0.3125rem;
  }
  .dt-pad-l10 {
    padding-left: 0.625rem;
  }
  .dt-pad-r10 {
    padding-right: 0.625rem;
  }
  .dt-pad-t10 {
    padding-top: 0.625rem;
  }
  .dt-pad-b10 {
    padding-bottom: 0.625rem;
  }
  .dt-pad-l15 {
    padding-left: 0.9375rem;
  }
  .dt-pad-r15 {
    padding-right: 0.9375rem;
  }
  .dt-pad-t15 {
    padding-top: 0.9375rem;
  }
  .dt-pad-b15 {
    padding-bottom: 0.9375rem;
  }
  .dt-pad-l20 {
    padding-left: 1.25rem;
  }
  .dt-pad-r20 {
    padding-right: 1.25rem;
  }
  .dt-pad-t20 {
    padding-top: 1.25rem;
  }
  .dt-pad-b20 {
    padding-bottom: 1.25rem;
  }
  .dt-pad-l30 {
    padding-left: 1.875rem;
  }
  .dt-pad-r30 {
    padding-right: 1.875rem;
  }
  .dt-pad-t30 {
    padding-top: 1.875rem;
  }
  .dt-pad-b30 {
    padding-bottom: 1.875rem;
  }
  .dt-pad-l40 {
    padding-left: 2.5rem;
  }
  .dt-pad-r40 {
    padding-right: 2.5rem;
  }
  .dt-pad-t40 {
    padding-top: 2.5rem;
  }
  .dt-pad-b40 {
    padding-bottom: 2.5rem;
  }
  .dt-pad-l50 {
    padding-left: 3.125rem;
  }
  .dt-pad-r50 {
    padding-right: 3.125rem;
  }
  .dt-pad-t50 {
    padding-top: 3.125rem;
  }
  .dt-pad-b50 {
    padding-bottom: 3.125rem;
  }
}

@media screen and (min-width: 75em) {
  .hg-pad-0 {
    padding: 0;
  }
  .hg-pad-2 {
    padding: 0.125rem;
  }
  .hg-pad-5 {
    padding: 0.3125rem;
  }
  .hg-pad-10 {
    padding: 0.625rem;
  }
  .hg-pad-15 {
    padding: 0.9375rem;
  }
  .hg-pad-20 {
    padding: 1.25rem;
  }
  .hg-pad-30 {
    padding: 1.875rem;
  }
  .hg-pad-40 {
    padding: 2.5rem;
  }
  .hg-pad-50 {
    padding: 3.125rem;
  }
  .hg-pad-v0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .hg-pad-h0 {
    padding-left: 0;
    padding-right: 0;
  }
  .hg-pad-v2 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .hg-pad-h2 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .hg-pad-v5 {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }
  .hg-pad-h5 {
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
  }
  .hg-pad-v10 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .hg-pad-h10 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .hg-pad-v15 {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }
  .hg-pad-h15 {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
  .hg-pad-v20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .hg-pad-h20 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .hg-pad-v30 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }
  .hg-pad-h30 {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
  .hg-pad-v40 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .hg-pad-h40 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .hg-pad-v50 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }
  .hg-pad-h50 {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }
  .hg-pad-l0 {
    padding-left: 0;
  }
  .hg-pad-r0 {
    padding-right: 0;
  }
  .hg-pad-t0 {
    padding-top: 0;
  }
  .hg-pad-b0 {
    padding-bottom: 0;
  }
  .hg-pad-l2 {
    padding-left: 0.125rem;
  }
  .hg-pad-r2 {
    padding-right: 0.125rem;
  }
  .hg-pad-t2 {
    padding-top: 0.125rem;
  }
  .hg-pad-b2 {
    padding-bottom: 0.125rem;
  }
  .hg-pad-l5 {
    padding-left: 0.3125rem;
  }
  .hg-pad-r5 {
    padding-right: 0.3125rem;
  }
  .hg-pad-t5 {
    padding-top: 0.3125rem;
  }
  .hg-pad-b5 {
    padding-bottom: 0.3125rem;
  }
  .hg-pad-l10 {
    padding-left: 0.625rem;
  }
  .hg-pad-r10 {
    padding-right: 0.625rem;
  }
  .hg-pad-t10 {
    padding-top: 0.625rem;
  }
  .hg-pad-b10 {
    padding-bottom: 0.625rem;
  }
  .hg-pad-l15 {
    padding-left: 0.9375rem;
  }
  .hg-pad-r15 {
    padding-right: 0.9375rem;
  }
  .hg-pad-t15 {
    padding-top: 0.9375rem;
  }
  .hg-pad-b15 {
    padding-bottom: 0.9375rem;
  }
  .hg-pad-l20 {
    padding-left: 1.25rem;
  }
  .hg-pad-r20 {
    padding-right: 1.25rem;
  }
  .hg-pad-t20 {
    padding-top: 1.25rem;
  }
  .hg-pad-b20 {
    padding-bottom: 1.25rem;
  }
  .hg-pad-l30 {
    padding-left: 1.875rem;
  }
  .hg-pad-r30 {
    padding-right: 1.875rem;
  }
  .hg-pad-t30 {
    padding-top: 1.875rem;
  }
  .hg-pad-b30 {
    padding-bottom: 1.875rem;
  }
  .hg-pad-l40 {
    padding-left: 2.5rem;
  }
  .hg-pad-r40 {
    padding-right: 2.5rem;
  }
  .hg-pad-t40 {
    padding-top: 2.5rem;
  }
  .hg-pad-b40 {
    padding-bottom: 2.5rem;
  }
  .hg-pad-l50 {
    padding-left: 3.125rem;
  }
  .hg-pad-r50 {
    padding-right: 3.125rem;
  }
  .hg-pad-t50 {
    padding-top: 3.125rem;
  }
  .hg-pad-b50 {
    padding-bottom: 3.125rem;
  }
}

.disp-i {
  display: inline;
}

.disp-ib {
  display: inline-block;
}

.disp-b {
  display: block;
}

.disp-tb {
  display: table;
}

.disp-tc {
  display: table-cell;
}

.disp-n {
  display: none;
}

.disp-f {
  display: flex;
}

.disp-if {
  display: inline-flex;
}

body[data-site-id="zh_HK"] .hide-for-hk {
  display: none !important;
}

body[data-site-id="zh_CN"] .show-for-hk {
  display: none !important;
}

body[data-site-id="en_US"] .show-for-hk {
  display: none !important;
}

body[data-site-id="zh_CN"] .hide-for-cn {
  display: none !important;
}

body[data-site-id="zh_HK"] .show-for-cn {
  display: none !important;
}

body[data-site-id="en_US"] .show-for-cn {
  display: none !important;
}

body[data-site-id="en_US"] .hide-for-row {
  display: none !important;
}

body[data-site-id="zh_HK"] .show-for-row {
  display: none !important;
}

body[data-site-id="zh_CN"] .show-for-row {
  display: none !important;
}

html:not(.lc-app) .show-for-app {
  display: none !important;
}

html.lc-app .hide-for-app {
  display: none !important;
}

html:not(.wechat-inapp) .show-for-wechat {
  display: none !important;
}

html.wechat-inapp .hide-for-wechat {
  display: none !important;
}

html:not(.web) .show-for-web {
  display: none !important;
}

html.web .hide-for-web {
  display: none !important;
}

@media print, screen and (min-width: 48em) {
  .tb-disp-i {
    display: inline;
  }
  .tb-disp-ib {
    display: inline-block;
  }
  .tb-disp-b {
    display: block;
  }
  .tb-disp-tb {
    display: table;
  }
  .tb-disp-tc {
    display: table-cell;
  }
  .tb-disp-n {
    display: none;
  }
  .tb-disp-f {
    display: flex;
  }
  .tb-disp-if {
    display: inline-flex;
  }
}

@media screen and (min-width: 64em) {
  .dt-disp-i {
    display: inline;
  }
  .dt-disp-ib {
    display: inline-block;
  }
  .dt-disp-b {
    display: block;
  }
  .dt-disp-tb {
    display: table;
  }
  .dt-disp-tc {
    display: table-cell;
  }
  .dt-disp-n {
    display: none;
  }
  .dt-disp-f {
    display: flex;
  }
  .dt-disp-if {
    display: inline-flex;
  }
}

@media screen and (min-width: 75em) {
  .hg-disp-i {
    display: inline;
  }
  .hg-disp-ib {
    display: inline-block;
  }
  .hg-disp-b {
    display: block;
  }
  .hg-disp-tb {
    display: table;
  }
  .hg-disp-tc {
    display: table-cell;
  }
  .hg-disp-n {
    display: none;
  }
  .hg-disp-f {
    display: flex;
  }
  .hg-disp-if {
    display: inline-flex;
  }
}

@media screen and (max-width: 47.9375em) {
  .mb-only-disp-i {
    display: inline;
  }
  .mb-only-disp-ib {
    display: inline-block;
  }
  .mb-only-disp-b {
    display: block;
  }
  .mb-only-disp-tb {
    display: table;
  }
  .mb-only-disp-tc {
    display: table-cell;
  }
  .mb-only-disp-n {
    display: none;
  }
  .mb-only-disp-f {
    display: flex;
  }
  .mb-only-disp-if {
    display: inline-flex;
  }
}

@media print, screen and (min-width: 48em) and (max-width: 63.9375em) {
  .tb-only-disp-i {
    display: inline;
  }
  .tb-only-disp-ib {
    display: inline-block;
  }
  .tb-only-disp-b {
    display: block;
  }
  .tb-only-disp-tb {
    display: table;
  }
  .tb-only-disp-tc {
    display: table-cell;
  }
  .tb-only-disp-n {
    display: none;
  }
  .tb-only-disp-f {
    display: flex;
  }
  .tb-only-disp-if {
    display: inline-flex;
  }
}

.float-l {
  float: left;
}

.float-r {
  float: right;
}

.float-n {
  float: none;
}

@media print, screen and (min-width: 48em) {
  .tb-float-l {
    float: left;
  }
  .tb-float-r {
    float: right;
  }
  .tb-float-n {
    float: none;
  }
}

@media screen and (min-width: 64em) {
  .dt-float-l {
    float: left;
  }
  .dt-float-r {
    float: right;
  }
  .dt-float-n {
    float: none;
  }
}

@media screen and (min-width: 75em) {
  .hg-float-l {
    float: left;
  }
  .hg-float-r {
    float: right;
  }
  .hg-float-n {
    float: none;
  }
}

@media screen and (max-width: 47.9375em) {
  .mb-only-float-l {
    float: left;
  }
  .mb-only-float-r {
    float: right;
  }
  .mb-only-float-n {
    float: none;
  }
}

@media print, screen and (min-width: 48em) and (max-width: 63.9375em) {
  .tb-only-float-l {
    float: left;
  }
  .tb-only-float-r {
    float: right;
  }
  .tb-only-float-n {
    float: none;
  }
}

.pos-r {
  position: relative;
}

.pos-a {
  position: absolute;
}

.pos-s {
  position: static;
}

.pos-f {
  position: fixed;
}

.pos-stky {
  position: -webkit-sticky;
  position: sticky;
}

@media print, screen and (min-width: 48em) {
  .tb-pos-r {
    position: relative;
  }
  .tb-pos-a {
    position: absolute;
  }
  .tb-pos-s {
    position: static;
  }
  .tb-pos-f {
    position: fixed;
  }
  .tb-pos-stky {
    position: -webkit-sticky;
    position: sticky;
  }
}

@media screen and (min-width: 64em) {
  .dt-pos-r {
    position: relative;
  }
  .dt-pos-a {
    position: absolute;
  }
  .dt-pos-s {
    position: static;
  }
  .dt-pos-f {
    position: fixed;
  }
  .dt-pos-stky {
    position: -webkit-sticky;
    position: sticky;
  }
}

@media screen and (min-width: 75em) {
  .hg-pos-r {
    position: relative;
  }
  .hg-pos-a {
    position: absolute;
  }
  .hg-pos-s {
    position: static;
  }
  .hg-pos-f {
    position: fixed;
  }
  .hg-pos-stky {
    position: -webkit-sticky;
    position: sticky;
  }
}

@media screen and (max-width: 47.9375em) {
  .mb-only-pos-r {
    position: relative;
  }
  .mb-only-pos-a {
    position: absolute;
  }
  .mb-only-pos-s {
    position: static;
  }
  .mb-only-pos-f {
    position: fixed;
  }
  .mb-only-pos-stky {
    position: -webkit-sticky;
    position: sticky;
  }
}

@media print, screen and (min-width: 48em) and (max-width: 63.9375em) {
  .tb-only-pos-r {
    position: relative;
  }
  .tb-only-pos-a {
    position: absolute;
  }
  .tb-only-pos-s {
    position: static;
  }
  .tb-only-pos-f {
    position: fixed;
  }
  .tb-only-pos-stky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.t-0 {
  top: 0px;
}

.t-2 {
  top: 2px;
}

.t-5 {
  top: 5px;
}

.t-10 {
  top: 10px;
}

.t-15 {
  top: 15px;
}

.t-20 {
  top: 20px;
}

.t-30 {
  top: 30px;
}

.t-40 {
  top: 40px;
}

.t-50 {
  top: 50px;
}

.r-0 {
  right: 0px;
}

.r-2 {
  right: 2px;
}

.r-5 {
  right: 5px;
}

.r-10 {
  right: 10px;
}

.r-15 {
  right: 15px;
}

.r-20 {
  right: 20px;
}

.r-30 {
  right: 30px;
}

.r-40 {
  right: 40px;
}

.r-50 {
  right: 50px;
}

.b-0 {
  bottom: 0px;
}

.b-2 {
  bottom: 2px;
}

.b-5 {
  bottom: 5px;
}

.b-10 {
  bottom: 10px;
}

.b-15 {
  bottom: 15px;
}

.b-20 {
  bottom: 20px;
}

.b-30 {
  bottom: 30px;
}

.b-40 {
  bottom: 40px;
}

.b-50 {
  bottom: 50px;
}

.l-0 {
  left: 0px;
}

.l-2 {
  left: 2px;
}

.l-5 {
  left: 5px;
}

.l-10 {
  left: 10px;
}

.l-15 {
  left: 15px;
}

.l-20 {
  left: 20px;
}

.l-30 {
  left: 30px;
}

.l-40 {
  left: 40px;
}

.l-50 {
  left: 50px;
}

.t-0p {
  top: 0%;
}

.t-5p {
  top: 5%;
}

.t-10p {
  top: 10%;
}

.t-15p {
  top: 15%;
}

.t-20p {
  top: 20%;
}

.t-25p {
  top: 25%;
}

.t-30p {
  top: 30%;
}

.t-35p {
  top: 35%;
}

.t-40p {
  top: 40%;
}

.t-45p {
  top: 45%;
}

.t-50p {
  top: 50%;
}

.t-55p {
  top: 55%;
}

.t-60p {
  top: 60%;
}

.t-65p {
  top: 65%;
}

.t-70p {
  top: 70%;
}

.t-75p {
  top: 75%;
}

.t-80p {
  top: 80%;
}

.t-85p {
  top: 85%;
}

.t-90p {
  top: 90%;
}

.t-95p {
  top: 95%;
}

.t-100p {
  top: 100%;
}

.r-0p {
  right: 0%;
}

.r-5p {
  right: 5%;
}

.r-10p {
  right: 10%;
}

.r-15p {
  right: 15%;
}

.r-20p {
  right: 20%;
}

.r-25p {
  right: 25%;
}

.r-30p {
  right: 30%;
}

.r-35p {
  right: 35%;
}

.r-40p {
  right: 40%;
}

.r-45p {
  right: 45%;
}

.r-50p {
  right: 50%;
}

.r-55p {
  right: 55%;
}

.r-60p {
  right: 60%;
}

.r-65p {
  right: 65%;
}

.r-70p {
  right: 70%;
}

.r-75p {
  right: 75%;
}

.r-80p {
  right: 80%;
}

.r-85p {
  right: 85%;
}

.r-90p {
  right: 90%;
}

.r-95p {
  right: 95%;
}

.r-100p {
  right: 100%;
}

.b-0p {
  bottom: 0%;
}

.b-5p {
  bottom: 5%;
}

.b-10p {
  bottom: 10%;
}

.b-15p {
  bottom: 15%;
}

.b-20p {
  bottom: 20%;
}

.b-25p {
  bottom: 25%;
}

.b-30p {
  bottom: 30%;
}

.b-35p {
  bottom: 35%;
}

.b-40p {
  bottom: 40%;
}

.b-45p {
  bottom: 45%;
}

.b-50p {
  bottom: 50%;
}

.b-55p {
  bottom: 55%;
}

.b-60p {
  bottom: 60%;
}

.b-65p {
  bottom: 65%;
}

.b-70p {
  bottom: 70%;
}

.b-75p {
  bottom: 75%;
}

.b-80p {
  bottom: 80%;
}

.b-85p {
  bottom: 85%;
}

.b-90p {
  bottom: 90%;
}

.b-95p {
  bottom: 95%;
}

.b-100p {
  bottom: 100%;
}

.l-0p {
  left: 0%;
}

.l-5p {
  left: 5%;
}

.l-10p {
  left: 10%;
}

.l-15p {
  left: 15%;
}

.l-20p {
  left: 20%;
}

.l-25p {
  left: 25%;
}

.l-30p {
  left: 30%;
}

.l-35p {
  left: 35%;
}

.l-40p {
  left: 40%;
}

.l-45p {
  left: 45%;
}

.l-50p {
  left: 50%;
}

.l-55p {
  left: 55%;
}

.l-60p {
  left: 60%;
}

.l-65p {
  left: 65%;
}

.l-70p {
  left: 70%;
}

.l-75p {
  left: 75%;
}

.l-80p {
  left: 80%;
}

.l-85p {
  left: 85%;
}

.l-90p {
  left: 90%;
}

.l-95p {
  left: 95%;
}

.l-100p {
  left: 100%;
}

@media print, screen and (min-width: 48em) {
  .tb-t-0 {
    top: 0px;
  }
  .tb-t-2 {
    top: 2px;
  }
  .tb-t-5 {
    top: 5px;
  }
  .tb-t-10 {
    top: 10px;
  }
  .tb-t-15 {
    top: 15px;
  }
  .tb-t-20 {
    top: 20px;
  }
  .tb-t-30 {
    top: 30px;
  }
  .tb-t-40 {
    top: 40px;
  }
  .tb-t-50 {
    top: 50px;
  }
  .tb-r-0 {
    right: 0px;
  }
  .tb-r-2 {
    right: 2px;
  }
  .tb-r-5 {
    right: 5px;
  }
  .tb-r-10 {
    right: 10px;
  }
  .tb-r-15 {
    right: 15px;
  }
  .tb-r-20 {
    right: 20px;
  }
  .tb-r-30 {
    right: 30px;
  }
  .tb-r-40 {
    right: 40px;
  }
  .tb-r-50 {
    right: 50px;
  }
  .tb-b-0 {
    bottom: 0px;
  }
  .tb-b-2 {
    bottom: 2px;
  }
  .tb-b-5 {
    bottom: 5px;
  }
  .tb-b-10 {
    bottom: 10px;
  }
  .tb-b-15 {
    bottom: 15px;
  }
  .tb-b-20 {
    bottom: 20px;
  }
  .tb-b-30 {
    bottom: 30px;
  }
  .tb-b-40 {
    bottom: 40px;
  }
  .tb-b-50 {
    bottom: 50px;
  }
  .tb-l-0 {
    left: 0px;
  }
  .tb-l-2 {
    left: 2px;
  }
  .tb-l-5 {
    left: 5px;
  }
  .tb-l-10 {
    left: 10px;
  }
  .tb-l-15 {
    left: 15px;
  }
  .tb-l-20 {
    left: 20px;
  }
  .tb-l-30 {
    left: 30px;
  }
  .tb-l-40 {
    left: 40px;
  }
  .tb-l-50 {
    left: 50px;
  }
  .tb-t-0p {
    top: 0%;
  }
  .tb-t-5p {
    top: 5%;
  }
  .tb-t-10p {
    top: 10%;
  }
  .tb-t-15p {
    top: 15%;
  }
  .tb-t-20p {
    top: 20%;
  }
  .tb-t-25p {
    top: 25%;
  }
  .tb-t-30p {
    top: 30%;
  }
  .tb-t-35p {
    top: 35%;
  }
  .tb-t-40p {
    top: 40%;
  }
  .tb-t-45p {
    top: 45%;
  }
  .tb-t-50p {
    top: 50%;
  }
  .tb-t-55p {
    top: 55%;
  }
  .tb-t-60p {
    top: 60%;
  }
  .tb-t-65p {
    top: 65%;
  }
  .tb-t-70p {
    top: 70%;
  }
  .tb-t-75p {
    top: 75%;
  }
  .tb-t-80p {
    top: 80%;
  }
  .tb-t-85p {
    top: 85%;
  }
  .tb-t-90p {
    top: 90%;
  }
  .tb-t-95p {
    top: 95%;
  }
  .tb-t-100p {
    top: 100%;
  }
  .tb-r-0p {
    right: 0%;
  }
  .tb-r-5p {
    right: 5%;
  }
  .tb-r-10p {
    right: 10%;
  }
  .tb-r-15p {
    right: 15%;
  }
  .tb-r-20p {
    right: 20%;
  }
  .tb-r-25p {
    right: 25%;
  }
  .tb-r-30p {
    right: 30%;
  }
  .tb-r-35p {
    right: 35%;
  }
  .tb-r-40p {
    right: 40%;
  }
  .tb-r-45p {
    right: 45%;
  }
  .tb-r-50p {
    right: 50%;
  }
  .tb-r-55p {
    right: 55%;
  }
  .tb-r-60p {
    right: 60%;
  }
  .tb-r-65p {
    right: 65%;
  }
  .tb-r-70p {
    right: 70%;
  }
  .tb-r-75p {
    right: 75%;
  }
  .tb-r-80p {
    right: 80%;
  }
  .tb-r-85p {
    right: 85%;
  }
  .tb-r-90p {
    right: 90%;
  }
  .tb-r-95p {
    right: 95%;
  }
  .tb-r-100p {
    right: 100%;
  }
  .tb-b-0p {
    bottom: 0%;
  }
  .tb-b-5p {
    bottom: 5%;
  }
  .tb-b-10p {
    bottom: 10%;
  }
  .tb-b-15p {
    bottom: 15%;
  }
  .tb-b-20p {
    bottom: 20%;
  }
  .tb-b-25p {
    bottom: 25%;
  }
  .tb-b-30p {
    bottom: 30%;
  }
  .tb-b-35p {
    bottom: 35%;
  }
  .tb-b-40p {
    bottom: 40%;
  }
  .tb-b-45p {
    bottom: 45%;
  }
  .tb-b-50p {
    bottom: 50%;
  }
  .tb-b-55p {
    bottom: 55%;
  }
  .tb-b-60p {
    bottom: 60%;
  }
  .tb-b-65p {
    bottom: 65%;
  }
  .tb-b-70p {
    bottom: 70%;
  }
  .tb-b-75p {
    bottom: 75%;
  }
  .tb-b-80p {
    bottom: 80%;
  }
  .tb-b-85p {
    bottom: 85%;
  }
  .tb-b-90p {
    bottom: 90%;
  }
  .tb-b-95p {
    bottom: 95%;
  }
  .tb-b-100p {
    bottom: 100%;
  }
  .tb-l-0p {
    left: 0%;
  }
  .tb-l-5p {
    left: 5%;
  }
  .tb-l-10p {
    left: 10%;
  }
  .tb-l-15p {
    left: 15%;
  }
  .tb-l-20p {
    left: 20%;
  }
  .tb-l-25p {
    left: 25%;
  }
  .tb-l-30p {
    left: 30%;
  }
  .tb-l-35p {
    left: 35%;
  }
  .tb-l-40p {
    left: 40%;
  }
  .tb-l-45p {
    left: 45%;
  }
  .tb-l-50p {
    left: 50%;
  }
  .tb-l-55p {
    left: 55%;
  }
  .tb-l-60p {
    left: 60%;
  }
  .tb-l-65p {
    left: 65%;
  }
  .tb-l-70p {
    left: 70%;
  }
  .tb-l-75p {
    left: 75%;
  }
  .tb-l-80p {
    left: 80%;
  }
  .tb-l-85p {
    left: 85%;
  }
  .tb-l-90p {
    left: 90%;
  }
  .tb-l-95p {
    left: 95%;
  }
  .tb-l-100p {
    left: 100%;
  }
}

@media screen and (min-width: 64em) {
  .dt-t-0 {
    top: 0px;
  }
  .dt-t-2 {
    top: 2px;
  }
  .dt-t-5 {
    top: 5px;
  }
  .dt-t-10 {
    top: 10px;
  }
  .dt-t-15 {
    top: 15px;
  }
  .dt-t-20 {
    top: 20px;
  }
  .dt-t-30 {
    top: 30px;
  }
  .dt-t-40 {
    top: 40px;
  }
  .dt-t-50 {
    top: 50px;
  }
  .dt-r-0 {
    right: 0px;
  }
  .dt-r-2 {
    right: 2px;
  }
  .dt-r-5 {
    right: 5px;
  }
  .dt-r-10 {
    right: 10px;
  }
  .dt-r-15 {
    right: 15px;
  }
  .dt-r-20 {
    right: 20px;
  }
  .dt-r-30 {
    right: 30px;
  }
  .dt-r-40 {
    right: 40px;
  }
  .dt-r-50 {
    right: 50px;
  }
  .dt-b-0 {
    bottom: 0px;
  }
  .dt-b-2 {
    bottom: 2px;
  }
  .dt-b-5 {
    bottom: 5px;
  }
  .dt-b-10 {
    bottom: 10px;
  }
  .dt-b-15 {
    bottom: 15px;
  }
  .dt-b-20 {
    bottom: 20px;
  }
  .dt-b-30 {
    bottom: 30px;
  }
  .dt-b-40 {
    bottom: 40px;
  }
  .dt-b-50 {
    bottom: 50px;
  }
  .dt-l-0 {
    left: 0px;
  }
  .dt-l-2 {
    left: 2px;
  }
  .dt-l-5 {
    left: 5px;
  }
  .dt-l-10 {
    left: 10px;
  }
  .dt-l-15 {
    left: 15px;
  }
  .dt-l-20 {
    left: 20px;
  }
  .dt-l-30 {
    left: 30px;
  }
  .dt-l-40 {
    left: 40px;
  }
  .dt-l-50 {
    left: 50px;
  }
  .dt-t-0p {
    top: 0%;
  }
  .dt-t-5p {
    top: 5%;
  }
  .dt-t-10p {
    top: 10%;
  }
  .dt-t-15p {
    top: 15%;
  }
  .dt-t-20p {
    top: 20%;
  }
  .dt-t-25p {
    top: 25%;
  }
  .dt-t-30p {
    top: 30%;
  }
  .dt-t-35p {
    top: 35%;
  }
  .dt-t-40p {
    top: 40%;
  }
  .dt-t-45p {
    top: 45%;
  }
  .dt-t-50p {
    top: 50%;
  }
  .dt-t-55p {
    top: 55%;
  }
  .dt-t-60p {
    top: 60%;
  }
  .dt-t-65p {
    top: 65%;
  }
  .dt-t-70p {
    top: 70%;
  }
  .dt-t-75p {
    top: 75%;
  }
  .dt-t-80p {
    top: 80%;
  }
  .dt-t-85p {
    top: 85%;
  }
  .dt-t-90p {
    top: 90%;
  }
  .dt-t-95p {
    top: 95%;
  }
  .dt-t-100p {
    top: 100%;
  }
  .dt-r-0p {
    right: 0%;
  }
  .dt-r-5p {
    right: 5%;
  }
  .dt-r-10p {
    right: 10%;
  }
  .dt-r-15p {
    right: 15%;
  }
  .dt-r-20p {
    right: 20%;
  }
  .dt-r-25p {
    right: 25%;
  }
  .dt-r-30p {
    right: 30%;
  }
  .dt-r-35p {
    right: 35%;
  }
  .dt-r-40p {
    right: 40%;
  }
  .dt-r-45p {
    right: 45%;
  }
  .dt-r-50p {
    right: 50%;
  }
  .dt-r-55p {
    right: 55%;
  }
  .dt-r-60p {
    right: 60%;
  }
  .dt-r-65p {
    right: 65%;
  }
  .dt-r-70p {
    right: 70%;
  }
  .dt-r-75p {
    right: 75%;
  }
  .dt-r-80p {
    right: 80%;
  }
  .dt-r-85p {
    right: 85%;
  }
  .dt-r-90p {
    right: 90%;
  }
  .dt-r-95p {
    right: 95%;
  }
  .dt-r-100p {
    right: 100%;
  }
  .dt-b-0p {
    bottom: 0%;
  }
  .dt-b-5p {
    bottom: 5%;
  }
  .dt-b-10p {
    bottom: 10%;
  }
  .dt-b-15p {
    bottom: 15%;
  }
  .dt-b-20p {
    bottom: 20%;
  }
  .dt-b-25p {
    bottom: 25%;
  }
  .dt-b-30p {
    bottom: 30%;
  }
  .dt-b-35p {
    bottom: 35%;
  }
  .dt-b-40p {
    bottom: 40%;
  }
  .dt-b-45p {
    bottom: 45%;
  }
  .dt-b-50p {
    bottom: 50%;
  }
  .dt-b-55p {
    bottom: 55%;
  }
  .dt-b-60p {
    bottom: 60%;
  }
  .dt-b-65p {
    bottom: 65%;
  }
  .dt-b-70p {
    bottom: 70%;
  }
  .dt-b-75p {
    bottom: 75%;
  }
  .dt-b-80p {
    bottom: 80%;
  }
  .dt-b-85p {
    bottom: 85%;
  }
  .dt-b-90p {
    bottom: 90%;
  }
  .dt-b-95p {
    bottom: 95%;
  }
  .dt-b-100p {
    bottom: 100%;
  }
  .dt-l-0p {
    left: 0%;
  }
  .dt-l-5p {
    left: 5%;
  }
  .dt-l-10p {
    left: 10%;
  }
  .dt-l-15p {
    left: 15%;
  }
  .dt-l-20p {
    left: 20%;
  }
  .dt-l-25p {
    left: 25%;
  }
  .dt-l-30p {
    left: 30%;
  }
  .dt-l-35p {
    left: 35%;
  }
  .dt-l-40p {
    left: 40%;
  }
  .dt-l-45p {
    left: 45%;
  }
  .dt-l-50p {
    left: 50%;
  }
  .dt-l-55p {
    left: 55%;
  }
  .dt-l-60p {
    left: 60%;
  }
  .dt-l-65p {
    left: 65%;
  }
  .dt-l-70p {
    left: 70%;
  }
  .dt-l-75p {
    left: 75%;
  }
  .dt-l-80p {
    left: 80%;
  }
  .dt-l-85p {
    left: 85%;
  }
  .dt-l-90p {
    left: 90%;
  }
  .dt-l-95p {
    left: 95%;
  }
  .dt-l-100p {
    left: 100%;
  }
}

@media screen and (min-width: 75em) {
  .hg-t-0 {
    top: 0px;
  }
  .hg-t-2 {
    top: 2px;
  }
  .hg-t-5 {
    top: 5px;
  }
  .hg-t-10 {
    top: 10px;
  }
  .hg-t-15 {
    top: 15px;
  }
  .hg-t-20 {
    top: 20px;
  }
  .hg-t-30 {
    top: 30px;
  }
  .hg-t-40 {
    top: 40px;
  }
  .hg-t-50 {
    top: 50px;
  }
  .hg-r-0 {
    right: 0px;
  }
  .hg-r-2 {
    right: 2px;
  }
  .hg-r-5 {
    right: 5px;
  }
  .hg-r-10 {
    right: 10px;
  }
  .hg-r-15 {
    right: 15px;
  }
  .hg-r-20 {
    right: 20px;
  }
  .hg-r-30 {
    right: 30px;
  }
  .hg-r-40 {
    right: 40px;
  }
  .hg-r-50 {
    right: 50px;
  }
  .hg-b-0 {
    bottom: 0px;
  }
  .hg-b-2 {
    bottom: 2px;
  }
  .hg-b-5 {
    bottom: 5px;
  }
  .hg-b-10 {
    bottom: 10px;
  }
  .hg-b-15 {
    bottom: 15px;
  }
  .hg-b-20 {
    bottom: 20px;
  }
  .hg-b-30 {
    bottom: 30px;
  }
  .hg-b-40 {
    bottom: 40px;
  }
  .hg-b-50 {
    bottom: 50px;
  }
  .hg-l-0 {
    left: 0px;
  }
  .hg-l-2 {
    left: 2px;
  }
  .hg-l-5 {
    left: 5px;
  }
  .hg-l-10 {
    left: 10px;
  }
  .hg-l-15 {
    left: 15px;
  }
  .hg-l-20 {
    left: 20px;
  }
  .hg-l-30 {
    left: 30px;
  }
  .hg-l-40 {
    left: 40px;
  }
  .hg-l-50 {
    left: 50px;
  }
  .hg-t-0p {
    top: 0%;
  }
  .hg-t-5p {
    top: 5%;
  }
  .hg-t-10p {
    top: 10%;
  }
  .hg-t-15p {
    top: 15%;
  }
  .hg-t-20p {
    top: 20%;
  }
  .hg-t-25p {
    top: 25%;
  }
  .hg-t-30p {
    top: 30%;
  }
  .hg-t-35p {
    top: 35%;
  }
  .hg-t-40p {
    top: 40%;
  }
  .hg-t-45p {
    top: 45%;
  }
  .hg-t-50p {
    top: 50%;
  }
  .hg-t-55p {
    top: 55%;
  }
  .hg-t-60p {
    top: 60%;
  }
  .hg-t-65p {
    top: 65%;
  }
  .hg-t-70p {
    top: 70%;
  }
  .hg-t-75p {
    top: 75%;
  }
  .hg-t-80p {
    top: 80%;
  }
  .hg-t-85p {
    top: 85%;
  }
  .hg-t-90p {
    top: 90%;
  }
  .hg-t-95p {
    top: 95%;
  }
  .hg-t-100p {
    top: 100%;
  }
  .hg-r-0p {
    right: 0%;
  }
  .hg-r-5p {
    right: 5%;
  }
  .hg-r-10p {
    right: 10%;
  }
  .hg-r-15p {
    right: 15%;
  }
  .hg-r-20p {
    right: 20%;
  }
  .hg-r-25p {
    right: 25%;
  }
  .hg-r-30p {
    right: 30%;
  }
  .hg-r-35p {
    right: 35%;
  }
  .hg-r-40p {
    right: 40%;
  }
  .hg-r-45p {
    right: 45%;
  }
  .hg-r-50p {
    right: 50%;
  }
  .hg-r-55p {
    right: 55%;
  }
  .hg-r-60p {
    right: 60%;
  }
  .hg-r-65p {
    right: 65%;
  }
  .hg-r-70p {
    right: 70%;
  }
  .hg-r-75p {
    right: 75%;
  }
  .hg-r-80p {
    right: 80%;
  }
  .hg-r-85p {
    right: 85%;
  }
  .hg-r-90p {
    right: 90%;
  }
  .hg-r-95p {
    right: 95%;
  }
  .hg-r-100p {
    right: 100%;
  }
  .hg-b-0p {
    bottom: 0%;
  }
  .hg-b-5p {
    bottom: 5%;
  }
  .hg-b-10p {
    bottom: 10%;
  }
  .hg-b-15p {
    bottom: 15%;
  }
  .hg-b-20p {
    bottom: 20%;
  }
  .hg-b-25p {
    bottom: 25%;
  }
  .hg-b-30p {
    bottom: 30%;
  }
  .hg-b-35p {
    bottom: 35%;
  }
  .hg-b-40p {
    bottom: 40%;
  }
  .hg-b-45p {
    bottom: 45%;
  }
  .hg-b-50p {
    bottom: 50%;
  }
  .hg-b-55p {
    bottom: 55%;
  }
  .hg-b-60p {
    bottom: 60%;
  }
  .hg-b-65p {
    bottom: 65%;
  }
  .hg-b-70p {
    bottom: 70%;
  }
  .hg-b-75p {
    bottom: 75%;
  }
  .hg-b-80p {
    bottom: 80%;
  }
  .hg-b-85p {
    bottom: 85%;
  }
  .hg-b-90p {
    bottom: 90%;
  }
  .hg-b-95p {
    bottom: 95%;
  }
  .hg-b-100p {
    bottom: 100%;
  }
  .hg-l-0p {
    left: 0%;
  }
  .hg-l-5p {
    left: 5%;
  }
  .hg-l-10p {
    left: 10%;
  }
  .hg-l-15p {
    left: 15%;
  }
  .hg-l-20p {
    left: 20%;
  }
  .hg-l-25p {
    left: 25%;
  }
  .hg-l-30p {
    left: 30%;
  }
  .hg-l-35p {
    left: 35%;
  }
  .hg-l-40p {
    left: 40%;
  }
  .hg-l-45p {
    left: 45%;
  }
  .hg-l-50p {
    left: 50%;
  }
  .hg-l-55p {
    left: 55%;
  }
  .hg-l-60p {
    left: 60%;
  }
  .hg-l-65p {
    left: 65%;
  }
  .hg-l-70p {
    left: 70%;
  }
  .hg-l-75p {
    left: 75%;
  }
  .hg-l-80p {
    left: 80%;
  }
  .hg-l-85p {
    left: 85%;
  }
  .hg-l-90p {
    left: 90%;
  }
  .hg-l-95p {
    left: 95%;
  }
  .hg-l-100p {
    left: 100%;
  }
}

@media screen and (max-width: 47.9375em) {
  .mb-only-t-0 {
    top: 0px;
  }
  .mb-only-t-2 {
    top: 2px;
  }
  .mb-only-t-5 {
    top: 5px;
  }
  .mb-only-t-10 {
    top: 10px;
  }
  .mb-only-t-15 {
    top: 15px;
  }
  .mb-only-t-20 {
    top: 20px;
  }
  .mb-only-t-30 {
    top: 30px;
  }
  .mb-only-t-40 {
    top: 40px;
  }
  .mb-only-t-50 {
    top: 50px;
  }
  .mb-only-r-0 {
    right: 0px;
  }
  .mb-only-r-2 {
    right: 2px;
  }
  .mb-only-r-5 {
    right: 5px;
  }
  .mb-only-r-10 {
    right: 10px;
  }
  .mb-only-r-15 {
    right: 15px;
  }
  .mb-only-r-20 {
    right: 20px;
  }
  .mb-only-r-30 {
    right: 30px;
  }
  .mb-only-r-40 {
    right: 40px;
  }
  .mb-only-r-50 {
    right: 50px;
  }
  .mb-only-b-0 {
    bottom: 0px;
  }
  .mb-only-b-2 {
    bottom: 2px;
  }
  .mb-only-b-5 {
    bottom: 5px;
  }
  .mb-only-b-10 {
    bottom: 10px;
  }
  .mb-only-b-15 {
    bottom: 15px;
  }
  .mb-only-b-20 {
    bottom: 20px;
  }
  .mb-only-b-30 {
    bottom: 30px;
  }
  .mb-only-b-40 {
    bottom: 40px;
  }
  .mb-only-b-50 {
    bottom: 50px;
  }
  .mb-only-l-0 {
    left: 0px;
  }
  .mb-only-l-2 {
    left: 2px;
  }
  .mb-only-l-5 {
    left: 5px;
  }
  .mb-only-l-10 {
    left: 10px;
  }
  .mb-only-l-15 {
    left: 15px;
  }
  .mb-only-l-20 {
    left: 20px;
  }
  .mb-only-l-30 {
    left: 30px;
  }
  .mb-only-l-40 {
    left: 40px;
  }
  .mb-only-l-50 {
    left: 50px;
  }
}

@media print, screen and (min-width: 48em) and (max-width: 63.9375em) {
  .tb-only-t-0 {
    top: 0px;
  }
  .tb-only-t-2 {
    top: 2px;
  }
  .tb-only-t-5 {
    top: 5px;
  }
  .tb-only-t-10 {
    top: 10px;
  }
  .tb-only-t-15 {
    top: 15px;
  }
  .tb-only-t-20 {
    top: 20px;
  }
  .tb-only-t-30 {
    top: 30px;
  }
  .tb-only-t-40 {
    top: 40px;
  }
  .tb-only-t-50 {
    top: 50px;
  }
  .tb-only-r-0 {
    right: 0px;
  }
  .tb-only-r-2 {
    right: 2px;
  }
  .tb-only-r-5 {
    right: 5px;
  }
  .tb-only-r-10 {
    right: 10px;
  }
  .tb-only-r-15 {
    right: 15px;
  }
  .tb-only-r-20 {
    right: 20px;
  }
  .tb-only-r-30 {
    right: 30px;
  }
  .tb-only-r-40 {
    right: 40px;
  }
  .tb-only-r-50 {
    right: 50px;
  }
  .tb-only-b-0 {
    bottom: 0px;
  }
  .tb-only-b-2 {
    bottom: 2px;
  }
  .tb-only-b-5 {
    bottom: 5px;
  }
  .tb-only-b-10 {
    bottom: 10px;
  }
  .tb-only-b-15 {
    bottom: 15px;
  }
  .tb-only-b-20 {
    bottom: 20px;
  }
  .tb-only-b-30 {
    bottom: 30px;
  }
  .tb-only-b-40 {
    bottom: 40px;
  }
  .tb-only-b-50 {
    bottom: 50px;
  }
  .tb-only-l-0 {
    left: 0px;
  }
  .tb-only-l-2 {
    left: 2px;
  }
  .tb-only-l-5 {
    left: 5px;
  }
  .tb-only-l-10 {
    left: 10px;
  }
  .tb-only-l-15 {
    left: 15px;
  }
  .tb-only-l-20 {
    left: 20px;
  }
  .tb-only-l-30 {
    left: 30px;
  }
  .tb-only-l-40 {
    left: 40px;
  }
  .tb-only-l-50 {
    left: 50px;
  }
}

.txt-l {
  text-align: left;
}

.txt-r {
  text-align: right;
}

.txt-c {
  text-align: center;
}

.txt-j {
  text-align: justify;
}

.va-t {
  vertical-align: top;
}

.va-b {
  vertical-align: bottom;
}

.va-m {
  vertical-align: middle;
}

.va-base {
  vertical-align: baseline;
}

.va-sub {
  vertical-align: sub;
}

.va-sup {
  vertical-align: super;
}

@media print, screen and (min-width: 48em) {
  .tb-txt-l {
    text-align: left;
  }
  .tb-txt-r {
    text-align: right;
  }
  .tb-txt-c {
    text-align: center;
  }
  .tb-txt-j {
    text-align: justify;
  }
}

@media screen and (min-width: 64em) {
  .dt-txt-l {
    text-align: left;
  }
  .dt-txt-r {
    text-align: right;
  }
  .dt-txt-c {
    text-align: center;
  }
  .dt-txt-j {
    text-align: justify;
  }
}

@media screen and (min-width: 75em) {
  .hg-txt-l {
    text-align: left;
  }
  .hg-txt-r {
    text-align: right;
  }
  .hg-txt-c {
    text-align: center;
  }
  .hg-txt-j {
    text-align: justify;
  }
}

@media screen and (max-width: 47.9375em) {
  .mb-only-txt-l {
    text-align: left;
  }
  .mb-only-txt-r {
    text-align: right;
  }
  .mb-only-txt-c {
    text-align: center;
  }
  .mb-only-txt-j {
    text-align: justify;
  }
}

@media print, screen and (min-width: 48em) and (max-width: 63.9375em) {
  .tb-only-txt-l {
    text-align: left;
  }
  .tb-only-txt-r {
    text-align: right;
  }
  .tb-only-txt-c {
    text-align: center;
  }
  .tb-only-txt-j {
    text-align: justify;
  }
}

.gap-0 {
  /* stylelint-disable-next-line */
  gap: 0;
}

.gap-2 {
  /* stylelint-disable-next-line */
  gap: 0.125rem;
}

.gap-5 {
  /* stylelint-disable-next-line */
  gap: 0.3125rem;
}

.gap-10 {
  /* stylelint-disable-next-line */
  gap: 0.625rem;
}

.gap-15 {
  /* stylelint-disable-next-line */
  gap: 0.9375rem;
}

.gap-20 {
  /* stylelint-disable-next-line */
  gap: 1.25rem;
}

.gap-30 {
  /* stylelint-disable-next-line */
  gap: 1.875rem;
}

.gap-40 {
  /* stylelint-disable-next-line */
  gap: 2.5rem;
}

.gap-50 {
  /* stylelint-disable-next-line */
  gap: 3.125rem;
}

@media print, screen and (min-width: 48em) {
  .tb-gap-0 {
    /* stylelint-disable-next-line */
    gap: 0;
  }
  .tb-gap-2 {
    /* stylelint-disable-next-line */
    gap: 0.125rem;
  }
  .tb-gap-5 {
    /* stylelint-disable-next-line */
    gap: 0.3125rem;
  }
  .tb-gap-10 {
    /* stylelint-disable-next-line */
    gap: 0.625rem;
  }
  .tb-gap-15 {
    /* stylelint-disable-next-line */
    gap: 0.9375rem;
  }
  .tb-gap-20 {
    /* stylelint-disable-next-line */
    gap: 1.25rem;
  }
  .tb-gap-30 {
    /* stylelint-disable-next-line */
    gap: 1.875rem;
  }
  .tb-gap-40 {
    /* stylelint-disable-next-line */
    gap: 2.5rem;
  }
  .tb-gap-50 {
    /* stylelint-disable-next-line */
    gap: 3.125rem;
  }
}

@media screen and (min-width: 64em) {
  .dt-gap-0 {
    /* stylelint-disable-next-line */
    gap: 0;
  }
  .dt-gap-2 {
    /* stylelint-disable-next-line */
    gap: 0.125rem;
  }
  .dt-gap-5 {
    /* stylelint-disable-next-line */
    gap: 0.3125rem;
  }
  .dt-gap-10 {
    /* stylelint-disable-next-line */
    gap: 0.625rem;
  }
  .dt-gap-15 {
    /* stylelint-disable-next-line */
    gap: 0.9375rem;
  }
  .dt-gap-20 {
    /* stylelint-disable-next-line */
    gap: 1.25rem;
  }
  .dt-gap-30 {
    /* stylelint-disable-next-line */
    gap: 1.875rem;
  }
  .dt-gap-40 {
    /* stylelint-disable-next-line */
    gap: 2.5rem;
  }
  .dt-gap-50 {
    /* stylelint-disable-next-line */
    gap: 3.125rem;
  }
}

@media screen and (min-width: 75em) {
  .hg-gap-0 {
    /* stylelint-disable-next-line */
    gap: 0;
  }
  .hg-gap-2 {
    /* stylelint-disable-next-line */
    gap: 0.125rem;
  }
  .hg-gap-5 {
    /* stylelint-disable-next-line */
    gap: 0.3125rem;
  }
  .hg-gap-10 {
    /* stylelint-disable-next-line */
    gap: 0.625rem;
  }
  .hg-gap-15 {
    /* stylelint-disable-next-line */
    gap: 0.9375rem;
  }
  .hg-gap-20 {
    /* stylelint-disable-next-line */
    gap: 1.25rem;
  }
  .hg-gap-30 {
    /* stylelint-disable-next-line */
    gap: 1.875rem;
  }
  .hg-gap-40 {
    /* stylelint-disable-next-line */
    gap: 2.5rem;
  }
  .hg-gap-50 {
    /* stylelint-disable-next-line */
    gap: 3.125rem;
  }
}

.box-border {
  box-sizing: border-box;
}

.box-content {
  box-sizing: content-box;
}

.of-a {
  overflow: auto;
}

.of-s {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.of-h {
  overflow: hidden;
}

.of-v {
  overflow: visible;
}

.ofx-a {
  overflow-x: auto;
}

.ofx-s {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}

.ofx-h {
  overflow-x: hidden;
}

.ofx-v {
  overflow-x: visible;
}

.ofy-a {
  overflow-y: auto;
}

.ofy-s {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.ofy-h {
  overflow-y: hidden;
}

.ofy-v {
  overflow-y: visible;
}

@media print, screen and (min-width: 48em) {
  .tb-of-a {
    overflow: auto;
  }
  .tb-of-s {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .tb-of-h {
    overflow: hidden;
  }
  .tb-of-v {
    overflow: visible;
  }
  .tb-ofx-a {
    overflow-x: auto;
  }
  .tb-ofx-s {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .tb-ofx-h {
    overflow-x: hidden;
  }
  .tb-ofx-v {
    overflow-x: visible;
  }
  .tb-ofy-a {
    overflow-y: auto;
  }
  .tb-ofy-s {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .tb-ofy-h {
    overflow-y: hidden;
  }
  .tb-ofy-v {
    overflow-y: visible;
  }
}

@media screen and (min-width: 64em) {
  .dt-of-a {
    overflow: auto;
  }
  .dt-of-s {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .dt-of-h {
    overflow: hidden;
  }
  .dt-of-v {
    overflow: visible;
  }
  .dt-ofx-a {
    overflow-x: auto;
  }
  .dt-ofx-s {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .dt-ofx-h {
    overflow-x: hidden;
  }
  .dt-ofx-v {
    overflow-x: visible;
  }
  .dt-ofy-a {
    overflow-y: auto;
  }
  .dt-ofy-s {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .dt-ofy-h {
    overflow-y: hidden;
  }
  .dt-ofy-v {
    overflow-y: visible;
  }
}

@media screen and (min-width: 75em) {
  .hg-of-a {
    overflow: auto;
  }
  .hg-of-s {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .hg-of-h {
    overflow: hidden;
  }
  .hg-of-v {
    overflow: visible;
  }
  .hg-ofx-a {
    overflow-x: auto;
  }
  .hg-ofx-s {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .hg-ofx-h {
    overflow-x: hidden;
  }
  .hg-ofx-v {
    overflow-x: visible;
  }
  .hg-ofy-a {
    overflow-y: auto;
  }
  .hg-ofy-s {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .hg-ofy-h {
    overflow-y: hidden;
  }
  .hg-ofy-v {
    overflow-y: visible;
  }
}

.opc-100 {
  opacity: 1;
}

.opc-90 {
  opacity: 0.9;
}

.opc-80 {
  opacity: 0.8;
}

.opc-70 {
  opacity: 0.7;
}

.opc-60 {
  opacity: 0.6;
}

.opc-50 {
  opacity: 0.5;
}

.opc-40 {
  opacity: 0.4;
}

.opc-30 {
  opacity: 0.3;
}

.opc-20 {
  opacity: 0.2;
}

.opc-10 {
  opacity: 0.1;
}

.opc-0 {
  opacity: 0;
}

.pop-shadow {
  box-shadow: 0 2px 8px -3px #666;
}

.card-shadow {
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.5);
}

.subtle-shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.lc-table th,
.lc-table td {
  padding: 0.3125rem;
}

.lc-table th:not(:nth-last-of-type(1)),
.lc-table td:not(:nth-last-of-type(1)) {
  border-right: 1px dotted rgba(0, 0, 0, 0.6);
}

.lc-table thead th,
.lc-table thead td {
  border-bottom: 2px solid #000;
}

.lc-table tbody tr:nth-of-type(1) th,
.lc-table tbody tr:nth-of-type(1) td {
  border-top: 2px solid #000;
}

.lc-table thead + tbody tr:nth-of-type(1) th,
.lc-table thead + tbody tr:nth-of-type(1) td {
  border-top: none;
}

.lc-table tr:not(:nth-last-of-type(1)) th,
.lc-table tr:not(:nth-last-of-type(1)) td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.lc-table-details th {
  font-weight: inherit;
}

.lc-table-details th,
.lc-table-details td {
  padding: 0.625rem 0.3125rem;
}

.lc-table-details thead tr th,
.lc-table-details tr:not(:last-child) td {
  border-bottom: 1px solid #ccc;
}

.lc-table-elegant th,
.lc-table-elegant td {
  padding: 0.625rem 0.3125rem;
}

.lc-table-elegant thead th,
.lc-table-elegant thead td {
  border-bottom: 1px solid #000;
  border-top: 2px solid #000;
  padding: 1em 0.3125rem 0.9em;
}

.lc-table-elegant th:not(:nth-last-of-type(1)),
.lc-table-elegant td:not(:nth-last-of-type(1)) {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.lc-table-elegant tbody tr:nth-of-type(even) th,
.lc-table-elegant tbody tr:nth-of-type(even) td {
  background: #f4f4f4;
}

.table-wrapper {
  overflow: hidden;
  position: relative;
}

.table-wrapper table {
  max-width: 300%;
}

@media print, screen and (min-width: 48em) {
  .table-wrapper table {
    max-width: 200%;
  }
}

.table-wrapper.is-active:before, .table-wrapper.is-active:after {
  content: "";
  top: 0;
  bottom: 0;
  width: 1.25rem;
  height: auto;
  position: absolute;
  pointer-events: none;
  opacity: 1;
  z-index: 1;
  transition: opacity 0.1s cubic-bezier(0.43, 0, 0.36, 1);
}

.table-wrapper:before {
  left: 0;
  right: auto;
  box-shadow: inset 1.25rem 0 1.25rem -1.25rem #ccc;
}

.table-wrapper:after {
  left: auto;
  right: 0;
  box-shadow: inset -1.25rem 0 1.25rem -1.25rem #ccc;
}

.table-wrapper.at-start:before, .table-wrapper.at-end:after {
  opacity: 0;
}

.table-wrapper td:nth-of-type(1),
.table-wrapper th:nth-of-type(1) {
  min-width: 8rem;
  max-width: 18.75rem;
}

@media screen and (max-width: 359px) {
  .table-wrapper td:nth-of-type(1),
  .table-wrapper th:nth-of-type(1) {
    max-width: 9.375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .table-wrapper td:nth-of-type(1),
  .table-wrapper th:nth-of-type(1) {
    max-width: 11.875rem;
  }
}

.table-scroller {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 20px;
}

.lc-table-fixed {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.lc-table-fixed td:nth-of-type(1),
.lc-table-fixed th:nth-of-type(1) {
  border-right: 2px solid #000;
}

.lc-table-fixed td:not(:nth-of-type(1)), .lc-table-fixed td.not-fixed,
.lc-table-fixed th:not(:nth-of-type(1)),
.lc-table-fixed th.not-fixed {
  visibility: hidden;
}

.an-r-180 {
  transition: transform 0.33s cubic-bezier(0.43, 0, 0.36, 1);
  transform: rotateZ(0deg);
}

.an-r-180.go {
  transform: rotateZ(-180deg);
}

.an-r-360 {
  transition: transform 0.33s cubic-bezier(0.43, 0, 0.36, 1);
  transform: rotateZ(0deg);
}

.an-r-360.go {
  transform: rotateZ(-360deg);
}

/* stylelint-disable length-zero-no-unit */
.an-pop-out {
  transform: translateY(0rem);
}

.an-pop-out.go {
  transition: transform 0.2s cubic-bezier(0.605, 0.065, 0.935, 0.55), opacity 0.2s cubic-bezier(0.605, 0.065, 0.935, 0.55);
  transform: translateY(-1rem);
  opacity: 0;
}

.an-pop-out.go.far {
  transform: translateY(-3rem);
}

.an-pop-in {
  transform: translateY(1rem);
  opacity: 0;
}

.an-pop-in.far {
  transform: translateY(3rem);
}

.an-pop-in.go {
  transition: transform 0.2s cubic-bezier(0.08, 0.455, 0.375, 0.96), opacity 0.2s cubic-bezier(0.08, 0.455, 0.375, 0.96);
  transform: translateY(0rem);
  opacity: 1;
}

/* stylelint-disable length-zero-no-unit */
.an-drop-out {
  transform: translateY(0rem);
}

.an-drop-out.go {
  transition: transform 0.2s cubic-bezier(0.605, 0.065, 0.935, 0.55), opacity 0.2s cubic-bezier(0.605, 0.065, 0.935, 0.55);
  transform: translateY(1rem);
  opacity: 0;
}

.an-drop-out.go.far {
  transform: translateY(3rem);
}

.an-drop-in {
  transform: translateY(-1rem);
  opacity: 0;
}

.an-drop-in.far {
  transform: translateY(-3rem);
}

.an-drop-in.go {
  transition: transform 0.2s cubic-bezier(0.08, 0.455, 0.375, 0.96), opacity 0.2s cubic-bezier(0.08, 0.455, 0.375, 0.96);
  transform: translateY(0rem);
  opacity: 1;
}

.fade {
  transition: opacity 0.33s cubic-bezier(0.43, 0, 0.36, 1);
}

.fade-in {
  transition: opacity 0.33s cubic-bezier(0.08, 0.455, 0.375, 0.96);
}

.fade-out {
  transition: opacity 0.33s cubic-bezier(0.605, 0.065, 0.935, 0.55);
}

.an-height,
.an-slide {
  transition: height 0.33s cubic-bezier(0.32, 0.05, 0.66, 1), margin 0.33s cubic-bezier(0.32, 0.05, 0.66, 1), padding 0.33s cubic-bezier(0.32, 0.05, 0.66, 1);
}

@-webkit-keyframes fadeInOnLoad {
  from {
    opacity: 0;
  }
}

@keyframes fadeInOnLoad {
  from {
    opacity: 0;
  }
}

@-webkit-keyframes dropInOnLoad {
  from {
    transform: translateY(-0.625rem);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes dropInOnLoad {
  from {
    transform: translateY(-0.625rem);
  }
  to {
    transform: translateY(0);
  }
}

@-webkit-keyframes fadeAndDropInOnLoad {
  from {
    opacity: 0;
    transform: translateY(-0.625rem);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes fadeAndDropInOnLoad {
  from {
    opacity: 0;
    transform: translateY(-0.625rem);
  }
  to {
    transform: translateY(0);
  }
}

@-webkit-keyframes popInOnLoad {
  from {
    transform: translateY(1.25rem);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes popInOnLoad {
  from {
    transform: translateY(1.25rem);
  }
  to {
    transform: translateY(0);
  }
}

@-webkit-keyframes fadeAndPopInOnLoad {
  from {
    opacity: 0;
    transform: translateY(1.25rem);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes fadeAndPopInOnLoad {
  from {
    opacity: 0;
    transform: translateY(1.25rem);
  }
  to {
    transform: translateY(0);
  }
}

@-webkit-keyframes fadeAndShiftROnLoad {
  from {
    opacity: 0;
    transform: translateX(-1.25rem);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes fadeAndShiftROnLoad {
  from {
    opacity: 0;
    transform: translateX(-1.25rem);
  }
  to {
    transform: translateY(0);
  }
}

@-webkit-keyframes fadeAndShiftLOnLoad {
  from {
    opacity: 0;
    transform: translateX(1.25rem);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes fadeAndShiftLOnLoad {
  from {
    opacity: 0;
    transform: translateX(1.25rem);
  }
  to {
    transform: translateY(0);
  }
}

@-webkit-keyframes fadeAndGrowOnLoad {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

@keyframes fadeAndGrowOnLoad {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

.an-load-fade,
.an-load-drop,
.an-load-fade-drop,
.an-load-fade-pop,
.an-load-grow,
.an-load-fade-shift-r,
.an-load-fade-shift-l {
  -webkit-animation-duration: 0.33s;
          animation-duration: 0.33s;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.455, 0.375, 0.96);
          animation-timing-function: cubic-bezier(0.08, 0.455, 0.375, 0.96);
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
}

.an-load-fade {
  -webkit-animation-name: fadeInOnLoad;
          animation-name: fadeInOnLoad;
}

.an-load-drop {
  -webkit-animation-name: dropInOnLoad;
          animation-name: dropInOnLoad;
}

.an-load-fade-drop {
  -webkit-animation-name: fadeAndDropInOnLoad;
          animation-name: fadeAndDropInOnLoad;
}

.an-load-pop {
  -webkit-animation-name: popInOnLoad;
          animation-name: popInOnLoad;
}

.an-load-fade-pop {
  -webkit-animation-name: fadeAndPopInOnLoad;
          animation-name: fadeAndPopInOnLoad;
}

.an-load-fade-shift-r {
  -webkit-animation-name: fadeAndShiftROnLoad;
          animation-name: fadeAndShiftROnLoad;
}

.an-load-fade-shift-l {
  -webkit-animation-name: fadeAndShiftLOnLoad;
          animation-name: fadeAndShiftLOnLoad;
}

.an-load-grow {
  -webkit-animation-name: fadeAndGrowOnLoad;
          animation-name: fadeAndGrowOnLoad;
}

.an-short,
.an-short.go {
  transition-duration: 0.165s;
  -webkit-animation-duration: 0.33s;
          animation-duration: 0.33s;
}

.an-default,
.an-default.go {
  transition-duration: 0.33s;
  -webkit-animation-duration: 0.66s;
          animation-duration: 0.66s;
}

.an-long,
.an-long.go {
  transition-duration: 0.66s;
  -webkit-animation-duration: 1.32s;
          animation-duration: 1.32s;
}

.an-vlong,
.an-vlong.go {
  transition-duration: 1.32s;
  -webkit-animation-duration: 2.64s;
          animation-duration: 2.64s;
}

.an-ease-in,
.an-ease-in.go {
  transition-timing-function: cubic-bezier(0.605, 0.065, 0.935, 0.55);
  -webkit-animation-timing-function: cubic-bezier(0.605, 0.065, 0.935, 0.55);
          animation-timing-function: cubic-bezier(0.605, 0.065, 0.935, 0.55);
}

.an-ease-out,
.an-ease-out.go {
  transition-timing-function: cubic-bezier(0.08, 0.455, 0.375, 0.96);
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.455, 0.375, 0.96);
          animation-timing-function: cubic-bezier(0.08, 0.455, 0.375, 0.96);
}

.an-ease,
.an-ease.go {
  transition-timing-function: cubic-bezier(0.43, 0, 0.36, 1);
  -webkit-animation-timing-function: cubic-bezier(0.43, 0, 0.36, 1);
          animation-timing-function: cubic-bezier(0.43, 0, 0.36, 1);
}

.an-ease-bounce,
.an-ease-bounce.go {
  transition-timing-function: cubic-bezier(0.405, 2.6, 0.55, 0.995);
  -webkit-animation-timing-function: cubic-bezier(0.405, 2.6, 0.55, 0.995);
          animation-timing-function: cubic-bezier(0.405, 2.6, 0.55, 0.995);
}

.payment-logo {
  background-image: url( ../../dist/img/paymentCards.png );
  display: inline-block;
  min-height: 48px;
  width: 65px;
  height: 42px;
}

.payment-logo__master {
  background-position: -5px -4px;
}

.payment-logo__visa {
  background-position: -84px -4px;
}

.payment-logo__ae {
  background-position: -158px -4px;
}

.payment-logo__jcb {
  background-position: -229px -4px;
}

.payment-logo__union {
  background-position: -299px -4px;
}

.payment-logo__alipay {
  background-position: -452px -4px;
}

.payment-logo__wechat-pay {
  background-position: -380px -4px;
}

@media print {
  .show-for-print {
    display: inherit !important;
  }
  .hide-for-print {
    display: none !important;
  }
}

.page-container {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
}

.site-header {
  z-index: 11;
}

@media screen and (max-width: 47.9375em) {
  .site-header {
    min-height: 60px;
  }
}

@media screen and (max-width: 47.9375em) {
  .site-header--fixed {
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    z-index: 21;
  }
}

.lane-crawford-logo {
  width: 8.375rem;
  height: 2.3125rem;
}

@media print, screen and (min-width: 48em) {
  .lane-crawford-logo {
    width: 14.75rem;
    height: 4rem;
  }
  .lane-crawford-logo--with-tagline {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 47.9375em) {
  .lane-crawford-logo {
    margin: 0.8125rem auto 0.25rem;
  }
}

.lane-crawford-logo__link {
  position: relative;
  z-index: 2;
}

.lane-crawford-logo__link, .lane-crawford-logo__image, .lane-crawford-logo__svg {
  width: 100%;
  height: 100%;
}

.iconbar {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
}

@media print, screen and (min-width: 48em) {
  .iconbar {
    top: 0.625rem;
    left: 0.9375rem;
    right: 0.9375rem;
  }
}

@media screen and (min-width: 75em) {
  .iconbar {
    left: 0;
    right: 0;
  }
}

@media screen and (max-width: 47.9375em) {
  .iconbar__item {
    position: absolute;
    width: 30px;
    height: 58px;
    right: 0;
    top: 0;
  }
}

@media print, screen and (min-width: 48em) {
  .iconbar__item {
    position: relative;
    float: left;
    height: 38px;
    line-height: 38px;
    width: auto;
  }
  .iconbar__item:not(.iconbar__item--last):before {
    content: "";
    position: absolute;
    right: -1px;
    height: 14px;
    width: 1px;
    background: #666;
    top: 12px;
    z-index: 1;
  }
}

.iconbar__item--notification:after {
  content: "";
  background: #a0783c;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1010;
}

.iconbar__item-inner {
  display: block;
  position: relative;
  background: #fff;
}

@media screen and (max-width: 47.9375em) {
  .iconbar__item-inner {
    width: 30px;
    height: 58px;
    top: 0;
    text-align: center;
    vertical-align: middle;
  }
}

@media print, screen and (min-width: 48em) {
  .iconbar__item-inner {
    height: 38px;
    padding: 0 10px 0 11px;
    border: 1px solid transparent;
    border-bottom: none;
    transition: border 0.28s cubic-bezier(0.08, 0.455, 0.375, 0.96);
  }
}

.iconbar__item.is-active {
  opacity: 1;
}

@media print, screen and (min-width: 48em) {
  .iconbar__item.is-active:before {
    display: none;
  }
  .iconbar__item.is-active .iconbar__item-inner {
    border-color: #ccc;
    z-index: 1009;
    transition: border 0.28s cubic-bezier(0.605, 0.065, 0.935, 0.55);
  }
}

.iconbar__bag-count {
  color: #fff;
  font-weight: bold;
  position: absolute;
}

.iconbar__bag-count[data-items="0"] {
  opacity: 0;
}

@media print, screen and (min-width: 48em) {
  .iconbar__company,
  .iconbar__user,
  .iconbar__site {
    position: absolute;
  }
  .iconbar__company,
  .iconbar__site {
    left: 0;
  }
  .iconbar__user {
    right: 0;
  }
  .iconbar__site {
    display: none;
  }
  .iconbar__bag-icon {
    position: relative;
    top: -2px;
  }
  .iconbar__bag-count {
    line-height: 36px;
    left: 2px;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    vertical-align: middle;
    text-shadow: 0 1px 0 #000, 1px 1px 0 #000, 1px 0 0 #000, 0 -1px 0 #000, -1px -1px 0 #000, -1px 0 0 #000;
    transition: transform 0.4s cubic-bezier(0.32, 0.05, 0.66, 1), opacity 0.4s cubic-bezier(0.32, 0.05, 0.66, 1);
  }
  .anim-out .iconbar__bag-count {
    opacity: 0;
    transform: translateY(-20px);
  }
  .anim-in .iconbar__bag-count {
    opacity: 0;
    transform: translateY(20px);
  }
  .no-anim .iconbar__bag-count {
    transition: none;
  }
  .iconbar__item--giftlist .iconbar__item-inner {
    width: 40px;
  }
}

.iconbar__giftlist-icon {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}

.iconbar__giftlist-icon--fill {
  opacity: 0;
  transform: translate(-50%, -70%);
  transition: transform 0.035s linear 0.4s, opacity 0.3s ease-in;
}

.iconbar__giftlist-icon--fill.filled {
  opacity: 1;
  transform: translate(-50%, -50%);
  transition: transform 0.3s cubic-bezier(0.405, 2.6, 0.55, 0.995), opacity 0.35s ease-out;
}

@media screen and (max-width: 47.9375em) {
  .iconbar__bag-icon,
  .iconbar__giftlist-icon,
  .iconbar__hamburger-icon,
  .iconbar__search-icon {
    font-size: 24px;
    height: 58px;
    line-height: 58px;
    width: 30px;
    padding: 0;
  }
  .iconbar__bag-icon,
  .iconbar__hamburger-icon,
  .iconbar__search-icon {
    top: 0;
    left: 0;
    position: absolute;
  }
  .iconbar__bag-icon,
  .iconbar__hamburger-icon {
    width: 40px;
  }
  .iconbar__bag-icon {
    font-size: 26px;
    width: 40px;
  }
  .iconbar__bag-icon[data-icon]:before {
    top: -3px;
  }
  .iconbar__bag-count {
    font-size: 14px;
    line-height: 58px;
    left: 0;
    top: 2px;
    width: 40px;
    height: 58px;
    vertical-align: middle;
    text-align: center;
  }
  .iconbar__giftlist-icon {
    font-size: 26px;
    top: 29px;
  }
  .iconbar__hamburger-icon {
    font-size: 21px;
  }
  .iconbar__search-icon {
    font-size: 20px;
  }
  .iconbar__item--giftlist {
    right: 41px;
  }
  .iconbar__item--hamburger {
    right: auto;
    left: 0;
    width: 40px;
  }
  .iconbar__item--hamburger .iconbar__item-inner {
    width: 40px;
  }
  .iconbar__item--bag {
    width: 40px;
    right: 1px;
  }
  .iconbar__item--bag .iconbar__item-inner {
    width: 40px;
  }
  .iconbar__item--search {
    right: auto;
    left: 40px;
  }
  .iconbar .iconbar__item--sign-in,
  .iconbar .iconbar__item--register,
  .iconbar .iconbar__item--myaccount,
  .iconbar .iconbar__item--stores,
  .iconbar .iconbar__item--phone {
    display: none;
  }
}

@media print, screen and (min-width: 48em) {
  .header-sign-in {
    z-index: 51;
    width: 300px;
    transform: translateY(-1px);
    display: none;
  }
}

.flyout-account__content {
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  perspective: 1000;
  will-change: height;
  transition: height 0.35s ease;
}

.account-title__arrow {
  position: absolute;
  right: 8px;
  top: 50%;
  margin-top: -9px;
  font-size: 14px;
  line-height: 18px;
  height: 18px;
  transition: transform 0.2s ease;
}

.flyout-account__header.is-open .account-title__arrow {
  transform: rotate(180deg);
}

@media print, screen and (min-width: 48em) {
  .header-my-account {
    z-index: 51;
    width: 240px;
    transform: translateY(-1px);
    display: none;
  }
}

.minicart {
  display: none;
}

@media print, screen and (min-width: 48em) {
  .minicart {
    display: block;
    width: 340px;
    font-size: 11px;
    z-index: 51;
    transform: translateY(-1px);
    box-shadow: 0 2px 8px -3px #666;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0.05s linear 0.28s, opacity 0.28s cubic-bezier(0.08, 0.455, 0.375, 0.96);
  }
  .minicart.is-open {
    opacity: 1;
    visibility: visible;
    transition: visibility 0.05s linear, opacity 0.28s cubic-bezier(0.605, 0.065, 0.935, 0.55) 0.05s;
  }
  .minicart__items {
    min-height: 20px;
    max-height: 400px;
    overflow-y: hidden;
    text-transform: none;
    margin-left: -10px;
    margin-right: -10px;
    transition: height 0.3s ease, max-height 0.3s ease, border 0.3s ease;
  }
  .minicart.is-open .minicart__items {
    overflow-y: auto;
  }
  .minicart__items--error {
    border-top: 2px solid #ca0814;
    border-bottom: 2px solid #ca0814;
  }
  .minicart__items--scroll {
    max-height: 260px;
  }
  .minicart__scrolly-top,
  .minicart__scrolly-bottom {
    height: 15px;
    left: 12px;
    right: 12px;
    position: absolute;
    z-index: 1;
    background: linear-gradient(0deg, transparent 0%, rgba(0, 0, 0, 0.03) 50%, rgba(0, 0, 0, 0.1) 100%);
  }
  .minicart__scrolly-bottom {
    margin-top: -16px;
    background: linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 0.03) 50%, rgba(0, 0, 0, 0.1) 100%);
  }
  .minicart__message {
    padding: 1.2em 3.4em;
    text-align: center;
    position: relative;
    line-height: 1.4;
  }
  .minicart__message a {
    text-decoration: underline;
  }
  .minicart__message p {
    margin: 0;
  }
  .minicart__message--not-available {
    color: #ca0814;
    border-bottom: 1px solid;
  }
  .minicart__message--upper {
    text-transform: uppercase;
  }
  .minicart__remove-icon {
    position: absolute;
    top: 5px;
    right: 0;
    cursor: pointer;
    color: black;
  }
  .minicart__header {
    overflow: hidden;
    margin: 0;
    padding-bottom: 7px;
    border-bottom: 1px solid #666;
  }
  .minicart__footer {
    padding: 0;
    border: none;
    border-top: 1px solid #666;
  }
  .minicart-header__item-count,
  .minicart-header__total,
  .minicart-footer-row__title,
  .minicart-footer-row__value {
    width: 50%;
    float: left;
  }
  .minicart-header__total,
  .minicart-footer-row__value {
    text-align: right;
  }
  .minicart-footer-row__title,
  .minicart-footer-row__value {
    font-size: 1.2em;
    line-height: 33px;
    vertical-align: middle;
    padding-top: 1px;
  }
  .minicart-footer-row__title {
    width: 65%;
  }
  .minicart-footer-row__value {
    width: 35%;
    font-weight: bold;
    font-size: 1.4em;
  }
  .minicart-footer__row {
    overflow: hidden;
    border-top: 1px solid #ccc;
  }
  .minicart-footer__row:first-child {
    border: none;
  }
  .minicart-footer__button {
    width: 48%;
    min-width: 0;
    float: left;
  }
  .minicart-footer__button .icon {
    position: relative;
    top: -2px;
    right: -5px;
    font-size: 14px;
  }
  .minicart-footer__button:last-child {
    float: right;
  }
  .minicart-footer__row--ddi .minicart-footer-row__value {
    font-family: "Lane Crawford", 微软雅黑体, "Microsoft YaHei", 华文细黑, "STXihei", 华文黑体, "STHeiti", 冬青黑体, "Hiragino Sans GB", sans-serif;
    text-transform: none;
    letter-spacing: normal;
    width: 100%;
    font-weight: normal;
    font-size: 14px;
    text-align: right;
    display: block;
  }
}

@media print, screen and (min-width: 48em) {
  .minicart-item {
    position: relative;
    padding: 10px;
    overflow: hidden;
    transition: background-color 3s ease-in;
  }
  .minicart-item:not(:last-of-type):after {
    content: "";
    position: absolute;
    left: 10px;
    right: 10px;
    bottom: 0;
    height: 1px;
    background: black;
  }
  .minicart-item--highlight {
    background-color: #ccc;
    transition: background-color 0.2s ease-out;
  }
  .minicart-item__image,
  .minicart-item__details {
    float: left;
  }
  .minicart-item__image {
    width: 20%;
  }
  .minicart-item__details {
    width: 80%;
    padding-left: 1em;
  }
  .minicart-item__brand {
    max-width: 200px;
  }
  .minicart-item__description {
    max-width: 90%;
  }
  .minicart-item__meta-row {
    margin-top: 1em;
    min-height: 2em;
  }
  .minicart-item__meta-row--sale {
    margin-top: 2.4em;
  }
  .minicart-item__size,
  .minicart-item__quantity {
    text-transform: uppercase;
  }
  .minicart-item__size {
    float: left;
  }
  .minicart-item__price {
    text-transform: uppercase;
  }
  .minicart-item__sale-price {
    margin-top: -1.2em;
  }
  .minicart-item__flag {
    font-size: 10px;
    line-height: 19px;
  }
  .minicart-item__remove-icon {
    position: absolute;
    right: 0;
    top: 0.4em;
    padding: 3px 10px;
    cursor: pointer;
  }
  .minicart__items--scroll .minicart-item__remove-icon {
    right: 2px;
  }
  .minicart-item__remove-icon.disabled {
    background: none;
    border: none;
    opacity: 0.3;
  }
  .minicart-item__spinner {
    text-align: center;
    padding: 1.5em 0;
    opacity: 0.5;
    transition: opacity 0.3s ease;
  }
  .minicart-item__price,
  .minicart-item__quantity {
    position: absolute;
    right: 10px;
  }
  .minicart__items--scroll .minicart-item__price, .minicart__items--scroll
  .minicart-item__quantity {
    right: 14px;
  }
}

.header-search {
  height: 46px;
  position: absolute;
  bottom: -47px;
  top: auto;
  left: 0;
  right: 0;
  background: #fff;
  text-align: center;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-in 0, visibility 0.05s linear 0.3s;
}

@media screen and (max-width: 47.9375em) {
  .header-search:after {
    position: absolute;
    content: "";
    width: auto;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background: #000;
  }
}

@media print, screen and (min-width: 48em) {
  .header-search {
    bottom: -46px;
    border-bottom: 1px solid #000;
  }
}

.header-search.is-open {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s ease-out 0.05s, visibility 0 linear 0;
}

.header-search__close-icon {
  width: 30px;
  height: 30px;
  line-height: 32px;
  border-radius: 30px;
  position: absolute;
  right: 10px;
  top: 7px;
  cursor: pointer;
  transition: background-color 0.2s ease-out;
}

@media print, screen and (min-width: 48em) {
  .header-search__close-icon {
    right: 15px;
  }
}

.header-search__close-icon.is-clear {
  background-color: #ccc;
}

.header-search__input {
  font-family: "Lane Crawford", 微软雅黑体, "Microsoft YaHei", 华文细黑, "STXihei", 华文黑体, "STHeiti", 冬青黑体, "Hiragino Sans GB", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.0375rem;
  border: none;
  position: absolute;
  left: 11%;
  width: 78%;
  height: 100%;
  text-align: center;
  font-size: 16px;
}

.header-search__input:focus {
  outline: none;
}

.navigation__search-icon {
  display: none;
}

@media print, screen and (min-width: 48em) {
  .navigation__search-icon {
    display: block;
    font-size: 16px;
    width: 36px;
    text-align: center;
    position: absolute;
    right: 12px;
    top: auto;
    bottom: 7px;
    cursor: pointer;
    z-index: 17;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
}

@media screen and (min-width: 75em) {
  .navigation__search-icon {
    right: 4px;
  }
}

.ajax-search {
  display: none;
}

.megamenu {
  font-size: 14px;
}

@media screen and (max-width: 47.9375em) {
  .navigation > .megamenu {
    display: none;
  }
}

html.nav-open,
html.nav-open body {
  overflow: hidden;
}

.megamenu__wrapper {
  position: static;
}

.megamenu__bu {
  z-index: 15;
}

.megamenu__item-item--image .megamenu__item-link {
  position: relative;
}

.megamenu__close-icon {
  display: none;
}

@media screen and (max-width: 47.9375em) {
  .megamenu__bu-item,
  .megamenu__section-item,
  .megamenu__item-item {
    display: block;
    position: relative;
    font-size: 18px;
    text-align: center;
    border-bottom: 1px solid #000;
  }
  .megamenu__bu-item:last-of-type,
  .megamenu__item-item:last-of-type,
  .megamenu__column:last-of-type .megamenu__section:last-of-type .megamenu__section-item {
    border-bottom: none;
  }
  .megamenu__bu-link,
  .megamenu__section-link,
  .megamenu__item-link {
    display: block;
    padding: 10px;
    line-height: 26px;
    font-weight: normal;
    min-height: 46px;
  }
  .megamenu__bu-link:after,
  .megamenu__section-link:after,
  .megamenu__item-link:after {
    content: "";
    position: absolute;
    right: 5px;
    top: 0;
    width: 20px;
    height: 48px;
    line-height: 48px;
    font-family: "lc-icons";
    font-size: 14px;
  }
  .megamenu__item-link:after {
    display: none;
  }
  .megamenu__bu-container,
  .megamenu__item {
    background: #fff;
    box-shadow: 1px 0 0 0 black;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 16;
    will-change: transform, overflow, visibility;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    visibility: hidden;
    transform: translate3d(-101%, 0, 0);
    transition: transform 0.28s cubic-bezier(0.605, 0.065, 0.935, 0.55), visibility 0.01s linear 0.31s;
    box-shadow: none;
    padding: 60px 10px 0;
    opacity: 1;
    transform: translate3d(201%, 0, 0);
  }
  .megamenu__bu-container.is-open,
  .megamenu__item.is-open {
    z-index: 17;
    visibility: visible;
    transform: translate3d(0%, 0, 0);
    transition: visibility 0.01s linear, transform 0.28s cubic-bezier(0.08, 0.455, 0.375, 0.96) 0.03s;
  }
  .megamenu__bu-container.is-locked,
  .megamenu__item.is-locked {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }
  .megamenu__item-item--image .megamenu__item-link {
    padding: 0;
  }
  .megamenu__section-bar,
  .megamenu__section-seeall {
    display: none;
  }
  .megamenu__bu-item[data-show-on-mobile=false],
  .megamenu__section[data-show-on-mobile=false],
  .megamenu__item-item[data-show-on-mobile=false] {
    display: none;
  }
}

@media print, screen and (min-width: 48em) {
  .megamenu__wrapper {
    position: relative;
  }
  .megamenu__bu {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
  }
  .megamenu.is-active .megamenu__bu {
    margin-left: 0;
    margin-right: 0;
  }
  .megamenu__bu-item {
    display: inline-block;
    border-bottom: 3px solid transparent;
    position: relative;
    top: 2px;
    padding: 0;
    margin-left: -3px;
    transition: border 0.15s cubic-bezier(0.32, 0.05, 0.66, 1);
  }
  .megamenu__bu-item[data-bu*=sale] .megamenu__bu-link {
    background: var(--sale-color, #fee000);
  }
  .megamenu__bu-item.is-active, .megamenu__bu-item.is-temp, .megamenu__bu-item:hover {
    border-bottom-color: #000;
  }
  .megamenu__bu-item.is-temp, .megamenu__bu-item:hover {
    font-weight: bold;
  }
  .megamenu__bu-link {
    display: block;
    padding: 0.4em 1.2em;
  }
  .megamenu__bu-container {
    background: #fff;
    position: absolute;
    right: -0.9375rem;
    left: -0.9375rem;
    text-align: left;
    font-size: 0.6875rem;
    line-height: 1.9;
    padding: 0.9375rem 0.9375rem 0;
    opacity: 0;
    visibility: hidden;
    z-index: 10;
    transition: visibility 0.05s linear 0.28s, opacity 0.28s cubic-bezier(0.08, 0.455, 0.375, 0.96);
  }
}

@media print, screen and (min-width: 48em) and (min-width: 48em) and (max-width: 63.9375em) {
  .megamenu__bu-container {
    padding-right: 3em;
  }
}

@media print, screen and (min-width: 48em) {
  .megamenu__bu-container.is-active {
    opacity: 1;
    visibility: visible;
    transition: visibility 0.05s linear, opacity 0.28s cubic-bezier(0.605, 0.065, 0.935, 0.55) 0.05s;
  }
  .megamenu__column {
    width: 20%;
    float: left;
  }
}

@media print, screen and (min-width: 48em) and (min-width: 48em) and (max-width: 63.9375em) {
  .megamenu__column {
    width: 25%;
  }
}

@media print, screen and (min-width: 48em) {
  .megamenu__section {
    margin-bottom: 2em;
  }
  .megamenu__section-link,
  .megamenu__item-link {
    display: block;
    padding-left: 8px;
    border-left: 3px solid transparent;
    transition: border 0.18s cubic-bezier(0.32, 0.05, 0.66, 1), color 0.18s cubic-bezier(0.32, 0.05, 0.66, 1);
  }
  .megamenu__item-link {
    padding-top: 4px;
    padding-bottom: 4px;
    line-height: 1.2;
  }
  .megamenu__section-link:hover,
  .megamenu__item-link:hover {
    color: #999;
  }
  .megamenu__section-link.is-active, .megamenu__section-link:active, .megamenu__section-link:focus,
  .megamenu__item-link.is-active,
  .megamenu__item-link:active,
  .megamenu__item-link:focus {
    border-left-color: #000;
  }
  .megamenu__section-link.is-active:hover, .megamenu__section-link:active:hover, .megamenu__section-link:focus:hover,
  .megamenu__item-link.is-active:hover,
  .megamenu__item-link:active:hover,
  .megamenu__item-link:focus:hover {
    color: #000;
  }
  .megamenu__section-link .megamenu__section-seeall,
  .megamenu__item-link .megamenu__section-seeall {
    color: #999;
  }
  .megamenu__section-link .megamenu__section-bar,
  .megamenu__item-link .megamenu__section-bar {
    color: #000;
  }
  .megamenu__item-item--image {
    margin: 0;
    max-height: 10rem;
  }
  .megamenu__item-item--image .megamenu__item-title {
    display: none;
  }
  .megamenu__item-item--image .megamenu__item-link {
    padding: 0 0 0 8px;
  }
  .megamenu__item-item--image .megamenu__item-link img {
    max-width: 10rem;
    max-height: 10rem;
  }
  .megamenu__item-item--image .megamenu__item-link.is-active, .megamenu__item-item--image .megamenu__item-link:active, .megamenu__item-item--image .megamenu__item-link:focus {
    border-left-color: transparent;
  }
  .megamenu__section-link {
    font-weight: bold;
  }
  .megamenu__section-bar {
    display: inline-block;
    margin: 0 5px;
  }
  .megamenu__section-seeall {
    font-weight: normal;
  }
  .megamenu-overlay {
    position: fixed;
    background: #2b2b2b;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0.05s linear 0.28s, opacity 0.28s cubic-bezier(0.08, 0.455, 0.375, 0.96);
  }
  .megamenu-overlay.is-active {
    opacity: 0.7;
    visibility: visible;
    transition: visibility 0.05s linear, opacity 0.28s cubic-bezier(0.605, 0.065, 0.935, 0.55) 0.05s;
  }
  .megamenu-fake-overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: none;
    z-index: 14;
  }
  .megamenu-fake-overlay.is-active {
    display: block;
  }
  .megamenu__bu-item[data-show-on-desktop=false],
  .megamenu__section[data-show-on-desktop=false],
  .megamenu__item-item[data-show-on-desktop=false] {
    display: none;
  }
  .megamenu__bu-container .megamenu__section-link[href=""],
  .megamenu__bu-container .megamenu__item-link[href=""] {
    cursor: text;
  }
  .megamenu__bu-container .megamenu__section-link[href=""].is-active,
  .megamenu__bu-container .megamenu__section-link[href=""]:active,
  .megamenu__bu-container .megamenu__section-link[href=""]:focus,
  .megamenu__bu-container .megamenu__item-link[href=""].is-active,
  .megamenu__bu-container .megamenu__item-link[href=""]:active,
  .megamenu__bu-container .megamenu__item-link[href=""]:focus {
    border-left-color: transparent;
  }
}

@media print, screen and (min-width: 48em) and (max-width: 63.9375em) {
  .megamenu__close-icon {
    width: 36px;
    display: block;
  }
}

html,
body {
  will-change: overflow;
}

.mobile-flyout {
  background: #fff;
  box-shadow: 1px 0 0 0 black;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 16;
  will-change: transform, overflow, visibility;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  visibility: hidden;
  transform: translate3d(-101%, 0, 0);
  transition: transform 0.15s cubic-bezier(0.605, 0.065, 0.935, 0.55), visibility 0.01s linear 0.31s;
  padding: 0 10px;
}

@media print, screen and (min-width: 48em) {
  .mobile-flyout {
    display: none;
  }
}

.mobile-flyout.is-open {
  z-index: 17;
  visibility: visible;
  transform: translate3d(0%, 0, 0);
  transition: visibility 0.01s linear, transform 0.15s cubic-bezier(0.08, 0.455, 0.375, 0.96) 0.03s;
}

.mobile-flyout.is-locked {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

@media screen and (max-width: 47.9375em) {
  .flyoutbar {
    position: relative;
    height: 59px;
    z-index: 30;
    margin: 0 -10px;
    background: white;
  }
  .flyoutbar:after {
    content: "";
    position: absolute;
    background-color: black;
    bottom: -1px;
    height: 3px;
    width: auto;
    left: 10px;
    right: 10px;
  }
  .flyoutbar__header {
    font-size: 24px;
    padding: 0;
    line-height: 60px;
    text-align: center;
    transition: transform 0.15s cubic-bezier(0.08, 0.455, 0.375, 0.96), opacity 0.15s cubic-bezier(0.08, 0.455, 0.375, 0.96);
    text-overflow: ellipsis;
    width: 70%;
    overflow: hidden;
    white-space: nowrap;
    margin: auto;
  }
  .flyoutbar__header.anim-out {
    transition: transform 0.15s cubic-bezier(0.605, 0.065, 0.935, 0.55), opacity 0.15s cubic-bezier(0.605, 0.065, 0.935, 0.55);
    transform: translateX(-30px);
    opacity: 0;
  }
  .flyoutbar__header.anim-out.reverse {
    transform: translateX(30px);
  }
  .flyoutbar__header.anim-in {
    transition: none;
    transform: translateX(30px);
    opacity: 0;
  }
  .flyoutbar__header.anim-in.reverse {
    transform: translateX(-30px);
  }
  .flyoutbar__list {
    position: absolute;
    left: 10px;
    right: 10px;
    top: 0;
  }
  .flyoutbar__item {
    border: none;
    position: absolute;
    width: 50px;
    height: 60px;
    right: -10px;
    top: 0;
  }
  .flyoutbar__item--back {
    right: auto;
    left: -10px;
  }
  .flyoutbar__item-inner {
    top: 0;
    padding: 0;
    width: 50px;
    height: 60px;
    line-height: 42px;
    text-align: center;
    display: block;
    vertical-align: middle;
  }
  .flyoutbar__back-icon,
  .flyoutbar__hamburger-icon {
    position: relative;
    top: 0;
    padding: 0;
  }
  .flyoutbar__back-text,
  .flyoutbar__hamburger-text {
    display: none;
  }
  .flyoutbar__hamburger-icon {
    font-size: 21px;
    top: 8px;
  }
  .flyoutbar__back-icon {
    font-size: 26px;
    top: 7px;
  }
  .flyoutbar__back-icon:before {
    font-weight: bold;
  }
}

.follow-us .lc-icon {
  min-width: 2rem;
}

@media print, screen and (min-width: 48em) {
  .footer__info {
    display: flex;
    justify-content: space-evenly;
  }
}

@media print, screen and (min-width: 48em) {
  .country-change-en__btn,
  .newsletter__subscribe-btn {
    width: 380px;
  }
}

.country-change-en__btn {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.country-change__country .lc-field__select-label,
.country-change__language .lc-field__select-label {
  border-color: black;
}

@media print {
  .desktop-needhelp__email:after {
    content: "customercare@lanecrawford.com";
  }
  body[data-site-id="zh_CN"] .desktop-needhelp__email:after {
    content: "customercare@lanecrawford.com.cn";
  }
}

.black-bar-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  z-index: 1;
  -webkit-font-smoothing: subpixel-antialiased;
}

.black-bar {
  position: relative;
  line-height: 1.2;
  letter-spacing: 0.1em;
  padding: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.black-bar a {
  text-decoration: none;
}

.black-bar a:hover {
  opacity: 0.7;
  transition-duration: 0.5s;
}

.consent-landing-wrapper {
  max-width: 48rem;
}

.pipl-consent-dialog,
.pipl-thankyou-dialog {
  padding: 10px 40px 0;
}

.pipl-consent-dialog__header h2,
.pipl-thankyou-dialog__header h2 {
  font-weight: bold;
  margin: 2rem 0 1rem;
}

@media print {
  .site-header .search,
  .site-header .megamenu,
  .site-header .navigation__search-icon,
  .site-header .iconbar__item:not(.iconbar__item--phone),
  .lc-footer .follow-us,
  .lc-footer .newsletter,
  .lc-footer .country-change {
    display: none;
  }
  #lane-crawford-logo {
    margin-bottom: 20px;
  }
}
