
.row.flush {

	margin-left: rem-calc( -10px );
	margin-right: rem-calc( -10px );

}

// mixin to be included for all the breakpoints

@mixin lc-grid-auto( $bp: "" ) {

	.#{$bp}auto {

		width: auto;

	}

}

// output styles in to breakpoints


@each $size in $breakpoint-classes {

	@include breakpoint($size) {

		@include lc-grid-auto( $size + "-" );

	}

}

