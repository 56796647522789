
$overlay-index: 12;

@mixin dialog {

	top: 1rem;
	position: absolute;
	z-index: $overlay-index + 1;

	background: $white;
	outline: none;

}

@mixin fixed-dialog {

	@include breakpoint( tb ) {

		width: 44rem;
		margin: auto;

	}

	@include breakpoint( 54em ) {

		width: auto;
		margin: auto 8vw;

	}

	@include breakpoint( 60em ) {

		width: 48rem;
		margin: auto;

	}

}

@mixin fixed-dialog-with-width {

	width: auto;
	margin: auto 0.5rem;

	@include breakpoint( mb only ) {

		width: auto !important;
		margin: auto;

	}

	@include breakpoint( tb ) {

		width: auto;
		margin: auto;

	}

	@include breakpoint( 54em ) {

		width: auto;
		margin: auto;

	}

	@include breakpoint( 60em ) {

		width: auto;
		margin: auto;

	}

}

@mixin full-dialog {

	left: 0.5rem;
	right: 0.5rem;
	transform: none;
	min-width: auto;
	max-width: none;

}

// code to handle the web-view
// fixed-position fkup in iOS 10.1.1
@mixin opacity-hack {

	animation: opacity-hack 20s ease infinite $fade-time;

}

@keyframes opacity-hack {

	0% { opacity: 1; }
	100% { opacity: 1; }

}



.modal-bg,
.ui-widget-overlay {

	background: rgba( $black, 0.8 );
	z-index: $overlay-index;

	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: fixed;

}

.ui-widget-preloader {

	z-index: 9999;

}

.ui-dialog {

	@include dialog;
	@include full-dialog;

	// if not full, then fixed on tablets+
	&--fixed {

		@include fixed-dialog;

	}

	&--with-width {

		@include fixed-dialog-with-width;

	}

	&--full {

		top: 10px !important;
		left: 10px;
		right: 10px;
		position: absolute;

	}

	html.wechat-inapp & {

		@include opacity-hack;

	}

	&-titlebar {

		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		height: 1px;

	}

	&-titlebar-close {

		top: 0;
		right: 0;
		position: absolute;
		z-index: 1;
		padding: 1rem;

	}

}
