

@mixin lc-field-labels( $bp: "" ) {

	.lc-field__label {

		font-size: rem-calc( 12px );
		letter-spacing: 0.05em;
		text-transform: uppercase;

		display: block;
		margin-bottom: rem-calc( 5px );

		&--new {

			color: $very-dark;
			font-family: $font-lc;
			font-size: 1em;
			letter-spacing: 0.025em;
			text-transform: inherit;

			margin-bottom: rem-calc( 2px );

		}

	}

}

@mixin lc-field-helps( $bp: "" ) {

	.lc-field__help {

		color: $dark-grey;
		font-family: $font-lc;
		font-size: rem-calc( 14px );
		letter-spacing: 0.025em;
		text-transform: none;

		display: block;
		padding-top: rem-calc( 2px );
		padding-bottom: rem-calc( 2px );

	}

}

@include lc-field-labels;
@include lc-field-helps;
