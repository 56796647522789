
$w-mb: rem-calc( 134px );
$h-mb: rem-calc( 37px );

$w-tb: rem-calc( 236px );
$h-tb: rem-calc( 64px );



.lane-crawford-logo {

	width: $w-mb;
	height: $h-mb;

	@include breakpoint( tb ) {

		width: $w-tb;
		height: $h-tb;

		&--with-tagline {

			margin-bottom: 25px;

		}

	}

	@include breakpoint( mb only ) {

		margin: rem-calc( 13px ) auto rem-calc( 4px );

	}

	&__link {

		position: relative;
		z-index: 2;

	}

	&__link,
	&__image,
	&__svg {

		width: 100%;
		height: 100%;

	}

}

