
.lc-help-popup,
.lc-help-panel {

	font-size: 14px;
	background: $very-light;

	padding: rem-calc( 10px );
	display: none;

}
