
// mixin to be included for all the breakpoints

@mixin lc-margins( $bp: "" ) {


	@each $pos in $spacing {

		.#{$bp}mar-#{$pos} {

			margin: rem-calc( $pos );

		}

	}

	@each $pos in $spacing {

		.#{$bp}mar-v#{$pos} {

			margin-top: rem-calc( $pos );
			margin-bottom: rem-calc( $pos );

		}

		.#{$bp}mar-h#{$pos} {

			margin-left: rem-calc( $pos );
			margin-right: rem-calc( $pos );

		}

	}

	@each $pos in $spacing {

		.#{$bp}mar-l#{$pos} {

			margin-left: rem-calc( $pos );

		}

		.#{$bp}mar-r#{$pos} {

			margin-right: rem-calc( $pos );

		}

		.#{$bp}mar-t#{$pos} {

			margin-top: rem-calc( $pos );

		}

		.#{$bp}mar-b#{$pos} {

			margin-bottom: rem-calc( $pos );

		}

	}

	.#{$bp}mar-a {

		margin: auto;

	}

	.#{$bp}mar-va {

		margin-top: auto;
		margin-bottom: auto;

	}

	.#{$bp}mar-ha {

		margin-left: auto;
		margin-right: auto;

	}

}



// output styles in to breakpoints

@include lc-margins;

@each $size in $breakpoint-classes {

	@include breakpoint($size) {

		@if $size != "mb" {

			@include lc-margins( $size + "-" );

		}

	}

}

//
//	commenting out, as I'm not really sure these
//	are valuable, and they add a bit of weight.
//
//
//
//	@include breakpoint( mb only ) {
//
//		@include lc-margins( "mb-only-" );
//
//	}
//
//	@include breakpoint( tb only ) {
//
//		@include lc-margins( "tb-only-" );
//
//	}
//
