
@media print {

	.site-header .search,
	.site-header .megamenu,
	.site-header .navigation__search-icon,
	.site-header .iconbar__item:not( .iconbar__item--phone ),
	.lc-footer .follow-us,
	.lc-footer .newsletter,
	.lc-footer .country-change {

		display: none;

	}

	#lane-crawford-logo {

		margin-bottom: 20px;

	}

}
