
$link-default: inherit;


%link {

	text-decoration: none;
	outline: none;
	transition: opacity 0.2s ease;

}

%link-active {

	opacity: 0.5;

}

%link-btn-active {

	opacity: 0.7;

}

a {

	@extend %link;

	color: $link-default;

	&:not( [ class ] ) {

		text-decoration: underline;

		&:hover,
		&:focus,
		&:active {

			@extend %link-active;

		}

	}

}

.link {

	@extend %link;

	&:hover,
	&:focus,
	&:active {

		@extend %link-active;

		&[ class *= "btn" ]:not( .btn--alt ) {

			@extend %link-btn-active;

		}

	}

}
