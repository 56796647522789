

$opacity-scale: (
 	"100": 1,
 	"90": 0.9,
 	"80": 0.8,
 	"70": 0.7,
 	"60": 0.6,
 	"50": 0.5,
 	"40": 0.4,
 	"30": 0.3,
 	"20": 0.2,
 	"10": 0.1,
 	"0": 0
);



@each $op in $opacity-scale {

	.opc-#{ nth( $op, 1 ) } {

		opacity: nth( $op, 2 );

	}

}
