// some modifiers for animation times.

.an-ease-in,
.an-ease-in.go {

	transition-timing-function: $lc-ease-in;
	animation-timing-function: $lc-ease-in;

}

.an-ease-out,
.an-ease-out.go {

	transition-timing-function: $lc-ease-out;
	animation-timing-function: $lc-ease-out;

}

.an-ease,
.an-ease.go {

	transition-timing-function: $lc-ease;
	animation-timing-function: $lc-ease;

}

.an-ease-bounce,
.an-ease-bounce.go {

	transition-timing-function: $lc-bounce-in;
	animation-timing-function: $lc-bounce-in;

}
