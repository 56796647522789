

.product-brick {

	user-select: none;

	&__hover {

		// rollover image
		opacity: 0;

	}

	&__heart,
	&__chips,
	&__icon {

		z-index: 2;

	}

	&__chips {

		min-height: 30px;

	}

	@include breakpoint( tb ) {

		&__name {

			// special height adjustment so that
			// products in a grid have prices and
			// flags aligned for the most part.
			min-height: 2rem;

		}

		&__link {

			.ie9 & {

				background-color: $ie9-transparent;

			}

		}

		&__index,
		&__hover {

			transition-property: opacity;
			transition-duration: 0.25s;
			transition-timing-function: $lc-ease-subtle;

			// transform is to stop gpu layer-flickering
			// when animating between opaque/transparent values
			transform: translate3d( 0, 0, 0 );

		}

		&--hover:hover,
		&--hover:focus {

			.product-brick__index:not( [ src *= "coming_soon" ] ) {

				// index image
				opacity: 0.95;

			}

			.product-brick__hover:not( [ src *= "coming_soon" ] ) {

				// rollover image
				opacity: 1;

			}

		}

	}

	&--placeholder {

		.product-brick__images {

			background-image: linear-gradient( 45deg, transparent 30%, rgba( 143, 141, 141, 0.2 ) 50%, transparent 70% );
			background-size: 800%;
			animation: productplaceholder 3s ease-out infinite;
			background-repeat: no-repeat;

		}

	}

}

@keyframes productplaceholder {

	0% {

		background-position: 0% 0%;

	}

	100% {

		background-position: 100% 100%;

	}

}
