
// easing information for the menu
$ease-in: $lc-ease-in;
$ease-out: $lc-ease-out;
$ease-time: 0.3s;

@mixin off-screen-panel() {

	background: $white;
	box-shadow: 1px 0 0 0 black;

	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;

	z-index: 16;
	will-change: transform, overflow, visibility;

	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;

	visibility: hidden;
	transform: translate3d( -101%, 0, 0 );
	transition:
		 transform $ease-time $ease-in,
		visibility 0.01s linear 0.31s;

}

@mixin off-screen-panel-open() {

	z-index: 17;
	visibility: visible;
	transform: translate3d( 0%, 0, 0 );
	transition:
	   visibility 0.01s linear,
		transform $ease-time $ease-out 0.03s;

}
