
$table-border-color: $black;
$cell-padding: rem-calc( 5px );


@mixin lc-table( $bp: "" ) {

	.lc-table {

		th,
		td {

			padding: $cell-padding;

		}

		th:not( :nth-last-of-type( 1 ) ),
		td:not( :nth-last-of-type( 1 ) ) {

			border-right: 1px dotted rgba( $table-border-color, 0.6 );

		}

		thead {

			th,
			td {

				border-bottom: 2px solid $table-border-color;

			}

		}

		tbody tr:nth-of-type( 1 ) th,
		tbody tr:nth-of-type( 1 ) td {

			border-top: 2px solid $table-border-color;

		}

		thead + tbody tr:nth-of-type( 1 ) th,
		thead + tbody tr:nth-of-type( 1 ) td {

			border-top: none;

		}

		tr:not( :nth-last-of-type( 1 ) ) th,
		tr:not( :nth-last-of-type( 1 ) ) td {

			border-bottom: 1px solid rgba( $table-border-color, 0.3 );

		}

	}

}



@include lc-table;
